import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { loginWithMetamask, loginWithWalletConnect } from "../api";
import { ThemeContext, UserContext } from "../App";
import { LoginWithWalletResponse } from "../types";
import { getWalletConnectAccounts, initWalletConnect } from "../walletConnect";
import BnDialog from "./BnDialog";

type WalletLoginProps = {
  onError: (e: string) => void;
};

type Wallet = {
  name: string;
  desc: string;
  auth: (w: Wallet) => any;
  installation_page?: string;
};

export default function WalletLogin({ onError }: WalletLoginProps) {
  const theme = useContext(ThemeContext);
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const { setUser } = useContext(UserContext);

  const wallets: Wallet[] = [
    {
      name: "MetaMask",
      desc: "Connect to your MetaMask Wallet",
      installation_page: "https://metamask.io/download/",
      auth: handleMetaMask,
    },
    {
      name: "WalletConnect",
      desc: "Choose your preferred wallet available in WalletConnect",
      auth: handleWalletConnect,
    },
  ];

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  async function handleLoginWithWallet(
    loginFn: () => Promise<LoginWithWalletResponse | undefined>,
    wallet: string,
  ) {
    try {
      const { primary_address, ...user } = (await loginFn())!;
      setUser({
        address: primary_address,
        full_access: true,
        has_wallet: true,
        type: wallet,
        ...user,
      });
      history.push("/overview");
    } catch (e: any) {
      const errors = e.response?.data?.non_field_errors ?? [];
      onError(errors.join(" "));
    }
  }

  function handleMetaMask(w: Wallet) {
    setIsOpen(false);

    if (!window.ethereum || !window.ethereum.isMetaMask) {
      // User has no MetaMask
      alert("Please download MetaMask and refresh this page.");
      window.open(w.installation_page, "_blank");
      return;
    }

    handleLoginWithWallet(loginWithMetamask, "METAMASK");
  }

  function handleWalletConnect(w: Wallet) {
    setIsOpen(false);

    const onConnect = () => {
      getWalletConnectAccounts(); // TODO: Does nothing should be removed...
      document.removeEventListener(
        "Basenode:walletconnectConnected",
        onConnect,
      );
      handleLoginWithWallet(loginWithWalletConnect, "WALLET_CONNECT");
    };

    document.removeEventListener("Basenode:walletconnectConnected", onConnect);
    document.addEventListener("Basenode:walletconnectConnected", onConnect);

    initWalletConnect();
  }

  return (
    <>
      <button
        className="cursor-pointer flex items-center justify-between w-64 h-16 border border-gray-450-t-50 rounded-md px-4"
        onClick={openModal}
      >
        <span className="text-green-800">Connect wallet</span>
        <img
          src={`/icons/${theme}/auth/wallet_logo_new.svg`}
          alt="Wallet address"
        />
      </button>
      <BnDialog
        isOpen={isOpen}
        title=""
        maxWidth="max-w-3xl"
        header={false}
        description={
          <div className="grid grid-cols-2 space-x-5">
            {wallets.map((wallet) => (
              <button
                key={wallet.name}
                onClick={() => wallet.auth(wallet)}
                className="relative flex flex-col items-center w-full h-64 px-14 bg-gray-100 rounded-2xl border border-gray-100 focus:outline-none focus:border-green-300 dark:bg-gray-900 dark:border-gray-900"
              >
                <div className="mt-9 h-18 w-18 flex align-center justify-center">
                  <img
                    src={`/icons/wallets/${wallet.name.toLowerCase()}.svg`}
                    alt={wallet.name}
                    className="w-16 h-16"
                  />
                </div>
                <h3 className="mt-4 text-20 font-bold text-green-800 capitalize dark:text-gray-300">
                  {wallet.name}
                </h3>
                <p className="mt-4 text-16 text-green-800">{wallet.desc}</p>
                {wallet.installation_page && (
                  <div className="text-10 text-green-800 absolute bottom-1">
                    <span>{`Don't have a ${wallet.name} wallet? `}</span>
                    <a
                      href={wallet.installation_page}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="underline"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Download
                    </a>
                  </div>
                )}
              </button>
            ))}
          </div>
        }
        onClose={closeModal}
      />
    </>
  );
}
