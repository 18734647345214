import React from "react";
import { useHistory } from "react-router-dom";

import Button from "../components/forms/_Button";
import { useUser } from "../hooks/useUser";

export default function NotFoundPage() {
  const history = useHistory();
  const user = useUser();

  return (
    <div className="flex flex-col items-center text-20 font-semibold mt-24 text-green-900">
      <img className="" src="/icons/404.svg" alt="not-found" />
      <p className="my-10">
        Oh no! We can&apos;t find the page you&apos;re looking for.
      </p>
      <Button
        text={`${user ? "Go to Overview" : "Login"}`}
        styling="primary-button"
        additionalClasses="normal-case text-16 px-10"
        normalText
        onClick={() => history.push("/overview")}
      />
    </div>
  );
}
