import { Popover, Transition } from "@headlessui/react";
import { addDays, format, isAfter, isBefore, isWithinInterval } from "date-fns";
import React, { Fragment, useContext } from "react";
import { UseFormReturn } from "react-hook-form";

import { ThemeContext } from "../App";
import BaseForm from "../components/forms/_BaseForm";
import Calendar from "./_Calendar";
import Input from "./forms/_Input";

type DatePickerProps = {
  form: UseFormReturn<any>;
  minDate: Date;
  isOnLeftSide?: boolean;
  showConfirmButton?: boolean;
};

export default function DatePicker({
  form,
  minDate,
  isOnLeftSide,
  showConfirmButton,
}: DatePickerProps) {
  const theme = useContext(ThemeContext);

  const registerOptions = {
    required: true,
    pattern: {
      value: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
      message: "date should be in yyyy-mm-dd format",
    },
    validate: {
      isValidDate: (potentialDate: string) =>
        !!Date.parse(potentialDate) &&
        isWithinInterval(new Date(potentialDate), {
          start: addDays(minDate, -1),
          end: new Date(Date.now()),
        }),
    },
  };
  const resetForm = () => {
    if (Object.keys(form.formState.errors).includes("start_date")) {
      form.reset({
        ...form.getValues(),
        start_date: format(minDate, "yyyy-MM-dd"),
      });
    }
    if (Object.keys(form.formState.errors).includes("end_date")) {
      form.reset({
        ...form.getValues(),
        end_date: format(new Date(Date.now()), "yyyy-MM-dd"),
      });
    }
  };
  return (
    <Popover className="absolute normal-case pl-20">
      <BaseForm form={form}>
        <Popover.Button className="flex justify-between items-center border border-gray-300 dark:text-gray-300 rounded-md mr-2 py-1.5 px-3 text-12 w-56">
          <p className="w-2/5">{form.getValues("start_date")}</p>
          <div className="h-5 w-px bg-gray-300"></div>
          <p className="w-2/5">{form.getValues("end_date")}</p>
          <img src={`/icons/${theme}/calendar.svg`} alt="calendar" />
        </Popover.Button>
        <p className="text-11 text-red-500">
          {form.formState.errors.start_date?.message ||
            form.formState.errors.end_date?.message}
        </p>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-20 -top-36 flex-col rounded-20px bg-white text-14 text-green-800 shadow-filter-date dark:bg-gray-950 dark:shadow-dark">
            <div
              className={`flex  px-12 pt-9 pb-3 flex-col ${
                isOnLeftSide ? "left-full" : "right-full"
              } lg:flex-row `}
            >
              <div className="flex flex-col items-start lg:mr-20">
                <div className="calendar-block">
                  <p>From</p>
                  <div>
                    <Input
                      form={form}
                      name="start_date"
                      registerOptions={{
                        ...registerOptions,
                        validate: {
                          ...registerOptions.validate,
                          isEarlierThan: (v: string) =>
                            isBefore(
                              addDays(new Date(v), -1),
                              new Date(form.getValues("end_date")),
                            ),
                        },
                      }}
                      autoComplete="off"
                      mainClasses="input-date"
                    />
                  </div>
                </div>
                <Calendar
                  form={form}
                  minDate={minDate}
                  formFieldName="start_date"
                />
              </div>
              <div className="flex flex-col items-center">
                <div className="calendar-block">
                  <p>To</p>
                  <div>
                    <Input
                      form={form}
                      name="end_date"
                      registerOptions={{
                        ...registerOptions,
                        validate: {
                          ...registerOptions.validate,
                          isLaterThan: (v: string) =>
                            isAfter(
                              addDays(new Date(v), 1),
                              new Date(form.getValues("start_date")),
                            ),
                        },
                      }}
                      autoComplete="off"
                      mainClasses="input-date"
                    />
                  </div>
                </div>
                <Calendar
                  form={form}
                  minDate={minDate}
                  formFieldName="end_date"
                />
              </div>
            </div>

            {showConfirmButton && (
              <div className="flex justify-end m-4 mr-10">
                {Object.keys(form.formState.errors).includes("start_date") ||
                Object.keys(form.formState.errors).includes("end_date") ? (
                  <div
                    onClick={() => resetForm()}
                    className="h-9 bg-green-800 text-white flex items-center px-3 rounded-md cursor-pointer dark:bg-gray-900 dark:text-gray-300"
                  >
                    Reset
                  </div>
                ) : (
                  <Popover.Button className="h-9 bg-green-800 text-white flex items-center px-3 rounded-md cursor-pointer dark:bg-gray-900 dark:text-gray-300">
                    Confirm
                  </Popover.Button>
                )}
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </BaseForm>
    </Popover>
  );
}
