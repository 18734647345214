import { Popover, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useState } from "react";

import { ThemeContext } from "../App";
import CheckedItemsContext from "../hooks/CheckedItemsContext";
import DocumentCurrencyFilter from "../hooks/DocumentCurrencyFilter";
import SortDocumentPageParameter from "../hooks/SortDocumentPageParameter";
import { AvailableFiat, LabelResponse } from "../types";
import { getFiatSign } from "../utils";
import LabelBadge from "./labels/LabelBadge";

type checkBoxPropType = {
  items: { [key: string]: string[] };
  colName: string;
  checkeditems?: string[];
  onCheckBoxChange?: (selected: string[], colName: string) => void;
};
const CheckBoxPopUp = ({
  items,
  colName,
  onCheckBoxChange,
}: checkBoxPropType) => {
  const theme = useContext(ThemeContext);
  const { checkedItems, setCheckedItems, allItems } =
    useContext(CheckedItemsContext);

  const { currencyFilter, setDocumentCurrencyFilter } = useContext(
    DocumentCurrencyFilter,
  );

  const { setDocumentSortPrameter } = useContext(SortDocumentPageParameter);

  const [show, setShow] = useState(false);
  const updateList = (item: string) => {
    if (checkedItems.includes(item)) {
      setCheckedItems(checkedItems.filter((itm) => itm !== item));
    } else {
      setCheckedItems([...checkedItems, item]);
    }
    setDocumentCurrencyFilter("");
  };
  // we should consider columns seperately
  const invoicesFiltered = items.status.every((e) => checkedItems.includes(e))
    ? false
    : true;

  const currenciesFiltered = currencyFilter ? true : false;
  const docTypeFiltered = items.docTypes.every((e) => checkedItems.includes(e))
    ? false
    : true;

  const currenciesOptions = AvailableFiat.filter((c) => c);

  const transactionsLabelsFiltered = allItems?.every((l) =>
    checkedItems.some((lab) => lab.id === l.id),
  )
    ? false
    : true;

  return (
    <Popover className="relative flex rounded-10px h-8 sm:w-8 w-10 sm:px-2 px-1 text-gray sm:text-13 text-12  dark:text-gray-300">
      <Popover.Button>
        {(!(docTypeFiltered && invoicesFiltered) && !currenciesFiltered) ||
        !transactionsLabelsFiltered ? (
          <div className=" bg-grey-200 dark:bg-gray-900 dark:shadow-dark rounded-sm ">
            <img
              src={`/icons/${theme}/filter.svg`}
              alt="border"
              className=" top-1"
            />
          </div>
        ) : (
          <div className=" bg-gray-600 dark:bg-gray-900 dark:shadow-dark p-1 rounded-sm">
            <img src="/icons/dark/filter.svg" alt="border" className=" top-1" />
          </div>
        )}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute -left-8  rounded-lg w-auto top-full mt-1 bg-white p-3 z-30 flex flex-col items-center focus:outline-none shadow-filter dark:bg-gray-950 dark:shadow-dark font-medium opacity-100">
          {colName === "status" && (
            <div className="top-6 left-0 z-10 p-2 rounded-xl flex flex-col space-y-6 text-center w-full overflow-hidden bg-white dark:bg-gray-900 dark:shadow-dark">
              <p className=" px-3 whitespace-nowrap text-centre bg-white dark:bg-gray-900">
                Filter by Status
              </p>

              {items.status.map((item, index) => (
                <div
                  key={item}
                  className="flex items-center w-full text-14 dark:bg-gray-900"
                >
                  <div
                    className="relative flex items-center justify-around  w-full h-5 bg-white  dark:bg-gray-900 cursor-pointer"
                    onClick={() => {
                      updateList(item);
                    }}
                  >
                    {checkedItems.includes(item) ? (
                      <img
                        src={`/icons/${theme}/check_rectangle.svg`}
                        className=" dark:bg-gray-600"
                        alt="check"
                      />
                    ) : (
                      <img
                        src={`/icons/${theme}/checkbox/border.svg`}
                        alt="border"
                        className=" dark:bg-gray-600"
                      />
                    )}
                    {item === "PAID" && (
                      <div className="mr-2 rounded-md text-center p-1 w-16 text-12 font-semibold bg-green-800 text-white ">
                        {item}
                      </div>
                    )}
                    {item === "DRAFT" && (
                      <div className="mr-2 rounded-md text-center p-1 w-16 text-12 font-semibold bg-white text-red-500 border-2 border-red-500">
                        {item}
                      </div>
                    )}
                    {item === "DUE" && (
                      <div className="mr-2 rounded-md text-center p-1 w-16 text-12 font-semibold bg-white text-green-800 border-2 border-green-300">
                        SENT
                      </div>
                    )}
                    {item === "EXPIRED" && (
                      <div className="mr-2 rounded-md text-center p-1 w-16 text-12 font-semibold bg-red-500 text-white">
                        {item}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          {colName === "amount" && (
            <div className="top-6 -left-7 z-20 p-3 rounded-xl flex flex-col space-y-6 bg-white dark:bg-gray-900 dark:shadow-dark">
              <p
                className=" whitespace-nowrap bg-white  cursor-pointer dark:bg-gray-900"
                onClick={() => setDocumentSortPrameter("total_amount")}
              >
                Sort by ascending
              </p>
              <p
                className=" whitespace-nowrap cursor-pointer bg-white dark:bg-gray-900"
                onClick={() => setDocumentSortPrameter("-total_amount")}
              >
                Sort by descending
              </p>
              <p className="whitespace-nowrap  bg-white dark:bg-gray-900">
                Filter by currency
              </p>

              <div
                className={`flex flex-col space-y-5 w-full overflow-auto ${
                  theme === "dark" ? "custom-scroll-dark" : "custom-scroll"
                }`}
                style={{ maxHeight: "250px" }}
                onClick={() => setShow(!show)}
              >
                <button
                  id="dropdownButton"
                  data-dropdown-toggle="dropdown"
                  className=" text-gray-900 bg-gray-100 dark:bg-gray-1000 dark:text-white font-medium rounded-lg text-sm px-3 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  type="button"
                >
                  {currencyFilter}
                  <svg
                    className="ml-16 w-4 h-4 self-end"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>
                <Transition
                  show={show}
                  enter="transition ease-out duration-100 transform"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-75 transform"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div
                    id="dropdown"
                    className={
                      "flex z-10 w-44 text-black list-none  rounded divide-y divide-gray-100 shadow-gray dark:bg-gray-700 " +
                      show
                        ? ""
                        : "hidden"
                    }
                  >
                    {/* <p className="block py-2 px-4 text-sm text-black hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">
                    {setcurrency}
                    </p> */}

                    {currenciesOptions.map((currency) => (
                      <div
                        key={currency.toString()}
                        className="flex py-1 px-6 items-centre text-sm text-gray-900 hover:bg-gray-50 dark:hover:bg-gray-800 dark:text-gray-200 dark:hover:text-white cursor-pointer"
                        onClick={() =>
                          currencyFilter.includes(currency.toString())
                            ? setDocumentCurrencyFilter("")
                            : setDocumentCurrencyFilter(currency.toString())
                        }
                      >
                        <div className="w-5 text-center">
                          {getFiatSign(currency)}
                        </div>
                        <p
                          className={`uppercase text-green-800 text-14 dark:bg-gray-900 dark:text-white ${
                            currency.toString() === currencyFilter
                              ? "font-bold"
                              : "font-normal"
                          }`}
                        >
                          {currency}
                        </p>
                      </div>
                    ))}
                  </div>
                </Transition>
              </div>
            </div>
          )}
          {colName === "type" && (
            <div className="flex flex-col  top-6 -left-5 z-20 p-3 rounded-xl  space-y-5 text-center bg-white dark:text-white dark:bg-gray-900 dark:shadow-dark">
              <p className="text-left bg-white  dark:bg-gray-900">
                Filter by Type
              </p>

              {items.docTypes.map((item, index) => (
                <div
                  key={item}
                  className="flex px-2 w-full text-12 dark:bg-gray-900"
                >
                  <div
                    className="relative flex items-center w-full h-6  dark:bg-gray-900 cursor-pointer"
                    onClick={() => {
                      checkedItems.includes(item)
                        ? setCheckedItems(
                            checkedItems.filter((itm: string) => itm !== item),
                          )
                        : setCheckedItems([...checkedItems, item]);
                    }}
                  >
                    {checkedItems.includes(item) ? (
                      <img
                        src={`/icons/${theme}/check_rectangle.svg`}
                        className=" dark:bg-gray-600"
                        alt="check"
                      />
                    ) : (
                      <img
                        src={`/icons/${theme}/checkbox/border.svg`}
                        alt="border"
                        className=" dark:bg-gray-600"
                      />
                    )}
                    <p className="bg-white dark:bg-gray-900 p-3 whitespace-nowrap capitalize">
                      {item.replaceAll("_", " ").toLowerCase()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
          {colName === "labels" && allItems && (
            <div className="flex flex-col  top-6 -left-5 z-20 p-3 rounded-xl  space-y-5 text-center bg-white dark:text-white dark:bg-gray-900 dark:shadow-dark">
              <p className="text-left bg-white  dark:bg-gray-900 whitespace-nowrap">
                Filter by Label
              </p>
              {(allItems as LabelResponse[]).map((label) => (
                <div
                  key={label.id}
                  className="flex px-2 w-full text-12 dark:bg-gray-900"
                >
                  <div
                    className="relative flex items-center space-x-2 w-full h-6  dark:bg-gray-900 cursor-pointer"
                    onClick={() => {
                      checkedItems.some((l) => l.id === label.id)
                        ? setCheckedItems(
                            checkedItems.filter(
                              (l: LabelResponse) => l.id !== label.id,
                            ),
                          )
                        : setCheckedItems([...checkedItems, label]);
                    }}
                  >
                    {checkedItems.some((l) => l.id === label.id) ? (
                      <img
                        src={`/icons/${theme}/check_rectangle.svg`}
                        className=" dark:bg-gray-600"
                        alt="check"
                      />
                    ) : (
                      <img
                        src={`/icons/${theme}/checkbox/border.svg`}
                        alt="border"
                        className=" dark:bg-gray-600"
                      />
                    )}
                    <LabelBadge label={label} />
                  </div>
                </div>
              ))}
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default CheckBoxPopUp;
