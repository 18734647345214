import React, { useContext, useEffect, useState } from "react";
import { FieldValues, UseFormReturn, useForm } from "react-hook-form";

import { checkAddress, createContact, editContact } from "../../api";
import { ThemeContext } from "../../App";
import useMutationWithForm from "../../hooks/useMutationWithForm";
import {
  AvailableNetworks,
  Contact,
  ContactAddress,
  ContactResponse,
} from "../../types";
import countries from "../../utils/countries.json";
import NetworkBox from "../NetworkBox";
import BaseForm from "./_BaseForm";
import Button from "./_Button";
import Dropdown from "./_Dropdown";
import Input from "./_Input";

type FormProps = {
  onSubmit: (contact?: ContactResponse) => void;
  defaultValues?: ContactResponse;
};

type NewAddressProps = {
  form: UseFormReturn;
  onRemove: (id: number) => void;
  theme: string;
  id: number;
  setFormAddressError: (value: string) => void;
};

const emptyAddress = { network: "ETHEREUM", address: "" };

export default function ContactForm({ onSubmit, defaultValues }: FormProps) {
  const theme = useContext(ThemeContext);

  const form = useForm<FieldValues>({
    defaultValues: {
      ...defaultValues,
      addresses: defaultValues?.addresses ?? [{ ...emptyAddress }],
    },
  });
  const [addressError, setAddressError] = useState("");
  const addresses: ContactAddress[] = form.watch("addresses");

  const { mutate: addNew } = useMutationWithForm(
    form,
    (data: Contact) => createContact(data),
    {
      onSuccess: (data) => onSubmit(data),
      onError: (error: any) => {
        if (error.response.data.addresses) {
          setAddressError(error.response.data.addresses.join(", "));
        }
      },
    },
  );

  const { mutate: edit } = useMutationWithForm(
    form,
    (data: Contact) => editContact(defaultValues!.id, data),
    {
      onSuccess: (data) => onSubmit(data),
      onError: (error: any) => {
        if (error.response.data.addresses) {
          setAddressError(error.response.data.addresses.join(", "));
        }
      },
    },
  );

  function addNewAddress() {
    const newId = addresses.length;
    form.setValue(`addresses.${newId}`, { ...emptyAddress });
    setAddressError("");
  }

  function removeAddress(id: number) {
    const updatedAddresses = addresses;
    updatedAddresses.splice(id, 1);
    form.reset({ ...form.getValues(), addresses: updatedAddresses });
    setAddressError("");
  }

  function onFormSubmit() {
    const data = {
      ...form.getValues(),
      addresses: form
        .getValues()
        .addresses.filter(
          (obj: { address: string; network: string }) => obj.address !== "",
        ),
    } as ContactResponse;
    const { id, labels, ...contact } = data;

    if (defaultValues) {
      edit(contact as Contact);
    } else {
      addNew(contact as Contact);
    }
  }

  return (
    <div className="relative pb-6">
      <BaseForm
        form={form}
        onSubmit={onFormSubmit}
        buttons={
          <Button
            text={defaultValues ? "SAVE" : "ADD"}
            type="submit"
            styling="primary-button"
            additionalClasses="w-1/2 mx-auto font-bold"
          />
        }
      >
        <p className="text-16 font-semibold text-green-800 mb-2 dark:text-gray-100">
          Let&apos;s input their information!
          <span className="text-12 ml-2 dark:text-gray-100">* Required</span>
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
          <div>
            <Input
              form={form}
              name="first_name"
              placeholder="First name*"
              registerOptions={{ required: true }}
            />
          </div>
          <div>
            <Input
              form={form}
              name="last_name"
              placeholder="Last name*"
              registerOptions={{ required: true }}
            />
          </div>
        </div>
        <div className="mt-2 grid grid-cols-2">
          <Input form={form} name="company" placeholder="Company name" />
        </div>
        <p className="text-16 font-semibold text-green-800 mt-2 dark:text-gray-100">
          Where can you reach them?
        </p>
        <div className="mt-2">
          <Input
            form={form}
            name="email"
            type="email"
            placeholder="Email*"
            registerOptions={{ required: true }}
          />
        </div>
        <div className="mt-2">
          <Input
            form={form}
            name="address_line1"
            placeholder="Address line 1*"
            registerOptions={{ required: true }}
          />
        </div>
        <div className="mt-2">
          <Input
            form={form}
            name="address_line2"
            placeholder="Address line 2"
          />
        </div>
        <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
          <div>
            <Dropdown
              form={form}
              name="country"
              placeholder="Country*"
              options={countries.map((c) => ({
                label: c,
                value: c,
              }))}
              registerOptions={{ required: true }}
            />
          </div>
          <div>
            <Input
              form={form}
              name="city"
              placeholder="City*"
              registerOptions={{ required: true }}
            />
          </div>
          <div>
            <Input
              form={form}
              name="postal_code"
              placeholder="Postal code*"
              registerOptions={{ required: true }}
            />
          </div>
          <div>
            <Input form={form} name="tax_number" placeholder="Tax number" />
          </div>
        </div>
        <div className="my-4">
          <p className="text-16 font-semibold text-green-800 dark:text-gray-100">
            What&apos;s their wallet information?
          </p>
          {addresses &&
            addresses.map((a: any, id: number) => (
              <NewAddress
                key={id}
                form={form}
                id={id}
                theme={theme}
                onRemove={removeAddress}
                setFormAddressError={setAddressError}
              />
            ))}
          {addressError && (
            <div className="col-span-12 text-red-500 text-12">
              {addressError}
            </div>
          )}
          <img
            src={`/icons/${theme}/plus.svg`}
            alt="add"
            className="mt-4 cursor-pointer"
            onClick={() => addNewAddress()}
          />
        </div>
      </BaseForm>
    </div>
  );
}

function NewAddress({
  form,
  onRemove,
  theme,
  id,
  setFormAddressError,
}: NewAddressProps) {
  const network = form.watch(`addresses.${id}.network`);
  const address = form.watch(`addresses.${id}.address`);
  const [addressError, setAddressError] = useState("");

  const { mutate } = useMutationWithForm(
    form,
    () => checkAddress(address, network),
    {
      onError: (e: any) => setAddressError(e.response?.data?.address[0]),
      onSuccess: () => {
        setAddressError("");
        form.clearErrors("address");
        setFormAddressError("");
        form.clearErrors("addresses");
      },
    },
  );

  useEffect(() => {
    checkNetwork();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, network]);

  function checkNetwork() {
    if (address) {
      mutate(address);
    }
  }

  return (
    <div className="mt-4 text-gray-800 text-12 dark:text-gray-300">
      <p>Network</p>
      <div className="flex">
        {AvailableNetworks.map((n) => (
          <NetworkBox
            key={n}
            kind="network"
            name={n}
            isSelected={network === n}
            onClick={() => form.setValue(`addresses.${id}.network`, n)}
          />
        ))}
      </div>
      <BaseForm form={form} onSubmit={() => {}}>
        <div className="flex items-start">
          <div className="w-full">
            <Input
              form={form}
              name={`addresses.${id}.address`}
              placeholder="Public Key Address"
            />
            <p className="text-12 text-left text-red-500 m-0">{addressError}</p>
          </div>
          <button
            type="submit"
            className="ml-3 w-8 my-4"
            onClick={() => onRemove(id)}
          >
            <img src={`/icons/${theme}/trash_light.svg`} alt="delete" />
          </button>
        </div>
      </BaseForm>
    </div>
  );
}
