import React, { useContext } from "react";
import { useQuery } from "react-query";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

import { getBalances, getPortfolioValueHistory } from "../../api";
import { ThemeContext } from "../../App";
import { Balance } from "../../types";
import { formatAmount } from "../../utils";
import ColoredAmount from "../ColoredAmount";
import FiatAmount from "../FiatAmount";
import Loader from "../Loader";
import AssetListPopUP from "./AssetListPopUP";

const chartColors = [
  "#1F7B71",
  "#F7931A",
  "#B3E9FE",
  "#00D395",
  "#2F5A55",
  "#54A097",
  "#F8DFD0",
  "#D5E6DE",
];

export default function TotalAssetsValue() {
  const theme = useContext(ThemeContext);

  const { data: balances, isLoading: balanceLoadingV2 } = useQuery(
    "balances",
    getBalances,
    { refetchInterval: 10000 },
  );

  const { data: portfolioValueHistory, isLoading: historyLoading } = useQuery(
    "portfolioValueHistory",
    () => getPortfolioValueHistory(),
    { refetchInterval: 10000 },
  );

  let pieArray: any[] = [];
  let tokensList: Balance[] = [];

  if (balances) tokensList = balances.tokens;

  tokensList.sort((a, b) => +b.value_fiat - +a.value_fiat);
  const numOfBalances = tokensList.length;

  pieArray = tokensList.map((t) => ({
    token: t.token.symbol,
    value: +t.value_fiat,
  }));

  let thisMonthChange = 0;
  if (portfolioValueHistory) {
    thisMonthChange = parseFloat(portfolioValueHistory!.change_fiat);
  }

  function customTooltip(props: any) {
    if (!props.payload) {
      return null;
    }
    if (props.active) {
      return (
        <div className="bg-white border-2 border-green-800 rounded-md p-3 dark:bg-gray-800 dark:border-gray-900 dark:shadow-lg">
          <p>
            {props.payload[0].payload.token}:{" "}
            <span className="font-bold">
              <FiatAmount
                amount={props.payload[0].value}
                fiat_currency={balances?.fiat_currency}
              />
            </span>
          </p>
        </div>
      );
    }
    return null;
  }

  return (
    <section className="w-full mlg:w-1/2 px-8 py-9 mlg:pr-1 lg:pr-9 overview-section ">
      <h2 className="overview-h2">Total Assets Value</h2>
      {balanceLoadingV2 ||
      !balances!.user_bal_sync ||
      historyLoading ||
      !portfolioValueHistory!.user_bal_sync ? (
        <Loader />
      ) : (
        <>
          <h3 className="text-34 w-full">
            <FiatAmount
              amount={balances!.total.value_fiat}
              fiat_currency={balances!.fiat_currency}
            />
          </h3>
          <h4 className="text-16 w-full">
            <ColoredAmount
              amount={Math.abs(thisMonthChange)}
              fiat_currency={balances!.fiat_currency}
              direction={thisMonthChange < 0 ? "OUTGOING" : "INCOMING"}
              isMonthly
            />
          </h4>
          <div className="flex sm:flex-row flex-col w-full mt-4 sm:h-60 justify-between">
            <div className="w-60 max-w-full">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart style={{ bacgroundColor: "green" }}>
                  <Pie
                    data={pieArray}
                    innerRadius={67.5}
                    outerRadius={109}
                    dataKey="value"
                    cy="50%"
                    cx="50%"
                    blendStroke
                    startAngle={90}
                    endAngle={-270}
                  >
                    {pieArray.map(({ token }, id) => (
                      <Cell key={token} fill={chartColors[id % 8]} />
                    ))}
                  </Pie>
                  <Tooltip content={customTooltip} />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div
              className={`sm:w-1/2 flex flex-col space-y-3 p-1 lg:p-3 ${
                theme === "dark" ? "custom-scroll-dark" : "custom-scroll"
              }`}
            >
              {numOfBalances <= 7 &&
                tokensList!.map((t, id) => {
                  if (+t.amount === 0) return "";
                  return (
                    <div className="flex items-center w-full" key={t.token.id}>
                      <div
                        className="w-max h-2 rounded-sm mr-4"
                        style={{
                          backgroundColor: chartColors[id % 8],
                        }}
                      >
                        <div className="w-5"></div>
                      </div>
                      <div className="flex w-44 mlg:w-full whitespace-nowrap justify-between">
                        <h4 className="text-14">{t.token.symbol}</h4>
                        <p className="text-14">{formatAmount(t.amount)}</p>
                      </div>
                    </div>
                  );
                })}
              {numOfBalances > 7 && (
                <>
                  {tokensList.slice(0, 7)!.map((t, id) => {
                    if (+t.amount === 0) return "";

                    return (
                      <div
                        className="flex items-center w-full"
                        key={t.token.id}
                      >
                        <div
                          className="w-max h-2 rounded-sm mr-4 "
                          style={{
                            backgroundColor: chartColors[id % 8],
                          }}
                        >
                          <div className="w-5"></div>
                        </div>
                        <div className="flex w-44 mlg:w-full whitespace-nowrap justify-between">
                          <h4 className="text-14">{t.token.symbol}</h4>
                          <p className="text-14">{formatAmount(t.amount)}</p>
                        </div>
                      </div>
                    );
                  })}
                  <AssetListPopUP
                    counter={numOfBalances - 7}
                    balances={tokensList.slice(7)}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </section>
  );
}
