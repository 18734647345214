import React from "react";

type ButtonProps = {
  text: string;
  onClick?: any;
  disabled?: boolean;
  normalText?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  additionalClasses?: string;
  styling:
    | "primary-button"
    | "document-button"
    | "transparent-button"
    | "always-green-button";
};

export default function Button({
  text,
  type,
  onClick,
  disabled,
  normalText,
  additionalClasses,
  styling,
}: ButtonProps) {
  return (
    <button
      className={`${additionalClasses} ${styling}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      <h2 className={`${!normalText && "text-12 uppercase"}`}>{text}</h2>
    </button>
  );
}
