import { Popover, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { getFungibleTokensData } from "../../api";
import { ThemeContext } from "../../App";
import { useUser } from "../../hooks/useUser";
import { FungibleTokensResponse } from "../../types";
import Loader from "../Loader";
import FungibleTokensTable from "./FungibleTokensTable";

const FungibleTokensLightbox = () => {
  const theme = useContext(ThemeContext);
  const user = useUser();

  const [fungibleTokensData, setFungibleTokensData] = useState<
    FungibleTokensResponse[]
  >([]);
  const fungibleTokens = useQuery(
    "fungible-tokens-summary",
    () => getFungibleTokensData(1, 10),
    {
      enabled: user.full_access,
    },
  );

  useEffect(() => {
    if (fungibleTokens.status === "success") {
      setFungibleTokensData(fungibleTokens.data);
    }
    if (fungibleTokens.status === "error") {
      fungibleTokens.refetch();
    }
  }, [fungibleTokens]);

  const hasMore = fungibleTokensData.length >= 7;

  const columns = [
    {
      header: "Asset Name",
      accessor: "asset_name",
      sortable: false,
      width: "25%",
    },
    {
      header: "Balance",
      accessor: "balance",
      filterable: true,
      width: "20%",
    },
    {
      header: "Current Price",
      accessor: "current_price",
      width: "15%",
    },
    {
      header: "Value",
      accessor: "value",
      filterable: true,
      sortable: true,
      width: "20%",
    },
    {
      header: "Unrealized Gains",
      accessor: "unrealized_gain",
      width: "20%",
    },
  ];
  return (
    <div className=" fungibleTokens mr-2.5 text-16 font-bold ">
      Fungible Tokens
      <div>
        {fungibleTokens.isLoading ? (
          <Loader />
        ) : (
          <FungibleTokensTable
            data={fungibleTokensData}
            columns={columns}
            hasMore={hasMore}
          />
        )}
        <Popover className="text-gray sm:text-13 text-12  dark:text-gray-300">
          {({ open }) => {
            // ensures that overflow values is always adjusted to open value
            if (open) {
              document.body.style.overflow = "hidden";
              localStorage.setItem("hiddenSource", "ft");
            } else if (localStorage.getItem("hiddenSource") === "ft") {
              document.body.style.overflow = "initial";
              localStorage.removeItem("hiddenSource");
            }
            return (
              <>
                <Popover.Button className="dark:bg-gray-900 cursor-pointer w-full">
                  <p
                    className="text-center font-bold m-auto"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    See All
                  </p>
                </Popover.Button>
                <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute overflow-y-auto left-0 top-0 w-screen h-screen bg-white p-2 z-30 flex-col  items-center dark:bg-gray-900 font-medium opacity-100">
                    <Popover.Button className="w-full flex justify-end ">
                      <img
                        src={`/icons/${theme}/close.svg`}
                        alt="close"
                        className="w-4 h-4"
                      />
                    </Popover.Button>
                    <div className="px-10 xl:px-20 mt-8">
                      <h2 className="font-bold text-16 mb-4">
                        Fungible Tokens
                      </h2>
                      <FungibleTokensTable
                        data={fungibleTokensData}
                        columns={columns}
                        hasMore={!hasMore}
                      />
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            );
          }}
        </Popover>
      </div>
    </div>
  );
};

export default FungibleTokensLightbox;
