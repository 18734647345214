import { Menu } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";

import { capitalize } from "../../utils";

type NavProps = { tabs: string[] };

export default function MobileNav({ tabs }: NavProps) {
  return (
    <div className="sm:hidden relative">
      <Menu>
        <Menu.Button>
          <img
            className="min-w-min"
            src="/icons/fi_more_horizontal.svg"
            alt="menu"
          />
        </Menu.Button>
        <Menu.Items className="origin-top-left absolute text-black mt-4 w-40 z-50 text-13 rounded-md shadow-lg bg-white focus:outline-none">
          {tabs.map((tab) => (
            <div key={tab}>
              <Menu.Item>
                <Link to={tab}>
                  <div className="px-4 py-2">{capitalize(tab)}</div>
                </Link>
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
}
