import { AxiosResponse } from "axios";
import { UseFormReturn, UseFormSetError } from "react-hook-form";
import { MutationFunction, UseMutationOptions, useMutation } from "react-query";

export default function useMutationWithForm<TData, TVariables>(
  form: UseFormReturn<any>,
  mutationFn: MutationFunction<TData, TVariables>,
  useMutationOptions: UseMutationOptions<TData, TVariables> = {},
) {
  const options: UseMutationOptions<TData, TVariables> = {
    ...useMutationOptions,
    onError: (error, variables, context) => {
      if (useMutationOptions.onError) {
        useMutationOptions.onError(error, variables, context);
      }

      const response: AxiosResponse | null = (error as any).response;

      if (response?.status === 400) {
        addServerErrors((error as any).response?.data, form.setError);
      }
    },
  };

  return useMutation(mutationFn, options as any);
}

function addServerErrors(
  serverErrors: Record<string, string[]>,
  setError: UseFormSetError<any>,
) {
  for (const key of Object.keys(serverErrors)) {
    setError(key, {
      type: "server",
      message: serverErrors[key] as any,
    });
  }
}
