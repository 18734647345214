import { format, isWithinInterval } from "date-fns";
import addDays from "date-fns/addDays";
import React, { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import { UseFormReturn } from "react-hook-form";

import { ThemeContext } from "../App";
import DocumentPageDateFilters from "../hooks/DocumentPageDateFilters";

type CalendarCompProps = {
  form: UseFormReturn<any>;
  formFieldName: string;
  minDate: Date;
  maxDate?: Date;
  disabled?: boolean;
  additionalClasses?: string;
  isBnTable?: boolean;
};
const now = new Date(Date.now());

export default function CalendarComp({
  form,
  formFieldName,
  minDate,
  maxDate = now,
  disabled,
  additionalClasses,
  isBnTable,
}: CalendarCompProps) {
  const theme = useContext(ThemeContext);
  const formDate = form.getValues(formFieldName);

  const { timePeriod, setTimePeriod } = useContext(DocumentPageDateFilters);

  const [date, setDate] = useState(
    Date.parse(formDate) ? new Date(formDate) : now,
  );

  useEffect(() => {
    setDate(Date.parse(formDate) ? new Date(formDate) : now);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch(formFieldName)]);

  const updateDay = (tileDate: Date) => {
    if (isBnTable && window.location.pathname === "/documents")
      setTimePeriod({
        start_date:
          formFieldName === "start_date" ? tileDate : timePeriod.start_date,
        end_date: formFieldName === "end_date" ? tileDate : timePeriod.end_date,
      });
  };

  return (
    <Calendar
      defaultValue={date}
      minDate={minDate}
      maxDate={maxDate}
      tileDisabled={() => disabled || false}
      className={`text-12 text-gray-800 h-56 dark:text-gray-300 ${
        disabled && "pointer-events-none"
      } ${additionalClasses}`}
      tileClassName={({ date: selectedDate }) => {
        const isSelected =
          format(selectedDate, "yyyy-MM-dd") === format(date, "yyyy-MM-dd");
        const isInRange = isWithinInterval(selectedDate, {
          start: addDays(minDate, -1),
          end: maxDate,
        });
        return `${isSelected && "tile-abbr-selected"} ${
          (disabled || !isInRange) && "tile-abbr-disabled"
        } `;
      }}
      nextLabel={
        <img
          src={`/icons/${theme}/arrows/calendar_next.svg`}
          alt="next"
          className="cursor-pointer"
        />
      }
      prevLabel={
        <img
          src={`/icons/${theme}/arrows/calendar_prev.svg`}
          alt="prev"
          className="cursor-pointer"
        />
      }
      next2Label={null}
      prev2Label={null}
      formatShortWeekday={(_, tileDate) => format(tileDate, "EEEEE")}
      showNeighboringMonth={false}
      calendarType="US"
      onChange={(tileDate: Date) => {
        form.clearErrors();
        updateDay(tileDate);
        form.setValue(formFieldName, format(tileDate, "yyyy-MM-dd"));
        form.trigger();
      }}
      view="month"
      maxDetail="month"
      minDetail="month"
    />
  );
}
