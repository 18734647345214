import React, { useContext } from "react";
import { useHistory } from "react-router";

import { ThemeContext } from "../../App";
import BnDialog from "../BnDialog";
import Button from "../forms/_Button";

type EndOfSubscriptionLightBoxProps = {
  dialogText: string;
  isSubscriptionDialogOpen: boolean;
  onCloseSubscriptionDialog: () => void;
};
const EndOfSubscriptionLightBox = ({
  dialogText,
  isSubscriptionDialogOpen,
  onCloseSubscriptionDialog,
}: EndOfSubscriptionLightBoxProps) => {
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const onClickSubscriptionOptions = () => {
    onCloseSubscriptionDialog();
    history.push("/pricing");
  };
  return (
    <div>
      <BnDialog
        isOpen={isSubscriptionDialogOpen}
        title=""
        maxWidth="max-w-xl"
        centerTitle
        description={
          <div className="flex  flex-col justify-center align-middle  m-3 mt-1">
            <div className="m-7 mt-2 text-center text-14 font-normal dark:text-gray-100">
              {dialogText}
            </div>
            <img
              className="my-6 h-44"
              src={`/icons/mascot/${theme}/mascot_endsubscription_allert.svg`}
              alt="plan is limitted"
            />

            <Button
              text="EXPLORE PLANS"
              type="button"
              styling="primary-button"
              additionalClasses="w-full"
              onClick={onClickSubscriptionOptions}
            />
          </div>
        }
        onClose={onCloseSubscriptionDialog}
      />
    </div>
  );
};

export default EndOfSubscriptionLightBox;
