import React from "react";
import { Link } from "react-router-dom";

type SectionButtonProps = {
  currSection: string | null;
  section: "profile" | "settings" | "integrations";
  theme: any;
};

export default function SectionButton({
  currSection,
  section,
  theme,
}: SectionButtonProps) {
  return (
    <div className="flex items-center space-x-1">
      <div
        className={`w-0.5 ${
          currSection === section ? "bg-green-300" : "bg-transparent"
        } h-8`}
      />
      <Link
        to={`/settings?section=${section}`}
        className={`flex items-center space-x-2 rounded-md w-36 py-2 pl-6 ${
          currSection === section && "bg-gray-100 dark:bg-gray-600"
        }`}
      >
        <img src={`/icons/${theme}/${section}.svg`} alt={section} />
        <p className="capitalize">{section}</p>
      </Link>
    </div>
  );
}
