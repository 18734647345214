import React, { PropsWithChildren, ReactElement } from "react";
import { UseFormReturn } from "react-hook-form";

import FormError from "./_FormError";

type FormProps = PropsWithChildren<{
  form: UseFormReturn<any>;
  onSubmit?: (data: any) => void;
  buttons?: ReactElement;
}>;

export default function LbForm({
  form,
  onSubmit,
  buttons,
  children,
}: FormProps) {
  return (
    <form
      className="flex flex-col"
      onSubmit={onSubmit && form.handleSubmit(onSubmit)}
    >
      {children}

      <div className="mt-4">
        <FormError errors={form.formState.errors.non_field_errors}></FormError>
      </div>

      {buttons}
    </form>
  );
}
