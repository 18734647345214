import React from "react";

import { AnalyticsType } from "../../types";
import FTCStatistics from "./FTCStatistics";
import TotalWallets from "./TotalWallets";
import UserHistoryChart from "./UserHistoryChart";
import UsersStatistics from "./UsersStatistics";

type AnalyticsProps = {
  usersStatsData?: AnalyticsType;
};

export default function AnalyticsSection({ usersStatsData }: AnalyticsProps) {
  return (
    <>
      <div className="mt-14 mb-5">
        <UsersStatistics data={usersStatsData} />
      </div>
      <div className="bg-white dark:bg-gray-900 p-12 pb-18 mb-8 rounded-2xl">
        <p className="dark:text-gray-100  text-18">User Growth</p>
        <UserHistoryChart />
      </div>
      <div className="flex flex-col mlg:flex-row space-x-0 mlg:space-x-6 lg:space-x-12 space-y-12 mlg:space-y-0">
        <TotalWallets />
        <FTCStatistics />
      </div>
    </>
  );
}
