import { Popover, Transition } from "@headlessui/react";
import React, {
  Fragment,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMutation, useQuery } from "react-query";

import {
  deleteLabel,
  editContact,
  editTransaction,
  getLabels,
} from "../../api";
import { ThemeContext } from "../../App";
import { LabelResponse } from "../../types";
import LabelBadge from "./LabelBadge";
import LabelDetailsPopup from "./LabelDetailsPopup";

type PopupProps = PropsWithChildren<{
  chosenLabels: LabelResponse[];
  onLabelChange: () => void;
  contactId?: number;
  transactionId?: number; //one of those two has to be provided
}>;

export default function LabelsPopup({
  contactId,
  transactionId,
  chosenLabels,
  onLabelChange,
  children,
}: PopupProps) {
  const theme = useContext(ThemeContext);

  const [headerLabels, setHeaderLabels] = useState(chosenLabels);
  const [hoveredLabel, setHoveredLabel] = useState<LabelResponse | null>(null);

  const { data: labels, refetch } = useQuery("labels", () => getLabels());

  const { mutate: removeLabel } = useMutation(
    "label",
    (id: number) => deleteLabel(id),
    {
      onSuccess: () => updateLabels(),
    },
  );

  useEffect(() => {
    setHeaderLabels(chosenLabels);
  }, [chosenLabels]);

  function updateLabels() {
    setHeaderLabels(headerLabels.filter((l) => l.id !== hoveredLabel?.id));
    refetch();
    onLabelChange();
  }

  async function onChange(data: any) {
    let response;
    if (contactId) {
      response = await editContact(contactId!, data);
    }
    if (transactionId) {
      response = await editTransaction(transactionId, data);
    }
    if (response) {
      setHeaderLabels(response.labels);
      onLabelChange();
    }
  }

  return (
    <Popover onClick={(e: any) => e.stopPropagation()}>
      <Popover.Button className="cursor-pointer flex flex-wrap">
        <div>{children}</div>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel className="absolute z-20 flex flex-col text-gray-800 text-12 bg-white w-72 rounded-lg shadow-lg dark:bg-gray-900">
          <div>
            <div
              className={`bg-gray-100 h-18 w-full flex flex-wrap items-start p-2 overflow-y-auto dark:bg-gray-950 ${
                theme === "dark" ? "custom-scroll-dark" : "custom-scroll"
              }`}
            >
              {headerLabels.map((label) => (
                <LabelBadge
                  key={label.id}
                  label={label}
                  onClose={() =>
                    onChange({
                      labels: headerLabels
                        .filter((l) => l.id !== label.id)
                        .map((l) => l.id),
                    })
                  }
                />
              ))}
            </div>
            <div
              className={`w-full flex flex-col items-start p-4 overflow-y-auto ${
                theme === "dark" ? "custom-scroll-dark" : "custom-scroll"
              }`}
              style={{ maxHeight: "200px" }}
            >
              <p className="text-green-800 text-11 mb-2 dark:text-green-300">
                Select Label(s)
              </p>
              {labels &&
                labels.map((label) => (
                  <div
                    key={label.id}
                    className="flex my-1.5 w-full justify-between"
                    onMouseEnter={() => setHoveredLabel(label)}
                    onMouseLeave={() => setHoveredLabel(null)}
                  >
                    <div className="flex items-center -mt-1">
                      <img src="/icons/six_dots.svg" alt="dots" />
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          onChange({
                            labels: [
                              ...headerLabels.map((l) => l.id),
                              label.id,
                            ],
                          })
                        }
                      >
                        <LabelBadge label={label} />
                      </div>
                    </div>
                    <div className="flex">
                      <LabelDetailsPopup
                        providedLabel={label}
                        onSubmit={updateLabels}
                        remove={removeLabel}
                      >
                        <img
                          src={`/icons/${theme}/actions/edit.svg`}
                          className={`mr-2 ${
                            hoveredLabel?.id === label.id
                              ? "inline-block"
                              : "hidden"
                          }`}
                          alt="edit"
                        />
                      </LabelDetailsPopup>
                      <img
                        src={`/icons/${theme}/actions/trash_light.svg`}
                        className={`cursor-pointer ${
                          hoveredLabel?.id === label.id
                            ? "inline-block"
                            : "hidden"
                        }`}
                        alt="delete"
                        onClick={() => removeLabel(label.id)}
                      />
                    </div>
                  </div>
                ))}
              <LabelDetailsPopup onSubmit={refetch}>
                <div className="flex cursor-pointer">
                  <img src="/icons/plus_gray_circle.svg" alt="add" />
                  <p className="ml-2 text-11 my-2 dark:text-gray-300">
                    Create Label
                  </p>
                </div>
              </LabelDetailsPopup>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
