import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { format } from "date-fns";
import React, { PropsWithChildren } from "react";

type PdfProps = PropsWithChildren<{ title: string }>;

export default function BasePdf({ title, children }: PdfProps) {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.header}>
          <View style={{ flex: 2, flexDirection: "column" }}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.date}>{format(Date.now(), "dd-MM-yyyy")}</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Image style={styles.topLogo} src="/pdfImages/LogoBig.png" />
          </View>
        </View>

        {children}

        <View style={styles.footer} fixed>
          <Image style={styles.bottomLogo} src="/pdfImages/footerImg.png" />
        </View>
      </Page>
    </Document>
  );
}

Font.register({
  family: "Nunito",
  src: "/fonts/Nunito-Regular.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 16,
    fontFamily: "Nunito",
  },
  date: {
    fontSize: 10,
    marginBottom: 40,
  },
  topLogo: {
    position: "absolute",
    right: 40,
    width: 127,
  },
  bottomLogo: {
    width: 120,
  },
  footer: {
    flexDirection: "row",
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 45,
  },
});
