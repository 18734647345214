import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import { getInvoiceLimit } from "../api";
import BnDialog from "../components/BnDialog";
import NoWalletInfo from "../components/NoWalletInfo";
import FungibleTokensLightbox from "../components/overview/FungibleTokensLightbox";
import InvoicesLightbox from "../components/overview/InvoicesLightbox";
import InvoiceStatus from "../components/overview/InvoiceStatus";
import LastActivity from "../components/overview/LastActivity";
import NonFungibleTokensLightbox from "../components/overview/NonFungibleTokensLightBox";
import PortfolioHistoryChart from "../components/overview/PortfolioHistoryChart";
import Suggestions from "../components/overview/Suggestions";
import TotalAssetsValue from "../components/overview/TotalAssetsValue";
import PageHeader from "../components/PageHeader";
import StartTutorial from "../components/tutorial/StartTutorial";
import Tutorial from "../components/tutorial/Tutorial";
import useEndTutorial from "../hooks/useEndTutorial";
import { useUser } from "../hooks/useUser";
import { LocationProps } from "../types";

export default function OverviewPage() {
  const user = useUser();
  const endTutorial = useEndTutorial();
  const history = useHistory();
  const location = useLocation<LocationProps>();
  const tutorial = location.state?.tutorial;
  const [isTutorialFlow, setIsTutorialFlow] = useState(false);
  const [tutorialState, setTutorialState] = useState<string>();

  const { data: invoiceLimits } = useQuery("invoiceLimits", () =>
    getInvoiceLimit(),
  );

  useEffect(() => {
    if (tutorial) {
      setTutorialState(tutorial);
    }
  }, [tutorial]);

  useEffect(() => {
    if (
      !user.is_tutorial_viewed &&
      !tutorial &&
      user.full_access &&
      user.has_wallet
    ) {
      setIsTutorialFlow(true);
    }
  }, [user.is_tutorial_viewed, user.full_access, user.has_wallet, tutorial]);

  if (user.has_wallet !== null && !user.has_wallet) {
    return <NoWalletInfo />;
  }

  function endTutorialFlow() {
    endTutorial();
    setIsTutorialFlow(false);
  }

  function startTutorialFlow() {
    history.push("/wallets", { tutorial: "wallet_tutorial_1" });
  }

  function closeTutorialFlow() {
    endTutorial();
    setTutorialState(undefined);
  }

  return (
    <div>
      <PageHeader title="Dashboard" />
      <section id="suggestions">
        <Suggestions />
      </section>
      <main className="flex flex-col space-y-12 mlg:space-y-6 lg:space-y-12">
        <section className="InvoiceSection flex items-start overview-section">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-x-10 w-full px-7 py-6 lg:px-14">
            <div>
              <div className="overview-h2 mb-5 py-4 mt-0.5">Invoicing</div>
              <div className="AwaitingInvoices border my-3 border-gray-300 text-12 w-full p-6">
                <InvoicesLightbox status="DUE" invoiceLimits={invoiceLimits!} />
              </div>
            </div>
            <div>
              <InvoiceStatus user={user} invoiceLimits={invoiceLimits} />
              <div className="AwaitingInvoices border my-3 border-gray-300 text-12 w-full p-6">
                <InvoicesLightbox
                  status="PAID"
                  invoiceLimits={invoiceLimits!}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="flex flex-col mlg:flex-row space-x-0 mlg:space-x-6 lg:space-x-12 space-y-12 mlg:space-y-0">
          <TotalAssetsValue />
          <LastActivity />
        </div>
        <section className="w-full px-7 py-6 lg:px-16 lg:py-14 space-x-7 overview-section overview-portfoliohistorychart">
          <div className="hidden md:block w-full">
            <h2 className="overview-h2 pl-6">My Portfolio</h2>
            <PortfolioHistoryChart />
          </div>
          <div className="w-full hidden md:block md:space-x-8 mt-14">
            <div className="FungibleTokens border my-3 border-gray-300 text-12 w-full p-4">
              <FungibleTokensLightbox />
            </div>
          </div>
          <div className="NonFungibleTokens border my-3 border-gray-300 text-12 w-full p-4">
            <NonFungibleTokensLightbox />
          </div>
        </section>
      </main>
      <BnDialog
        isOpen={isTutorialFlow}
        maxWidth="max-w-xl"
        title="Welcome to Basenode.io!"
        centerTitle
        description={
          <StartTutorial onStart={startTutorialFlow} onEnd={endTutorialFlow} />
        }
        onClose={endTutorialFlow}
      />
      <Tutorial
        title="Look over your portfolio"
        desc="Go to the overview page to see an overview of all your assets in one place."
        isOpen={!!tutorialState && !user.is_tutorial_viewed}
        image={tutorialState}
        currentTutorial={3}
        maxTutorial={7}
        onClose={closeTutorialFlow}
        nextTutorial={() =>
          history.push("/transactions", { tutorial: "transaction_tutorial" })
        }
        prevTutorial={() =>
          history.push("/wallets", { tutorial: "wallet_tutorial_2" })
        }
        positionStyle="absolute top-24 left-8 xl:left-36"
        pointer={{
          className: "absolute left-14 -top-14",
          style: {
            borderBottom: "30px solid white",
            borderRight: "30px solid transparent",
            borderTop: "30px solid transparent",
            borderLeft: "30px solid transparent",
          },
        }}
      />
    </div>
  );
}
