import React, { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useMutation } from "react-query";

import { createCex, createWallet, deleteWallet, editWallet } from "../../api";
import useMutationWithForm from "../../hooks/useMutationWithForm";
import {
  AvailableNetworks,
  Cex,
  Network,
  Wallet,
  WalletResponse,
} from "../../types";
import NetworkBox from "../NetworkBox";
import ToggleButton from "../ToggleButton";
import BaseForm from "./_BaseForm";
import Button from "./_Button";
import Dropdown from "./_Dropdown";
import Input from "./_Input";

type FormProps = {
  onSubmit: (type: string) => void;
  defaultValues?: WalletResponse;
};

export default function WalletForm({ onSubmit, defaultValues }: FormProps) {
  const walletForm = useForm<FieldValues>({ defaultValues });
  const cexForm = useForm<FieldValues>();
  const [network, setNetwork] = useState<Network | null>(
    defaultValues?.network ?? null,
  );
  const [networkError, setNetworkError] = useState("");
  const [isPublicKey, setIsPublicKey] = useState<boolean>(true);

  const { mutate: addWallet } = useMutationWithForm(
    walletForm,
    (data: Wallet) => createWallet(data),
    { onSuccess: () => onSubmit("wallet") },
  );

  const { mutate: addCex } = useMutationWithForm(
    cexForm,
    (data: Cex) => createCex(data),
    { onSuccess: () => onSubmit("cex") },
  );

  const { mutate: modifyWallet } = useMutationWithForm(
    walletForm,
    (data: Wallet) => editWallet(defaultValues!.id, data),
    { onSuccess: () => onSubmit("wallet") },
  );

  const { mutate: removeWallet } = useMutation(
    () => deleteWallet(defaultValues!.id),
    {
      onSuccess: () => onSubmit("wallet"),
      onError: (error: any) => {
        const msgs: string[] = error?.response?.data?.non_field_errors ?? [];
        if (msgs.length > 0) {
          setNetworkError(msgs.join(" "));
        }
      },
    },
  );

  function onCexFormSubmit() {
    const data = cexForm.getValues();
    addCex(data as Cex);
  }

  function onWalletFormSubmit() {
    if (!network) {
      setNetworkError("Network is required");
      return;
    }
    setNetworkError("");

    const data = {
      ...walletForm.getValues(),
      network,
    } as WalletResponse;
    const { id, ...wallet } = data;
    if (defaultValues) {
      modifyWallet(wallet as Wallet);
    } else {
      addWallet(wallet as Wallet);
    }
  }

  return (
    <div className="relative pb-6">
      <ToggleButton
        onToggle={() => setIsPublicKey((prev) => !prev)}
        leftText="Public Address"
        rightText="Centralized Exchange"
        isDefault={isPublicKey}
        invertColors
      ></ToggleButton>
      {isPublicKey ? (
        <BaseForm
          form={walletForm}
          onSubmit={onWalletFormSubmit}
          buttons={
            <>
              {defaultValues && (
                <Button
                  text="DELETE"
                  type="button"
                  styling="transparent-button"
                  additionalClasses="absolute bottom-0 right-20"
                  onClick={() => removeWallet()}
                />
              )}
              <Button
                text={defaultValues ? "SAVE" : "ADD"}
                type="submit"
                styling="primary-button"
                additionalClasses={
                  defaultValues ? "absolute bottom-0 right-0" : "w-full"
                }
              />
            </>
          }
        >
          <div>
            <div className="my-6">
              <Input
                form={walletForm}
                name="name"
                placeholder="Name"
                registerOptions={{ required: true }}
              />
            </div>
            <div className="my-6">
              <Input
                form={walletForm}
                name="address"
                placeholder="Address"
                registerOptions={{ required: true }}
                disabled={!!defaultValues}
              />
            </div>
          </div>
          <div className="my-4 flex items-center">
            <div className="text-13 text-green-800 mr-6 font-bold dark:text-gray-300">
              Network
            </div>
            {AvailableNetworks.map((n) => (
              <NetworkBox
                key={n}
                kind="network"
                name={n}
                isSelected={network === n}
                onClick={() => setNetwork(n)}
                disabled={!!defaultValues}
              />
            ))}
            <p className="text-12 text-red-500 m-0">{networkError}</p>
          </div>
        </BaseForm>
      ) : (
        <BaseForm
          form={cexForm}
          onSubmit={onCexFormSubmit}
          buttons={
            <Button
              text="ADD"
              type="submit"
              styling="primary-button"
              additionalClasses="w-full"
            />
          }
        >
          <div>
            <div className="my-6">
              <Dropdown
                form={cexForm}
                name="exchange"
                placeholder="Select Exchange"
                options={[{ label: "binance", value: "BINANCE" }]}
                registerOptions={{ required: true }}
              />
            </div>
            <div className="my-6">
              <Input
                form={cexForm}
                name="api_key"
                placeholder="Cex API key"
                type="password"
                registerOptions={{ required: true }}
              />
            </div>
            <div className="my-6">
              <Input
                form={cexForm}
                name="secret_key"
                placeholder="Cex Secret key"
                type="password"
                registerOptions={{ required: true }}
              />
            </div>
          </div>
        </BaseForm>
      )}
    </div>
  );
}
