import format from "date-fns/format";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { getDocuments } from "../../api";
import { useUser } from "../../hooks/useUser";
import { DocumentResponse, LimitResponse } from "../../types";
import FiatAmount from "../FiatAmount";
import Loader from "../Loader";
import { showSnackbar } from "../Snackbar";
import EndOfSubscriptionLightBox from "./EndOfSubscriptionLightBox";

type InvoicesLightboxProps = {
  status: string;
  invoiceLimits: LimitResponse;
};
function InvoicesLightbox({ status, invoiceLimits }: InvoicesLightboxProps) {
  const user = useUser();
  const history = useHistory();

  const [invoicesData, setInvoicesData] = useState<DocumentResponse[]>([]);
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] =
    useState(false);

  const invoicesQueryParams = {
    limit: 2,
    offset: 0,
    ordering: "-created_at",
    state: [status],
    kind: ["INVOICE"],
  };
  const invoicesRes = useQuery(
    "documents_" + status,
    () => getDocuments(invoicesQueryParams),
    {
      enabled: user.full_access,
    },
  );

  useEffect(() => {
    if (invoicesRes.status === "success") {
      setInvoicesData(invoicesRes.data?.results);
    }
    if (invoicesRes.status === "error") {
      invoicesRes.refetch();
    }
    // eslint-disable-next-line
  }, [invoicesRes.status, invoicesRes.isLoading]);

  const data = invoicesData.map((item) => ({
    token: item.invoice?.token,
    created_at: new Date(item.created_at),
    name: item.name,
    id: item.id,
    symbol: item.invoice?.token.symbol,
    total_amount: item.invoice?.total_amount,
    value_fiat: item.invoice?.value_fiat,
    recipient:
      item.invoice?.contact.first_name + " " + item.invoice?.contact.last_name,
  }));

  function handleCreateInvoice() {
    if (!user.full_access) {
      showSnackbar(
        "Create invoice feature is available only for logged-in users.",
      );
      return;
    }

    if (invoiceLimits.can_create) {
      history.push("/new-invoice");
    } else {
      setIsSubscriptionDialogOpen(true);
    }
  }

  return (
    <>
      <div className="mr-2.5 font-bold h-52">
        {status === "DUE" ? (
          <div className="flex items-center text-16">
            <img
              className="w-6 h-6 mr-2"
              src="/icons/Send_fill.svg"
              alt="sent"
            />
            Awaiting Payment
          </div>
        ) : (
          <div className="flex items-center text-16">
            <img
              className="w-6 h-6 mr-2"
              src="/icons/Paid_Invoice.svg"
              alt="completed"
            />
            Payment Completed
          </div>
        )}
        {invoicesRes.isLoading ? (
          <Loader />
        ) : (
          <div>
            {data.map((doc, index) => (
              <div
                key={index}
                className={`${index === 0 ? " border-b-2 " : "  "}`}
              >
                <div className="mt-2 ">
                  {format(new Date(doc.created_at), "MMM d, yyyy")}
                </div>
                <div className="flex items-center">
                  <InitialIcon initials={doc.name.charAt(0) || "A"} />
                  <div className="flex items-center min-w-full justify-between">
                    <div className="flex-col p-2">
                      <div className="font-bold">{doc.recipient}</div>
                      <div className="font-normal">{doc.name}</div>
                    </div>
                    <div className="flex mr-3">
                      <div className="pr-2">
                        <FiatAmount amount={doc.total_amount || 0} />
                      </div>
                      <div>{user.fiat_currency}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {invoicesData.length > 0 && (
              <div
                className="font-bold text-center justify-center mt-4 cursor-pointer"
                onClick={() => {
                  window.scrollTo(0, 0);
                  history.push("/documents");
                }}
              >
                See All
              </div>
            )}
            {invoicesData.length === 0 && (
              <div className="text-center m-5 mt-16">
                Create an invoice
                <span
                  className="text-green-300 pl-1 cursor-pointer "
                  onClick={handleCreateInvoice}
                >
                  here.{" "}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      <EndOfSubscriptionLightBox
        dialogText={`Oh no! You can only create ${invoiceLimits?.limit} invoices on your current plan. Upgrade your plan to proceed.`}
        isSubscriptionDialogOpen={isSubscriptionDialogOpen}
        onCloseSubscriptionDialog={() => setIsSubscriptionDialogOpen(false)}
      />
    </>
  );
}

export default InvoicesLightbox;

export const InitialIcon = ({ initials }: { initials: string }) => (
  <div className="rounded-full mx-1 text-16 w-6 h-6 text-center text-gray-50 bg-green-300 px-2 py-0.5 justify-center">
    {initials}
  </div>
);
