import { Popover, Transition } from "@headlessui/react";
import { addDays, format, isAfter, isBefore, isWithinInterval } from "date-fns";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { ThemeContext } from "../App";
import BaseForm from "../components/forms/_BaseForm";
import { AdminTier, LoginMethod, Network, Token } from "../types";
import { capitalize } from "../utils";
import Calendar from "./_Calendar";
import Dropdown from "./forms/_Dropdown";
import Input from "./forms/_Input";
import NetworkBox from "./NetworkBox";
import Slider from "./Slider";

type BnFilterProps = {
  form: UseFormReturn<any>;
  minDate: Date;
  tokens?: Token[];
  networks?: Network[];
  loginMethods?: LoginMethod[];
  userPlans?: AdminTier[];
  isAdminOnly?: boolean;
  isRightSide?: boolean;
  title?: string | JSX.Element;
  nftCollections?: string[];
  nftPropertiesNumber?: number;
  customReset?: () => void;
  customIsResetNeeded?: () => boolean;
};

export default function BnFilter({
  title = "Timeframe",
  minDate,
  form,
  tokens,
  networks,
  loginMethods,
  isAdminOnly,
  userPlans,
  isRightSide,
  nftCollections,
  nftPropertiesNumber,
  customReset = () => {},
  customIsResetNeeded = () => false,
}: BnFilterProps) {
  const theme = useContext(ThemeContext);
  const [firstRender, setFirstRender] = useState(true);
  const [isFilter, setIsFilter] = useState(false);

  function handleFilterChoice(elem: string, formKey: string) {
    const elemsArr = form.getValues(formKey);
    form.setValue(
      formKey,
      elemsArr.includes(elem)
        ? elemsArr.filter((c: string) => c !== elem)
        : [...elemsArr, elem],
    );
  }

  useEffect(() => {
    function isResetNeeded() {
      return (
        form.watch("start_date") !== format(minDate, "yyyy-MM-dd") ||
        form.watch("end_date") !== format(new Date(Date.now()), "yyyy-MM-dd") ||
        customIsResetNeeded()
      );
    }
    const timeout = setTimeout(
      () => {
        setIsFilter(isResetNeeded);
        setFirstRender(false);
      },
      firstRender ? 1000 : 0,
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [customIsResetNeeded, firstRender, form, minDate]);

  function resetFilters() {
    form.setValue("start_date", format(minDate, "yyyy-MM-dd"));
    form.setValue("end_date", format(new Date(Date.now()), "yyyy-MM-dd"));
    customReset();
  }
  return (
    <div className="flex items-center gap-x-4">
      <button
        onClick={resetFilters}
        className={`flex items-center space-x-1 ${
          isFilter ? "block" : "invisible"
        }`}
      >
        <img
          src={`/icons/${theme}/close.svg`}
          alt="filter"
          className="w-4 h-4"
        />
        <span className="text-green-800 dark:text-gray-300 font-bold">
          Clear Filters
        </span>
      </button>
      <Popover className="relative">
        <Popover.Button className="bg-green-800 flex space-x-2 items-center px-5 py-3 rounded-md">
          <img
            src={`/icons/${theme}/page_header/filter_white.svg`}
            alt="filter"
          />
          <span className="text-white font-bold">Filter By</span>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            className={`absolute z-10 flex flex-col space-y-9 w-80 p-2 mt-3 transform sm:p-7 sm:pr-9 rounded-lg bg-white capitalize text-14 text-green-800 shadow-filter ${
              isRightSide ? "-left-12" : "-right-8"
            } dark:bg-gray-950 dark:shadow-dark dark:text-gray-300`}
          >
            {!nftCollections && (
              <div className="flex flex-col space-y-5">
                <p>{title}</p>
                <DateTablePopover form={form} minDate={minDate} theme={theme} />
              </div>
            )}
            {tokens && (
              <div className="flex flex-col mt-7 mb-2">
                tokens
                <div className="grid grid-cols-4">
                  {tokens.map((t) => (
                    <NetworkBox
                      key={t.id}
                      kind="token"
                      name={t.symbol}
                      isSelected={form
                        .watch("selectedTokens")
                        .includes(t.id.toString())}
                      img={t.image_url}
                      onClick={() =>
                        handleFilterChoice(t.id.toString(), "selectedTokens")
                      }
                    />
                  ))}
                </div>
              </div>
            )}
            {loginMethods && (
              <div className="flex flex-col mt-7 mb-2">
                Login Method
                <div className="grid grid-cols-4">
                  {loginMethods.map((l) => (
                    <NetworkBox
                      key={l}
                      kind="token"
                      name={l}
                      isSelected={form
                        .watch("selectedLoginMethods")
                        .includes(l)}
                      img={`/icons/wallets/${l.toLowerCase()}.svg`}
                      onClick={() =>
                        handleFilterChoice(l, "selectedLoginMethods")
                      }
                    />
                  ))}
                </div>
              </div>
            )}
            {networks && (
              <div className="flex flex-col mt-7 mb-2">
                Blockchain
                <div className="grid grid-cols-4">
                  {networks.map((n) => (
                    <NetworkBox
                      key={n}
                      kind="token"
                      name={n}
                      isSelected={form.watch("selectedNetworks").includes(n)}
                      img={`/icons/network/${n.toLowerCase()}.svg`}
                      onClick={() => handleFilterChoice(n, "selectedNetworks")}
                    />
                  ))}
                </div>
              </div>
            )}
            {userPlans && (
              <div className="flex flex-col mt-7 mb-2">
                Status
                <Dropdown
                  form={form}
                  name="selectedPlans"
                  label="Select plan"
                  options={userPlans.map((p) => ({
                    label: capitalize(p),
                    value: p,
                  }))}
                  multiSelect
                />
              </div>
            )}
            {isAdminOnly !== undefined && (
              <div className="flex items-center gap-x-3">
                <div
                  className={`w-5 h-5 rounded relative flex items-center justify-center cursor-pointer ${
                    isAdminOnly ? "bg-green-200" : "border border-green-200"
                  }`}
                  onClick={() => {
                    form.setValue("isAdminOnly", !isAdminOnly);
                  }}
                >
                  {isAdminOnly && (
                    <img src={`/icons/${theme}/check_white.svg`} alt="check" />
                  )}
                </div>
                <span className="text-14">Admins only</span>
              </div>
            )}
            {nftCollections && (
              <NftFilter
                form={form}
                collections={nftCollections}
                propertiesNumber={nftPropertiesNumber || 0}
              />
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}

type DateTablePopoverProps = {
  form: UseFormReturn<any>;
  minDate: Date;
  theme: string;
  isOnLeftSide?: boolean;
  isSmallIcon?: boolean;
  isBnTable?: boolean;
};

export function DateTablePopover({
  form,
  minDate,
  theme,
  isOnLeftSide,
  isSmallIcon,
  isBnTable,
}: DateTablePopoverProps) {
  const registerOptions = {
    required: true,
    pattern: {
      value: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
      message: "date should be in yyyy-mm-dd format",
    },
    validate: {
      isValidDate: (potentialDate: string) =>
        !!Date.parse(potentialDate) &&
        isWithinInterval(new Date(potentialDate), {
          start: addDays(minDate, -1),
          end: new Date(Date.now()),
        }),
    },
  };

  return (
    <Popover className="relative normal-case">
      <BaseForm form={form}>
        {!isSmallIcon ? (
          <Popover.Button className="flex justify-between items-center border border-gray-300 rounded-md mr-2 py-1.5 px-3 text-12 w-full">
            <p className="w-1/3">{form.watch("start_date")}</p>
            <div className="h-5 w-px bg-gray-300"></div>
            <p className="w-1/3">{form.watch("end_date")}</p>
            <img src={`/icons/${theme}/calendar.svg`} alt="calendar" />
          </Popover.Button>
        ) : (
          <Popover.Button className="flex flex-auto mt-4 items-center">
            <img
              src={`/icons/${theme}/filter.svg`}
              alt="border"
              className=" min-w-max"
            />
          </Popover.Button>
        )}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            className={`absolute z-10 flex px-12 py-9 flex-col ${
              isOnLeftSide ? "left-full" : "right-full"
            } mr-10 -top-1/3 lg:flex-row rounded-20px bg-white text-14 text-green-800 shadow-filter-date dark:bg-gray-950 dark:shadow-dark`}
          >
            <div className="flex flex-col items-start lg:mr-20">
              <div className="calendar-block">
                <p>From</p>
                <div>
                  <Input
                    form={form}
                    name="start_date"
                    registerOptions={{
                      ...registerOptions,
                      validate: {
                        ...registerOptions.validate,
                        isEarlierThan: (v: string) =>
                          isBefore(
                            addDays(new Date(v), -1),
                            new Date(form.getValues("end_date") || Date.now()),
                          ),
                      },
                    }}
                    autoComplete="off"
                    mainClasses="input-date"
                  />
                </div>
              </div>
              <Calendar
                form={form}
                minDate={minDate}
                formFieldName="start_date"
                isBnTable
              />
            </div>
            <div className="flex flex-col items-center">
              <div className="calendar-block">
                <p>To</p>
                <div>
                  <Input
                    form={form}
                    name="end_date"
                    registerOptions={{
                      ...registerOptions,
                      validate: {
                        ...registerOptions.validate,
                        isLaterThan: (v: string) =>
                          isAfter(
                            addDays(new Date(v), 1),
                            new Date(form.getValues("start_date") || minDate),
                          ),
                      },
                    }}
                    autoComplete="off"
                    mainClasses="input-date"
                  />
                </div>
              </div>
              <Calendar
                form={form}
                minDate={minDate}
                formFieldName="end_date"
              />
            </div>
          </Popover.Panel>
        </Transition>
      </BaseForm>
    </Popover>
  );
}

function NftFilter({
  form,
  collections,
  propertiesNumber,
}: {
  form: UseFormReturn<any>;
  collections: string[];
  propertiesNumber: number;
}) {
  const sortingOptions = [
    {
      order_by: "-created_at",
      label: "Newest",
    },
    {
      order_by: "price",
      label: "Price (Low to High)",
    },
    {
      order_by: "-price",
      label: "Price (High to Low)",
    },
  ];

  return (
    <div className="flex flex-col gap-y-5  text-14 color-green-800">
      <div className="flex flex-col gap-y-3">
        {sortingOptions.map((o) => (
          <SortingOption key={o.label} {...o} form={form} />
        ))}
      </div>
      <div className="flex flex-col">
        Collection
        <Dropdown
          form={form}
          name="collection"
          placeholder="Select collection"
          options={collections.map((c) => ({
            label: c,
            value: c,
          }))}
          disabled={!collections.length}
        />
      </div>
      <div className="flex flex-col">
        Number of properties
        <Slider
          value={+form.getValues("properties_number")}
          onChange={(val: number) => {
            form.setValue("properties_number", val);
          }}
          min={0}
          max={propertiesNumber}
          marks={propertiesNumber + 1}
          startLabel="Off"
        />
      </div>
    </div>
  );
}

type SortingOptionProps = {
  form: UseFormReturn<any>;
  order_by: string;
  label: string;
  defaultOption?: string;
};

function SortingOption({
  form,
  order_by,
  label,
  defaultOption = "created_at",
}: SortingOptionProps) {
  const theme = useContext(ThemeContext);

  const isChecked = form.getValues("ordering") === order_by;

  return (
    <div
      className="flex justify-between cursor-pointer"
      onClick={() => {
        if (form.getValues("ordering") === order_by) {
          form.setValue("ordering", defaultOption);
        } else form.setValue("ordering", order_by);
      }}
    >
      <p className={`${isChecked && "font-semibold"}`}>{label}</p>
      {isChecked && <img src={`/icons/${theme}/check.svg`} alt="check" />}
    </div>
  );
}
