import React from "react";

import { copyText } from "../utils";

type AddressProps = {
  address: string;
  name?: string;
};

export default function ContactAddress({ address, name }: AddressProps) {
  return (
    <span
      className="cursor-pointer text-green-300 truncate"
      onClick={() => copyText(address)}
    >
      {name ?? address}
    </span>
  );
}
