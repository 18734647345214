/**
 * Used for local development only.
 * Note: none of these will work when deployed to any environment!
 */

/**
 * The URL to the backend API - defaults to relative to the website.
 */
export const API_URL = process.env.REACT_APP_API_URL ?? "";

/**
 * Whether to use Django Debug Toolbar for requests to the API.
 * This can be helpful for debugging, but can also slow down the application significantly.
 */
export const DEBUG = (process.env.REACT_APP_DEBUG ?? "false") === "true";
