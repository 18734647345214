import { FaqElem } from "../types";

const FaqData: FaqElem[] = [
  {
    question: "Can I use basenode.io on my mobile device?",
    answer:
      "Yes! The basenode.io WebApp is responsive and can be used on most mobile browsers. We are working on a native app for Android and iOS.",
  },
  {
    question: "What are the benefits of basenode.io Premium?",
    answer:
      'We believe that basenode.io should be free to use for the majority of users. With a premium membership, you are supporting our mission to make getting paid in crypto super easy. On top of that, some restrictions of the free version are lifted. Learn more about the premium plan <a href="/pricing">here.</a>',
  },
  {
    question: "What Tokens do you support? ",
    answer:
      'Currently, we support the top 1000 tokens per network. If we don’t have your favorite token, message us on <a href="https://discord.com/invite/UwGymSsS5g">Discord</a> and we will implement it as soon as we can.',
  },
  {
    question: "Which Blockchains do you support?",
    answer: `We currently have support for Bitcoin, Ethereum, BSC, Polygon & Fantom.\n
    Is your favorite Blockchain-Network missing? Message us on <a href="https://discord.com/invite/UwGymSsS5g">Discord</a> and we can implement it!`,
  },
  {
    question:
      "Can I connect a Wallet from a centralized exchange (Coinbase, Binance, etc.)? ",
    answer: `Currently this feature is only available for Binance wallets, but Coinbase & Kraken fill follow soon.\n
      Is your favorite exchange missing?  Message us on <a href="https://discord.com/invite/UwGymSsS5g">Discord</a> and we can implement it!`,
  },
  {
    question: "Where can I get a Wallet to get started? ",
    answer: `We do not offer custodial wallets and believe that you should hold your own keys. The most secure way to store your crypto is a hardware wallet like <a href="https://shiftcrypto.ch/">Bitbox©</a> or <a href="https://ledger.com/">Ledger©</a>. The best user experience for the basenode.io App is available by using <a href="https://metamask.io/">MetaMask©</a> or a <a href="https://walletconnect.com/registry?type=wallet&version=2">Walletconnect©</a> compatible wallet.  Additionally you can also securly login with your Google account and add your public Key/Wallet Address. `,
  },
  {
    question: "My Balance is not shown correctly, what can I do?",
    answer: `If your balances or token holdings are not shown correctly, message our <a href="https://www.basenode.io/contact">Support</a> and we will investigate the issue immediately. For this please provide your Login Type (e.g. MetaMask); The Public Key/Wallet, which has problems; and ideally screenshots of the issue.
    `,
  },

  {
    question: "How many Wallets can I connect to basenode.io?",
    answer: `In the Free Version you can only connect 1 wallet, if you have the need for additional wallets you need a premium plan. Learn more about our premium plans <a href="/pricing">here.</a>`,
  },
  {
    question: "Which FIAT-currencies do you support?",
    answer: `Currently we support more than 25 FIAT-currencies from around the world. If your country’s currency is missing, message us on <a href="https://discord.com/invite/UwGymSsS5g">Discord</a> and we will implement it!`,
  },
  {
    question: "Is my data safely stored?",
    answer:
      "At basenode all of your data is stored in an encrypted vault, to which only you hold the keys. There is no way for anyone but yourself (or someone with your public key) to view the data you enter into the app.",
  },
  {
    question: "How can basenode guarantee privacy for its users?",
    answer:
      "Basenode will never ask you for any personal information. If you want to stay anonymous, you can login securely with your preferred wallet.",
  },
  {
    question: "Does basenode.io store my coins?",
    answer:
      "No, all coins/tokens are stored on the blockchain, basenode also is not a wallet provider.",
  },
  {
    question: "Can I use basenode.io in my country?",
    answer: `Outside from North Korea basenode should be available to you. If you have problems with accessing our app do not hesitate to message our <a href="https://www.basenode.io/contact">Support.</a>`,
  },
  {
    question: "Can you please support my local language?",
    answer:
      "Currently the basenode app is only available in English, but we will add a lot more in the near future.",
  },
  {
    question: "What new features are you planning to introduce?",
    answer: `For this please take a look at our <a href="https://www.basenode.io/features">roadmap</a>.`,
  },
];

export default FaqData;
