import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router";

import { deleteContact, getContacts, getInvoiceLimit } from "../api";
import ActionPopover from "../components/ActionPopover";
import BnDialog from "../components/BnDialog";
import ContactForm from "../components/forms/ContactForm";
import LabelBadge from "../components/labels/LabelBadge";
import LabelsPopup from "../components/labels/LabelsPopup";
import Loader from "../components/Loader";
import EndOfSubscriptionLightBox from "../components/overview/EndOfSubscriptionLightBox";
import PageHeader from "../components/PageHeader";
import { showSnackbar } from "../components/Snackbar";
import Tutorial from "../components/tutorial/Tutorial";
import useEndTutorial from "../hooks/useEndTutorial";
import { useUser } from "../hooks/useUser";
import {
  ContactAddress,
  ContactResponse,
  ContactsParams,
  LabelResponse,
  LocationProps,
} from "../types";
import { shortenAddress } from "../utils";

export default function ContactsPage() {
  const location = useLocation<LocationProps>();
  const history = useHistory();
  const queryClient = useQueryClient();
  const endTutorial = useEndTutorial();
  const tutorial = location.state?.tutorial;

  const [tutorialState, setTutorialState] = useState<string>();

  const user = useUser();
  const [queryParams, setQueryParams] = useState<ContactsParams>({
    ordering: "name",
    search: location.state ? location.state.search : "",
  });

  const [contacts, setContacts] = useState<ContactResponse[]>([]);
  const [contactToView, setContactToView] = useState<ContactResponse | null>(
    null,
  );
  const [isContactDescriptionOpen, setIsContactDescriptionOpen] =
    useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [onEdit, setOnEdit] = useState<ContactResponse | undefined>(undefined);

  const { data, isLoading, refetch } = useQuery(
    ["contacts", queryParams],
    () => getContacts(queryParams),
    { enabled: user.full_access },
  );

  const { mutate: removeContact } = useMutation(
    (id: number) => deleteContact(id),
    {
      onSuccess: () => onDeleteContact(),
      onError: (error: any) => {
        if (error.response.data.non_field_errors) {
          showSnackbar(error.response.data.non_field_errors.join(", "));
        }
      },
    },
  );
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] =
    useState(false);

  const { data: InvoiceLimits } = useQuery("invoiceLimits", () =>
    getInvoiceLimit(),
  );
  useEffect(() => {
    if (location?.state?.search) {
      setQueryParams((prevParams) => ({
        ...prevParams,
        search: location.state.search,
      }));
    }
  }, [location]);

  useEffect(() => {
    if (data) {
      setContacts(data);
    }
  }, [data]);

  useEffect(() => {
    if (contactToView) {
      setContactToView(
        data!.find((contact) => contact.id === contactToView.id) ?? null,
      );
    }
  }, [data, contactToView]);

  useEffect(() => {
    if (tutorial) {
      setTutorialState(tutorial);
    }
  }, [tutorial]);

  function closeTutorialFlow() {
    endTutorial();
    setTutorialState(undefined);
  }

  if (!user.full_access) {
    return (
      <div>
        <PageHeader title="Contacts" />
        <div className="w-full mt-8 text-center font-bold text-green-800 dark:text-gray-300">
          This feature is available only for logged in users.
        </div>
      </div>
    );
  }

  function onContactSubmittion() {
    onDialogClose();
    refetch();
    queryClient.refetchQueries("transactions");
  }

  function onDialogClose() {
    setIsDialogOpen(false);
    setTimeout(() => {
      setOnEdit(undefined);
    }, 300); //Wait for dialog to close
  }

  function onDeleteContact() {
    refetch();
    showSnackbar("Contact Deleted Successfully!");
  }

  function handleContactDescription(contact: ContactResponse) {
    setContactToView(contact);
    setIsContactDescriptionOpen(true);
  }

  function onViewContactDialogClose() {
    setIsContactDescriptionOpen(false);
    setContactToView(null);
    setTimeout(() => {}, 300); //Wait for dialog to close
  }

  return (
    <div>
      <EndOfSubscriptionLightBox
        dialogText={`Oh no! You can only create ${InvoiceLimits?.limit} invoices on your current plan. Upgrade your plan to proceed.`}
        isSubscriptionDialogOpen={isSubscriptionDialogOpen}
        onCloseSubscriptionDialog={() => setIsSubscriptionDialogOpen(false)}
      />
      <Tutorial
        title="Organize your contacts"
        desc="Use the Contacts page to save information about your contacts and their wallets. Here you should input your client's information for easier invoicing. On the Contacts page, you can also create an invoice right away."
        isOpen={!!tutorialState && !user.is_tutorial_viewed}
        image={tutorialState}
        currentTutorial={5}
        maxTutorial={7}
        onClose={closeTutorialFlow}
        nextTutorial={() =>
          history.push("/documents", { tutorial: "document_tutorial_1" })
        }
        prevTutorial={() =>
          history.push("/transactions", { tutorial: "transaction_tutorial" })
        }
        positionStyle="absolute top-24 left-52 xl:left-96"
        pointer={{
          className: "absolute left-14 -top-14",
          style: {
            borderBottom: "30px solid white",
            borderRight: "30px solid transparent",
            borderTop: "30px solid transparent",
            borderLeft: "30px solid transparent",
          },
        }}
      />
      <div className="mx-4">
        <PageHeader
          title="Contacts"
          headerButtonProps={{
            iconName: "add_contact",
            text: "Add Contact",
            onClick: () => setIsDialogOpen(true),
          }}
        />
      </div>
      {contacts.length ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {contacts.map((contact) => (
            <div
              key={contact.id}
              className="bg-white rounded-20px p-8 m-2 cursor-pointer relative dark:bg-gray-900 dark:text-gray-300"
              onClick={() => handleContactDescription(contact)}
            >
              <div
                className="absolute top-0 right-0 m-3"
                onClick={(e) => e.stopPropagation()}
              >
                <ActionPopover
                  actions={[
                    {
                      icon: "edit",
                      contentText: "edit contact",
                      clickAction: () => {
                        setOnEdit({
                          ...contact,
                          labels: contact.labels.map(
                            (l: LabelResponse) => l.name,
                          ),
                        });
                        setIsDialogOpen(true);
                      },
                    },
                    {
                      icon: "create_invoice",
                      contentText: "Create invoice",
                      clickAction: () => {
                        InvoiceLimits?.can_create
                          ? history.push("/new-invoice", {
                              invoice: { contact },
                            })
                          : setIsSubscriptionDialogOpen(true);
                      },
                    },
                    {
                      icon: "trash_documents",
                      contentText: "Delete",
                      clickAction: () => {
                        removeContact(contact.id);
                      },
                    },
                  ]}
                />
              </div>
              <div className="flex flex-col items-center mb-5">
                <img
                  src="/icons/light/user-octagon.svg"
                  alt="action"
                  className="mb-4"
                />
                <h3 className="font-bold">
                  {contact.first_name} {contact.last_name}
                </h3>
                <p className="font-extralight text-14">{contact.company}</p>
              </div>
              <div className="flex text-12 truncate mb-2">
                <p className="font-semibold mr-8">Email</p>
                <p>{contact.email}</p>
              </div>
              <div className="flex text-12 mb-2">
                <p className="font-semibold mr-4">Address</p>
                <div className="flex">
                  {contact.addresses.map((address: ContactAddress) => (
                    <img
                      key={`${address.network}-${address.address}`}
                      className="w-4 mr-2"
                      src={`/icons/network/${address.network.toLowerCase()}.svg`}
                      alt={address.network}
                    />
                  ))}
                </div>
              </div>
              <div className="flex text-12">
                <p className="font-semibold mr-8">Label</p>
                <div>
                  <LabelsPopup
                    chosenLabels={contact.labels}
                    contactId={contact.id}
                    onLabelChange={onContactSubmittion}
                  >
                    <div className="flex flex-wrap">
                      {contact.labels.length > 0 ? (
                        contact.labels.map((label: LabelResponse) => (
                          <LabelBadge key={label.id} label={label} />
                        ))
                      ) : (
                        <p className="text-white hover:text-gray-900 dark:text-gray-900 dark:hover:text-gray-300">
                          + Add labels
                        </p>
                      )}
                    </div>
                  </LabelsPopup>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : isLoading ? (
        <div className="absolute mt-10 left-0 right-0 z-50">
          <Loader />
        </div>
      ) : (
        <h1 className="text-40 text-center mt-8 text-green-800">
          No Contacts Added.
        </h1>
      )}
      <BnDialog
        isOpen={isDialogOpen}
        title={`${onEdit ? "Edit" : "Add"} Contact`}
        centerTitle
        description={
          <ContactForm onSubmit={onContactSubmittion} defaultValues={onEdit} />
        }
        onClose={onDialogClose}
      />
      {contactToView && (
        <BnDialog
          isOpen={isContactDescriptionOpen}
          title=""
          centerTitle
          description={
            <ContactDetailsDialog
              contact={contactToView}
              onContactSubmittion={onContactSubmittion}
            />
          }
          onClose={onViewContactDialogClose}
        />
      )}
    </div>
  );
}

type ContactToViewProps = {
  contact: ContactResponse;
  onContactSubmittion: () => void;
};

function ContactDetailsDialog({
  contact,
  onContactSubmittion,
}: ContactToViewProps) {
  return (
    <>
      <div className="flex justify-between divide-x-2 dark:text-gray-300">
        <div className="flex flex-col justify-center text-center w-1/2">
          <h3 className="font-bold text-18 mb-4">
            {contact.first_name} {contact.last_name}
          </h3>
          <p className="font-extralight text-14">{contact.company}</p>
        </div>
        <div className="pl-8 w-1/2">
          <div className="flex text-12 mb-2">
            <p className="font-semibold mr-8">email</p>
            <div className="break-all">
              <p>{contact.email}</p>
            </div>
          </div>
          <div className="flex text-12 mb-2">
            <p className="font-semibold mr-4">address</p>
            <div>
              {contact.addresses.map((address: ContactAddress) => (
                <div
                  className="flex"
                  key={`${address.network}-${address.address}`}
                >
                  <img
                    className="w-4 mr-2"
                    src={`/icons/network/${address.network.toLowerCase()}.svg`}
                    alt={address.network}
                  />
                  <p>{shortenAddress(address.address)}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex text-12 mb-2">
            <p className="font-semibold mr-8">label</p>
            <LabelsPopup
              chosenLabels={contact.labels}
              contactId={contact.id}
              onLabelChange={onContactSubmittion}
            >
              <div className="flex flex-wrap">
                {contact.labels.length > 0 ? (
                  contact.labels.map((label: LabelResponse) => (
                    <LabelBadge key={label.id} label={label} />
                  ))
                ) : (
                  <p>+ Add labels</p>
                )}
              </div>
            </LabelsPopup>
          </div>
          <div className="flex text-12 mb-2">
            <p className="font-semibold mr-4">location</p>
            <div>
              <p>{contact.address_line1}</p>
              <p>{`${contact.city}, ${contact.country}`}</p>
              <p>{contact.postal_code}</p>
            </div>
          </div>
        </div>
      </div>
      <button></button>
    </>
  );
}
