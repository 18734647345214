import { Popover, Transition } from "@headlessui/react";
import React, {
  Fragment,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";

import { createLabel, editLabel } from "../../api";
import { ThemeContext } from "../../App";
import useMutationWithForm from "../../hooks/useMutationWithForm";
import { AvailableColors, LabelResponse } from "../../types";
import { capitalize } from "../../utils";
import BaseForm from "../forms/_BaseForm";
import Input from "../forms/_Input";

type PopupProps = PropsWithChildren<{
  onSubmit: () => void;
  providedLabel?: LabelResponse;
  remove?: (id: number) => void;
}>;

const colors = [...AvailableColors];

export default function LabelDetailsPopup({
  providedLabel,
  onSubmit,
  remove,
  children,
}: PopupProps) {
  const theme = useContext(ThemeContext);

  const form = useForm();
  const [selectedColor, setSelectedColor] = useState(
    providedLabel?.color ?? "RED",
  );
  const [showOnTop, setShowOnTop] = useState(false);

  useEffect(() => {
    if (providedLabel) {
      form.setValue("name", providedLabel.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: addNew } = useMutationWithForm(
    form,
    () =>
      createLabel({
        name: form.getValues("name"),
        color: selectedColor,
      }),
    {
      onSuccess: () => {
        onSubmit();
        form.reset();
        setSelectedColor("RED");
      },
    },
  );

  const { mutate: edit } = useMutationWithForm(
    form,
    () =>
      editLabel(providedLabel!.id, {
        name: form.getValues("name"),
        color: selectedColor,
      }),
    { onSuccess: () => onSubmit() },
  );

  function handleRefBoundaries(el: HTMLDivElement | null) {
    const windowHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0,
    );
    const elHeight = el?.clientHeight;
    const yPosition = el?.getBoundingClientRect().y;
    const approxBottomMargin = 80;

    if (
      yPosition &&
      windowHeight - yPosition < approxBottomMargin + elHeight!
    ) {
      setShowOnTop(true);
    }
  }

  return (
    <Popover className="">
      <Popover.Button className="cursor-pointer flex flex-wrap">
        {children}
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel
          className={`absolute z-20 right-28 flex flex-col text-gray-800 text-12 bg-white w-60 rounded-lg shadow-lg dark:bg-gray-950 ${
            showOnTop ? "bottom-0" : "top-0"
          }`}
        >
          <div ref={handleRefBoundaries} className="flex flex-col p-4">
            <BaseForm
              form={form}
              onSubmit={providedLabel ? edit : addNew}
              buttons={
                <Popover.Button>
                  <div className="w-full">
                    <button
                      type="submit"
                      className="primary-button absolute bottom-0 right-0 mr-4 mb-2"
                    >
                      {providedLabel ? "EDIT" : "ADD"}
                    </button>
                  </div>
                </Popover.Button>
              }
            >
              <Input
                form={form}
                name="name"
                placeholder="Name"
                registerOptions={{ required: true }}
              />
              <p className="mb-2 text-green-800 text-11 dark:text-gray-300">
                Choose color
              </p>
              <div className="flex flex-col mb-5">
                {colors.map((col) => (
                  <div
                    className="flex my-1 items-center cursor-pointer"
                    key={col}
                    onClick={() => setSelectedColor(col)}
                  >
                    <div
                      className={`w-4 h-4 mr-2 bg-${col.toLowerCase()}-100`}
                    ></div>
                    <p className="text-gray-800 dark:text-gray-300">
                      {capitalize(col)}
                    </p>
                    {selectedColor === col && (
                      <img
                        className="ml-auto"
                        src={`/icons/${theme}/check.svg`}
                        alt="check"
                      />
                    )}
                  </div>
                ))}
                {providedLabel && (
                  <div
                    className="flex my-1 items-center cursor-pointer"
                    key="removeLabel"
                    onClick={() => remove && remove(providedLabel.id)}
                  >
                    <img
                      src={`/icons/${theme}/actions/trash_light.svg`}
                      alt="delete"
                      className="w-4 h-4 mr-2"
                    />
                    <p className="text-gray-800">Remove label</p>
                  </div>
                )}
              </div>
            </BaseForm>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
