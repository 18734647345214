import { PricingCardProps } from "../types";

const pricingData: PricingCardProps[] = [
  {
    priceMonthly: "Free",
    priceAnnually: "Free",
    btnText: "Unsubscribe",
    features: ["NFT & DeFi support", "Unlimited history coverage"],
  },
  {
    btnText: "Subscribe",
    percentGain: "8",
    features: [
      "NFT & DeFi support",
      "Unlimited history coverage",
      "Quickbooks Integration",
    ],
  },
  {
    priceMonthly: "Individual Price",
    priceAnnually: "Individual Price",
    btnText: "Reach Out",
    features: [
      "Payroll Management",
      "Company Wallet Management",
      "Whitelabel Invoices",
      "Individual Payment Links",
      "API Access",
    ],
  },
];

export default pricingData;
