import Web3 from "web3";

import { Network, NetworkChainData } from "./types";
// Taken from https://ethereumdev.io/abi-for-erc20-contract-on-ethereum/
import erc20contractABI from "./utils/erc20contractABI.json";

const ethereum = window.ethereum;
export const web3 = new Web3(ethereum);

async function suggestedPriorityFee() {
  if (ethereum.chainId === "0x38") {
    return null;
  }
  try {
    const data = await web3.eth.getFeeHistory(1, "latest", [50]);
    return data.reward[0][0];
  } catch (priorityFeeError) {
    console.error(priorityFeeError);
  }
}

// Unlocks MetaMask or fails if user doesn't have it, must be called before anything else
export async function ensureAddress() {
  if (!ethereum || !ethereum.isMetaMask) {
    // User has no MetaMask
    alert("No MetaMask installed.");
  } else {
    await ethereum.request({ method: "eth_requestAccounts" });
    return ethereum.selectedAddress;
  }
}

export async function signChallenge(challenge: string) {
  const signature = await ethereum.request({
    method: "personal_sign",
    params: [ethereum.selectedAddress, challenge],
  });
  return signature;
}

export async function transferEther(toAddress: string, amountHex: string) {
  const txHash = await ethereum.request({
    method: "eth_sendTransaction",
    params: [
      {
        from: ethereum.selectedAddress,
        to: toAddress,
        value: amountHex,
      },
    ],
  });
  return txHash;
}

export async function switchNetwork(
  networkChainData: NetworkChainData[Network],
) {
  try {
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: networkChainData!.chainId }],
    });
    return true;
  } catch (switchError: any) {
    // The network has not been added to MetaMask...
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [networkChainData],
        });
        return true;
      } catch (addError) {
        console.error(addError);
        alert(`Failed to add ${networkChainData!.chainName} to MetaMask.`);
        return false;
      }
    }
    console.error(switchError);
    alert(`Cannot switch to the ${networkChainData!.chainName} network`);
    return false;
  }
}

export class ERC20Contract {
  private readonly contract: any;

  constructor(address: string) {
    // https://github.com/ChainSafe/web3.js/issues/3310
    this.contract = new web3.eth.Contract(erc20contractABI as any, address);
  }
  public async transfer(
    toAddress: string,
    amountHex: string,
    data?: any,
    callback?: any,
  ) {
    return this.contract.methods
      .transfer(toAddress, amountHex)
      .send({
        from: ethereum.selectedAddress,
        maxPriorityFeePerGas: await suggestedPriorityFee(),
      })
      .on("transactionHash", (hash: any) => {
        if (callback && data) {
          callback(data, hash);
        }
      });
  }
}
