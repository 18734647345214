import { Popover, Transition } from "@headlessui/react";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { getInvoiceLimit, getTransactions, getWalletLimit } from "../../api";
import { ThemeContext, UserContext } from "../../App";
import BnDialog from "../BnDialog";
import ContactForm from "../forms/ContactForm";
import DocumentForm from "../forms/DocumentForm";
import WalletForm from "../forms/WalletForm";
import EndOfSubscriptionLightBox from "../overview/EndOfSubscriptionLightBox";

type QuickAddItem = {
  icon: string;
  alt: string;
  text: string;
  onClick: () => void;
};

export default function QuickAdd() {
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const { user } = useContext(UserContext);

  const [isCreateReportDialogOpen, setIsCreateReportDialogOpen] =
    useState(false);
  const [isAddContactDialogOpen, setIsAddContactDialogOpen] = useState(false);
  const [isWalletDialogOpen, setIsWalletDialogOpen] = useState(false);
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] = useState({
    isOpen: false,
    subject: "invoices",
  });

  const { data: lastTransaction } = useQuery(["lastTransaction"], () =>
    getTransactions({
      limit: 1,
      offset: 0,
      ordering: "created_at",
    }),
  );
  const { data: invoiceLimits } = useQuery("invoiceLimits", () =>
    getInvoiceLimit(),
  );
  const { data: walletLimits } = useQuery("walletLimits", () =>
    getWalletLimit(),
  );
  const items: QuickAddItem[] = [
    {
      icon: "/icons/light/page_header/create_invoice_green.svg",
      alt: "Invoice",
      text: "Create Invoice",
      onClick: () => {
        invoiceLimits?.can_create
          ? history.push("/new-invoice")
          : setIsSubscriptionDialogOpen({ isOpen: true, subject: "invoices" });
      },
    },
    {
      icon: "/icons/light/page_header/reports.svg",
      alt: "Report",
      text: "Create Report",
      onClick: () => {
        setIsCreateReportDialogOpen(true);
      },
    },
    {
      icon: "/icons/light/page_header/add_contact_green.svg",
      alt: "Contact",
      text: "Add Contact",
      onClick: () => {
        setIsAddContactDialogOpen(true);
      },
    },
    {
      icon: "/icons/light/page_header/wallets.svg",
      alt: "Wallet",
      text: "Add Wallet",
      onClick: () => {
        walletLimits?.can_create
          ? setIsWalletDialogOpen(true)
          : setIsSubscriptionDialogOpen({ isOpen: true, subject: "wallets" });
      },
    },
  ];

  function onSubmittion() {
    setIsCreateReportDialogOpen(false);
    setIsAddContactDialogOpen(false);
    setIsWalletDialogOpen(false);
  }

  useEffect(() => {
    onSubmittion();
  }, []);

  return (
    <Popover className="relative z-30">
      <EndOfSubscriptionLightBox
        dialogText={
          planLimitsMessage(
            isSubscriptionDialogOpen.subject,
            isSubscriptionDialogOpen.subject === "invoices"
              ? invoiceLimits?.limit || 0
              : walletLimits?.limit || 0,
          ) || ""
        }
        isSubscriptionDialogOpen={isSubscriptionDialogOpen.isOpen}
        onCloseSubscriptionDialog={() =>
          setIsSubscriptionDialogOpen({ isOpen: false, subject: "invoices" })
        }
      />
      <Popover.Button className="text-green-800 dark:text-gray-300 font-bold leading-8 text-40">
        +
      </Popover.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel
          className={`absolute -right-1 mt-2 rounded-lg bg-white px-8 py-5 w-${
            user.full_access ? 48 : 64
          } shadow-filter dark:bg-gray-950 dark:shadow-dark`}
        >
          <div
            className="absolute right-2 -top-4 dark:border-b-gray-950"
            style={{
              borderBottom: `8px solid ${
                theme === "light" ? "white" : "#242629"
              }`,
              borderRight: "8px solid transparent",
              borderTop: "8px solid transparent",
              borderLeft: "8px solid transparent",
            }}
          />
          <div
            className={
              user.full_access
                ? "flex flex-col space-y-4 wrap-nowrap cursor-pointer"
                : ""
            }
          >
            {user.full_access ? (
              items.map((item) => (
                <div
                  key={item.text}
                  onClick={item.onClick}
                  className="flex items-center space-x-2"
                >
                  <img className="w-4 h-4" src={item.icon} alt={item.alt} />
                  <span className="text-green-800 dark:text-gray-300 text-14 whitespace-nowrap">
                    {item.text}
                  </span>
                </div>
              ))
            ) : (
              <p className="text-green-800 dark:text-gray-300 font-bold leading-8">
                Sign-in with MetaMask, WalletConnect, or Google to access this
                feature.
              </p>
            )}
          </div>
        </Popover.Panel>
      </Transition>
      <BnDialog
        isOpen={isCreateReportDialogOpen}
        title="Create new report"
        centerTitle
        maxWidth="max-w-2xl"
        description={
          <DocumentForm
            onSubmit={onSubmittion}
            minDate={
              new Date(lastTransaction?.results[0]?.created_at || Date.now())
            }
          />
        }
        onClose={onSubmittion}
      />
      <BnDialog
        isOpen={isAddContactDialogOpen}
        title="Contact information"
        centerTitle
        description={<ContactForm onSubmit={onSubmittion} />}
        onClose={onSubmittion}
      />
      <BnDialog
        isOpen={isWalletDialogOpen}
        title="Add Wallet"
        centerTitle
        description={<WalletForm onSubmit={onSubmittion} />}
        onClose={onSubmittion}
      />
    </Popover>
  );
}
export const planLimitsMessage = (subject: string, limit: number) => {
  if (subject === "invoices") {
    const message =
      "Oh no! You can only create " +
      limit +
      " invoices on your current plan. Upgrade your plan to proceed.";
    return message;
  }
  if (subject === "wallets") {
    const message =
      "Oh no! You have reached your maximum " +
      limit +
      " wallets and public keys. Upgrade your plan to see more.";
    return message;
  }
  if (subject === "transactions") {
    const message =
      "Oh no, with your current plan you can only load " +
      limit +
      " transactions. To import the rest of your transactions, upgrade your plan.";
    return message;
  }
};
