import { useContext } from "react";
import { useMutation } from "react-query";

import { updateUser } from "../api";
import { UserContext } from "../App";
import { showSnackbar } from "../components/Snackbar";

export default function useEndTutorial() {
  const { user, setUser } = useContext(UserContext);

  const { mutate: endTutorial } = useMutation(
    "tutorial",
    () => updateUser({ is_tutorial_viewed: true }),
    {
      onSuccess: () => setUser({ ...user, is_tutorial_viewed: true }),
      onError: () => showSnackbar("Something went wrong while ending tutorial"),
    },
  );
  return endTutorial;
}
