import React, { useContext } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

import { manageCurrentSubscription } from "../api";
import { ThemeContext } from "../App";
import {
  AccountSection,
  IntegrationsSection,
  ProfileSection,
  SectionButton,
  UserAvatar,
} from "../components/settings";
import { showSnackbar } from "../components/Snackbar";
import useQueryParam from "../hooks/useQueryParam";

export default function SettingsPage() {
  const theme = useContext(ThemeContext);
  const history = useHistory();

  const query = useQueryParam();
  const section = query.get("section");

  const { mutate: manageSubscription } = useMutation(
    () => manageCurrentSubscription(),
    {
      onSuccess: (data) => {
        window.open(data.redirect_url, "_self");
      },
      onError: (e: any) => {
        showSnackbar(e.response?.data?.plan);
        setTimeout(() => {
          history.push("/pricing");
        }, 3000);
      },
    },
  );
  return (
    <div
      style={{ minHeight: "80vh" }}
      className="bg-white mt-8 rounded-20px p-6 my-auto mlg:p-12 w-full overflow-auto dark:bg-gray-900 grow"
    >
      <UserAvatar />
      <div
        style={{ gridTemplateColumns: "10rem auto" }}
        className="grid space-x-32 mt-8"
      >
        <div className="flex flex-col space-y-2 text-14 text-green-800 dark:text-gray-300">
          <SectionButton
            currSection={section}
            section="profile"
            theme={theme}
          />
          <SectionButton
            currSection={section}
            section="settings"
            theme={theme}
          />
          <div
            className="flex items-center space-x-2 rounded-md w-36 py-2 pl-6 ml-2 cursor cursor-pointer"
            onClick={() => manageSubscription()}
          >
            <p>$</p>
            <p>Billing</p>
          </div>
          <SectionButton
            currSection={section}
            section="integrations"
            theme={theme}
          />
        </div>
        {section === "settings" ? (
          <AccountSection />
        ) : section === "profile" ? (
          <ProfileSection />
        ) : (
          <IntegrationsSection />
        )}
      </div>
    </div>
  );
}
