import Hotjar from "@hotjar/browser";
import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import FaqButton from "./components/FaqButton";
import Footer from "./components/Footer";
import { BasicNavBar, FullNavBar } from "./components/navBar";
import useLocalStorage from "./hooks/useLocalStorage";
import { useUser } from "./hooks/useUser";
import AddressFromURLPage from "./pages/AddressFromURLPage";
import AdminPage from "./pages/AdminPage";
import ContactsPage from "./pages/ContactsPage";
import DocumentsPage from "./pages/DocumentsPage";
import FaqPage from "./pages/FaqPage";
import FiatPaymentKYCPage from "./pages/FiatPaymentKYCPage";
import HomePage from "./pages/HomePage";
import InvoiceCreationPage from "./pages/InvoiceCreationPage";
import InvoiceDetailsPage from "./pages/InvoiceDetailsPage";
import InvoicePaymentPage from "./pages/InvoicePaymentPage";
import NotFoundPage from "./pages/NotFoundPage";
import OverviewPage from "./pages/OverviewPage";
import PricingPage from "./pages/PricingPage";
import SettingsPage from "./pages/SettingsPage";
import TransactionsPage from "./pages/TransactionsPage";
import WalletsPage from "./pages/WalletsPage";

export const publicRoutes = [
  { path: "/auth", component: HomePage },
  { path: "/pay-invoice/:id", component: InvoiceDetailsPage },
  { path: "/pay-invoice/:id/payment", component: InvoicePaymentPage },
  { path: "/not-found", component: NotFoundPage },
  { path: "/faq", component: FaqPage },
];
const privateRoutes = [
  { path: "/overview", component: OverviewPage },
  { path: "/transactions", component: TransactionsPage },
  { path: "/contacts", component: ContactsPage },
  { path: "/wallets", component: WalletsPage },
  { path: "/documents", component: DocumentsPage },
  { path: "/invoices/:id", component: InvoiceDetailsPage },
  { path: "/new-invoice", component: InvoiceCreationPage },
  { path: "/settings", component: SettingsPage },
  { path: "/pricing", component: PricingPage },
  { path: "/fiat-payment-kyc", component: FiatPaymentKYCPage },
];

const adminRoutes = [{ path: "/admin", component: AdminPage }];

export default function Routes() {
  const user = useUser();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [theme] = useLocalStorage("theme", "light");
  const BASENODE_MAIN_APP = "app.basenode.io";

  const hotjarSiteId =
    BASENODE_MAIN_APP === window.location.hostname ? 3560031 : 0;
  const hotjarSnippetVersion = 6;

  useEffect(() => {
    window.addEventListener("Basenode:logout", () => {
      queryClient.resetQueries();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  useEffect(() => {
    Hotjar.init(hotjarSiteId, hotjarSnippetVersion);
  }, [hotjarSiteId]);

  function isOnPublic() {
    return (
      publicRoutes.some((route) => location.pathname === route.path) ||
      location.pathname.includes("pay-invoice")
    );
  }

  function isOnPrivate() {
    return (
      privateRoutes.some((route) => location.pathname === route.path) ||
      location.pathname.includes("invoices")
    );
  }

  function isOnAdmin() {
    return adminRoutes.some((route) => location.pathname === route.path);
  }

  if (!user && (isOnPrivate() || isOnAdmin())) {
    return <Redirect to="/auth" />;
  }

  if (!user?.is_staff && isOnAdmin()) {
    return <Redirect to="/overview" />;
  }

  return (
    <>
      {user ? <FullNavBar /> : <BasicNavBar />}
      <div
        style={{ minHeight: "90vh" }}
        className={
          "flex flex-col justify-between space-y-8 mx-auto sm:px-10 px-4 2xl:w-3/4 w-full max-w-6xl my-0 overflow-y-auto" +
          (isOnAdmin() && " max-w-7xl")
        }
      >
        <div style={{ flexGrow: 1 }}>
          <Switch>
            <Route exact path="/">
              <Redirect to={user ? "/overview" : "/auth"} />
            </Route>
            {privateRoutes.map((route) => (
              <Route
                exact
                key={route.path}
                path={route.path}
                component={route.component}
              />
            ))}
            {adminRoutes.map((route) => (
              <Route
                exact
                key={route.path}
                path={route.path}
                component={route.component}
              />
            ))}
            <Route
              component={isOnPublic() ? PublicRoutes : AddressFromURLPage}
            />
          </Switch>
        </div>
        {(isOnPrivate() || (location.pathname === "/faq" && user)) && (
          <div className="sticky flex justify-end bottom-10">
            <FaqButton />
          </div>
        )}
        {!["/transactions", "/documents"].includes(location.pathname) && (
          <Footer />
        )}
      </div>
    </>
  );
}

function PublicRoutes() {
  // If user logedin and wants to pay invoice
  return (
    <>
      {publicRoutes.map((route) => (
        <Route
          exact
          key={route.path}
          path={route.path}
          component={route.component}
        />
      ))}
    </>
  );
}
