import React, { ReactElement } from "react";
import { useHistory } from "react-router";

import BnDialog from "./BnDialog";
import Button from "./forms/_Button";

type NotificationLightBoxProps = {
  dialogTitle: string;
  dialogMessage: ReactElement;
  isNotificationDialogOpen: boolean;
  onCloseDialog: () => void;
  mascotImage?: string;
  destinationOnClose?: string;
  buttonText?: string;
};
const NotificationLightBox = ({
  dialogTitle,
  dialogMessage,
  isNotificationDialogOpen,
  onCloseDialog,
  mascotImage,
  destinationOnClose,
  buttonText,
}: NotificationLightBoxProps) => {
  const history = useHistory();
  const onClickOptions = () => {
    destinationOnClose && history.push(destinationOnClose);
  };
  return (
    <div>
      <BnDialog
        isOpen={isNotificationDialogOpen}
        title={dialogTitle}
        maxWidth="max-w-xl"
        centerTitle
        titleSize={20}
        description={
          <div className="flex  flex-col justify-center align-middle  m-3 mt-1 ">
            <div className="m-7 mt-2 text-center text-16 font-normal dark:text-gray-100">
              {dialogMessage}
            </div>

            {mascotImage && (
              <img className="mt-7 mb-12 h-36" src={mascotImage} alt="notify" />
            )}

            <Button
              text={buttonText || "OK"}
              type="button"
              styling="primary-button"
              additionalClasses="w-full focus:outline-none font-bold"
              onClick={onClickOptions}
            />
          </div>
        }
        onClose={onCloseDialog}
        closeButton={false}
      />
    </div>
  );
};

export default NotificationLightBox;
