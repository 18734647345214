import { pdf } from "@react-pdf/renderer";

export default async function downloadPDF(
  name: string,
  component: JSX.Element,
) {
  const blob = await pdf(component).toBlob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function downloadFileByHref(name: string, href: string) {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.href = href;
  a.download = name;
  a.target = "_blank";
  a.rel = "noreferrer noopener";
  a.click();
}
