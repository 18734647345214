import React from "react";

import { Crypto, Network } from "../types";
import { capitalize } from "../utils";

type NetworkBoxProps = {
  kind: "currency" | "network" | "token";
  name: Crypto | Network | string;
  isSelected: boolean;
  onClick: () => void;
  img?: string;
  disabled?: boolean;
};

export default function NetworkBox({
  kind,
  name,
  isSelected,
  onClick,
  img,
  disabled,
}: NetworkBoxProps) {
  return (
    <div
      className={`rounded-md w-10 h-10 flex items-center justify-center bg-gray-100 my-4 mr-6 dark:bg-gray-900 ${
        disabled ? "cursor-default" : "cursor-pointer"
      } ${
        isSelected &&
        "border border-green-900 dark:border-gray-50 dark:bg-gray-50"
      } `}
      style={{ boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)" }}
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      <img
        className="w-5 h-5"
        src={
          kind === "token" && img
            ? img
            : `/icons/${kind}/${name.toLowerCase()}.svg`
        }
        alt={kind === "network" ? capitalize(name) : name}
      />
    </div>
  );
}
