import React, { useContext, useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { useHistory } from "react-router-dom";

import { loginWithGoogle } from "../api";
import { ThemeContext, UserContext } from "../App";
import DemoLogin from "../components/DemoLogin";
import Loader from "../components/Loader";
import WalletLogin from "../components/WalletLogin";

const googleClientId =
  "240555916041-djtnmd6bkhmj41g7iidhqcmp73vuc4li.apps.googleusercontent.com";

export default function HomePage() {
  const history = useHistory();
  const theme = useContext(ThemeContext);

  const { user, setUser } = useContext(UserContext);

  const [errMess, setErrMess] = useState("");
  const [isPageLoaded, setIsPageLoaded] = useState(
    document.readyState === "complete",
  );

  useEffect(() => {
    if (user) {
      history.push("/overview");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const handleLoad = () => {
      setIsPageLoaded(document.readyState === "complete");
    };

    document.addEventListener("readystatechange", handleLoad);

    return () => {
      document.removeEventListener("readystatechange", handleLoad);
    };
  }, []);

  async function responseGoogle(googleObject: any) {
    try {
      const res = await loginWithGoogle(googleObject.accessToken);

      setUser({
        ...res,
        signed_email: googleObject.profileObj.email,
        address: "",
        full_access: true,
        type: "GOOGLE",
      });
      history.push("/overview");
    } catch (e: any) {
      const errors = e.response?.data?.non_field_errors ?? [];
      setErrMess(errors.join(" "));
    }
  }

  return (
    <div>
      {isPageLoaded ? (
        <div className="w-full flex flex-col items-center space-y-8 tall:space-y-12">
          <div>
            <p className="mt-4 tall:mt-20 h-12 text-center text-green-800 text-30 font-bold dark:text-gray-300">
              Sign In
            </p>
          </div>

          <div className="flex flex-col items-center w-full">
            <GoogleLogin
              clientId={googleClientId}
              render={(renderProps) => (
                <button
                  className="cursor-pointer mb-3 flex items-center justify-between w-64 h-16 border border-gray-450-t-50 rounded-md px-4"
                  onClick={renderProps.onClick}
                >
                  <span className="text-green-800">Sign in with Google</span>
                  <img
                    src={`/icons/${theme}/auth/google_logo_no_border.svg`}
                    alt="Google account"
                  />
                </button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy="single_host_origin"
            />
            <WalletLogin onError={(e) => setErrMess(e)} />
            <p className="text-red-500">{errMess}</p>

            <div className="relative max-w-lg w-full h-px bg-gray-500 my-10">
              <div className="absolute left-1/2 transform -translate-x-1/2 -top-2.5 w-14 h-5 bg-white flex justify-center items-center text-18 text-green-800 dark:bg-gray-950 dark:text-gray-300">
                Or
              </div>
            </div>
            <DemoLogin />
          </div>
        </div>
      ) : (
        <Loader loaderStyle="w-20 h-auto" />
      )}
    </div>
  );
}
