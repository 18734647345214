import { createContext } from "react";

const DocumentCurrencyFilter = createContext<{
  currencyFilter: string;
  setDocumentCurrencyFilter: (currency: string) => void;
}>({
  currencyFilter: "ETH",
  setDocumentCurrencyFilter: () => {},
});

export default DocumentCurrencyFilter;
