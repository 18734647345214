import SumsubWebSdk from "@sumsub/websdk-react";
import React, { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { getEmailOTP, getFTC, setUpFTC } from "../api";
import BnDialog from "../components/BnDialog";
import Button from "../components/forms/_Button";
import CreateFTCForm from "../components/forms/CreateFTCForm";
import KYCFTCForm from "../components/forms/KYCFTCForm";
import Loader from "../components/Loader";
import { showSnackbar } from "../components/Snackbar";
import { useUser } from "../hooks/useUser";
import { FTCSetupResponse } from "../types";

export default function FiatPaymentKYCPage() {
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isKYCForm, setIsKYCForm] = useState(false);
  const [kycToken, setKycToken] = useState<string>("");
  const [ftc, setFtc] = useState<FTCSetupResponse>();

  const user = useUser();
  const history = useHistory();

  if (!user.full_access) {
    history.push("/auth");
  }

  useQuery("ftc", () => getFTC(), {
    refetchInterval: 10000,
    enabled: !ftc || ftc.kyc !== "FULL_USER",
    onSuccess: (data) => handleFTC(data),
  });

  if (kycToken) {
    return (
      <SumsubWebSdk
        accessToken={kycToken}
        expirationHandler={() => {}} //This should not expire....
      />
    );
  }

  if (isKYCForm && ftc?.ftc_session_id) {
    return (
      <KYCFTCForm
        onKYCFormSuccess={setKycToken}
        ftc_session_id={ftc.ftc_session_id}
      />
    );
  }

  function onOTPSuccess(ftc: FTCSetupResponse) {
    setFtc(ftc);
    setIsKYCForm(true);
    setIsOTPSent(false);
  }

  function onCreateFTCSuccess(ftc: FTCSetupResponse) {
    setFtc(ftc);
  }

  function handleFTC(data: FTCSetupResponse) {
    if (!ftc) {
      setFtc(data);
    } else if (ftc.kyc !== data.kyc) {
      setFtc((prevFTC) => {
        if (prevFTC) {
          return {
            ...prevFTC,
            kyc: data.kyc,
          };
        }
      });
    }

    if (data.kyc === "FULL_USER" && kycToken) {
      showSnackbar(
        "Fiat-to-Crypto invoicing successfully activated for all Invoices.",
      );

      setTimeout(() => {
        history.push("/auth");
      }, 5000);
    }
  }

  return (
    <>
      <div className="flex justify-center mt-10">
        {!ftc ? (
          <CreateFTCForm onSuccess={onCreateFTCSuccess} />
        ) : ftc.kyc === "FULL_USER" ? (
          <KYCSuccess />
        ) : (
          <LoginFTCUser
            uuid={ftc.user_uuid}
            onSuccess={() => setIsOTPSent(true)}
          />
        )}
      </div>
      {ftc && (
        <BnDialog
          isOpen={isOTPSent}
          title="Please enter the Code you received via email from us. Check your Spam folder, if you can not find the confirmation email."
          centerTitle
          description={
            <OTPForm ftcId={ftc.user_uuid} onSuccess={onOTPSuccess} />
          }
          onClose={() => setIsOTPSent(false)}
        />
      )}
    </>
  );
}

type OTPFormProps = {
  ftcId: string;
  onSuccess: (ftc: FTCSetupResponse) => void;
};

function OTPForm({ ftcId, onSuccess }: OTPFormProps) {
  const [otp, setOTP] = useState<(number | null)[]>([
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  const inputRefs = useRef<HTMLInputElement[]>([]);

  const {
    mutate: setUpUser,
    isLoading,
    error,
  } = useMutation((otp: string) => setUpFTC(ftcId, otp), {
    onSuccess: (ftc) => {
      onSuccess(ftc);
    },
  });

  function handleOTPChange(
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) {
    const enteredDigit = parseInt(e.target.value, 10);
    const updatedOTP = [...otp];

    // Check if the entered value is a valid number (0-9) or empty string
    if (!isNaN(enteredDigit) || e.target.value === "") {
      updatedOTP[index] = isNaN(enteredDigit) ? null : enteredDigit;
      setOTP(updatedOTP);

      if (index < otp.length - 1 && e.target.value !== "") {
        inputRefs.current[index + 1].focus();
      }
    }
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const isEveryDigitNumber = otp.every((digit) => typeof digit === "number");
    if (!isEveryDigitNumber) return;

    const enteredOTP = otp.join("");
    setUpUser(enteredOTP);
  }

  return (
    <div className="flex justify-center items-center">
      {!isLoading ? (
        <form onSubmit={handleSubmit} className="text-center">
          <div className="flex justify-center space-x-4 my-5">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                minLength={1}
                maxLength={1}
                className="w-10 h-10 text-20 text-center border-2 rounded focus:outline-none border-green-800 focus:border-green-300 focus:ring-green-300 focus:ring"
                value={digit !== null ? digit : ""}
                onChange={(e) => handleOTPChange(e, index)}
                ref={(input) => {
                  if (input) {
                    inputRefs.current[index] = input;
                  }
                }}
              />
            ))}
          </div>
          <Button text="Verify" styling="primary-button" type="submit" />
          {error && (
            <p className="text-12 text-red-500 font-semibold">
              {((error as any).response?.data?.non_field_errors ?? []).join(
                " ",
              )}
            </p>
          )}
        </form>
      ) : (
        <Loader />
      )}
    </div>
  );
}

type LoginFTCUserType = {
  uuid: string;
  onSuccess: () => void;
};

function LoginFTCUser({ uuid, onSuccess }: LoginFTCUserType) {
  const { mutate: getOTP, isLoading } = useMutation(
    (ftc_id: string) => getEmailOTP(ftc_id),
    {
      onSuccess,
    },
  );

  return (
    <div>
      {!isLoading ? (
        <div className="flex flex-col justify-center items-center">
          <p className="text-green-800 dark:text-gray-300 text-20 font-bold">
            Click on the Button below to Setup your Fiat to Crypto Account.
          </p>
          <Button
            styling="primary-button"
            additionalClasses="mt-10 w-1/2"
            text="Setup Account"
            onClick={() => getOTP(uuid)}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

function KYCSuccess() {
  const history = useHistory();

  return (
    <div className="mt-10 space-y-6">
      <p className="text-center text-20 text-green-800 dark:text-gray-300 font-bold ">
        Your KYC is Successful. Your Invoices can now get paid in Fiat!
      </p>
      <div className="flex justify-center">
        <Button
          styling="primary-button"
          text="Overview"
          additionalClasses="mr-10"
          onClick={() => history.push("/auth")}
        />
        <Button
          styling="primary-button"
          text="New Invoice"
          additionalClasses="bg-yellow-50"
          onClick={() => history.push("/new-invoice")}
        />
      </div>
    </div>
  );
}
