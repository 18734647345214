import React from "react";

import { TransactionCex, Wallet } from "../types";

type WalletDetailsProps = {
  wallet: TransactionCex | Wallet;
};

function WalletDetails({ wallet }: WalletDetailsProps) {
  return (
    <div className="flex space-x-3 text-gray-1000 font-semibold dark:text-gray-300">
      {/* Check if network is a wallet and not a centralised exchange (cex)... */}
      {"network" in wallet ? (
        <>
          <img
            src={`/icons/network/${wallet.network.toLowerCase()}.svg`}
            alt={wallet.name}
            className="w-4 h-4"
          />
          <div>{wallet.name}</div>
        </>
      ) : (
        <>
          <img
            src={`/icons/cex/${wallet.exchange.toLowerCase()}.svg`}
            alt={wallet.exchange}
            className="w-4 h-4"
          />
          <div>{wallet.exchange}</div>
        </>
      )}
    </div>
  );
}

export default WalletDetails;
