import { createContext } from "react";

import { InvoiceTypes } from "../types";

const invoiceTypes = InvoiceTypes.map((c) => c);
const CheckedItemsContext = createContext<{
  checkedItems: any[];
  setCheckedItems: (checkedItems: any[]) => void;
  allItems?: any[];
}>({
  checkedItems: invoiceTypes,
  setCheckedItems: () => {},
  allItems: [],
});

export default CheckedItemsContext;
