import React, { useContext, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useQuery } from "react-query";

import { createFTC, getWallets } from "../../api";
import { ThemeContext } from "../../App";
import useMutationWithForm from "../../hooks/useMutationWithForm";
import { FTC, FTCSetupResponse, WalletResponse } from "../../types";
import countries from "../../utils/countriesAlphaCode.json";
import BnDialog from "../BnDialog";
import InfoPopover from "../InfoPopover";
import Loader from "../Loader";
import BaseForm from "./_BaseForm";
import Button from "./_Button";
import Dropdown from "./_Dropdown";
import Input from "./_Input";
import WalletForm from "./WalletForm";

type CreateFTCFormProps = {
  onSuccess: (ftc: FTCSetupResponse) => void;
};

const supportedTokens = ["USDC", "USDT"];

export default function CreateFTCForm({ onSuccess }: CreateFTCFormProps) {
  const theme = useContext(ThemeContext);
  const form = useForm<FieldValues>();
  const [chosenWallet, setChosenWallet] = useState<WalletResponse | null>(null);
  const [walletError, setWalletError] = useState<string>();
  const [isTokenOpen, setIsTokenOpen] = useState(false);
  const [chosenCrypto, setChosenCrypto] = useState(supportedTokens[0]);

  const { mutate: addFTC, isLoading } = useMutationWithForm(
    form,
    (data: FTC) => createFTC(data),
    {
      onSuccess: (ftc) => {
        onSuccess(ftc);
      },
    },
  );

  useEffect(() => {
    if (chosenWallet) {
      form.setValue("wallet", chosenWallet.id);
    }
  }, [chosenWallet, form]);

  useEffect(() => {
    form.setValue("token_preference", chosenCrypto);
  }, [chosenCrypto, form]);

  function onCreateFTCFormSubmit() {
    if (!chosenWallet) setWalletError("No Wallet selected.");
    const data = form.getValues() as FTC;
    addFTC(data);
  }

  function choseWallet(w: WalletResponse) {
    setChosenWallet(w);
    setWalletError(undefined);
  }

  return (
    <div className="w-full">
      <div className="w-1/2 p-10 mx-auto rounded-md">
        <p className="text-24 p-2 font-bold text-green-800 dark:text-gray-300">
          Fiat-to-Crypto Invoicing
        </p>
        <p>
          With Fiat-to-Crypto invoicing your clients can choose to pay your
          invoices in Euro (EUR) using a regular bank transfer. The payment is
          instantly converted into stable coins and sent to your wallet. The
          initial setup will take about 5 minutes and requires a light KYC
          process.
        </p>
        <p>
          <br />
          <strong>A flat fee of 1.5% of the invoice amount applies.</strong>
        </p>
        <div className="flex mt-10 mb-2">
          <p className="font-semibold mr-2">
            How do you want to receive funds? *
          </p>
          <div className="relative">
            <InfoPopover
              text="The cryptocurrency that you will receive as payment."
              additionalPopoverClasses="left-10 -bottom-8"
            />
          </div>
        </div>
        <div className="w-48 px-6 py-4 mb-10 absolute border bg-white border-gray-450 dark:bg-gray-950 rounded-md focus:outline-none">
          <div className="flex justify-between">
            <div className="flex">
              <img
                src={`/icons/token/${chosenCrypto.toLowerCase()}.svg`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "/icons/logo_small.svg";
                }}
                alt={chosenCrypto}
                className="w-4 h-4 mt-1 mr-3"
              />
              <p>{chosenCrypto}</p>
            </div>
            <img
              className="w-5 relative left-2 cursor-pointer"
              src={
                isTokenOpen
                  ? `/icons/${theme}/checkbox/check.svg`
                  : `/icons/${theme}/arrows/expand_down_light.svg`
              }
              alt="dropdown"
              onClick={() => setIsTokenOpen((prev) => !prev)}
            />
          </div>
          {isTokenOpen && !isLoading && (
            <div
              className={`overflow-y-auto overflow-x-hidden max-h-60 ${
                theme === "dark" ? "custom-scroll-dark" : "custom-scroll"
              }`}
            >
              {supportedTokens.map((token) => (
                <div
                  className="flex mt-2 cursor-pointer"
                  key={token}
                  onClick={() => {
                    setChosenCrypto(token);
                    setIsTokenOpen(false);
                  }}
                >
                  <img
                    src={`/icons/token/${token.toLowerCase()}.svg`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "/icons/logo_small.svg";
                    }}
                    alt={token}
                    className="w-4 h-4 mt-1 mr-3"
                  />
                  <p>{token}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {!isLoading ? (
        <div className="bg-white w-1/2 p-10 mt-20 mx-auto rounded-md">
          <p className="font-bold text-green-800 dark:text-gray-300">
            Please make sure to fill your name below exactly like stated in your
            identity document.
          </p>
          <BaseForm
            form={form}
            onSubmit={onCreateFTCFormSubmit}
            buttons={
              <Button
                text="Continue"
                normalText
                type="submit"
                styling="primary-button"
                additionalClasses="w-full capitalize text-16 cursor-pointer font-bold"
              />
            }
          >
            <div className="space-y-6">
              <Input
                form={form}
                name="first_name"
                placeholder="First Name*"
                type="text"
                registerOptions={{ required: true }}
              />
              <Input
                form={form}
                name="last_name"
                placeholder="Last Name*"
                type="text"
                registerOptions={{ required: true }}
              />
              <Input
                form={form}
                name="email"
                placeholder="Email*"
                type="email"
                registerOptions={{ required: true }}
              />
              <div>
                <TargetAddress
                  chosenWallet={chosenWallet}
                  setChosenWallet={choseWallet}
                />
                {walletError && (
                  <p className="text text-red-500 text-12">{walletError}</p>
                )}
              </div>
              <Dropdown
                form={form}
                name="country"
                placeholder="Country*"
                options={Object.entries(countries).map(([key, value]) => ({
                  label: value,
                  value: key,
                }))}
                registerOptions={{ required: true }}
              />
            </div>
          </BaseForm>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

type TargetAddressProps = {
  chosenWallet: WalletResponse | null;
  setChosenWallet: (w: WalletResponse) => void;
};

function TargetAddress({ chosenWallet, setChosenWallet }: TargetAddressProps) {
  const theme = useContext(ThemeContext);

  const [isWalletOpen, setIsWalletOpen] = useState(false);
  const [newWalletOpen, setNewWalletOpen] = useState(false);

  const [wallets, setWallets] = useState<WalletResponse[]>();

  const {
    data,
    isLoading: walletsLoading,
    refetch: refetchWallets,
  } = useQuery("wallets", () => getWallets({ ordering: "name" }));

  function onWalletSubmit() {
    setNewWalletOpen(false);
    refetchWallets();
  }

  useEffect(() => {
    if (data) {
      setWallets(
        data.filter((w) => w.network === "POLYGON" || w.network === "ETHEREUM"),
      );
    }
  }, [data]);

  return (
    <div className="px-4 py-2 w-full bg-gray-100 dark:bg-gray-900 rounded-md">
      <div className="flex justify-between items-center">
        <div className="flex">
          <img
            src={
              chosenWallet
                ? `/icons/network/${chosenWallet.network.toLowerCase()}.svg`
                : "/icons/warning_circle.svg"
            }
            alt="icon"
            className="mr-4 w-5"
          />
          <p
            className={`text-left text-14 ${!chosenWallet && "text-gray-450"}`}
          >
            {chosenWallet ? chosenWallet.name : "Find or add wallet*"}
          </p>
        </div>
        <img
          src={`/icons/${theme}/arrows/${
            isWalletOpen ? "expand_up" : "expand_down_light"
          }.svg`}
          alt="dropdown"
          className="h-4 cursor-pointer"
          onClick={() => setIsWalletOpen((prev: boolean) => !prev)}
        />
      </div>
      {isWalletOpen && (
        <div>
          <div
            className="flex cursor-pointer my-3 w-max p-1 rounded-md hover:bg-opacity-50 hover:bg-gray-100"
            onClick={() => setNewWalletOpen(true)}
          >
            <img src={`/icons/${theme}/plus.svg`} alt="add" className="mr-2" />
            <p className="text-16">Add new wallet</p>
          </div>
          <div
            className={`flex flex-col overflow-y-auto -mr-8 ${
              theme === "dark" ? "custom-scroll-dark" : "custom-scroll"
            }`}
            style={{ maxHeight: "280px" }}
          >
            {walletsLoading && <Loader />}
            {wallets?.length === 0 && !walletsLoading && (
              <p className="text-12">No wallets found</p>
            )}
            {wallets &&
              wallets.map((w) => (
                <div
                  key={w.id}
                  className="my-2 p-1 cursor-pointer mr-10"
                  onClick={() => {
                    setChosenWallet(w);
                    setIsWalletOpen(false);
                  }}
                >
                  <div className="flex items-center w-full">
                    <img
                      src={`/icons/network/${w.network.toLowerCase()}.svg`}
                      alt="network"
                      className="mr-2 w-5"
                    />
                    <p className="text-14 text-left text-gray-800 mr-4 dark:text-gray-300">
                      {w.name}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      <BnDialog
        isOpen={newWalletOpen}
        title="Add Wallet"
        centerTitle
        description={<WalletForm onSubmit={onWalletSubmit} />}
        onClose={() => setNewWalletOpen(false)}
      />
    </div>
  );
}
