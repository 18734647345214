import React from "react";
import ReactTooltip, { Place } from "react-tooltip";

type TooltipProps = {
  place?: Place | undefined;
};

export default function Tooltip({ place = "left" }: TooltipProps) {
  return (
    <ReactTooltip
      place={place}
      delayHide={100}
      delayShow={100}
      arrowColor="transparent"
      className="bg-gray-450 text-white text-14 h-7 px-2 whitespace-nowrap opacity-100 rounded flex items-center dark:bg-gray-600 dark:text-gray-300"
    />
  );
}
