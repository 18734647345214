import { addDays } from "date-fns";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { getFTC } from "../../api";
import { UserContext } from "../../App";
import { SuggestionProps } from "../../types";
import Suggestion from "./Suggestion";

export default function Suggestions() {
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);

  const { data: ftc } = useQuery("ftc", () => getFTC(), {
    refetchInterval: false,
  });

  function handleFTCClose() {
    setUser({
      ...user,
      suggestions: {
        ...user.suggestions,
        ftcTimeout: addDays(new Date(), 1),
      },
    });
  }

  function handleVisibleFTC() {
    if (!user.full_access) return false;

    if (ftc && ftc.kyc === "FULL_USER") return false;

    if (!user?.suggestions?.ftcTimeout) return true;

    return new Date() > new Date(user.suggestions.ftcTimeout);
  }

  function isSuggestionVisible() {
    return handleVisibleFTC();
  }

  const suggestions: SuggestionProps[] = [
    {
      header: "Set up Fiat-to-Crypto Invoicing",
      body: "Receive Crypto or Bitcoin, while your clients pay in EUR. Payments are automatically converted and sent to your Crypto Wallet.",
      onClose: handleFTCClose,
      onClick: () => history.push("/fiat-payment-kyc"),
      isVisible: handleVisibleFTC(),
    },
  ];

  return (
    <div className={`${isSuggestionVisible() ? "block" : "hidden"} mb-8`}>
      <h2 className="text-18 text-green-900 pb-8">Suggested for you</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-8">
        {suggestions.map((s) => (
          <Suggestion
            key={s.header}
            header={s.header}
            body={s.body}
            onClose={s.onClose}
            onClick={s.onClick}
            isVisible={s.isVisible}
            extraClass={s.extraClass}
          />
        ))}
      </div>
    </div>
  );
}
