import format from "date-fns/format";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { getUserGrowthHistory } from "../../api";
import { ThemeContext } from "../../App";
import Loader from "../Loader";
import CustomTooltip from "../overview/CustomTooltip";

export default function UserHistoryChart() {
  const [selectedInterval, setSelectedInterval] = useState("1M");
  const timeIntervals = [
    { label: "1W", enabled: true },
    { label: "1M", enabled: true },
    { label: "3M", enabled: true },
    { label: "1Y", enabled: true },
  ];
  const theme = useContext(ThemeContext);

  const { data, isLoading } = useQuery(
    ["userGrowthHistory", selectedInterval],
    () => getUserGrowthHistory(selectedInterval),
    { refetchInterval: 10000 },
  );

  if (isLoading) {
    return (
      <div style={{ minHeight: "245px" }}>
        <Loader />
      </div>
    );
  }
  //store data in state variable for reuse

  const domain = [
    Math.ceil(Math.min(0, ...data!.map((p) => p.num_users))),
    Math.ceil(Math.max(...data!.map((p) => p.num_users))),
  ];

  function formatxTick(value: string) {
    //formats date as day-month like 21 JUN
    return format(new Date(value), "d MMM").toUpperCase();
  }

  return (
    <div className="w-full h-56 mb-10 text-12 text-green-800 ">
      <div className="text-13 text-green-800 flex space-x-8 self-end mr-7 mb-5 justify-end">
        {timeIntervals.map((interval) => {
          const selected = selectedInterval === interval.label;
          return (
            <div
              key={interval.label}
              className={`relative w-6 text-center ${selected && "font-bold"} ${
                interval.enabled
                  ? "cursor-pointer text-green-800 dark:text-gray-300"
                  : "cursor-default text-green-800 opacity-50 dark:text-gray-450"
              }`}
              onClick={() => {
                if (interval.enabled) setSelectedInterval(interval.label);
              }}
            >
              {interval.label}
              {selected && (
                <div className="absolute inset-x-0 mt-px top-9 border border-green-300 dark:border-gray-300"></div>
              )}
            </div>
          );
        })}
      </div>
      <div className="w-full h-px bg-gray-450-t-58 mb-5"></div>
      <ResponsiveContainer className="-ml-10" width="102%" height="100%">
        <AreaChart
          data={data!.map(({ measured_on, num_users }) => ({
            date: measured_on.toString(),
            num_users,
          }))}
        >
          <XAxis
            padding={{ right: 30 }}
            interval={Math.ceil(data!.length / 8)}
            tickSize={10}
            dataKey="date"
            tickMargin={10}
            tickLine={false}
            tickFormatter={formatxTick}
            reversed
            tick={{ fill: theme === "dark" ? "#D8DFE6" : "#2F5A55" }}
          />
          <YAxis
            padding={{ top: 20 }}
            tickLine={false}
            tickMargin={10}
            width={100}
            domain={domain as any}
            tickFormatter={formatTick}
            tick={{ fill: theme === "dark" ? "#D8DFE6" : "#2F5A55" }}
          />
          <defs>
            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#54A097" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#54A097" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type="linear"
            dataKey="num_users"
            stroke="#54A097"
            fillOpacity={0.8}
            fill="url(#colorValue)"
          />
          <Tooltip
            content={<CustomTooltip payload={data!} />}
            position={{ y: 30 }}
            cursor={{
              stroke: "green",
              strokeWidth: 0.5,
              height: "num_users",
              strokeDasharray: "3 3",
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
export function formatTick(value: string) {
  const formattedValue =
    Math.abs(parseFloat(value)) < 1000
      ? value
      : Math.abs(parseFloat(value)) >= 1000000000
      ? `${(parseFloat(value) / 1000000000).toFixed(2)}B`
      : Math.abs(parseFloat(value)) >= 1000000
      ? `${(parseFloat(value) / 1000000).toFixed(2)}M`
      : `${(parseFloat(value) / 1000).toFixed(2)}K`; //rounded to 2 decimals
  return formattedValue;
}
