import React from "react";
import { FieldValues, useForm } from "react-hook-form";

import { createKYCApplicant } from "../../api";
import useMutationWithForm from "../../hooks/useMutationWithForm";
import { AvailableSourceOfFunds, FTCKYC } from "../../types";
import { capitalize } from "../../utils";
import countries from "../../utils/countriesAlphaCode.json";
import Loader from "../Loader";
import BaseForm from "./_BaseForm";
import Button from "./_Button";
import Dropdown from "./_Dropdown";
import Input from "./_Input";

type KYCFTCFormProps = {
  onKYCFormSuccess: (s: string) => void;
  ftc_session_id: string;
};

export default function KYCFTCForm({
  onKYCFormSuccess,
  ftc_session_id,
}: KYCFTCFormProps) {
  const form = useForm<FieldValues>();

  const { mutate: createApplicant, isLoading } = useMutationWithForm(
    form,
    (data: FTCKYC) => createKYCApplicant(data),
    {
      onSuccess: (data) => {
        onKYCFormSuccess(data.token);
      },
      onError: (e) => console.error(e),
    },
  );

  function onKYCFormSubmit() {
    const data = {
      ...form.getValues(),
      ftc_session_id,
    } as FTCKYC;
    createApplicant(data);
  }

  return (
    <div className="w-full">
      {!isLoading ? (
        <div className="bg-white w-1/2 p-10 mx-auto rounded-md">
          <p className="text-center font-bold text-green-800 dark:text-gray-300">
            KYC Applicant Form
          </p>
          <BaseForm
            form={form}
            onSubmit={onKYCFormSubmit}
            buttons={
              <Button
                text="Submit"
                type="submit"
                styling="primary-button"
                additionalClasses="w-full cursor-pointer font-bold"
              />
            }
          >
            <div className="space-y-6">
              <Input
                form={form}
                name="address_line_1"
                placeholder="Address Line 1*"
                type="text"
                registerOptions={{ required: true }}
              />
              <Input
                form={form}
                name="address_line_2"
                placeholder="Address Line 2"
                type="text"
              />
              <Input
                form={form}
                name="post_code"
                placeholder="Post Code*"
                type="text"
                registerOptions={{ required: true }}
              />
              <Input
                form={form}
                name="city"
                placeholder="City*"
                type="text"
                registerOptions={{ required: true }}
              />
              <Dropdown
                form={form}
                name="country"
                placeholder="Country*"
                options={Object.entries(countries).map(([key, value]) => ({
                  label: value,
                  value: key,
                }))}
                registerOptions={{ required: true }}
              />
              <div className="flex items-center">
                <p className="font-light w-1/2">Date of Birth*</p>
                <Input
                  form={form}
                  name="date_of_birth"
                  type="date"
                  registerOptions={{
                    required: true,
                    validate: {
                      positive: (value) =>
                        new Date(value) < new Date() ||
                        "Must be Less than Today's date.",
                    },
                  }}
                />
              </div>
              <Dropdown
                form={form}
                name="source_of_funds"
                placeholder="Source of Funds*"
                options={AvailableSourceOfFunds.map((f) => ({
                  label: capitalize(f),
                  value: f,
                }))}
              />
            </div>
          </BaseForm>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}
