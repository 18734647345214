import React from "react";
type StatCardProps = {
  cardTitle: string;
  primaryValue: number;
  secondaryValue: number;
  iconPath: string;
};
const StatCard = ({
  cardTitle,
  primaryValue,
  secondaryValue,
  iconPath,
}: StatCardProps) => (
  <div className="flex flex-auto items-start bg-white rounded-2xl p-7 text-green-800 m-2 dark:bg-gray-900 dark:text-gray-300 ">
    <img className="h-10 mx-3" src={iconPath} alt="activity_type" />
    <div>
      <p className=" text-16 font-medium mb-2.5">{cardTitle}</p>
      <div className="flex flex-row items-end text-24 align-text-bottom font-bold ">
        <div
          className={
            "items-end " +
            (primaryValue >= 0
              ? " text-green-800 dark:text-green-300"
              : "text-red-500")
          }
        >
          {primaryValue}
        </div>

        <div
          className={
            "flex flex-row text-16 font-normal ml-5 mr-2 pb-1 " +
            (secondaryValue > 0 ? " text-green-400" : "text-red-500")
          }
        >
          <img
            className="w-3 mr-2"
            src={
              secondaryValue > 0
                ? "/icons/light/arrows/arrow_up_filled.svg"
                : "/icons/light/arrows/arrow_down_filled.svg"
            }
            alt="increase"
          />
          <p>{secondaryValue + "%"}</p>
        </div>
      </div>
    </div>
  </div>
);

export default StatCard;
