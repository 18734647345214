import { Popover, Transition } from "@headlessui/react";
import React, { PropsWithChildren, useContext } from "react";
import { useForm } from "react-hook-form";

import { ThemeContext } from "../App";
import { AvailableFiat, Fiat } from "../types";
import { getFiatSign } from "../utils";
import CurrencySearch from "./forms/CurrencySearchForm";

type PopverProps = PropsWithChildren<{
  onUpdate: (currency: Fiat) => void;
  chosenCurrency: Fiat;
  withSearch?: boolean;
}>;

export default function CurrencyPopover({
  onUpdate,
  chosenCurrency,
  withSearch = false,
  children,
}: PopverProps) {
  const theme = useContext(ThemeContext);
  const form = useForm();

  function updateCurrency(currency: Fiat) {
    onUpdate(currency);
    form.reset();
  }

  const inputValue = form.watch("currency");
  const currenciesOptions = AvailableFiat.filter((c) => {
    if (inputValue) {
      return c.includes(inputValue.toUpperCase());
    } else {
      return true;
    }
  });

  return (
    <Popover>
      <div className="relative z-30">
        {children}

        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Popover.Panel
            className={`absolute rounded-lg w-80 top-full mt-1 bg-white py-3 z-30 flex flex-col items-center focus:outline-none shadow-filter dark:bg-gray-950 dark:shadow-dark " ${
              withSearch && "-right-14"
            } `}
          >
            {withSearch && (
              <>
                <CurrencySearch form={form} />
                <h3 className="text-green-800 mx-4 py-3 text-12 font-normal text-left self-start dark:text-gray-450-t-50">
                  Select base currency
                </h3>
              </>
            )}
            <div
              className={`flex flex-col space-y-4 w-full overflow-auto ${
                theme === "dark" ? "custom-scroll-dark" : "custom-scroll"
              }`}
              style={{ maxHeight: "300px" }}
            >
              {currenciesOptions.map((fiat) => (
                <div key={fiat} onClick={() => updateCurrency(fiat)}>
                  <Popover.Button
                    className={`h-10 px-10 flex items-center space-x-10 text-green-300 w-full text-24 hover:bg-gray-100 dark:hover:bg-gray-900 focus:outline-none ${
                      fiat === chosenCurrency
                        ? "bg-gray-100 font-bold dark:bg-gray-900"
                        : "bg-white dark:bg-gray-950"
                    }`}
                  >
                    <div className="w-5 text-center">{getFiatSign(fiat)}</div>
                    <p
                      className={`uppercase text-green-800 text-14 dark:text-gray-300 ${
                        fiat === chosenCurrency ? "font-bold" : "font-normal"
                      }`}
                    >
                      {fiat}
                    </p>
                  </Popover.Button>
                </div>
              ))}
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
}
