import { Popover, Transition } from "@headlessui/react";
import format from "date-fns/format";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { usePopper } from "react-popper";
import { useQuery } from "react-query";

import { getNonFungibleTokensData } from "../../api";
import { ThemeContext } from "../../App";
import useDebounce from "../../hooks/useDebounce";
import { useUser } from "../../hooks/useUser";
import { NftParams, NonFungibleTokensData } from "../../types";
import { getFiatSign } from "../../utils";
import BnFilter from "../BnFilter";
import ContactAddress from "../ContactAddress";
import Loader from "../Loader";

type FormReturnProps = {
  start_date: string;
  end_date: string;
  ordering: string;
  collection: string;
  properties_number: number;
};

type NonFungibleTokensPopUpProps = {
  id: string;
  state: boolean;
};
const NonFungibleTokensLightbox = () => {
  const theme = useContext(ThemeContext);
  const user = useUser();

  const [numberOfProperties, setNumberOfProperties] = useState(0);
  const [collections, setCollections] = useState<string[]>([]);
  const [maxProperties, setMaxProperties] = useState(10);

  const [queryParams, setQueryParams] = useState<NftParams>({
    ordering: "-created_at",
    collection: "",
  });

  const { data: nonFungibleTokens, isLoading } = useQuery(
    ["non-fungible-tokens-summary", queryParams],
    () => getNonFungibleTokensData(queryParams),
    {
      enabled: user.full_access,
      refetchInterval: 30000,
    },
  );
  useEffect(() => {
    if (nonFungibleTokens) {
      // get all user's nft collections
      const coll = new Set(nonFungibleTokens.map((token) => token.collection));
      setCollections(Array.from(coll));

      // get maximum number of user's nft properties
      setMaxProperties(
        Math.max(...nonFungibleTokens.map((o) => o.properties.length)),
      );
    }
  }, [nonFungibleTokens]);

  const [nonFungibleTokenPopOver, showNonFungibleTokenPopOver] =
    useState<NonFungibleTokensPopUpProps>({ id: "-1", state: false });
  const [isHovered, setIsHovered] = React.useState(false);
  const idDebounced: string = useDebounce(
    String(nonFungibleTokenPopOver.id),
    1000,
  );
  const hovered: boolean = useDebounce(isHovered, 2000);

  const handleMouseEnter = (id: string) => {
    showNonFungibleTokenPopOver({ id, state: true });
    setIsHovered(true);
  };

  const handleMouseLeave = (id: string) => {
    showNonFungibleTokenPopOver({ id: "-1", state: false });
    setIsHovered(false);
  };

  const form = useForm<FormReturnProps>({
    mode: "onChange",
    defaultValues: {
      start_date: format(new Date(Date.now()), "yyyy-MM-dd"),
      end_date: format(new Date(Date.now()), "yyyy-MM-dd"),
      ordering: "-created_at",
      collection: "",
      properties_number: 0, // 0 means 'off'
    },
  });
  useEffect(() => {
    const data = form.getValues();

    setQueryParams({ ordering: data.ordering, collection: data.collection });
    setNumberOfProperties(data.properties_number);

    // eslint-disable-next-line
  }, [JSON.stringify(form.watch())]);

  function onResetFilters() {
    form.setValue("collection", "");
    form.setValue("ordering", "-created_at");
    form.setValue("properties_number", 0);
  }

  return (
    <div className="mr-2.5 text-16 font-bold">
      Non-Fungible Tokens
      <div className="mt-5">
        {isLoading ? (
          <Loader></Loader>
        ) : nonFungibleTokens?.length ? (
          <div className="flex flex-row ">
            {nonFungibleTokens.slice(0, 5).map((token) => (
              <div key={token.nft_id} className="relative mr-4 w-56  ">
                <img
                  src={token.image_url}
                  alt=""
                  className="flex rounded-lg object-fill "
                  onMouseEnter={() => handleMouseEnter(token.nft_id)}
                  onMouseLeave={() => handleMouseLeave(token.nft_id)}
                  onError={({ currentTarget }) => {
                    if (
                      currentTarget.src.endsWith("axie-full-transparent.png")
                    ) {
                      currentTarget.src = "/axie-egg.png";
                    } else {
                      currentTarget.onerror = null;
                    }
                  }}
                />
                {nonFungibleTokenPopOver.id === token.nft_id &&
                  hovered &&
                  idDebounced === String(token.nft_id) && (
                    <BasicNftDetailedPopover token={token} />
                  )}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center m-auto">You have no non-fungible tokens</p>
        )}
        <Popover className="flex justify-center rounded-10px h-5 text-gray sm:text-13 text-12  dark:text-gray-300">
          {({ open }) => {
            // ensures that overflow values is always adjusted to open value
            if (open) {
              document.body.style.overflow = "hidden";
              localStorage.setItem("hiddenSource", "nft");
            } else if (localStorage.getItem("hiddenSource") === "nft") {
              document.body.style.overflow = "initial";
              localStorage.removeItem("hiddenSource");
            }
            return (
              <>
                {nonFungibleTokens?.length ? (
                  <Popover.Button className="font-bold text-center mt-2 cursor-pointer">
                    <p
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      See All
                    </p>
                  </Popover.Button>
                ) : null}
                <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute overflow-x-hidden overflow-y-auto w-screen h-screen top-0 left-0 bg-white px-7 py-8 z-30 flex-col  items-center dark:bg-gray-900 font-medium opacity-100 dark:text-gray-300">
                    <Popover.Button>
                      <div className="text-gray-800 font-bold flex flex-row items-center dark:text-gray-300">
                        <img
                          src={`/icons/${theme}/actions/li_reply.svg`}
                          alt="back"
                          className="pr-2"
                        />
                        Back
                      </div>
                    </Popover.Button>
                    <div className="relative flex gap-y-2 justify-between items-center px-16">
                      <div className="text-gray-800 font-bold my-5 dark:text-gray-300">
                        Non-Fungible Tokens
                      </div>
                      <BnFilter
                        form={form}
                        minDate={new Date(Date.now())}
                        nftCollections={collections}
                        nftPropertiesNumber={maxProperties}
                        customReset={onResetFilters}
                        customIsResetNeeded={() =>
                          form.getValues("collection") !== "" ||
                          form.getValues("ordering") !== "created_at" ||
                          form.getValues("properties_number") !== 0
                        }
                      />
                    </div>
                    {isLoading && <Loader />}
                    {nonFungibleTokens ? (
                      <div className="flex flex-wrap gap-x-9 gap-y-9 m-auto">
                        {nonFungibleTokens.map((token) => (
                          <DetailedNftPopover
                            token={token}
                            key={token.nft_id}
                            numberOfProperties={numberOfProperties}
                          />
                        ))}
                      </div>
                    ) : (
                      !isLoading && (
                        <div className="flex flex-row  items-center h-36 ">
                          <div className="text-center w-full">
                            You have no non-fungible tokens
                          </div>
                        </div>
                      )
                    )}
                  </Popover.Panel>
                </Transition>
              </>
            );
          }}
        </Popover>
      </div>
    </div>
  );
};

export default NonFungibleTokensLightbox;

function BasicNftDetailedPopover({ token }: { token: NonFungibleTokensData }) {
  return (
    <div className="absolute top-44 text-10 font-normal z-30 ">
      <div className="absolute flex bg-white dark:bg-gray-800 shadow-lg w-60  p-6 flex-col dark:text-gray-300">
        <img
          src={token.image_url}
          alt="NFT"
          className="h-52  rounded-lg object-contain "
          onError={({ currentTarget }) => {
            if (currentTarget.src.endsWith("axie-full-transparent.png")) {
              currentTarget.src = "/axie-egg.png";
            } else {
              currentTarget.onerror = null;
            }
          }}
        />
        <div className="flex flex-col  w-56 ">
          <div className="my-1">
            <div>{token.collection}</div>
          </div>
          <div className="text-green-900 dark:text-green-300 font-bold text my-1">
            {token.name}
          </div>
          <div className="flex  flex-auto justify-between my-1">
            <div>Contract address</div>
            <div className="truncate text-green-800 dark:text-green-300 pr-6">
              {token.contract_address.length > 14
                ? token.contract_address.slice(0, 7) +
                  "..." +
                  token.contract_address.slice(-4)
                : token.contract_address}
            </div>
          </div>

          <div className="flex flex-row justify-between my-2">
            <div>Issuance Date</div>
            <div className=" px-6 ">
              {token.created_at
                ? format(new Date(token.created_at as string), "yyyy-MM-dd")
                : "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DetailedNftPopover({
  token,
  numberOfProperties,
}: {
  token: NonFungibleTokensData;
  numberOfProperties: number;
}) {
  const theme = useContext(ThemeContext);

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);
  const [arrowRef, setArrowRef] = useState<any>(null);
  const { styles, attributes } = usePopper(
    buttonRef.current,
    popperRef.current,
    {
      placement: "bottom",
      strategy: "absolute",
      modifiers: [
        { name: "arrow", options: { element: arrowRef } },
        {
          name: "offset",
          options: {
            offset: [0, 20],
          },
        },
        {
          name: "flip",
          options: {
            fallbackPlacements: ["bottom"],
          },
        },
      ],
    },
  );

  return (
    <Popover key={token.nft_id}>
      {({ open }) => (
        <>
          <Popover.Button
            className={`outline-none ${open && "mb-80"} shadow-filter`}
            ref={buttonRef}
          >
            <img
              src={token.image_url}
              alt="NFT"
              className="h-48 w-48 rounded-lg object-contain "
              onError={({ currentTarget }) => {
                if (currentTarget.src.endsWith("axie-full-transparent.png")) {
                  currentTarget.src = "/axie-egg.png";
                } else {
                  currentTarget.onerror = null;
                }
              }}
            />
          </Popover.Button>
          <Popover.Panel
            ref={popperRef}
            style={styles.popper}
            {...attributes.popper}
          >
            <div
              ref={(ref: any) => setArrowRef(ref)}
              className="-top-10"
              style={{
                ...styles.arrow,
                borderBottom: `22px solid ${
                  theme === "light" ? "#F2F4F5" : "#494949"
                }`,
                borderRight: "22px solid transparent",
                borderTop: "22px solid transparent",
                borderLeft: "22px solid transparent",
              }}
            />
            <div
              className="flex bg-gray-100 dark:bg-gray-800 w-screen px-4 xl:pl-16 xl:pr-32 2xl:text-18"
              style={{
                transform: `translateX(-${
                  styles.popper.transform?.split("(")[1].split(",")[0] || 0
                })`,
              }}
            >
              <img
                src={token.image_url}
                alt="NFT"
                className="w-56 xl:w-72 2xl:w-80 rounded-lg object-contain "
                onError={({ currentTarget }) => {
                  if (currentTarget.src.endsWith("axie-full-transparent.png")) {
                    currentTarget.src = "/axie-egg.png";
                  } else {
                    currentTarget.onerror = null;
                  }
                }}
              />
              <div className="flex flex-col py-6 w-full ">
                <p className="text-18">{token.collection}</p>
                <p className="text-green-900 font-bold text-24 mt-2.5 dark:text-green-300">
                  {token.name} #{token.nft_id}
                </p>
                <div className="flex items-center  flex-auto justify-between items-center  mt-7">
                  <p>ID</p>
                  <p>{token.nft_id}</p>
                </div>
                <div className="flex items-center  flex-auto justify-between items-center  mt-3">
                  <p>Contract address</p>
                  <ContactAddress address={token.contract_address} />
                </div>
                <div className="flex items-center justify-between mt-3">
                  <p>Purchase Price</p>
                  {token.is_price_synced || parseFloat(token.price) ? (
                    <p>
                      {getFiatSign(token.currency)} {token.price}
                    </p>
                  ) : (
                    <div className="text-14 font-semibold text-green-300 flex items-center gap-x-2">
                      <div className="dots-spinner" />
                      <p className="loading-dots">
                        Loading price (up to 10 min)
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex items-center justify-between mt-3">
                  <p>Issuance Date</p>
                  <p>
                    {token.created_at
                      ? format(
                          new Date(token.created_at as string),
                          "yyyy-MM-dd",
                        )
                      : "-"}
                  </p>
                </div>
                <div className="flex items-center justify-between items-start mt-3 gap-x-4">
                  <p>List of properties</p>
                  <div className="flex items-center flex-wrap justify-end gap-x-2 gap-y-2">
                    {token.properties
                      .slice(0, numberOfProperties || token.properties.length)
                      .map((property) => (
                        <p
                          className="px-2 py-1 font-bold text-14 bg-green-180 text-green-800 rounded-md capitalize whitespace-nowrap dark"
                          key={property.property_id}
                        >
                          {property.name}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
