import { Popover } from "@headlessui/react";
import React, { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { updateUser } from "../../api";
import { UserContext } from "../../App";
import { Fiat } from "../../types";
import { getFiatSign } from "../../utils";
import CurrencyPopover from "../CurrencyPopover";

export default function CurrencySelection() {
  const queryClient = useQueryClient();
  const { user, setUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const { mutate } = useMutation(
    "fiat",
    (currency: Fiat) => updateUser({ fiat_currency: currency }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        queryClient.resetQueries();
      },
      onSettled: () => setIsLoading(false),
    },
  );

  function updateCurrency(currency: Fiat) {
    setIsLoading(true);
    mutate(currency);
    setUser({ ...user, fiat_currency: currency });
  }

  return (
    <CurrencyPopover
      onUpdate={updateCurrency}
      chosenCurrency={user.fiat_currency || "USD"}
      withSearch
    >
      <Popover.Button className="text-green-800 dark:text-gray-300 text-30 font-bold flex items-center justify-center w-5 focus:outline-none cursor-pointer">
        {isLoading ? (
          <img src="/loader.gif" alt="loading..." />
        ) : (
          getFiatSign(user.fiat_currency || "USD")
        )}
      </Popover.Button>
    </CurrencyPopover>
  );
}
