import React from "react";
import { FieldErrors } from "react-hook-form";

const ERROR_MESSAGES: Record<string, string> = {
  required: "This field is required",
  isValidDate: "Date is invalid or is not in the date range",
  isLaterThan: 'This date should be after "From" Date',
  isEarlierThan: 'This date should be before "To" Date',
};

function formatErrors(errors: string[]) {
  return (
    <>
      {errors.map((error, id) => (
        <div key={id}>{error}</div>
      ))}
    </>
  );
}

function getMessage(errors: FieldErrors | string[]) {
  if (Array.isArray(errors)) {
    return formatErrors(errors);
  }
  if (errors.message) {
    if (Array.isArray(errors.message)) {
      return formatErrors(errors.message);
    }
    return errors.message;
  }
  if (errors.type in ERROR_MESSAGES) {
    return ERROR_MESSAGES[errors.type];
  }
}

type FormErrorProps = { errors?: FieldErrors | string[] };

export default function FormError({ errors }: FormErrorProps) {
  if (!errors) {
    return null;
  }
  return (
    <span className="text-12 text-left text-red-500 m-0">
      {getMessage(errors)}
    </span>
  );
}
