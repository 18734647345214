import { format } from "date-fns";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import { getInvoice } from "../api";
import { ThemeContext } from "../App";
import ItemsSummary from "../components/ItemsSummary";
import Loader from "../components/Loader";
import PageHeader from "../components/PageHeader";
import { showSnackbar } from "../components/Snackbar";
import { InvoiceResponse } from "../types";
import { capitalize, formatAmount, getFiatSign } from "../utils";

export default function InvoiceDetailsPage() {
  const history = useHistory();
  const location = useLocation();
  const invoiceID = location.pathname.split("/")[2];

  const { data: invoice } = useQuery(
    ["invoice", invoiceID],
    () => getInvoice(invoiceID),
    {
      retry: false,
      onError: () => {
        showSnackbar("Invoice not found");
        history.push("/auth");
      },
    },
  );

  if (!invoice) {
    return <Loader />;
  }

  const statusBadge = `mt-2 rounded-md py-0.5 text-14 font-semibold ${
    invoice.state === "DUE"
      ? "text-black text-right"
      : "text-white px-3 text-center"
  } ${
    (invoice.state === "DRAFT" || invoice.state === "EXPIRED") && "bg-red-500"
  } ${invoice.state === "PROCESSING" && "bg-yellow-50"} ${
    invoice.state === "PAID" && "bg-green-300"
  } dark:text-white`;

  const currencyImg = getFiatSign(invoice.fiat_currency);

  function isOnPublic() {
    return location.pathname.includes("pay-invoice");
  }

  return (
    <div>
      <PageHeader
        title={
          isOnPublic()
            ? `Invoice from ${invoice.owner.first_name} ${invoice.owner.last_name}`
            : invoice.name
        }
        headerButtonProps={
          isOnPublic() && invoice.state === "DUE"
            ? {
                text: "Pay Invoice",
                onClick: () =>
                  history.push(`/pay-invoice/${invoiceID}/payment`, {
                    invoice,
                  }),
              }
            : undefined
        }
      />

      <div className="bg-white text-gray-800 rounded-20px p-6 pb-12 mlg:p-12 w-full my-8 dark:bg-gray-900 dark:text-gray-300">
        {invoice.kind !== "BASENODE" ? (
          <AdvisoryInvoice invoice={invoice} currencyImg={currencyImg} />
        ) : (
          <BasenodeInvoice
            invoice={invoice}
            statusBadge={statusBadge}
            isOnPublic={isOnPublic}
            currencyImg={currencyImg}
          />
        )}
        {invoice.message && (
          <div className="mt-10">
            <p className="text-green-800 text-18 font-semibold mb-4 dark:text-green-300">
              Note to client
            </p>
            <div className="w-full border border-gray-450 rounded-md px-10 py-6 space-y-3">
              <p>{invoice.message}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

type InvoiceTemplateProps = {
  invoice: InvoiceResponse;
  currencyImg: string;
  statusBadge?: string;
  isOnPublic?: () => boolean;
};

function BasenodeInvoice({
  invoice,
  currencyImg,
  statusBadge,
  isOnPublic,
}: InvoiceTemplateProps) {
  const theme = useContext(ThemeContext);

  return (
    <div>
      <div className="flex justify-between items-end">
        <div className="flex text-16">
          <div className="font-bold space-y-1">
            <p>Invoice:</p>
            <p>Issued:</p>
            {invoice.state !== "DRAFT" && <p>Due:</p>}
          </div>
          <div className="space-y-1 ml-4">
            <p>{invoice.name}</p>
            <p>{format(new Date(invoice.issued_at), "MMM dd, yyyy")}</p>
            <p>
              {invoice.state !== "DRAFT" &&
                format(new Date(invoice.expires_at), "MMM dd, yyyy")}
            </p>
          </div>
        </div>
        <div>
          <p className="-mt-1 ml-2 text-18 font-bold">
            {currencyImg} {formatAmount(invoice.total_amount)}
          </p>
          <div className={statusBadge}>{invoice.state}</div>
        </div>
      </div>

      <div className="mt-14 grid md:grid-cols-2 grid-cols-1 gap-x-10">
        <div>
          <p className="text-green-800 text-18 font-semibold mb-4 dark:text-green-300">
            Bill to
          </p>
          <div className="w-full h-80 border border-gray-450 rounded-md flex justify-between items-start px-10 pt-6">
            <div className="flex">
              <div className="avatar mr-4">
                {invoice.contact.first_name[0].toUpperCase()}
                {invoice.contact.last_name[0].toUpperCase()}
              </div>
              <div>
                <div
                  className="my-2"
                  style={{
                    minHeight:
                      invoice.owner.company || invoice.contact.company
                        ? "3rem"
                        : 0,
                  }}
                >
                  <p className="font-semibold text-18">
                    {invoice.contact.first_name} {invoice.contact.last_name}
                  </p>
                  <p className="mt-1">{invoice.contact.company}</p>
                </div>
                <p className="text-16 my-4">{invoice.contact.email}</p>
                <p>{invoice.contact.address_line1}</p>
                <p>{invoice.contact.address_line2}</p>
                <p>{invoice.contact.postal_code}</p>
                <p>{invoice.contact.city}</p>
                <p>{invoice.contact.country}</p>
              </div>
            </div>
            {!isOnPublic!() && (
              <img
                src={`/icons/${theme}/message.svg`}
                alt="mail"
                className="h-8"
              />
            )}
          </div>
        </div>
        <div>
          <p className="text-green-800 mt-10 md:mt-0 text-18 font-semibold mb-4 dark:text-green-300">
            From
          </p>
          <div className="w-full h-80 border border-gray-450 rounded-md flex justify-between items-start px-10 pt-6">
            <div>
              <div
                className="my-2"
                style={{
                  minHeight:
                    invoice.owner.company || invoice.contact.company
                      ? "3rem"
                      : 0,
                }}
              >
                <p className="font-semibold text-18">
                  {invoice.owner.first_name} {invoice.owner.last_name}
                </p>
                <p className="mt-1">{invoice.owner.company}</p>
              </div>
              <p className="text-16 my-4">{invoice.owner.email}</p>
              <p>{invoice.owner.address_line1}</p>
              <p>{invoice.owner.address_line2}</p>
              <p>{invoice.owner.postal_code}</p>
              <p>{invoice.owner.city}</p>
              <p>{invoice.owner.country}</p>
              <div className="mt-4">
                <p>{capitalize(invoice.wallet.network)}</p>
                <p>{invoice.wallet.address}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-16">
        <p className="text-green-800 text-18 font-semibold mb-4 dark:text-green-300">
          Items
        </p>
        <div className="border border-gray-450 rounded-md px-10 py-6">
          <table className="min-w-full table-fixed text-center">
            <thead className="text-green-800 dark:text-gray-300">
              <tr className="h-10">
                <th className="text-left">Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Currency</th>
                <th>Discount</th>
                <th>Tax</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoice.items.map((item) => (
                <tr key={item.name} className="h-10 font-semibold">
                  <td className="text-left">{item.name}</td>
                  <td>{item.quantity}</td>
                  <td>{formatAmount(item.unit_price)}</td>
                  <td>{invoice.fiat_currency}</td>
                  <td>
                    {item.discount_is_percent
                      ? `${formatAmount(item.discount, 2)}%`
                      : formatAmount(item.discount)}
                  </td>
                  <td>
                    {item.tax_is_percent
                      ? `${formatAmount(item.tax, 2)}%`
                      : formatAmount(item.tax)}
                  </td>
                  <td>{formatAmount(item.total_amount)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex justify-end mt-6">
        <ItemsSummary
          currency={invoice.fiat_currency}
          total_amount={invoice.total_amount}
          total_tax_amount={invoice.total_tax_amount}
          total_base_amount={invoice.total_base_amount}
        />
      </div>
    </div>
  );
}

function AdvisoryInvoice({ invoice, currencyImg }: InvoiceTemplateProps) {
  return (
    <div className="w-full md:w-4/5 md:ml-auto md:mr-12 text-10 md:text-16">
      <div className="flex justify-between">
        <div>
          <p>{invoice.contact.company}</p>
          <p>
            Attn.: {capitalize(invoice.contact.first_name)}
            {capitalize(invoice.contact.last_name)}
          </p>
          <p>{invoice.contact.address_line1}</p>
          <p>{invoice.contact.postal_code}</p>
          <p>{invoice.contact.city}</p>
          <p>{invoice.contact.country}</p>
        </div>
        <div className="grid grid-cols-2 gap-x-5 gap-y-1">
          <p className="mb-8">Invoice</p>
          <p className="mb-8">{invoice.name}</p>
          <p>Invoice No.:</p>
          <p>{invoice.number}</p>
          <p>Date of Invoice:</p>
          <p>{format(new Date(invoice.created_at), "d.mm.y")}</p>
          <p>Covering period:</p>
          <p>{invoice.covering_period}</p>
          <p>VAT No.:</p>
          <p>{invoice.owner.tax_number ? invoice.owner.tax_number : "N/A"}</p>
          <p className="mt-8">Vendor No.:</p>
          <p className="mt-8">{invoice.vendor}</p>
        </div>
      </div>
      <div className="mt-14 space-y-4">
        <p>Dear Ms {invoice.contact.first_name},</p>
        <p>
          Please accept our invoice covering the incurred charges on {}
          {invoice.project_name}:
        </p>
        <table className="min-w-full table-fixed text-center">
          <thead>
            <tr className="h-10 text-right">
              <th colSpan={6} className="text-left">
                Description
              </th>
              <th>Effort</th>
              <th>Price</th>
              <th>Cu.</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {invoice.items.map((item) => (
              <tr key={item.name} className="h-10 text-right">
                <td className="text-left" colSpan={6}>
                  {item.name}
                </td>
                <td>{item.quantity} WD</td>
                <td>{formatAmount(item.unit_price)}</td>
                <td>{invoice.fiat_currency}</td>
                <td>{formatAmount(item.total_amount)}</td>
              </tr>
            ))}
            <tr>
              <td className="border-b-2" colSpan={100}></td>
            </tr>
            <tr className="text-right">
              <td className="text-left" colSpan={8}>
                Net Amount
              </td>
              <td>{invoice.fiat_currency}</td>
              <td>{formatAmount(invoice.total_base_amount)}</td>
            </tr>
            <tr className="text-right">
              <td className="text-left" colSpan={8}>
                VAT at {invoice.total_tax_percentage}%
              </td>
              <td>{invoice.fiat_currency}</td>
              <td>{formatAmount(invoice.total_tax_amount)}</td>
            </tr>
            <tr>
              <td className="border-b-2" colSpan={100}></td>
            </tr>
            <tr className="text-right">
              <td className="text-left" colSpan={8}>
                Total Amount
              </td>
              <td>{invoice.fiat_currency}</td>
              <td>{formatAmount(invoice.total_amount)}</td>
            </tr>
            <tr>
              <td className="border-b-2" colSpan={100}></td>
            </tr>
          </tbody>
        </table>
        <p>Invoice not subject to Swiss VAT shifted to recipient.</p>
        <p>
          Thank you, we really appreciate your business. Payment is due within
          {} {invoice.days_to_expire} days of receiving this invoice.
        </p>
        {/* TODO: This is better off as a grid... */}
        <div className="flex">
          <div>
            <p>Bank</p>
            <p>Account Number</p>
            <p>Clearing Number</p>
            <p>SWIFT-Code</p>
            <p>IBAN</p>
          </div>
          <div className="ml-5">
            <p>{invoice.owner.bank_name}&nbsp;</p>
            <p>{invoice.owner.bank_account}</p>
            <p>{invoice.owner.clearing_number}&nbsp;</p>
            <p>{invoice.owner.swift}&nbsp;</p>
            <p>{invoice.owner.iban}</p>
          </div>
        </div>
        <p>We are looking forward to continuing our cooperation.</p>
        <p className="font-extralight">The Advisory House AG</p>
      </div>
    </div>
  );
}
