import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useContext } from "react";
import { Controller, RegisterOptions, UseFormReturn } from "react-hook-form";

import { ThemeContext } from "../../App";
import { DropdownOption } from "../../types";
import FormError from "./_FormError";

export type InputProps = {
  form: UseFormReturn<any>;
  name: string;
  registerOptions?: RegisterOptions;
  options: DropdownOption<any>[];
  placeholder?: string;
  label?: string;
  noDataMessage?: string;
  defaultValue?: any[];
  multiSelect?: boolean;
  disabled?: boolean;
};

export default function Dropdown({
  form,
  name,
  registerOptions,
  options,
  placeholder,
  label,
  noDataMessage,
  defaultValue,
  multiSelect,
  disabled = false,
}: InputProps) {
  const theme = useContext(ThemeContext);
  const selectedOptions: any = form.watch(name) || defaultValue;

  function onChange(value: any[]) {
    if (!multiSelect) {
      form.setValue(name, value);
      return;
    }

    if (selectedOptions?.includes(value)) {
      const newValues = selectedOptions.filter((o: any) => o !== value);
      form.setValue(name, newValues);
    } else {
      const prevOptions = selectedOptions ?? [];
      form.setValue(name, [...prevOptions, value]);
    }
  }

  return (
    <Controller
      name={name}
      control={form.control}
      rules={registerOptions}
      render={() => (
        <>
          <Listbox
            value={selectedOptions}
            onChange={(value) => onChange(value)}
            disabled={disabled}
          >
            {({ open }) => (
              <div className="mt-2 relative">
                <Listbox.Button
                  className={`relative w-full bg-gray-100 dropdown text-left capitalize border border-transparent dark:bg-gray-900 dark:border-gray-300 ${
                    disabled &&
                    "cursor-default bg-gray-450-t-58 dark:bg-gray-600"
                  } ${
                    selectedOptions?.length === 0 && "dark:text-gray-450-t-50"
                  }`}
                >
                  <div
                    className={`block truncate ${
                      selectedOptions?.length > 0
                        ? "text-black dark:text-gray-300"
                        : "text-gray-450 py-3 "
                    }`}
                  >
                    {label
                      ? label
                      : multiSelect
                      ? options
                          .reduce((labels: any, { label, value }) => {
                            if (selectedOptions.some((o: any) => o === value))
                              return [...labels, label];

                            return labels;
                          }, [])
                          ?.join(", ")
                      : options.find((o) => o.value === selectedOptions)
                          ?.label ||
                        placeholder ||
                        ""}
                  </div>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <img
                      className="w-4"
                      src={`/icons/${theme}/arrows/expand_down_light.svg`}
                      alt="icon"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className={`absolute z-10 w-full bg-white shadow-lg max-h-48 rounded-md py-1 overflow-auto focus:outline-none  dark:bg-gray-900 ${
                      theme === "dark" ? "custom-scroll-dark" : "custom-scroll"
                    }`}
                  >
                    {options.length > 0 ? (
                      options.map((option) => (
                        <Listbox.Option
                          key={option.key || option.label}
                          className={({ active }) =>
                            `text-14 ${
                              active && "bg-gray-200"
                            } cursor-pointer select-none relative py-1 pl-5 pr-2  text-gray-800 hover:bg-white hover:text-green-300 dark:text-gray-300 dark:hover:text-green-300 dark:bg-gray-900`
                          }
                          value={option.value}
                        >
                          {() => (
                            <div className="flex gap-x-1">
                              {multiSelect ? (
                                <span className="flex items-center">
                                  {selectedOptions?.includes(option.value) ? (
                                    <img
                                      className="w-3"
                                      src={`/icons/${theme}/check.svg`}
                                      alt="check"
                                    />
                                  ) : (
                                    <div className="w-3" />
                                  )}
                                </span>
                              ) : null}
                              <span className="block truncate capitalize">
                                {option.label}
                              </span>
                            </div>
                          )}
                        </Listbox.Option>
                      ))
                    ) : (
                      <Listbox.Option
                        className="select-none relative p-2"
                        value={null}
                      >
                        <span className="text-gray-450 text-sm">
                          {noDataMessage ?? "No matches found"}
                        </span>
                      </Listbox.Option>
                    )}
                  </Listbox.Options>
                </Transition>
              </div>
            )}
          </Listbox>
          <FormError errors={form.formState.errors[name]}></FormError>
        </>
      )}
    />
  );
}
