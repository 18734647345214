import format from "date-fns/esm/format/index";
import React, { useContext, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { ThemeContext } from "../App";
import DocumentPageDateFilters from "../hooks/DocumentPageDateFilters";
import { AvailableFiat, DocumentTypes, InvoiceTypes } from "../types";
import formatAmount from "../utils/formatAmount";
import getFiatSign from "../utils/getFiatSign";
import { DateTablePopover } from "./BnFilter";
import CheckBoxPopUp from "./CheckBoxPopUp";
import ContactAddress from "./ContactAddress";
import Loader from "./Loader";

type TableProps = {
  columns: Column[];
  data: Record<string, any>[];
  noDataMessage: string | JSX.Element;
  onColumnClick?: (col: Column) => void;
  orderedBy?: string;
  onCheckBoxChange?: (selected: number[]) => void;
  minDate?: Date;
  disableExpand?: boolean;
};

export type Column = {
  header: string | JSX.Element;
  accessor: string;
  sortable?: boolean;
  filterable?: boolean;
  width?: string;
};

const items: { [key: string]: string[] } = {
  status: InvoiceTypes.map((c) => c),
  docTypes: DocumentTypes.map((c) => c),
  currenciesOptions: AvailableFiat.filter((c) => c),
};
export default function BnTable({
  columns,
  data,
  noDataMessage,
  onColumnClick,
  orderedBy,
  onCheckBoxChange,
  minDate,
  disableExpand,
}: TableProps) {
  const theme = useContext(ThemeContext);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const { timePeriod, setTimePeriod } = useContext(DocumentPageDateFilters);
  const [selectedCoins] = useState<string[]>([]);
  const [showPopOver, setShowPopOver] = useState(10000);
  const [isNftLoaded, setIsNftLoaded] = useState(false);
  const location = useLocation();
  const form = useForm<FieldValues>({
    mode: "onChange",
    defaultValues: {
      start_date: format(new Date(timePeriod.start_date), "yyyy-MM-dd"),
      end_date: format(new Date(timePeriod.end_date), "yyyy-MM-dd"),
      selectedCoins,
    },
  });

  useEffect(() => {
    const data = form.getValues();
    // Need some time to clear possible errors in the form
    const timeout = setTimeout(() => {
      if (
        Object.keys(form.formState.errors).length === 0 &&
        Date.parse(data.start_date) &&
        Date.parse(data.end_date)
      ) {
        setTimePeriod({
          start_date: new Date(data.start_date),
          end_date: new Date(data.end_date),
        });
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(form.watch())]);

  useEffect(() => {
    form.setValue("start_date", timePeriod.start_date);
    form.setValue("end_date", timePeriod.end_date);
  }, [timePeriod, form]);

  function onSingleSelect(rowId: number) {
    const newSelection = selectedRows.includes(rowId)
      ? selectedRows.filter((selected) => selected !== rowId)
      : [...selectedRows, rowId];

    setSelectedRows(newSelection);
    onCheckBoxChange!(newSelection);
  }

  function onAllSelect() {
    const newSelection =
      selectedRows.length === data.length ? [] : [...data.map((row) => row.id)];

    setSelectedRows(newSelection);
    onCheckBoxChange!(newSelection);
  }

  useEffect(() => {
    if (onCheckBoxChange) {
      setSelectedRows(
        selectedRows.filter((rowId) => data.some((row) => row.id === rowId)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (onCheckBoxChange && !columns.some((col) => col.accessor === "check")) {
    columns.unshift({
      header: "check",
      accessor: "check",
      width: "5%",
    });
  }

  return (
    <div className="inline-block min-w-full text-12 text-gray-800 overflow-y-visible pb-10 dark:text-gray-300">
      <table className="min-w-full table-fixed overflow-y-visible">
        <colgroup>
          {columns.map((col) => (
            <col key={col.accessor} style={{ width: col.width || "auto" }} />
          ))}
        </colgroup>

        <thead className="bg-gray-100 font-bold text-13 jusstify-centre text-green-800 border-b dark:bg-gray-900 dark:text-gray-300 dark:border-gray-450-t-50">
          <tr>
            {columns.map((col) => {
              const isOrderedBy = orderedBy?.includes(col.accessor);
              return (
                <th key={col.accessor} className="h-10 text-left px-7">
                  <div
                    className={`flex items_centre space-x-2 ${
                      col.sortable && "cursor-pointer"
                    } ${isOrderedBy && "font-black"}`}
                    onClick={() => onColumnClick && onColumnClick(col)}
                  >
                    {col.accessor === "check" ? (
                      <div
                        className="relative items-center w-4 h-4 cursor-pointer"
                        onClick={() => onAllSelect()}
                      >
                        <img
                          src={`/icons/${theme}/checkbox/border.svg`}
                          alt="border"
                          className="absolute"
                        />
                        {selectedRows.length === data.length && (
                          <img
                            src={`/icons/${theme}/checkbox/check.svg`}
                            alt="check"
                            className="absolute top-0.5 left-0.5"
                          />
                        )}
                      </div>
                    ) : (
                      <span className="self-center">{col.header}</span>
                    )}
                    {col.sortable && (
                      <img
                        src={`/icons/${theme}/arrows/expand_down.svg`}
                        alt="expand"
                        className={
                          isOrderedBy && orderedBy?.includes("-")
                            ? "transform rotate-180"
                            : ""
                        }
                      />
                    )}
                    {col.filterable &&
                      (col.accessor === "created_at" ? (
                        <div
                          className="flex-auto w-4 items-center"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <DateTablePopover
                            form={form}
                            minDate={minDate || new Date(Date.now())}
                            theme={theme}
                            isOnLeftSide={columns.some(
                              (col) => col.accessor === "status",
                            )}
                            isSmallIcon
                            isBnTable
                          />
                        </div>
                      ) : (
                        <CheckBoxPopUp items={items} colName={col.accessor} />
                      ))}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.length === 0 && (
            <tr className="bg-white text-center dark:bg-gray-900">
              <td
                className="py-40 text-gray-450 text-20 font-bold dark:text-gray-300"
                colSpan={columns.length}
              >
                {noDataMessage}
              </td>
            </tr>
          )}
          {data.map((row, rowId) => (
            <tr
              key={rowId}
              className={`relative bg-white even:bg-gray-50 dark:bg-gray-900 ${
                showPopOver === rowId && location.pathname === "/transactions"
                  ? "h-52"
                  : "h-24"
              }`}
              onClick={() => {
                setIsNftLoaded(false);
                showPopOver === rowId
                  ? setShowPopOver(10000)
                  : setShowPopOver(rowId);
              }}
            >
              {onCheckBoxChange && (
                <td className="whitespace-nowrap px-7">
                  <div
                    className="relative w-4 h-4 cursor-pointer"
                    onClick={() => onSingleSelect(row.id)}
                  >
                    <img
                      src={`/icons/${theme}/checkbox/border.svg`}
                      alt="border"
                      className="absolute"
                    />
                    {selectedRows.includes(row.id) && (
                      <img
                        src={`/icons/${theme}/checkbox/check.svg`}
                        alt="check"
                        className="absolute top-0.5 left-0.5"
                      />
                    )}
                  </div>
                </td>
              )}
              {columns.map(
                (col) =>
                  col.accessor !== "check" && (
                    <td
                      key={col.accessor}
                      className={`${
                        col.accessor === "expand"
                          ? showPopOver === rowId
                            ? "transform rotate-180"
                            : ""
                          : "px-7"
                      } whitespace-nowrap ${
                        showPopOver === rowId && !disableExpand
                          ? col.accessor === "expand"
                            ? "pt-28"
                            : "pb-28"
                          : ""
                      }`}
                    >
                      {col.accessor
                        .split(".")
                        .reduce((val, key) => val && val[key], row)}
                    </td>
                  ),
              )}
              {showPopOver === rowId && location.pathname === "/transactions" && (
                <div className=" h-26 dark:bg-gray-900 absolute left-0 bottom-2 w-full flex">
                  <div style={{ width: columns[0].width }} />
                  <div
                    className="whitespace-nowrap px-7"
                    style={{ width: columns[1].width }}
                  >
                    <div className="flex justify-between py-1 space-x-1 truncate">
                      <p>{row.fromTo.label}</p>
                      <ContactAddress
                        address={row.fromTo.address}
                        name={row.fromTo.name}
                      />
                    </div>
                    {row.network !== "BTC" && (
                      <>
                        <div className="flex justify-between py-1 ">
                          <div>Gas costs </div>
                          <div>
                            {parseFloat(row.gas) > 0
                              ? formatAmount(row.gas)
                              : "No data Available"}
                          </div>
                        </div>

                        <div className="flex justify-between whitespace-nowrap py-1">
                          <div>Gas price (gwei) </div>
                          <div>
                            {parseFloat(row.gas_price) > 0
                              ? formatAmount(row.gas_price)
                              : "No data Available"}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="flex justify-between py-1">
                      <div>Transaction fee </div>
                      {row.fee != null
                        ? formatAmount(parseFloat(row.fee)) +
                          "  " +
                          getFiatSign(row.fiat_currency)
                        : "No data Available"}
                    </div>
                  </div>
                  {row.nft_url && (
                    <div
                      className="relative w-max h-24"
                      style={{ width: columns[2].width }}
                    >
                      {!isNftLoaded && (
                        <div className="absolute w-max -top-1/2 left-10">
                          <Loader />
                        </div>
                      )}
                      <img
                        className="h-20 w-auto rounded-md"
                        src={row.nft_url}
                        alt="Your NFT"
                        onLoad={() => setIsNftLoaded(true)}
                        onError={({ currentTarget }) => {
                          if (
                            currentTarget.src.endsWith(
                              "axie-full-transparent.png",
                            )
                          ) {
                            currentTarget.src = "/axie-egg.png";
                          } else {
                            currentTarget.onerror = null;
                            setIsNftLoaded(true);
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
