import React from "react";

type ToggleProps = {
  onToggle: () => void;
  leftText: string; //initial default
  rightText: string;
  isDefault: boolean;
  invertColors?: boolean;
};

const ToggleButton = ({
  onToggle,
  leftText,
  rightText,
  isDefault,
  invertColors = false,
}: ToggleProps) => {
  const leftOptionStyle = invertColors
    ? isDefault
      ? "bg-white dark:bg-green-300"
      : "bg-gray-100 dark:bg-gray-900"
    : isDefault
    ? "bg-gray-100 dark:bg-green-300"
    : "bg-white dark:bg-gray-900";

  const rightOptionStyle = invertColors
    ? isDefault
      ? "bg-gray-100 dark:bg-gray-900"
      : "bg-white dark:bg-green-300"
    : isDefault
    ? "bg-white dark:bg-gray-900"
    : "bg-gray-100 dark:bg-green-300";

  const leftFontStyle = isDefault ? " font-bold" : " font-semibold";
  const rightFontStyle = isDefault ? " font-semibold" : " font-bold";

  return (
    <div
      className={
        "flex px-2 py-1.5 items-center text-center  rounded-2xl mx-auto max-w-min max-h-min text-gray-900 dark:text-white dark:shadow-dark" +
        (invertColors
          ? " bg-gray-100 dark:bg-gray-900"
          : " bg-white dark:bg-gray-900")
      }
    >
      <div
        className={
          "px-4 py-2 whitespace-nowrap rounded-2xl cursor-pointer " +
          leftOptionStyle +
          leftFontStyle
        }
        onClick={() => onToggle()}
      >
        {leftText}
      </div>
      <div
        className={
          "px-4 py-2 whitespace-nowrap rounded-2xl cursor-pointer " +
          rightOptionStyle +
          rightFontStyle
        }
        onClick={() => onToggle()}
      >
        {rightText}
      </div>
    </div>
  );
};
export default ToggleButton;
