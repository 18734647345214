import React from "react";

import { Fiat } from "../../types";
import { formatAmount } from "../../utils";
import { formatTick } from "../admin/UserHistoryChart";

type CustomTooltipProps = { payload: any; fiat_currency?: Fiat };

function CustomTooltip({ payload, fiat_currency }: CustomTooltipProps) {
  if (payload && payload.length && payload[0].payload?.value_fiat) {
    return (
      <div className="flex flex-col left-0 py-1 px-2 rounded-lg bg-gray-100 dark:bg-gray-600 dark:text-white dark:shadow-dark text-center justify-center  shadow-lg ">
        <p className="p-.5">{payload[0]?.payload?.date}</p>
        {payload[0].payload?.value_fiat && (
          <p className="p-.5 font-bold">
            {" "}
            {formatAmount(payload[0].payload!.value_fiat)}
            {" " + fiat_currency}{" "}
          </p>
        )}
      </div>
    );
  } else if (payload && payload.length && payload[0].payload?.num_users) {
    return (
      <div className="flex flex-col left-0 py-1 px-2 rounded-lg bg-gray-100 dark:bg-gray-600 dark:text-white dark:shadow-dark text-center justify-center  shadow-lg ">
        <p className="p-.5">{payload[0].payload.date}</p>
        {payload[0].payload.num_users && (
          <p className="p-.5 font-bold">
            {" "}
            {formatTick(payload[0].payload.num_users)}
            {" users"}
          </p>
        )}
      </div>
    );
  } else {
    return null;
  }
}

export default CustomTooltip;
