import { Switch } from "@headlessui/react";
import Big from "big.js";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import {
  createStripeSessionSubscription,
  getPricingPlans,
  getUser,
  manageCurrentSubscription,
  subscriptionPurchase,
} from "../api";
import { ThemeContext, UserContext } from "../App";
import BnDialog from "../components/BnDialog";
import CancelPaymentDialog from "../components/payments/CancelPaymentDialog";
import NewPlanDialog from "../components/payments/NewPlanDialog";
import SwitchToFreePlan from "../components/payments/SwitchToFreePlan";
import { showSnackbar } from "../components/Snackbar";
import useQueryParam from "../hooks/useQueryParam";
import {
  PaymentProvider,
  PricingCardProps,
  PricingPlanResponseItem,
  SelectedPlan,
  Tier,
} from "../types";
import { capitalize } from "../utils";
import pricingData from "../utils/pricingData";

export default function PricingPage() {
  const { user, setUser } = useContext(UserContext);
  const query = useQueryParam();

  const [period, setPeriod] = useState<"Monthly" | "Annually">("Monthly");
  const [currentPlan, setCurrentPlan] = useState<Tier>(
    capitalize(user.subscription.plan) as Tier,
  );
  const [isNewPlanDialogOpen, setIsNewPlanDialogOpen] = useState(false);
  const [isCancelPlanDialogOpen, setIsCancelPlanDialogOpen] = useState(false);
  const [isSwitchToFreePlanDialogOpen, setIsSwitchToFreePlanDialogOpen] =
    useState(false);

  const { data: plans } = useQuery("pricing-plans", getPricingPlans);

  const { data: currentUser } = useQuery("user", getUser);

  const fiatSymbol = "€";

  const { mutate: createStripeSession } = useMutation(
    (priceId: string) => createStripeSessionSubscription(priceId),
    {
      onSuccess: (data) => {
        window.open(data.redirect_url, "_self");
      },
    },
  );

  const { mutate: createSubscription } = useMutation(
    (paymentProvider: PaymentProvider) =>
      subscriptionPurchase(paymentProvider, "PLUS"),
    {
      onSuccess: (data) => {
        setUser({ ...user, ...data.user });
        setIsNewPlanDialogOpen(true);
      },
      onError: (e) => console.log(e),
    },
  );

  const { mutate: cancelSubscription } = useMutation(
    () => manageCurrentSubscription(),
    {
      onSuccess: (data) => {
        window.open(data.redirect_url, "_self");
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      setUser({
        ...user,
        subscription: currentUser.subscription,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    setCurrentPlan(capitalize(user.subscription.plan) as Tier);
  }, [user]);

  useEffect(() => {
    const payment = query.get("payment");
    if (payment === "success" && user.subscription.plan === "STARTER") {
      createSubscription("STRIPE");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function togglePeriod() {
    setPeriod((curr) => (curr === "Monthly" ? "Annually" : "Monthly"));
  }

  async function onPlanChoose(plan: SelectedPlan) {
    switch (plan.tier.toUpperCase()) {
      case "PLUS":
        createStripeSession(
          period === "Monthly"
            ? plan.monthly_plan_stripe_id!
            : plan.annual_plan_stripe_id!,
        );
        break;
      case "STARTER":
        if (user.subscription.cancelled_at) {
          showSnackbar("You have cancelled your current subscription already.");
        } else setIsSwitchToFreePlanDialogOpen(true);
        break;
      case "ENTERPRISE":
        window.open("https://basenode.io/contact", "_blank");
        break;
    }
  }

  function onCloseNewPlan() {
    setIsNewPlanDialogOpen(false);
  }

  return (
    <div className="flex flex-col items-center text-green-800">
      <h1 className="text-22 dark:text-gray-100">Plans &amp; Pricing</h1>
      <div className="dark:text-gray-100 mt-4">
        {currentPlan.toUpperCase() === "STARTER" ? (
          <p></p>
        ) : user.subscription.cancelled_at ? (
          <p className="text-center">
            You are currently on the&nbsp;
            <span className="capitalize font-bold">{currentPlan} Plan</span>.
            Your subscription ends at&nbsp;
            {format(
              new Date(user.subscription.ends_at || Date.now()),
              "yyyy-MM-dd",
            )}
            . You have cancelled your subscription so it will not be extended.
          </p>
        ) : (
          <p className="text-center">
            You are currently on the&nbsp;
            <span className="capitalize font-bold">{currentPlan} Plan</span>.
            Your next billing date is&nbsp;
            {format(
              new Date(user.subscription.ends_at || Date.now()),
              "yyyy-MM-dd",
            )}
            . Click&nbsp;
            <span
              onClick={() => setIsCancelPlanDialogOpen(true)}
              className="underline cursor-pointer"
            >
              here
            </span>
            &nbsp; to cancel plan.
          </p>
        )}
      </div>
      <div className="flex items-center justify-center gap-x-4 mt-6">
        <span className="text-green-800 font-bold dark:text-gray-100">
          Annually
        </span>
        <Switch
          checked={period === "Monthly"}
          onChange={togglePeriod}
          className="bg-green-180 relative inline-flex h-7 w-12 items-center rounded-full "
        >
          <span className="sr-only">Toggle period</span>
          <span
            className={`${
              period === "Monthly" ? "translate-x-6" : "translate-x-1"
            } inline-block h-5 w-5 transform transition duration-200 ease-in-out rounded-full bg-green-300`}
          />
        </Switch>
        <span className="texr-green-800 font-bold dark:text-gray-100">
          Monthly
        </span>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-20 gap-y-6 gap-20 mt-10">
        {plans &&
          Object.keys(plans).map((plan, index) => {
            const p = plans[plan as Tier];
            plan = capitalize(plan);
            const planFiatSymbol = p.monthly_price_usd ? fiatSymbol : "";
            const planData = pricingData[index];

            return (
              <PricingCard
                key={plan}
                {...planData}
                btnText={planData.btnText}
                tier={plan as Tier}
                backendData={p}
                priceMonthly={`${planFiatSymbol}${
                  (p.monthly_price_usd && Big(p.monthly_price_usd)) ??
                  planData.priceMonthly
                }`}
                priceAnnually={`${planFiatSymbol}${
                  (p.annual_price_usd && Big(p.annual_price_usd)) ??
                  planData.priceAnnually
                }`}
                period={p.monthly_price_usd ? period : undefined}
                isCurrent={plan === currentPlan}
                onPlanChoose={() => onPlanChoose({ tier: plan as Tier, ...p })}
              />
            );
          })}
      </div>
      <BnDialog
        isOpen={isNewPlanDialogOpen}
        title=""
        centerTitle
        description={<NewPlanDialog plan="PLUS" onSubmit={onCloseNewPlan} />}
        onClose={onCloseNewPlan}
        header={false}
      />
      <BnDialog
        isOpen={isCancelPlanDialogOpen}
        title=""
        centerTitle
        header={false}
        description={
          <CancelPaymentDialog
            plan={currentPlan}
            onSubmit={() => cancelSubscription()}
            plan_end_date={format(
              new Date(user.subscription.ends_at || Date.now()),
              "yyyy-MM-dd",
            )}
            onCancel={() => setIsCancelPlanDialogOpen(false)}
          />
        }
        onClose={() => setIsCancelPlanDialogOpen(false)}
      />
      <BnDialog
        isOpen={isSwitchToFreePlanDialogOpen}
        title=""
        centerTitle
        header={false}
        description={
          <SwitchToFreePlan
            onSubmit={() => cancelSubscription()}
            plan_end_date={format(
              new Date(user.subscription.ends_at || Date.now()),
              "yyyy-MM-dd",
            )}
            onCancel={() => setIsSwitchToFreePlanDialogOpen(false)}
          />
        }
        onClose={() => setIsSwitchToFreePlanDialogOpen(false)}
      />
    </div>
  );
}

function PricingCard({
  tier,
  priceMonthly,
  priceAnnually,
  btnText,
  percentGain,
  features,
  period,
  isCurrent,
  onPlanChoose,
  backendData,
}: PricingCardProps & { backendData: PricingPlanResponseItem }) {
  const theme = useContext(ThemeContext);

  const pluralSign = tier?.toUpperCase() !== "STARTER" ? "s" : "";

  return (
    <div
      style={{ minHeight: "480px", minWidth: "260px" }}
      className={`${
        isCurrent ? "bg-green-50 dark:bg-gray-800" : "bg-white dark:bg-gray-900"
      } relative flex flex-col px-8 py-10 border border-green-180-t-50 rounded-lg text-green-800 dark:text-gray-100 dark:border-gray-200`}
    >
      <p className="mb-1">{tier}</p>
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <h2 className="text-22 font-bold">
            {period === "Monthly" ? priceMonthly : priceAnnually}
          </h2>
          {!!period && (
            <span>&nbsp;/&nbsp;{period === "Monthly" ? "Month" : "Annum"}</span>
          )}
        </div>
        {period === "Annually" && (
          <p className="font-bold text-14">-{percentGain}%</p>
        )}
      </div>
      <button
        onClick={() => {
          onPlanChoose && onPlanChoose();
        }}
        className={`${
          isCurrent ? "bg-gray-500 dark:bg-green-800" : "bg-green-300"
        } text-white font-bold rounded-lg my-9 px-10 py-2 dark:text-gray-100`}
        disabled={isCurrent}
      >
        {isCurrent ? "Current Plan" : btnText}
      </button>
      <div className="space-y-4">
        {tier?.toUpperCase() !== "ENTERPRISE" && (
          <>
            <div className="flex items-center space-x-4 font-bold">
              <img src={`/icons/${theme}/check.svg`} alt="feature" />
              <p className="text-14">{`${
                backendData.max_num_transactions
                  ? `Up to ${backendData.max_num_transactions}`
                  : "Unlimited"
              } transactions ${
                backendData.max_num_transactions ? "/ wallet" : ""
              }`}</p>
            </div>
            <div className="flex items-center space-x-4">
              <img src={`/icons/${theme}/check.svg`} alt="feature" />
              <p className="text-14">{`Connect ${
                backendData.max_num_wallets && backendData.max_num_wallets > 1
                  ? "up to"
                  : ""
              } ${
                backendData.max_num_wallets
                  ? backendData.max_num_wallets
                  : "Unlimited"
              } Wallet${pluralSign}`}</p>
            </div>
            <div className="flex items-center space-x-4">
              <img src={`/icons/${theme}/check.svg`} alt="feature" />
              <p className="text-14">{`${
                backendData.max_num_invoices
                  ? backendData.max_num_invoices
                  : "Unlimited"
              } Smart Invoice${pluralSign} ${
                backendData.max_num_invoices ? "/ Year" : ""
              }`}</p>
            </div>
          </>
        )}
        {features.map((f, id) => (
          <div key={f} className="flex items-center space-x-4">
            <img src={`/icons/${theme}/check.svg`} alt="feature" />
            <p className="text-14">{f}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
