const DECIMAL_PLACES = {
  USDT: 6,
  USDC: 6,
  WBTC: 8,
};

type DecimalKey = keyof typeof DECIMAL_PLACES;

export default function amountToHex(symbol: string, amount: number) {
  const decimal = DECIMAL_PLACES[symbol as DecimalKey] || 18;
  return `0x${(amount * 10 ** decimal).toString(16)}`;
}
