import { Network } from "../types";

export default function getExplorerLink(network: Network, tx_hash: string) {
  const link = {
    AVALANCHE: "https://cchain.explorer.avax.network/tx/",
    BINANCE_SMART_CHAIN: "https://bscscan.com/tx/",
    BITCOIN: "https://www.blockchain.com/btc/tx/",
    ETHEREUM: "https://etherscan.io/tx/",
    FANTOM: "https://ftmscan.com/tx/",
    POLYGON: "https://polygonscan.com/tx/",
    RONIN: "https://explorer.roninchain.com/tx/",
  }[network];

  return link + tx_hash;
}
