import React from "react";

import BnDialog from "../BnDialog";

type TutorialProps = {
  title: string;
  desc: string;
  currentTutorial: number;
  maxTutorial: number;
  nextTutorial: () => void;
  prevTutorial: () => void;
  onClose: () => void;
  isOpen: boolean;
  image?: string;
  maxWidth?: string;
  positionStyle?: string;
  pointer?: {
    className: string;
    style: React.CSSProperties;
  };
};

export default function Tutorial({
  title,
  desc,
  image,
  currentTutorial,
  maxTutorial,
  nextTutorial,
  prevTutorial,
  onClose,
  isOpen,
  maxWidth = "max-w-lg",
  positionStyle,
  pointer,
}: TutorialProps) {
  return (
    <BnDialog
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      centerTitle
      positionStyle={positionStyle}
      pointer={pointer}
      maxWidth={maxWidth}
      description={
        <TutorialBody
          desc={desc}
          image={image}
          currentTutorial={currentTutorial}
          maxTutorial={maxTutorial}
          nextTutorial={nextTutorial}
          prevTutorial={prevTutorial}
        />
      }
    />
  );
}

type TutorialBodyProps = {
  desc: string;
  image?: string;
  currentTutorial: number;
  maxTutorial: number;
  nextTutorial: () => void;
  prevTutorial: () => void;
};

function TutorialBody({
  desc,
  image,
  currentTutorial,
  maxTutorial,
  nextTutorial,
  prevTutorial,
}: TutorialBodyProps) {
  return (
    <div className="flex flex-col items-center">
      <p className="text-green-900 text-16 text-center">{desc}</p>
      {image && (
        <img
          className="my-4"
          src={`/icons/tutorial/${image}.svg`}
          alt={image}
        />
      )}
      <div className="flex mt-2 justify-between w-1/2">
        <img
          onClick={prevTutorial}
          src="/icons/tutorial/arrow_left.svg"
          alt="previous"
          className="cursor-pointer"
        />
        <p className="text-gray-600 mt-2">{`${currentTutorial} of ${maxTutorial}`}</p>
        <img
          onClick={nextTutorial}
          src="/icons/tutorial/arrow_right.svg"
          alt="next"
          className="cursor-pointer"
        />
      </div>
      <button></button>
    </div>
  );
}
