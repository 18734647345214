/* eslint-disable @typescript-eslint/no-var-requires */
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import Big from "big.js";
import { format } from "date-fns";
import React from "react";

import { InvoiceItemResponse, InvoiceResponse } from "../../types";
import { formatAmount, getFiatSign, getInvoiceItemAmounts } from "../../utils";
import BasePdf from "./BasePdf";

type PdfProps = { invoice: InvoiceResponse };

export default function InvoicePdf({ invoice }: PdfProps) {
  const paymentUrl = `${window.location.origin}/pay-invoice/${invoice.id}`;
  const totalBaseAmount = invoice.items
    .map((item) => getInvoiceItemAmounts(item).basePrice)
    .reduce((sum, amount) => Big(sum).add(Big(amount)), Big(0))
    .toString();
  const taxAmount = Big(invoice.total_amount)
    .sub(Big(totalBaseAmount))
    .toString();

  function createItemBox(item: InvoiceItemResponse) {
    return (
      <View style={styles.itemBox}>
        <View>
          <Text style={styles.nunito}>{item.name}</Text>
          <View style={styles.itemDetails}>
            <Text style={styles.nunito}>{item.quantity} x </Text>
            <Text style={styles.nunito}>{`${getFiatSign(
              invoice.fiat_currency,
            )} ${formatAmount(item.unit_price)}`}</Text>
            {parseFloat(item.discount) !== 0 && (
              <>
                <Text style={styles.nunito}> | Discount </Text>
                <Text style={styles.nunito}>
                  {item.discount_is_percent
                    ? `(${formatAmount(item.discount, 2)}%)`
                    : `${getFiatSign(invoice.fiat_currency)} ${formatAmount(
                        item.discount,
                      )}`}
                </Text>
              </>
            )}
            <Text style={styles.nunito}> | Tax</Text>
            <Text style={styles.nunito}>
              {item.tax_is_percent
                ? `(${formatAmount(item.tax, 2)}%)`
                : `${getFiatSign(invoice.fiat_currency)} ${formatAmount(
                    item.tax,
                  )}`}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.nunito}>{`${getFiatSign(
            invoice.fiat_currency,
          )} ${formatAmount(item.total_amount)}`}</Text>
        </View>
      </View>
    );
  }

  return (
    <BasePdf
      title={`Invoice from ${invoice.owner.first_name} ${invoice.owner.last_name}`}
    >
      <View style={styles.invoiceHeader}>
        <View>
          <Text style={styles.headerText}>{invoice.name}</Text>
          <Text style={styles.headerText}>
            Issued: {format(new Date(invoice.issued_at), "MMM dd, yyyy")}
          </Text>
          <Text style={styles.headerText}>
            Due: {format(new Date(invoice.expires_at), "MMM dd, yyyy")}
          </Text>
        </View>

        <View style={styles.headerRight}>
          <View style={styles.headerAmount}>
            <Text>{`${getFiatSign(invoice.fiat_currency)} ${formatAmount(
              invoice.total_amount,
            )}`}</Text>
          </View>
          <Text style={[styles.status, styles[invoice.state]]}>
            {invoice.state}
          </Text>
        </View>
      </View>

      <View style={styles.fromTo}>
        <View style={{ flex: 1, marginRight: 10 }}>
          <Text style={styles.subtitle}>Bill to</Text>
          <View style={styles.personBox}>
            <Text style={styles.personBoxTitle}>
              {invoice.contact.first_name} {invoice.contact.last_name}
            </Text>
            <Text style={styles.personBoxText}>{invoice.contact.email}</Text>
            <Text style={styles.personBoxText}>
              {invoice.contact.address_line1}
              {invoice.contact.address_line2
                ? ` ${invoice.contact.address_line2}, `
                : ", "}
              {invoice.contact.postal_code} {invoice.contact.city},{" "}
              {invoice.contact.country}
            </Text>
          </View>
        </View>
        <View style={{ flex: 1, marginLeft: 10 }}>
          <Text style={styles.subtitle}>From</Text>
          <View style={styles.personBox}>
            <Text style={styles.personBoxTitle}>
              {invoice.owner.first_name} {invoice.owner.last_name}
            </Text>
            <Text style={styles.personBoxText}>{invoice.owner.email}</Text>
            <Text style={styles.personBoxText}>
              {invoice.owner.address_line1}
              {invoice.owner.address_line2
                ? ` ${invoice.owner.address_line2}, `
                : ", "}
              {invoice.owner.postal_code} {invoice.owner.city},{" "}
              {invoice.owner.country}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.items}>
        <Text style={styles.subtitle}>Items</Text>

        {invoice.items.map((item, id) => (
          <React.Fragment key={id}>{createItemBox(item)}</React.Fragment>
        ))}
      </View>

      <View
        wrap={false}
        style={{ flexDirection: "row", justifyContent: "flex-end" }}
      >
        <View style={styles.itemsSummary}>
          <View
            style={{
              marginRight: 15,
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Text style={styles.summaryText}>Total without Tax</Text>
            <Text style={styles.summaryText}>Total Tax amount</Text>
            <Text style={styles.summaryFinalText}>Subtotal</Text>
          </View>
          <View>
            <View style={styles.summaryAmount}>
              <Text>{`${getFiatSign(invoice.fiat_currency)} ${formatAmount(
                totalBaseAmount,
              )}`}</Text>
            </View>
            <View style={styles.summaryAmount}>
              <Text>{`${getFiatSign(invoice.fiat_currency)} ${formatAmount(
                taxAmount,
              )}`}</Text>
            </View>
            <View style={styles.summaryFinalAmount}>
              <Text>{`${getFiatSign(invoice.fiat_currency)} ${formatAmount(
                invoice.total_amount,
              )}`}</Text>
            </View>
          </View>
        </View>
      </View>

      <View wrap={false}>
        <Text style={styles.subtitle}>Note to client</Text>
        <Text style={styles.nunito}>{invoice.message}</Text>
        <Text style={styles.nunito}>This invoice can be paid on: </Text>
        <Text style={styles.nunito}>{paymentUrl}</Text>
      </View>
    </BasePdf>
  );
}

Font.register({
  family: "Nunito",
  format: "truetype",
  fonts: [
    { src: "/fonts/Nunito-Regular.ttf" },
    { src: "/fonts/Nunito-SemiBold.ttf", fontWeight: 600 },
    { src: "/fonts/Nunito-Bold.ttf", fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  invoiceHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    color: "#494949",
  },
  headerText: {
    fontFamily: "Nunito",
    fontSize: 14,
    marginTop: 4,
  },
  headerRight: {
    flexDirection: "column",
    alignItems: "flex-end",
    fontFamily: "Nunito",
  },
  headerAmount: {
    flexDirection: "row",
    alignItems: "center",
    fontSize: 20,
    fontFamily: "Nunito",
  },
  currencyBigIcon: {
    width: 15,
    height: 15,
    marginRight: 4,
  },
  currencyIcon: {
    width: 10,
    height: 10,
    marginRight: 4,
  },
  status: {
    fontFamily: "Nunito",
    fontSize: 12,
    marginTop: 4,
  },
  DUE: {
    color: "#494949",
  },
  PAID: {
    backgroundColor: "#54A097",
    color: "#FFFFFF",
    paddingHorizontal: 8,
    paddingVertical: 1,
    borderRadius: 5,
  },
  DRAFT: {
    backgroundColor: "#D14836",
    color: "#FFFFFF",
    paddingHorizontal: 8,
    paddingVertical: 1,
    borderRadius: 5,
  },
  EXPIRED: {
    backgroundColor: "#D14836",
    color: "#FFFFFF",
    paddingHorizontal: 8,
    paddingVertical: 1,
    borderRadius: 5,
  },

  subtitle: {
    fontFamily: "Nunito",
    fontWeight: 600,
    fontSize: 14,
    color: "#2F5A55",
    marginBottom: 8,
  },
  fromTo: {
    flexDirection: "row",
    marginTop: 40,
  },
  personBox: {
    minHeight: 150,
    flexDirection: "column",
    justifyContent: "center",
    border: "1",
    borderColor: "#BDBDBD",
    borderRadius: 5,
    padding: 20,
  },
  personBoxTitle: {
    fontFamily: "Nunito",
    fontWeight: 600,
    fontSize: 14,
    color: "#494949",
  },
  personBoxText: {
    fontFamily: "Nunito",
    fontSize: 12,
    color: "#494949",
    marginTop: 8,
  },

  items: {
    marginTop: 20,
    flexDirection: "column",
  },
  itemBox: {
    color: "#494949",
    fontSize: 14,
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1",
    borderColor: "#BDBDBD",
    borderRadius: 5,
    padding: 20,
    marginBottom: 10,
  },
  itemDetails: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
  },
  itemsSummary: {
    fontFamily: "Nunito",
    marginTop: 20,
    flexDirection: "row",
    fontSize: 12,
  },
  summaryText: {
    fontFamily: "Nunito",
    color: "#2F5A55",
    marginBottom: 15,
  },
  summaryFinalText: {
    fontFamily: "Nunito",
    fontWeight: 700,
    color: "#2F5A55",
    fontSize: 14,
  },
  summaryAmount: {
    fontFamily: "Nunito",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    color: "#494949",
    marginBottom: 15,
  },
  summaryFinalAmount: {
    fontFamily: "Nunito",
    fontWeight: 700,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    color: "#494949",
    fontSize: 14,
  },
  nunito: {
    fontFamily: "Nunito",
    fontSize: 12,
    color: "#494949",
  },
});
