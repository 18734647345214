import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import { ThemeContext } from "../../App";
import { useUser } from "../../hooks/useUser";
import { capitalize, isBusiness } from "../../utils";
import CurrencySelection from "./CurrencySelection";
import MobileNav from "./MobileNav";
import QuickAdd from "./QuickAdd";
import SearchBar from "./SearchBar";
import UserButton from "./UserButton";

const navClasses =
  "bg-transparent w-full text-16 h-24 flex items-center justify-between gap-x-4 pl-5 pr-5 xl:pl-20 xl:pr-7 dark:bg-gray-950";

export function BasicNavBar() {
  return (
    <div className={navClasses}>
      <Link to="/">
        <img src="/icons/logo_new.svg" alt="logo" />
      </Link>
    </div>
  );
}

export function FullNavBar() {
  const location = useLocation();
  const user = useUser();
  const navFlex = "flex items-center gap-x-3 xl:gap-x-9";
  const tabs = ["overview", "transactions", "contacts", "wallets", "documents"];
  const theme = useContext(ThemeContext);
  return (
    <div className={navClasses}>
      <div className={navFlex}>
        <Link to="/" className="mr-0 xl:mr-10 min-w-min">
          <img
            src={
              isBusiness(user.signed_email)
                ? "/icons/basenode_business.svg"
                : `/icons/${theme}/basenode_small_logo.svg`
            }
            alt="logo"
            className={isBusiness(user.signed_email) ? "w-18" : ""}
          />
        </Link>
        <MobileNav tabs={tabs} />
        {tabs.map((tab) => (
          <Link
            key={tab}
            to={`/${tab}`}
            className="pseudo sm:inline hidden"
            data-text={capitalize(tab)}
          >
            <p
              className={`text-green-800 dark:text-gray-300 font-bold ${
                location.pathname.includes(tab) &&
                "border-b pb-1 border-green-800 dark:border-gray-300"
              }`}
            >
              {capitalize(tab)}
            </p>
          </Link>
        ))}
      </div>
      <div className={navFlex}>
        <SearchBar />
        <CurrencySelection />
        <QuickAdd />
        <UserButton />
      </div>
    </div>
  );
}
