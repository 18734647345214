import React from "react";
import ReactSlider from "react-slider";

type SliderProps = {
  value: number;
  onChange: (val: number) => void;
  min: number;
  max: number;
  marks: number | number[] | boolean;
  width?: string;
  startLabel?: string;
  endLabel?: string;
};

export default function Slider({
  value,
  onChange,
  min,
  max,
  marks,
  width = "w-full",
  startLabel,
  endLabel,
}: SliderProps) {
  return (
    <div className={`${width}`}>
      <ReactSlider
        value={value}
        onChange={onChange}
        className="mx-1"
        trackClassName="top-1.5 h-1 bg-gray-450 rounded-md"
        thumbClassName="cursor-pointer bg-green-300 h-4 w-1 rounded-md hover:bg-green-400 shadow-md"
        renderThumb={(props, state) => (
          <div
            {...props}
            className="relative cursor-pointer bg-green-300 h-4 w-1 rounded-md hover:bg-green-400 shadow-md"
          >
            {![min, max].includes(state.value) && (
              <p
                className="absolute top-4 color-green-800 text-10"
                style={{ left: "-1px" }}
              >
                {state.value}
              </p>
            )}
          </div>
        )}
        min={min}
        max={max}
        marks={marks}
      />
      <div className="flex justify-between items-center mt-2">
        <p className="text-10 color-green-800 ">{startLabel || min}</p>
        <p className="text-10 color-green-800 ">{endLabel || max}</p>
      </div>
    </div>
  );
}
