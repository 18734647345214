import Hotjar from "@hotjar/browser";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useLocation } from "react-router-dom";

import Snackbar from "./components/Snackbar";
import useLocalStorage from "./hooks/useLocalStorage";
import { useCachedUser } from "./hooks/useUser";
import Routes from "./routes";

declare global {
  interface Window {
    ethereum: any;
  }
}

// Default null is irrelevant as there will always be a provider configured.
export const UserContext = React.createContext(null as any);
export const ThemeContext = React.createContext(null as any);

const queryClient = new QueryClient();

export default function App() {
  const location = useLocation<any>();
  const userContextData = useCachedUser();
  const BASENODE_MAIN_APP = "app.basenode.io";

  const hotjarSiteId =
    BASENODE_MAIN_APP === window.location.hostname ? 3560031 : 0;
  const hotjarSnippetVersion = 6;

  const [theme, setTheme] = useLocalStorage("theme", "light");
  useEffect(() => {
    if (theme) {
      setTheme(theme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    Hotjar.init(hotjarSiteId, hotjarSnippetVersion);
  }, [hotjarSiteId]);

  return (
    <UserContext.Provider value={userContextData}>
      <QueryClientProvider client={queryClient}>
        <ThemeContext.Provider value={theme}>
          <div
            className={`min-h-screen flex flex-col ${
              userContextData.user ||
              location.pathname.includes("pay-invoice") ||
              location.pathname.includes("faq")
                ? "bg-gray-100"
                : "bg-white"
            } dark:bg-gray-950`}
          >
            <Routes />
            <Snackbar />
          </div>
        </ThemeContext.Provider>
      </QueryClientProvider>
    </UserContext.Provider>
  );
}
