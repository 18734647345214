import { Popover, Transition } from "@headlessui/react";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

import { getSearchResults } from "../../api";
import { ThemeContext } from "../../App";
import useDebounce from "../../hooks/useDebounce";
import { SearchResultList } from "../../types";
import { capitalize } from "../../utils";
import Loader from "../Loader";

export default function SearchBar() {
  const history = useHistory();
  const form = useForm();
  const [isData, setIsData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const popoverRef = useRef<HTMLInputElement | null>(null);
  const theme = useContext(ThemeContext);

  const { ref, ...rest } = form.register("search");
  const searchInput: string = useDebounce(form.watch("search"), 500);

  const { data, mutate } = useMutation(
    "searchResults",
    (query: string) => getSearchResults(query),
    { onSuccess: () => setIsLoading(false) },
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  }, [searchInputRef]);

  useEffect(() => {
    setIsData(false);
    if (searchInput) {
      setIsFocused(true);
      mutate(searchInput);
    }
  }, [mutate, searchInput]);

  useEffect(() => {
    setIsLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch("search")]);

  function handleSearchChoice(link: string) {
    setIsFocused(false);
    history.push(link, {
      search: searchInput, // query string
    });
  }

  function handleClickOutside(e: any) {
    if (!popoverRef.current?.contains(e.target)) {
      setIsFocused(false);
    }
  }

  function resetSearch() {
    form.setValue("search", "");
    setIsFocused(true);
    history.replace(history.location.pathname, {
      search: " ", // query string
    });
    searchInputRef.current?.focus();
  }
  return (
    <Popover className="relative flex items-center">
      {({ open }) => (
        <>
          {!searchInput && !open && !isFocused && (
            <Popover.Button className="focus:outline-none">
              <img
                src={`/icons/${theme}/green_loupe.svg`}
                alt="search"
                className="w-7 h-7 cursor-pointer"
              />
            </Popover.Button>
          )}

          {(open || searchInput || isFocused) && (
            <Popover.Panel className="flex items-center" static>
              <div
                className={`flex items-center h-10 ${
                  open || searchInput || isFocused
                    ? "bg-white space-x-5 rounded-40px px-6 ml-0.5 dark:bg-gray-900"
                    : ""
                }`}
                ref={popoverRef}
              >
                <img
                  src={`/icons/${theme}/green_loupe.svg`}
                  alt="search"
                  className="w-7 h-7 cursor-pointer"
                />

                <input
                  {...rest}
                  name="search"
                  placeholder="Search"
                  className="outline-none w-full text-16 text-green-800 placeholder-gray-450-t-50 dark:bg-gray-900 dark:text-gray-300"
                  autoFocus
                  autoComplete="off"
                  ref={(e) => {
                    ref(e);
                    searchInputRef.current = e; // you can still assign to ref
                  }}
                  onClick={() => setIsFocused(true)}
                />

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                  show={isFocused && !!form.getValues("search")}
                >
                  <div className="absolute z-10 top-full -left-5 w-full min-w-min mt-3 p-4 bg-white rounded-md text-16 text-green-800 flex flex-col shadow-search-results dark:bg-gray-950 dark:text-gray-300 dark:shadow-dark">
                    {isLoading && (
                      <div className="-mt-10 mb-10">
                        <Loader />
                      </div>
                    )}

                    {data &&
                      !isLoading &&
                      Object.entries(data).map(([title, resultList]) => {
                        const isResult = resultList.length !== 0;
                        if (isResult) {
                          setIsData(true);
                          return (
                            <GroupedResults
                              key={title}
                              title={title}
                              resultList={resultList}
                              handleSearchChoice={handleSearchChoice}
                            />
                          );
                        } else {
                          return "";
                        }
                      })}

                    {!isData && !isLoading && (
                      <p className="text-red-500">{capitalize("no results")}</p>
                    )}
                  </div>
                </Transition>

                <div className="w-14 h-7">
                  {searchInput && (
                    <img
                      src={`/icons/${theme}/close.svg`}
                      alt="close"
                      className="cursor-pointer w-full h-full"
                      onClick={resetSearch}
                    />
                  )}
                </div>
              </div>
            </Popover.Panel>
          )}
        </>
      )}
    </Popover>
  );
}

type GroupedResultsProps = {
  title: string;
  resultList: SearchResultList[];
  handleSearchChoice: any;
};

function GroupedResults({
  title,
  resultList,
  handleSearchChoice,
}: GroupedResultsProps) {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <h2 className="font-semibold text-center text-green-300 dark:text-gray-300">
        {capitalize(title)}
      </h2>
      {resultList.slice(0, showMore ? 6 : 3).map(({ id, text }) => (
        <Popover.Button className="text-left" key={id}>
          <p
            onClick={() => handleSearchChoice(`/${title}`)}
            className="whitespace-nowrap cursor-pointer my-1"
          >
            {text}
          </p>
        </Popover.Button>
      ))}
      {resultList.length > 3 && (
        <p
          className="text-11 cursor-pointer capitalize font-bold mt-1"
          onClick={() => {
            setShowMore((prevShow) => !prevShow);
          }}
        >
          see {showMore ? "less" : "more"}
        </p>
      )}
    </>
  );
}
