import React, { useContext } from "react";

import { ThemeContext } from "../../App";
import { useUser } from "../../hooks/useUser";

export default function UserAvatar() {
  const user = useUser();
  const theme = useContext(ThemeContext);

  const isGoogleUser = user.type === "GOOGLE";

  function generateIcon() {
    if (!user.type)
      return (
        <img
          className="w-7 h-7 m-auto"
          src={`/icons/${theme}/guest_user_icon.svg`}
          alt="Guest"
        />
      );

    if (isGoogleUser)
      return (
        <p className="text-white opacity-90 text-28 m-auto dark:text-green-800">
          {user.signed_email[0].toUpperCase() ?? "W"}
        </p>
      );
    return (
      <img
        className="w-9 h-9 m-auto"
        src={`/icons/wallets/${user.type.toLowerCase()}.svg`}
        alt={user.type.toLowerCase()}
      />
    );
  }
  return (
    <div className="flex items-center w-full space-x-5">
      <div className="bg-green-200 dark:bg-gray-300 w-12 h-12 rounded-full flex items-center">
        {generateIcon()}
      </div>
      <div className="truncate text-14 text-green-800 dark:text-gray-300">
        <p>Signed in as </p>
        <p className=" font-bold">
          {isGoogleUser ? user.signed_email : user.address}
        </p>
      </div>
    </div>
  );
}
