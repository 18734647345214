/* eslint-disable react-hooks/exhaustive-deps */

import { format, isSameDay } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import {
  deleteDocument,
  getDocumentCSV,
  getDocumentPdfData,
  getDocuments,
  getHoldingsPdfData,
  getInvoice,
  getInvoiceLimit,
  getTransactions,
  invoicePdfUrl,
} from "../api";
import { ThemeContext } from "../App";
import ActionPopover from "../components/ActionPopover";
import BnDialog from "../components/BnDialog";
import BnTable, { Column } from "../components/BNTable";
import DocumentForm from "../components/forms/DocumentForm";
import Loader from "../components/Loader";
import { planLimitsMessage } from "../components/navBar/QuickAdd";
import NoWalletInfo from "../components/NoWalletInfo";
import EndOfSubscriptionLightBox from "../components/overview/EndOfSubscriptionLightBox";
import PageHeader from "../components/PageHeader";
import HoldingsPdf from "../components/pdfDocs/HoldingsPdf";
import TransactionPdf from "../components/pdfDocs/TransactionPdf";
import { showSnackbar } from "../components/Snackbar";
import ToggleButton from "../components/ToggleButton";
import Tooltip from "../components/Tooltip";
import EndTutorial from "../components/tutorial/EndTutorial";
import Tutorial from "../components/tutorial/Tutorial";
import CheckedItemsContext from "../hooks/CheckedItemsContext";
import DocumentCurrencyFilter from "../hooks/DocumentCurrencyFilter";
import DocumentPageDateFilters from "../hooks/DocumentPageDateFilters";
import SortDocumentPageParameter from "../hooks/SortDocumentPageParameter";
import useEndTutorial from "../hooks/useEndTutorial";
import useLocalStorage from "../hooks/useLocalStorage";
import { useUser } from "../hooks/useUser";
import {
  Action,
  DateFilter,
  DocumentResponse,
  DocumentTypes,
  DocumentsParams,
  InvoiceTypes,
  LocationProps,
  TimePeriod,
} from "../types";
import {
  downloadFileByHref,
  downloadPDF,
  previewPDF,
  previewPDFbyHref,
  toLowerAndOutUnderScore,
} from "../utils";

export const FilterContextData = React.createContext(null as any);
export default function DocumentsPage() {
  const theme = useContext(ThemeContext);
  const user = useUser();
  const history = useHistory();
  const endTutorial = useEndTutorial();
  const location = useLocation<LocationProps>();
  const tutorial = location.state?.tutorial;

  const anchorRef = useRef<HTMLAnchorElement | null>(null);

  const [onEdit, setOnEdit] = useState<DocumentResponse | undefined>(undefined);
  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] = useState({
    isOpen: false,
    subject: "invoices",
  });
  const [docToExport, setDocToExport] = useState<DocumentResponse | null>(null);
  const [docToPreview, setDocToPreview] = useState<DocumentResponse | null>(
    null,
  );

  const [isFiltered, setIsFiltered] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState<string[]>(
    InvoiceTypes.map((c) => c),
  ); //invoice states

  const now = new Date(Date.now());
  //is decumen reporttype or invoice type
  const [isInvoice, setIsInvoice] = useState(true);
  const [documentsFilters, setDocumentsFilters] = useLocalStorage<DateFilter>(
    "documents_filters",
    { start_date: "", end_date: "" },
  );
  const [currencyFilter, setDocumentCurrencyFilter] = useState<string>("");
  const [sortBy, setDocumentSortPrameter] = useState<string>("created_at");
  //see explanation at Transactions Page
  const [shouldReplaceData, setShouldReplaceData] = useState(true);
  const [invoiceToRemove, setInvoiceToRemove] = useState<number | null>(null);
  const [tutorialState, setTutorialState] = useState<string>();

  //quey  first report used to set min date
  const { data: FirstDocument } = useQuery(["firstDocument"], () =>
    getDocuments({
      limit: 1,
      offset: 0,
      ordering: "created_at",
    }),
  );
  const { data: invoiceLimits } = useQuery("invoiceLimits", () =>
    getInvoiceLimit(),
  );

  // query last transaction used to set min date in report generation form
  const { data: lastTransaction } = useQuery(["lastTransaction"], () =>
    getTransactions({
      limit: 1,
      offset: 0,
      ordering: "created_at",
    }),
  );

  const { mutate: removeDocument } = useMutation(
    (id: number) => deleteDocument(id),
    {
      onSuccess: () => onDeleteDocument(),
    },
  );

  //minimum date used in date picker for tx dialog
  const [minDateOfTransaction, setMinDateOfTransaction] = useState<Date>(
    (lastTransaction && new Date(lastTransaction.results[0]?.created_at)) ||
      new Date(now),
  );

  //minimum date used in date picker of document filters
  const [minDate, setMinDate] = useState(
    new Date(
      Date.parse(documentsFilters.start_date.toString()) ||
        FirstDocument?.results[0]?.created_at ||
        now,
    ),
  );
  useEffect(() => {
    if (lastTransaction) {
      const newMinDate = new Date(
        lastTransaction.results[0]?.created_at || now,
      );
      setMinDateOfTransaction(newMinDate);
    }
  }, [lastTransaction]);

  const [timePeriod, setTimePeriod] = useState<TimePeriod>({
    start_date: new Date(
      Date.parse(documentsFilters.start_date.toString()) || minDate,
    ),
    end_date: new Date(Date.parse(documentsFilters.end_date.toString()) || now),
  });

  useEffect(() => {
    if (FirstDocument) {
      const newMinDate = new Date(
        FirstDocument?.results[0]?.created_at || Date.now() - 1,
      );
      if (isSameDay(timePeriod.start_date, minDate)) {
        setTimePeriod((prev) => ({ ...prev, start_date: newMinDate }));
      }
      setMinDate(newMinDate);
    }
  }, [FirstDocument]);

  const [queryParams, setQueryParams] = useState<DocumentsParams>({
    limit: 10,
    offset: 0,
    ordering: "-created_at",
    // search: location.state ? location.state.search : "",
    state: InvoiceTypes.map((c) => c),
    kind: ["INVOICE"],
    created_at_after: format(
      new Date(documentsFilters.start_date.toString() || minDate),
      "yyyy-MM-dd",
    ),
    created_at_before: format(
      new Date(documentsFilters.end_date.toString() || now),
      "yyyy-MM-dd",
    ),
  });

  const { data, isLoading, refetch } = useQuery(
    ["documents", queryParams],
    () => getDocuments(queryParams),
    {
      enabled: user.full_access,
    },
  );

  useEffect(() => {
    if (
      !isSameDay(timePeriod.start_date, minDate) ||
      !isSameDay(timePeriod.end_date, now) ||
      currencyFilter !== "" ||
      (isInvoice && checkedItems.length !== InvoiceTypes.length) ||
      (!isInvoice && checkedItems.length !== DocumentTypes.length)
    ) {
      setIsFiltered(true);
    } else setIsFiltered(false);
  }, [currencyFilter, checkedItems, timePeriod, minDate]);

  useEffect(() => {
    setShouldReplaceData(true);
    if (isInvoice) {
      setQueryParams((prevParams) => ({
        ...prevParams,
        limit: 10,
        offset: 0,
        state: checkedItems,
        currency: currencyFilter ? currencyFilter : "",
        kind: ["INVOICE"],
        aditional_ordering: "None",
      }));
    } else {
      setQueryParams((prevParams) => ({
        ...prevParams,
        limit: 10,
        offset: 0,
        kind: checkedItems,
      }));
    }
  }, [checkedItems, currencyFilter]);

  useEffect(() => {
    if (sortBy === "total_amount") {
      setQueryParams((prevParams) => ({
        ...prevParams,
        limit: 10,
        offset: 0,
        ordering: "created_at",
        aditional_ordering: "total_amount",
      }));
    } else {
      setQueryParams((prevParams) => ({
        ...prevParams,
        limit: 10,
        offset: 0,
        ordering: "created_at",
        aditional_ordering: "-total_amount",
      }));
    }
  }, [sortBy]);

  useEffect(() => {
    if (data && !isLoading) {
      if (shouldReplaceData) {
        setTableData(formatTableData(data.results));
      } else {
        setTableData([...tableData, ...formatTableData(data.results)]);
      }
    }
  }, [JSON.stringify(data)]);

  //refetch after filter options change
  useEffect(() => {
    if (timePeriod.start_date) {
      onFilterChange();
    }
  }, [timePeriod]);

  //Storing user filter settings
  useEffect(() => {
    setDocumentsFilters({
      start_date: format(timePeriod.start_date || now, "yyyy-MM-dd"),
      end_date: format(timePeriod.end_date || now, "yyyy-MM-dd"),
    });
    setQueryParams((prev) => ({
      ...prev,
      created_at_after: format(timePeriod.start_date || now, "yyyy-MM-dd"),
      created_at_before: format(timePeriod.end_date || now, "yyyy-MM-dd"),
    }));
  }, [timePeriod]);

  useEffect(() => {
    if (docToExport) {
      generatePDF();
    }
  }, [docToExport]);

  useEffect(() => {
    if (docToPreview) {
      previewDoc();
    }
  }, [docToPreview]);

  useEffect(() => {
    if (tutorial) {
      setTutorialState(tutorial);
    }
  }, [tutorial]);

  const updateReportsListOnToggle = () => {
    setIsInvoice((isInvoice) => !isInvoice);
    setShouldReplaceData(true);
    if (!isInvoice) {
      setCheckedItems(InvoiceTypes.map((c) => c));
    } else {
      setCheckedItems(DocumentTypes.map((c) => c));
    }
  };
  if (!user.full_access) {
    return (
      <div>
        <PageHeader title="Documents" />
        <div className="w-full mt-8 text-center font-bold text-green-800 dark:text-gray-300">
          This feature is available only for logged in users.
        </div>
      </div>
    );
  }
  if (!user.has_wallet) {
    return <NoWalletInfo />;
  }

  async function generatePDF() {
    if (docToExport!.invoice) {
      const res = await getInvoice(docToExport!.invoice.id);
      if (res) {
        const href = invoicePdfUrl(docToExport!.invoice.id, true);
        downloadFileByHref(docToExport!.name, href);
      }
    } else if (docToExport!.kind === "TRANSACTION_REPORT") {
      const res = await getDocumentPdfData(docToExport!.id);

      if (res) {
        await downloadPDF(
          docToExport!.name,
          <TransactionPdf docDetails={docToExport!} transactions={res} />,
        );
      }
    } else if (docToExport!.kind === "HOLDINGS_REPORT") {
      const res = await getHoldingsPdfData(docToExport!.id);
      if (res) {
        await downloadPDF(
          docToExport!.name,
          <HoldingsPdf docDetails={docToExport!} holdings={res} />,
        );
      }
    }
    setDocToExport(null);
    setExportLoading(false);
  }

  async function previewDoc() {
    if (docToPreview!.invoice) {
      const res = await getInvoice(docToPreview!.invoice.id);
      if (res) {
        previewPDFbyHref(invoicePdfUrl(res.id));
      }
    } else if (docToPreview!.kind === "TRANSACTION_REPORT") {
      const res = await getDocumentPdfData(docToPreview!.id);
      if (res) {
        await previewPDF(
          <TransactionPdf docDetails={docToPreview!} transactions={res} />,
        );
      }
    } else if (docToPreview!.kind === "HOLDINGS_REPORT") {
      const res = await getHoldingsPdfData(docToPreview!.id);
      if (res) {
        await previewPDF(
          <HoldingsPdf docDetails={docToPreview!} holdings={res} />,
        );
      }
    }

    setDocToPreview(null);
    setExportLoading(false);
  }

  //Manage styling and content of the table cells
  function formatTableData(data: DocumentResponse[]) {
    return data.map((doc) => ({
      id: doc.id,
      name: (
        <div>
          {doc.invoice && <div>{doc.invoice.name}</div>}
          {!doc.invoice && <p>{doc.name}</p>}
          <p
            className={`
            loading-dots text-10 text-gray-800 
            ${
              !(doc.kind === "QUICKBOOKS_REPORT" && !doc.quickbooks_url) &&
              "hidden"
            }`}
          >
            Generating
          </p>
        </div>
      ),
      amount: (
        <div className="flex items-center">
          {doc.invoice && (
            <div>
              {doc.invoice.total_amount} {doc.invoice.fiat_currency}
            </div>
          )}
          {!doc.invoice && <p>{doc.name}</p>}
        </div>
      ),
      status: (
        <div className="flex items-center">
          {doc.invoice && doc.invoice.state === "PAID" && (
            <div className="mr-2 rounded-md text-center p-1 w-16 text-12 font-semibold bg-green-800 text-white ">
              {doc.invoice.state}
            </div>
          )}
          {doc.invoice && doc.invoice.state === "PROCESSING" && (
            <div className="mr-2 rounded-md text-center p-2 w-24 text-12 font-semibold bg-yellow-50 text-white ">
              {doc.invoice.state}
            </div>
          )}
          {doc.invoice && doc.invoice.state === "DRAFT" && (
            <div className="mr-2 rounded-md text-center p-1 w-16 text-12 font-semibold bg-white text-red-500 border-2 border-red-500">
              {doc.invoice.state}
            </div>
          )}
          {doc.invoice && doc.invoice.state === "DUE" && (
            <div className="mr-2 rounded-md text-center p-1 w-16 text-12 font-semibold bg-white text-green-800 border-2 border-green-300">
              SENT
            </div>
          )}
          {doc.invoice && doc.invoice.state === "EXPIRED" && (
            <div className="mr-2 rounded-md text-center p-1 w-16 text-12 font-semibold bg-red-500 text-white">
              {doc.invoice.state}{" "}
            </div>
          )}
        </div>
      ),
      type: (
        <div className="capitalize">{toLowerAndOutUnderScore(doc.kind)}</div>
      ),
      report_range: doc.invoice ? (
        "-"
      ) : (
        <div className="whitespace-nowrap flex">
          {format(new Date(doc.start_date), "yyyy-MM-dd")}
          <img
            src={`/icons/${theme}/arrows/arrow_right_light.svg`}
            alt="arrow right"
          />
          {format(new Date(doc.end_date), "yyyy-MM-dd")}
        </div>
      ),
      created_at: (
        <div>
          <p>{format(new Date(doc.created_at), "yyyy-MM-dd")}</p>
          <p>{format(new Date(doc.created_at), "HH:mm:ss")}</p>
        </div>
      ),
      end_date: (
        <div>
          {doc.invoice?.expires_at && (
            <div>
              <p>{format(new Date(doc.invoice.expires_at), "yyyy-MM-dd")}</p>
              <p>{format(new Date(doc.invoice.expires_at), "HH:mm:ss")}</p>
            </div>
          )}
        </div>
      ),
      action: <ActionPopover actions={getDocumentActions(doc)} />,
      csvName: doc.name,
    }));
  }

  function getDocumentActions(doc: DocumentResponse) {
    let actions: Action[] = [];
    if (doc.kind === "QUICKBOOKS_REPORT") {
      actions.push({
        icon: "documents/search",
        contentText: "Quickbooks",
        clickAction: () => {
          if (doc.quickbooks_url && anchorRef.current) {
            anchorRef.current.href = doc.quickbooks_url;
            anchorRef.current.click();
          } else {
            showSnackbar(
              "Sorry, cannot show this report in the Quickbooks app.",
            );
          }
        },
      });
    } else if (doc.invoice) {
      if (doc.invoice.state === "DRAFT") {
        actions.push({
          icon: "documents/edit",
          contentText: "Edit invoice",
          clickAction: async () => {
            const invoice = await getInvoice(doc.invoice!.id);
            history.push("new-invoice", { invoice });
          },
        });
      }
      actions.push({
        icon: "documents/search",
        contentText: "View invoice",
        clickAction: () => {
          setExportLoading(true);
          setDocToPreview(doc);
        },
      });
      actions.push({
        icon: "trash_documents",
        contentText: "Delete",
        clickAction: () => {
          setInvoiceToRemove(doc.id);
        },
      });
      actions.push({
        icon: "documents/export_as_pdf",
        contentText: "Export as PDF",
        clickAction: () => {
          setExportLoading(true);
          setDocToExport(doc);
        },
      });
    } else {
      actions = [
        {
          icon: "documents/search",
          contentText: "View Report",
          clickAction: () => {
            setExportLoading(true);
            setDocToPreview(doc);
          },
        },
        {
          icon: "documents/edit",
          contentText: "Edit Report",
          clickAction: () => {
            setOnEdit(doc);
            setIsDialogOpen(true);
          },
        },
        {
          icon: "documents/export_as_csv",
          contentText: "Export as CSV",
          clickAction: () => {
            getDocumentCSV(doc.id, doc.name);
          },
        },
        {
          icon: "documents/export_as_pdf",
          contentText: "Export as PDF",
          clickAction: () => {
            setExportLoading(true);
            setDocToExport(doc);
          },
        },
      ];
    }

    return actions;
  }

  const columns: Column[] = [
    {
      header: "Name",
      accessor: "name",
      sortable: true,
      width: "20%",
    },
    {
      header: "Type",
      accessor: "type",
      filterable: true,
      width: "20%",
    },
    {
      header: "Report range",
      accessor: "report_range",
      width: "25%",
    },
    {
      header: "Date Created",
      accessor: "created_at",
      filterable: true,
      sortable: true,
      width: "20%",
    },
    {
      header: "Action",
      accessor: "action",
      width: "10%",
    },
  ];
  const columnsInvoice: Column[] = [
    {
      header: "Name",
      accessor: "name",
      sortable: true,
      width: "25%",
    },
    {
      header: "Status",
      accessor: "status",
      filterable: true,
      width: "10%",
    },
    {
      header: "Date Created",
      accessor: "created_at",
      filterable: true,
      sortable: true,
      width: "22.5%",
    },
    {
      header: "Due Date",
      accessor: "end_date",
      sortable: true,
      width: "17.5%",
    },
    {
      header: "Amount",
      accessor: "amount",
      filterable: true,
      width: "15%",
    },
    {
      header: "Action",
      accessor: "action",
      width: "10%",
    },
  ];
  function getMoreData() {
    if (tableData.length !== 0) {
      setShouldReplaceData(false);
      setQueryParams((prevParams) => ({
        ...prevParams,
        limit: 10,
        offset:
          prevParams.limit === 10 ? prevParams.offset! + 10 : prevParams.limit,
      }));
    }
  }

  function onColumnClick(col: Column) {
    if (col.sortable) {
      setShouldReplaceData(true);
      setQueryParams((prevParams) => ({
        ...prevParams,
        limit: 10,
        offset: 0,
        ordering:
          isInvoice && col.accessor === "end_date"
            ? queryParams.ordering === "-invoice__expires_at"
              ? "invoice__expires_at"
              : "-invoice__expires_at"
            : queryParams.ordering === col.accessor
            ? `-${col.accessor}`
            : col.accessor,
      }));
    }
  }

  function onDeleteDocument() {
    refetch();
    showSnackbar("Invoice was successfully deleted");
  }

  function onInvoiceDialogClose() {
    setInvoiceToRemove(null);
    setTimeout(() => {}, 300); //Wait for dialog to close
  }

  const resetFilters = () => {
    setShouldReplaceData(true);
    setTimePeriod({ start_date: minDate, end_date: now });
    setDocumentsFilters({
      start_date: format(minDate, "yyyy-MM-dd"),
      end_date: format(now, "yyyy-MM-dd"),
    });
    setQueryParams((prev) => ({
      ...prev,
      created_at_after: format(minDate, "yyyy-MM-dd"),
      created_at_before: format(now, "yyyy-MM-dd"),
    }));
    setDocumentCurrencyFilter("");
    setDocumentSortPrameter("created_at");
    if (isInvoice) {
      setCheckedItems(InvoiceTypes.map((c) => c));
    } else {
      setCheckedItems(DocumentTypes.map((c) => c));
    }
    setIsFiltered(false);
    setTimePeriod({ start_date: minDate, end_date: now });
  };

  function onFilterChange() {
    setShouldReplaceData(true);
    setQueryParams((prevParams) => ({
      ...prevParams,
      limit: 10,
      offset: 0,
      created_at_after: format(new Date(timePeriod.start_date), "yyyy-MM-dd"),
      created_at_before: format(new Date(timePeriod.end_date), "yyyy-MM-dd"),
    }));
  }

  function deleteSelected() {
    setShouldReplaceData(true);
    Promise.all(selectedDocuments.map((id) => deleteDocument(id))).then(() => {
      setQueryParams((prevParams) => ({
        ...prevParams,
        limit: tableData.length - selectedDocuments.length,
        offset: 0,
      }));
      setSelectedDocuments([]);
    });
  }

  function exportSelected() {
    Promise.all(
      selectedDocuments.map((id) =>
        getDocumentCSV(id, tableData.find((doc) => doc.id === id)!.csvName),
      ),
    ).catch(() => {
      showSnackbar("Some of the chosen documents could not be exported as CSV");
    });
  }

  function onDocumentSubmittion() {
    setIsDialogOpen(false);
    setShouldReplaceData(true);
    setQueryParams((prevParams) => ({
      ...prevParams,
      limit: 10,
      offset: 0,
    }));
  }

  function onReportDocumentSubmission() {
    setIsDialogOpen(false);
    setShouldReplaceData(true);
    setQueryParams((prevParams) => ({
      ...prevParams,
      limit: 10,
      offset: 0,
    }));
    refetch();
    setIsInvoice(false);
  }

  function closeTutorialFlow() {
    endTutorial();
    setTutorialState(undefined);
  }

  function activateNextTutorialFlow(flow: number) {
    setTutorialState(undefined);
    setTimeout(() => {
      setTutorialState(`document_tutorial_${flow}`);
    }, 300); // wait for modal to close...
  }

  const headerButtonProps = {
    iconName: "create_report",
    text: "Create Invoice",
    onClick: () => {
      isInvoice && invoiceLimits?.can_create && history.push("/new-invoice");
      isInvoice &&
        !invoiceLimits?.can_create &&
        setIsSubscriptionDialogOpen({
          isOpen: true,
          subject: "invoices",
        });
      setOnEdit(undefined);
      setIsDialogOpen(true);
    },
  };

  return (
    <DocumentPageDateFilters.Provider value={{ timePeriod, setTimePeriod }}>
      <SortDocumentPageParameter.Provider
        value={{ sortBy, setDocumentSortPrameter }}
      >
        <DocumentCurrencyFilter.Provider
          value={{ currencyFilter, setDocumentCurrencyFilter }}
        >
          <CheckedItemsContext.Provider
            value={{ checkedItems, setCheckedItems }}
          >
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
              <a
                ref={anchorRef}
                href="*"
                className="hidden"
                target="_blank"
                rel="noreferrer noopener"
              />
              <PageHeader
                title="Documents"
                headerButtonProps={headerButtonProps}
                Header={
                  <ToggleButton
                    onToggle={updateReportsListOnToggle}
                    leftText="Invoice"
                    rightText="Report"
                    isDefault={isInvoice}
                  />
                }
                updateReportsList={updateReportsListOnToggle}
                docType={isInvoice ? "invoice" : "report"}
                onResetFilters={resetFilters}
                isFiltered={isFiltered}
              />

              {exportLoading && (
                <div className="absolute -mt-10 left-0 right-0 z-50">
                  <Loader />
                </div>
              )}
              <div className="bg-white rounded-20px p-6 mlg:p-12 w-full min-h-screen overflow-auto dark:bg-gray-900">
                <div className="flex ml-7 mb-5 gap-x-5">
                  <Tooltip place="right" />

                  <img
                    src={`/icons/${theme}/actions/export_documents.svg`}
                    alt="export selected"
                    onClick={exportSelected}
                    className="cursor-pointer"
                    data-tip="Export Selected As CSV"
                  />
                  <img
                    src={`/icons/${theme}/actions/trash_documents.svg`}
                    alt="delete selected"
                    onClick={deleteSelected}
                    className="cursor-pointer"
                    data-tip="Delete Selected"
                  />
                </div>
                <InfiniteScroll
                  dataLength={tableData.length}
                  next={getMoreData}
                  hasMore={!!data?.next}
                  loader={<p className="text-center text-12">Loading...</p>}
                >
                  <BnTable
                    columns={isInvoice ? columnsInvoice : columns}
                    data={tableData}
                    disableExpand
                    noDataMessage={isLoading ? <Loader /> : "No Documents Made"}
                    onColumnClick={onColumnClick}
                    orderedBy={queryParams.ordering}
                    onCheckBoxChange={(docs: number[]) =>
                      setSelectedDocuments(docs)
                    }
                    minDate={minDate}
                  />
                </InfiniteScroll>
              </div>
              {isInvoice && invoiceLimits?.can_create ? (
                <BnDialog
                  isOpen={isDialogOpen}
                  title={`${onEdit ? "Edit" : "Create New"} Invoice`}
                  centerTitle
                  maxWidth="max-w-2xl"
                  description={
                    <DocumentForm
                      onSubmit={onDocumentSubmittion}
                      minDate={minDate}
                      defaultValues={onEdit}
                      onEdit={!!onEdit}
                    />
                  }
                  onClose={() => setIsDialogOpen(false)}
                />
              ) : (
                <EndOfSubscriptionLightBox
                  dialogText={
                    planLimitsMessage(
                      isSubscriptionDialogOpen.subject,
                      invoiceLimits?.limit || 0,
                    ) || ""
                  }
                  isSubscriptionDialogOpen={isSubscriptionDialogOpen.isOpen}
                  onCloseSubscriptionDialog={() =>
                    setIsSubscriptionDialogOpen({
                      isOpen: false,
                      subject: "invoices",
                    })
                  }
                />
              )}

              {!isInvoice && (
                <BnDialog
                  isOpen={isDialogOpen}
                  title={`${onEdit ? "Edit" : "Create New"} Report`}
                  centerTitle
                  maxWidth="max-w-2xl"
                  description={
                    <DocumentForm
                      onSubmit={onReportDocumentSubmission}
                      minDate={minDateOfTransaction}
                      defaultValues={onEdit}
                      onEdit={!!onEdit}
                    />
                  }
                  onClose={() => setIsDialogOpen(false)}
                />
              )}
              {invoiceToRemove && (
                <BnDialog
                  isOpen={invoiceToRemove !== null}
                  title=""
                  centerTitle
                  description={
                    <RemoveInvoiceDialogConfirmation
                      onCancel={onInvoiceDialogClose}
                      onDelete={() => {
                        removeDocument(invoiceToRemove);
                        onInvoiceDialogClose();
                      }}
                    />
                  }
                  onClose={onInvoiceDialogClose}
                />
              )}
              <Tutorial
                title="Invoicing and Payments"
                desc="You can make crypto-invoices and send them to the contacts you've saved. If your client has no crypto, you can still get paid in crypto to your wallet: Here, you can check the status of your invoice and make sure you get paid for your work."
                isOpen={
                  tutorialState === "document_tutorial_1" &&
                  !user.is_tutorial_viewed
                }
                maxWidth="max-w-xl"
                currentTutorial={6}
                maxTutorial={7}
                onClose={closeTutorialFlow}
                nextTutorial={() => activateNextTutorialFlow(2)}
                prevTutorial={() =>
                  history.push("/contacts", { tutorial: "contact_tutorial" })
                }
                positionStyle="relative top-8 left-36"
                pointer={{
                  className: "absolute left-14 -top-14",
                  style: {
                    borderBottom: "30px solid white",
                    borderRight: "30px solid transparent",
                    borderTop: "30px solid transparent",
                    borderLeft: "30px solid transparent",
                  },
                }}
              />
              <Tutorial
                title="Create personalized reports based on your wallet activity"
                desc="You can create PDF or CSV reports of your Wallets. We offer Transaction reports, Holding reports, Reports for Quickbooks and more."
                isOpen={
                  tutorialState === "document_tutorial_2" &&
                  !user.is_tutorial_viewed
                }
                currentTutorial={7}
                maxTutorial={7}
                onClose={closeTutorialFlow}
                nextTutorial={() => activateNextTutorialFlow(3)}
                prevTutorial={() => activateNextTutorialFlow(1)}
                positionStyle="relative top-2 left-48"
                pointer={{
                  className: "absolute left-14 -top-14",
                  style: {
                    borderBottom: "30px solid white",
                    borderRight: "30px solid transparent",
                    borderTop: "30px solid transparent",
                    borderLeft: "30px solid transparent",
                  },
                }}
              />
              <BnDialog
                isOpen={
                  tutorialState === "document_tutorial_3" &&
                  !user.is_tutorial_viewed
                }
                title="You're ready to go!"
                centerTitle
                description={<EndTutorial />}
                onClose={closeTutorialFlow}
              />
            </div>
          </CheckedItemsContext.Provider>
        </DocumentCurrencyFilter.Provider>
      </SortDocumentPageParameter.Provider>
    </DocumentPageDateFilters.Provider>
  );
}

type RemoveInvoiceDialogProps = {
  onCancel: () => void;
  onDelete: () => void;
};

function RemoveInvoiceDialogConfirmation({
  onCancel,
  onDelete,
}: RemoveInvoiceDialogProps) {
  return (
    <div>
      <p className="dark:text-green-300 text-green-800 text-center">
        Are you sure you want to delete your invoice?
      </p>
      <div className="flex mt-6 justify-center">
        <button
          onClick={onCancel}
          className="transparent-button w-28 font-bold border border-green-800 mr-4 dark:border-gray-450"
        >
          Cancel
        </button>
        <button onClick={onDelete} className="primary-button w-28 font-bold">
          Delete
        </button>
      </div>
    </div>
  );
}
