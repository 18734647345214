import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";

import { logout } from "./api";
import { web3 } from "./metamask";
import { InvoiceResponse } from "./types";
import erc20contractABI from "./utils/erc20contractABI.json";

// Create a connector
let connector = new WalletConnect({
  bridge: "https://bridge.walletconnect.org", // Required
  qrcodeModal: QRCodeModal,
});

export function initWalletConnect(login = true) {
  // Check if connection is already established
  if (!connector.connected) {
    // Reassigning fixes bug - popup does not reopen if previously closed without connection
    connector = new WalletConnect({
      bridge: "https://bridge.walletconnect.org", // Required
      qrcodeModal: QRCodeModal,
    });
    connector.createSession();
  } else {
    document.dispatchEvent(
      new CustomEvent("Basenode:walletconnectConnected", {}),
    );
  }

  // Subscribe to connection events
  connector.on("connect", (error, payload) => {
    if (error) {
      throw error;
    }
    document.dispatchEvent(
      new CustomEvent("Basenode:walletconnectConnected", {}),
    );
  });

  connector.on("session_update", (error, payload) => {
    if (error) {
      throw error;
    }
  });

  connector.on("disconnect", (error, payload) => {
    if (error) {
      throw error;
    }
    if (login) {
      logout();
    }
  });
}

export function getWalletConnectAccounts() {
  return connector.accounts;
}

export async function signChallenge(challenge: string, addr: string) {
  const res = await connector.signPersonalMessage([
    web3.utils.toHex(challenge),
    addr,
  ]);
  return res;
}

export function disconnectWalletConnect() {
  if (connector.connected) connector.killSession();
}

export async function payWithWalletConnect(
  invoice: InvoiceResponse,
  value: string,
) {
  let tx;

  if (invoice.contract_address) {
    const contract = new web3.eth.Contract(
      erc20contractABI as any,
      invoice.contract_address,
    );

    tx = {
      from: connector.accounts[0],
      to: invoice.contract_address,
      gas: 65000,
      data: contract.methods
        .transfer(invoice.wallet.address, value)
        .encodeABI(),
    };
  }
  // Native Asset transfer...
  else {
    tx = {
      from: connector.accounts[0],
      to: invoice.wallet.address,
      value,
    };
  }

  try {
    const txHash = await connector.sendTransaction(tx);
    return txHash;
  } catch (error) {
    alert("Something went wrong while paying the invoice!");
    return false;
  } finally {
    connector.killSession();
  }
}
