import { Popover, Transition } from "@headlessui/react";
import React, { Fragment, useContext } from "react";

import { ThemeContext } from "../App";
import { Action } from "../types";
import { capitalize } from "../utils";

type PopoverProps = {
  actions: Action[];
  src?: string;
  direction?: "left" | "right";
};

export default function ActionPopover({
  actions,
  src,
  direction = "right",
}: PopoverProps) {
  const theme = useContext(ThemeContext);

  return (
    <Popover className="relative">
      <Popover.Button className="focus:outline-none">
        <img
          src={src || `/icons/${theme}/fi_more-vertical.svg`}
          alt="action"
          className="min-w-max"
        />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0 translate-y-1"
        enterTo="transform scale-100 opacity-100 translate-y-0"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100 translate-y-0"
        leaveTo="transform scale-95 opacity-0 translate-y-1"
      >
        <Popover.Panel
          className={`action z-10 ${direction}-0 top-0 dark:bg-gray-900 dark:text-gray-300`}
        >
          {actions.map(({ icon, contentText, clickAction, Component }) => {
            if (Component) return Component;

            return (
              <div
                className="action-element"
                onClick={clickAction}
                key={contentText}
              >
                <img
                  src={`/icons/${theme}/actions/${icon}.svg`}
                  alt="check"
                  className="ml-1"
                />
                <p className="ml-2 truncate hover:text-gray-900 dark:hover:text-gray-100">
                  {capitalize(contentText)}
                </p>
              </div>
            );
          })}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
