import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { LimitResponse } from "../../types";
import { capitalize } from "../../utils";
import Button from "../forms/_Button";
import EndOfSubscriptionLightBox from "./EndOfSubscriptionLightBox";

type InvoiceStatusProps = {
  user: any;
  invoiceLimits?: LimitResponse;
};

export default function InvoiceStatus({
  user,
  invoiceLimits,
}: InvoiceStatusProps) {
  const history = useHistory();
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] =
    useState(false);

  if (!user.full_access) {
    return (
      <div className="bg-red-500-t-85 py-1 text-center rounded-md text-white font-bold">
        <p>Guest User</p>
        <p>Login to Create Invoice!</p>
      </div>
    );
  }

  function handleNewInvoice(invoiceLimits: LimitResponse) {
    if (invoiceLimits.can_create) {
      history.push("/new-invoice");
    } else {
      setIsSubscriptionDialogOpen(true);
    }
  }

  return (
    <>
      <div className="flex justify-between ">
        <div
          className={`${
            invoiceLimits?.limit ? "bg-gray-600" : "bg-yellow-50"
          } ${invoiceLimits?.limit ? "text-white" : ""} ${
            invoiceLimits?.limit ? "dark:bg-gray-900 " : "dark:bg-yellow-50"
          }dark:text-gray-300  text-center rounded-md mr-2 py-1 w-1/2 font-bold`}
        >
          <p>
            {user.subscription.plan === "STARTER"
              ? "Free Plan"
              : `${capitalize(user.subscription.plan)} Plan`}
          </p>
          <p>
            {invoiceLimits?.limit
              ? `${invoiceLimits.count} / ${invoiceLimits.limit} Invoices Used`
              : "Unlimited Invoices"}
          </p>
        </div>
        <Button
          text="New Invoice"
          onClick={() => handleNewInvoice(invoiceLimits!)}
          normalText
          styling="primary-button"
          additionalClasses="w-1/2 ml-2 capitalize font-bold text-16"
        />
      </div>
      <EndOfSubscriptionLightBox
        dialogText={`Oh no! You can only create ${invoiceLimits?.limit} invoices on your current plan. Upgrade your plan to proceed.`}
        isSubscriptionDialogOpen={isSubscriptionDialogOpen}
        onCloseSubscriptionDialog={() => setIsSubscriptionDialogOpen(false)}
      />
    </>
  );
}
