import { Disclosure } from "@headlessui/react";
import parse, {
  Element,
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from "html-react-parser";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

import { ThemeContext } from "../App";
import { FaqElem } from "../types";
import { faqData } from "../utils";

export default function FaqPage() {
  const theme = useContext(ThemeContext);

  const [searchedFaqData, setSearchedFaqData] = useState<FaqElem[]>([]);
  const [allQuestionsVisible, setAllQuestionsVisible] = useState(false);

  useEffect(() => {
    setSearchedFaqData(faqData);
  }, []);

  function onChangeSearch(text: string) {
    setAllQuestionsVisible(false);
    if (!text) setSearchedFaqData(faqData);
    else {
      const newSearchedFaqData = faqData.filter((f) =>
        f.question.toLowerCase().includes(text.toLowerCase()),
      );
      setSearchedFaqData(newSearchedFaqData);
    }
  }

  return (
    <div className="flex flex-col items-center space-y-10 pb-10 dark:text-green-100">
      <img src={`/icons/${theme}/faq_hands.svg`} alt="cooperation" />
      <h2 className="text-26 text-green-800 dark:text-green-100">
        Frequently Asked Questions
      </h2>
      <div className="w-3/4 max-w-screen-md flex flex-col space-y-2 dark:text-green-100">
        <p className="text-green-800 text-center dark:text-green-100">
          Please start here. How can we help you?
        </p>
        <SearchBar onChange={onChangeSearch} />
      </div>
      {searchedFaqData.length ? (
        <>
          {searchedFaqData
            .map((f) => <QuestionBox key={f.question} {...f} />)
            .slice(0, 5)}
          {searchedFaqData.length > 5 &&
            (allQuestionsVisible ? (
              searchedFaqData
                .map((f) => <QuestionBox key={f.question} {...f} />)
                .slice(5)
            ) : (
              <p
                className="text-14 text-green-800 cursor-pointer dark:text-green-100"
                onClick={() => setAllQuestionsVisible(true)}
              >
                Show all questions ({searchedFaqData.length - 5} more)
              </p>
            ))}
        </>
      ) : (
        <p className="text-red-500">
          No questions found according to your searching phrase
        </p>
      )}
      <button className="bg-green-150 text-green-800 px-10 py-3 rounded-md ">
        Rewatch tutorial
      </button>
    </div>
  );
}

type SearchBarProps = {
  onChange: (text: string) => void;
};

function SearchBar({ onChange }: SearchBarProps) {
  const [searchInput, setSearchInput] = useState("");
  const searchBarRef = useRef<HTMLInputElement | null>(null);
  const theme = useContext(ThemeContext);

  function resetSearch() {
    setSearchInput("");
    onChange("");
  }

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const text = e.currentTarget.value;
    setSearchInput(text);
    onChange(text);
  }

  return (
    <div
      className="flex items-center w-full h-10 bg-white space-x-5 rounded-40px px-6 ml-0.5 dark:bg-gray-900 dark:text-green-100"
      ref={searchBarRef}
    >
      <img
        src={`/icons/${theme}/green_loupe.svg`}
        alt="search"
        className="w-7 h-7 cursor-pointer "
      />

      <input
        name="search"
        placeholder="Search"
        className="outline-none w-full text-16 text-green-800 placeholder-gray-450-t-50 dark:bg-gray-900 dark:text-gray-100 dark:placeholder-white"
        autoFocus
        autoComplete="off"
        value={searchInput}
        onChange={onInputChange}
      />

      <div className="w-14 h-7">
        {searchInput && (
          <img
            src={`/icons/${theme}/close.svg`}
            alt="close"
            className="cursor-pointer w-full h-full"
            onClick={resetSearch}
          />
        )}
      </div>
    </div>
  );
}
function QuestionBox({ question, answer }: FaqElem) {
  return (
    <div
      style={{ transition: "height 0.5s ease-out" }}
      className="bg-white dark:bg-gray-900 dark:text-green-100 py-8 px-14 w-5/6 rounded-20px transition-height duration-500 ease-in-out"
    >
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`text-20 ${
                open
                  ? "text-green-300 dark:text-green-180"
                  : "text-green-800 dark:text-green-250"
              } font-semibold w-full h-full flex justify-between items-center`}
            >
              <span>{question}</span>
              <span className="text-30">{open ? "-" : "+"}</span>
            </Disclosure.Button>
            <Disclosure.Panel className="mt-10">
              {answer
                ? parseWithLinks(answer)
                : "Answer not yet defined. Sorry for the inconvinience."}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
function parseWithLinks(text: string) {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      const typedDomNode = domNode as Element;

      if (typedDomNode.attribs && typedDomNode.name === "a") {
        return typedDomNode.attribs.href.startsWith("http") ? (
          <a
            {...attributesToProps(typedDomNode.attribs)}
            className="underline text-green-800 dark:text-blue-100"
            target="_blank"
          >
            {typedDomNode.children &&
              domToReact(typedDomNode.children, options)}
          </a>
        ) : (
          <Link
            to={typedDomNode.attribs.href}
            {...attributesToProps(typedDomNode.attribs)}
            className="underline text-green-800 dark:text-blue-100 "
          >
            {typedDomNode.children &&
              domToReact(typedDomNode.children, options)}
          </Link>
        );
      }

      return false;
    },
  };

  return parse(text, options);
}
