import React from "react";
import { RegisterOptions, UseFormReturn } from "react-hook-form";

import FormError from "./_FormError";

export type InputProps = {
  form: UseFormReturn;
  name: string;
  registerOptions?: RegisterOptions;
  mainClasses?: string;
  additionalClasses?: string;

  // native props
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  readOnly?: boolean;
};

export default function TextArea({
  form,
  name,
  registerOptions,
  mainClasses,
  additionalClasses,
  ...inputProps
}: InputProps) {
  return (
    <div className="relative w-full flex flex-col my-2">
      <textarea
        className={`${
          mainClasses || "input"
        } resize-none  ${additionalClasses}`}
        {...inputProps}
        {...form.register(name, registerOptions)}
      ></textarea>
      <FormError
        errors={name
          .split(".")
          .reduce((val, key) => val && val[key], form.formState.errors)}
      ></FormError>
    </div>
  );
}
