import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

import { loginWithAddress } from "../api";
import { UserContext } from "../App";
import BnDialog from "./BnDialog";

type DemoWallet = {
  name: string;
  address: string;
};

export default function DemoLogin() {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { setUser } = useContext(UserContext);

  const { mutate, isLoading } = useMutation(
    (address: string) => loginWithAddress(address),
    {
      onSuccess: (data) => {
        setUser({ ...data, full_access: false, has_wallet: true });
        history.push("/overview");
      },
    },
  );

  function handleSubmit(address: string) {
    mutate(address);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const demoWallets: DemoWallet[] = [
    {
      name: "Vitalik Buterin",
      address: "0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045",
    },
    {
      name: "Satoshi Nakamoto",
      address: "12cbQLTFMXRnSzktFkuoG3eHoMeFtpTu3S",
    },
  ];

  return (
    <>
      <button
        className="cursor-pointer flex items-center justify-between w-64 h-16 border border-gray-450-t-50 rounded-md px-4"
        onClick={openModal}
      >
        <span className="text-green-800">Try our demo</span>
        <img src="/icons/play_circle.svg" alt="demo login" />
      </button>
      <BnDialog
        isOpen={isOpen}
        title="Choose a demo wallet to get started:"
        maxWidth="max-w-3xl"
        closeButton={false}
        description={
          <div className="grid grid-cols-2 space-x-5">
            {demoWallets.map((demoWallet) => (
              <button
                key={demoWallet.address}
                disabled={isLoading}
                onClick={() => handleSubmit(demoWallet.address)}
                className="flex justify-center items-center h-52 text-20 font-bold text-green-800 bg-gray-100 rounded-2xl border border-gray-100 focus:outline-none focus:border-green-300 dark:bg-gray-900 dark:border-gray-900"
              >
                <p>{demoWallet.name}</p>
              </button>
            ))}
          </div>
        }
        onClose={closeModal}
      />
    </>
  );
}
