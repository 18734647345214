import Big from "big.js";

import { InvoiceItem } from "../types";

export default function getInvoiceItemAmounts(item: InvoiceItem) {
  const quantity = Big(item.quantity || 0);
  const unit_price = Big(item.unit_price || 0);
  const discount = Big(item.discount || 0);
  const tax = Big(item.tax || 0);
  const amount = quantity.times(unit_price);
  const basePrice = amount
    .sub(
      item.discount_is_percent
        ? Big(amount).times(discount.div(100)).toFixed(5)
        : discount,
    )
    .toFixed(5);
  const totalTax = item.tax_is_percent
    ? Big(basePrice).times(tax.div(100)).toFixed(5)
    : tax;
  const taxedPrice = Big(basePrice).add(Big(totalTax)).toFixed(5);
  return { basePrice, taxedPrice, totalTax };
}
