import React, { ReactElement, useContext } from "react";
import { useLocation } from "react-router-dom";

import { ThemeContext } from "../App";
import { useUser } from "../hooks/useUser";

type PageHeaderCreateButtonProps = {
  text: string;
  iconName?: string;
  onClick: () => void;
  theme?: string;
};

type HeaderProps = {
  title: string;
  Header?: ReactElement;
  isLiveIcon?: boolean;
  headerButtonProps?: PageHeaderCreateButtonProps;
  updateReportsList?: () => void;
  docType?: string;
  onResetFilters?: () => void;
  isFiltered?: boolean;
};

export default function PageHeader({
  title,
  Header,
  isLiveIcon = false,
  headerButtonProps,
  docType,
  onResetFilters,
  isFiltered,
}: HeaderProps) {
  const theme = useContext(ThemeContext);
  const user = useUser();
  const location = useLocation();
  const pageSpecificText =
    docType === "invoice" ? "Create Invoice" : "Create Report";
  const isDocumentPage = location.pathname === "/documents";

  return (
    <div
      className="mt-18 mb-4 w-full flex flex-wrap gap-y-6 justify-between"
      style={{ minHeight: "60px" }}
    >
      {title && (
        <div className="flex items-center space-x-4">
          <div>
            <p className="text-18 md:text-26 font-bold text-green-900 dark:text-gray-300">
              {title}
            </p>
            {isLiveIcon && (
              <div className="flex dark:text-gray-300">
                Live{" "}
                <img
                  className="ml-2"
                  src={`/icons/${theme}/page_header/live.svg`}
                  alt="live"
                />
              </div>
            )}
          </div>
        </div>
      )}
      {Header}
      {headerButtonProps && (
        <div className="flex items-center gap-x-4 relative">
          {isDocumentPage && user.full_access && isFiltered && (
            <div
              className="flex cursor-pointer absolute transform -translate-x-full -left-4"
              onClick={onResetFilters}
            >
              <img
                className="w-4 mr-2 cursor-pointer"
                src={`/icons/${theme}/close.svg`}
                alt="Clear"
              />
              <div className="font-bold text-green-800 dark:text-gray-50">
                Clear Filters
              </div>
            </div>
          )}
          <PageHeaderCreateButton
            {...headerButtonProps}
            text={isDocumentPage ? pageSpecificText : headerButtonProps.text}
            theme={theme}
          />
        </div>
      )}
    </div>
  );
}

export function PageHeaderCreateButton({
  text,
  iconName,
  onClick,
  theme,
}: PageHeaderCreateButtonProps) {
  return (
    <div
      className="w-44 h-12 font-bold bg-green-800 text-white flex justify-center items-center px-5 rounded-md cursor-pointer dark:bg-gray-900 dark:text-gray-300"
      onClick={onClick}
    >
      {iconName && (
        <img
          src={`/icons/${theme}/page_header/${iconName}.svg`}
          alt={iconName}
          className="mr-5"
        />
      )}
      <h2 className="text-16 whitespace-nowrap">{text}</h2>
    </div>
  );
}
