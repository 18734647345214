import React from "react";

import { Tier } from "../../types";

export default function NewPlanDialog({
  plan,
  plan_end_date,
  onSubmit,
  onCancel,
}: {
  plan: Tier;
  plan_end_date: string;
  onSubmit: () => void;
  onCancel: () => void;
}) {
  return (
    <div className="flex flex-col justify-between items-center">
      <p className="text-16 color-green-900 w-full dark:text-gray-300">
        Are you sure you want to cancel your{" "}
        <span className="text-green-300 font-bold capitalize">
          {plan.toUpperCase()} plan?
        </span>
      </p>
      <p className="text-16 color-green-900 w-full mt-4 dark:text-gray-300">
        If you proceed, you will be reverted back to the Free Plan after{" "}
        <span className="text-green-300 font-bold">{plan_end_date}</span>
      </p>
      <div className="flex gap-x-2 mt-8">
        <button
          onClick={onCancel}
          className="text-16 w-40 transparent-button outline-none border border-green-800 font-bold"
        >
          Go back
        </button>
        <button onClick={onSubmit} className="text-16 w-40 always-green-button">
          Cancel Plan
        </button>
      </div>
    </div>
  );
}
