import React, { useContext, useEffect } from "react";
import { FieldValues, useForm, useWatch } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import { updateUser } from "../../api";
import { UserContext } from "../../App";
import { AvailableFiat, EditableUser } from "../../types";
import { getFiatSign } from "../../utils";
import BaseForm from "./_BaseForm";
import Dropdown from "./_Dropdown";

export default function SettingsAccountForm() {
  const queryClient = useQueryClient();
  const { user, setUser } = useContext(UserContext);

  const { mutate } = useMutation(
    "user_data",
    (data: EditableUser) => updateUser(data),
    { onSuccess: () => queryClient.resetQueries() },
  );

  function updateData(data: EditableUser) {
    mutate(data);
    setUser({ ...user, ...data });
  }

  const form = useForm<FieldValues>({
    defaultValues: {
      currency: user.fiat_currency || "USD",
      language: user.language || "English",
      timezone: user.timezone || "European Central Time",
    },
  });
  const currency = useWatch({ control: form.control, name: "currency" });
  const language = useWatch({ control: form.control, name: "language" });
  const timezone = useWatch({ control: form.control, name: "timezone" });

  useEffect(() => {
    if (currency.toString() !== user.currency)
      updateData({ fiat_currency: currency });
    if (language.toString() !== user.language) updateData({ language });
    if (timezone.toString() !== user.timezone) updateData({ timezone });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, language, timezone]);

  const labelStyles = "text-14 text-gray-800 truncate dark:text-gray-300";

  return (
    <BaseForm form={form}>
      <div className="flex flex-col space-y-7 ">
        <div>
          <p className={labelStyles}>Select preferred base currency</p>
          <Dropdown
            form={form}
            name="currency"
            placeholder="Base currency"
            options={AvailableFiat.map((f) => ({
              key: f,
              label: (
                <p key={f}>
                  <span className="font-bold text-green-300 mr-2">
                    {getFiatSign(f)}
                  </span>
                  {f}
                </p>
              ),
              value: f,
            }))}
          />
        </div>
      </div>
    </BaseForm>
  );
}
