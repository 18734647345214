import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { format } from "date-fns";
import React from "react";

import { DocumentResponse, TransactionReportPdfRecord } from "../../types";
import { formatAmount } from "../../utils";

type PdfProps = {
  docDetails: DocumentResponse;
  transactions: TransactionReportPdfRecord[];
};

export default function TransactionPdf({ docDetails, transactions }: PdfProps) {
  function createTableRow(row: TransactionReportPdfRecord) {
    return (
      <>
        <View style={{ flexDirection: "row", marginVertical: "5" }}>
          <View style={{ flex: 1 }}>
            <Text style={styles.tableCell}>
              {format(new Date(row.created_at), "dd.MM.yyyy HH:mm")}
            </Text>
          </View>
          <View style={styles.senderCell}>
            <View>
              <Text style={styles.fromAddress}>From</Text>
              <Text style={styles.tableCell}>To</Text>
            </View>
            <View>
              <Text style={styles.fromAddress}>{row.from_address}</Text>
              <Text style={styles.tableCell}>{row.to_address}</Text>
            </View>
          </View>
          <View style={{ flex: 1.5 }}>
            <Text style={styles.tokenCell}>{row.token.symbol}</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.tableCell}>{formatAmount(row.amount)}</Text>
          </View>
          <View style={{ flex: 1.5 }}>
            <Text style={styles.fiat}>{formatAmount(row.value_fiat)}</Text>
          </View>
        </View>
        <View style={styles.tableSmallSeparator}></View>
      </>
    );
  }

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.header}>
          <View style={{ flex: 2, flexDirection: "column" }}>
            <Text style={styles.title}>Transaction Report</Text>
            <Text style={styles.date}>
              Date range: {}
              {`${format(
                new Date(docDetails.start_date),
                "M/d/yyyy",
              )} - ${format(new Date(docDetails.end_date), "M/d/yyyy")}`}
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <Image style={styles.topLogo} src="/pdfImages/LogoBig.png" />
          </View>
        </View>

        <View style={styles.table}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <Text style={styles.tableHeader}>Date</Text>
            </View>
            <View style={{ flex: 3 }}>
              <Text style={styles.tableHeader}>Sender</Text>
            </View>
            <View style={{ flex: 1.5 }}>
              <Text style={styles.tokenHeader}>Token</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.tableHeader}>Amount</Text>
            </View>
            <View style={{ flex: 1.5 }}>
              <Text
                style={styles.tableHeader}
              >{`Currency Value (${docDetails.user_fiat_currency})`}</Text>
            </View>
          </View>
          <View style={styles.tableBigSeparator}></View>

          {transactions.map((t, id) => (
            <React.Fragment key={id}>{createTableRow(t)}</React.Fragment>
          ))}
        </View>

        <View style={styles.footer} fixed>
          <Image style={styles.bottomLogo} src="/pdfImages/footerImg.png" />
        </View>
      </Page>
    </Document>
  );
}

Font.register({
  family: "Nunito",
  src: "/fonts/Nunito-Regular.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 14,
    fontFamily: "Nunito",
  },
  date: {
    fontSize: 10,
    marginBottom: 30,
    marginTop: 10,
  },
  topLogo: {
    position: "absolute",
    top: -20,
    right: 0,
    width: 127,
  },
  bottomLogo: {
    width: 120,
  },
  footer: {
    flexDirection: "row",
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 45,
  },
  tableHeader: {
    fontSize: 8,
  },
  tokenHeader: {
    fontSize: 8,
    textAlign: "center",
  },
  tableCell: {
    fontSize: 7,
  },
  tableBigSeparator: {
    width: "100%",
    height: 1,
    marginVertical: 5,
    backgroundColor: "#2F5A55",
  },
  tableSmallSeparator: {
    width: "100%",
    height: 1,
    marginVertical: 5,
    backgroundColor: "#2F5A55",
    opacity: 0.5,
  },
  senderCell: {
    flex: 3,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tokenCell: {
    fontSize: 7,
    textAlign: "center",
  },
  fromAddress: {
    fontSize: 7,
    marginBottom: 10,
  },
  fiat: {
    fontSize: 7,
    textAlign: "center",
  },
});
