import React, { useContext } from "react";

import { ThemeContext } from "../../App";
import { Label } from "../../types";

type BadgeProps = { label: Label; onClose?: () => void };

export default function LabelBadge({ label, onClose }: BadgeProps) {
  const theme = useContext(ThemeContext);

  return (
    <div
      className={`mb-1 px-1.5 mr-2 py-0.5 rounded-sm bg-${label.color.toLowerCase()}-100 flex`}
    >
      <p className="dark:text-gray-800">{label.name}</p>
      {onClose && (
        <img
          className="w-2.5 ml-0.5 cursor-pointer"
          src={`${
            theme === "dark"
              ? "/icons/dark/actions/close.svg"
              : "/icons/light/close.svg"
          }`}
          alt="delete"
          onClick={onClose}
        />
      )}
    </div>
  );
}
