import React, { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import {
  connectQuickbooks,
  connectQuickbooksAuthData,
  disconnectQuickbooks,
  quickbooksConnectionState,
} from "../../api";
import { ThemeContext } from "../../App";
import useQueryParam from "../../hooks/useQueryParam";
import BnDialog from "../BnDialog";
import InfoPopover from "../InfoPopover";
import Loader from "../Loader";
import { showSnackbar } from "../Snackbar";

type IntegrationItemProps = {
  src: string;
  alt: string;
  infoText?: string;
  onClick?: (isConnected: boolean) => void;
  onRedirect: () => void;
  checkConnectionState: () => Promise<boolean>;
  disconnect?: () => void;
};

export default function IntegrationsSection() {
  const theme = useContext(ThemeContext);
  const query = useQueryParam();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  // toggled just to refetch data about integrations connection state
  const [refetchConnectionState, setRefetchConnectionState] = useState(false);

  const anchorRef = useRef<HTMLAnchorElement | null>(null);

  const [integration, setIntegration] = useState(query.get("integration"));
  const [isIntegrationFinished, setIsIntegrationFinished] = useState(false);
  const [isAddIntegrationOpen, setIsAddIntegrationOpen] = useState(false);
  const [
    isDisconnectIntegrationDialogOpen,
    setIsDisconnectIntegrationDialogOpen,
  ] = useState(false);

  const integrations: { [key: string]: IntegrationItemProps } = {
    quickbooks: {
      src: `/icons/${theme}/integrations/quickbooks.svg`,
      alt: "quickbooks",
      infoText:
        "You can only use the Quickbooks integration on our app with Quickbook`s Essential plan or higher.",
      onClick: async (isConnected: boolean) => {
        if (isConnected) {
          setIsDisconnectIntegrationDialogOpen(true);
          setIntegration("quickbooks");
        } else {
          const { auth_url } = await connectQuickbooks();
          anchorRef.current!.href = auth_url;
          anchorRef.current!.click();
          setIsLoading(true);
        }
      },
      onRedirect: async () => {
        const code = query.get("code");
        const realmId = query.get("realmId");
        if (!code || !realmId) {
          showSnackbar(
            "Unknown error occured while integrating Quickbooks. Try again later.",
          );
          setIntegration("");
          return;
        }
        await connectQuickbooksAuthData(code, realmId);
        setIsIntegrationFinished(true);
      },
      checkConnectionState: async () => {
        const res = await quickbooksConnectionState();
        return res.state;
      },
      disconnect: async () => {
        await disconnectQuickbooks();
        onCloseDisconnectDialog();
        setRefetchConnectionState((prev) => !prev);
        showSnackbar("Disconnected successfully");
      },
    },
  };

  useEffect(() => {
    if (
      integration &&
      integrations[integration] &&
      !isDisconnectIntegrationDialogOpen
    ) {
      integrations[integration].onRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration]);

  function onIntegrationProceed() {
    history.replace("/settings?section=integrations");
    setIntegration("");
    setRefetchConnectionState((prev) => !prev);
  }

  function onAddIntegrationAPIKey(key: string) {
    setIsAddIntegrationOpen(false);
    // TODO: handle add integration API key
  }

  function disconnectNotPossible(integration: string) {
    showSnackbar(`Cannot disconnect from ${integration} app`);
  }

  function onCloseDisconnectDialog() {
    setIsDisconnectIntegrationDialogOpen(false);
    setIntegration("");
  }

  return (
    <div>
      {isLoading && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Loader />
        </div>
      )}
      <ul>
        {Object.values(integrations).map((integration) => (
          <li key={integration.alt}>
            <IntegrationItem
              {...integration}
              shouldRefetch={refetchConnectionState}
            />
          </li>
        ))}
      </ul>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content*/}
      <a href="@" ref={anchorRef} className="hidden" />
      <div className="flex items-center gap-x-4 cursor-pointer mt-5">
        <img src={`/icons/${theme}/plus.svg`} alt="add" />
        <span
          onClick={() => setIsAddIntegrationOpen(true)}
          className="text-green-800 dark:text-gray-300 text-14 cursor-pointer"
        >
          Input another API key
        </span>
      </div>
      {integration && (
        <BnDialog
          isOpen={!!integration && !isDisconnectIntegrationDialogOpen}
          title=""
          description={
            <IntegrationStatus
              src={integrations[integration].src}
              integration={integration}
              onProceed={onIntegrationProceed}
              isFinished={isIntegrationFinished}
            />
          }
          onClose={() => {}}
        />
      )}
      {integration && (
        <BnDialog
          isOpen={!!integration && isDisconnectIntegrationDialogOpen}
          title=""
          description={
            <DisconnectIntegration
              integration={integration}
              onProceed={
                integrations[integration].disconnect ||
                (() => disconnectNotPossible(integration))
              }
            />
          }
          onClose={onCloseDisconnectDialog}
        />
      )}
      <BnDialog
        isOpen={isAddIntegrationOpen}
        title="Add integration"
        centerTitle
        description={
          <AddIntegrationAPIKeyBox onSubmit={onAddIntegrationAPIKey} />
        }
        onClose={() => setIsAddIntegrationOpen(false)}
      />
    </div>
  );
}

function IntegrationItem({
  src,
  alt,
  shouldRefetch,
  infoText,
  checkConnectionState,
  onClick,
}: IntegrationItemProps & { shouldRefetch: boolean }) {
  const theme = useContext(ThemeContext);

  const { data: state, isLoading } = useQuery(
    [`${alt}-connection-state`, shouldRefetch],
    checkConnectionState,
  );

  function onItemClick() {
    onClick && state !== undefined && onClick(state);
  }

  return (
    <div
      className="w-full px-5 py-3 border border-gray-300 rounded-md cursor-pointer flex justify-between items-center"
      onClick={onItemClick}
    >
      <div className="flex gap-x-4 items-center">
        <img src={src} alt={alt} />
        {infoText ? (
          <div onClick={(e) => e.stopPropagation()}>
            <InfoPopover
              text={infoText}
              infoSize="w-4 h-4"
              additionalPopoverClasses="right-0 xl:right-auto ml-4"
            />
          </div>
        ) : null}
      </div>
      {isLoading ? (
        <div className="dots-spinner" />
      ) : state ? (
        <img src={`/icons/${theme}/check.svg`} alt="Connected" />
      ) : (
        <img src={`/icons/${theme}/close_gray.svg`} alt="Not connected" />
      )}
    </div>
  );
}

type IntegrationStatusProps = {
  src: string;
  integration: string;
  isFinished: boolean;
  onProceed: () => void;
};

function IntegrationStatus({
  src,
  integration,
  isFinished,
  onProceed,
}: IntegrationStatusProps) {
  return (
    <div className="flex flex-col items-center content-center gap-y-4">
      <img src={src} alt={integration} className="h-10" />
      <p className={`text-green-800 ${!isFinished && "loading-dots"}`}>
        {isFinished ? "Connected successfully" : "Working on the connection"}
      </p>
      <button onClick={onProceed} className="always-green-button outline-none">
        Proceed
      </button>
    </div>
  );
}

type DisconnectIntegrationProps = {
  integration: string;
  onProceed: () => void;
};

function DisconnectIntegration({
  integration,
  onProceed,
}: DisconnectIntegrationProps) {
  const theme = useContext(ThemeContext);

  return (
    <div className="flex flex-col items-center content-center gap-y-4">
      <img
        src={`/icons/mascot/${theme}/mascot_endsubscription_allert.svg`}
        alt=""
      />
      <p className="text-green-800">
        Are you sure you want to disconnect Basenode from your {integration}{" "}
        account?
      </p>
      <button onClick={onProceed} className="always-green-button outline-none">
        Proceed
      </button>
    </div>
  );
}

type AddIntegrationAPIKeyBoxProps = {
  onSubmit: (key: string) => void;
};

function AddIntegrationAPIKeyBox({ onSubmit }: AddIntegrationAPIKeyBoxProps) {
  const [apiKey, setApiKey] = useState("");
  return (
    <div className="w-full">
      <label className="text-gray-800">
        Input API key here
        <input
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          id="apiKey"
          type="text"
          className="block dark:bg-gray-900 input mt-2"
        />
      </label>
      <button
        onClick={() => onSubmit(apiKey)}
        disabled={!apiKey}
        className="always-green-button w-full mt-6"
      >
        DONE
      </button>
    </div>
  );
}
