import React from "react";

import { FungibleTokensResponse } from "../../types";
import { formatAmount } from "../../utils";

type FungibleTokensTableType = {
  data: FungibleTokensResponse[];
  columns: any[];
  hasMore?: boolean;
  ShowPopup?: () => void;
};
function FungibleTokensTable({
  data,
  columns,
  hasMore,
}: FungibleTokensTableType) {
  const tokensToShow = hasMore
    ? data.slice(0, 7).sort((a, b) => b.value - a.value)
    : data.sort((a, b) => b.value - a.value);

  return (
    <div className="relative pb-6 ">
      <table className="min-w-full table-fixed overflow-y-visible text-right">
        <colgroup>
          {columns.map((col) => (
            <col key={col.accessor} style={{ width: col.width || "auto" }} />
          ))}
        </colgroup>

        <thead className="bg-white font-bold text-13  text-right text-green-800 border-b dark:bg-gray-900 dark:text-gray-300 dark:border-gray-450-t-50">
          <tr>
            {columns.map((col) =>
              col.accessor === "asset_name" ? (
                <th key={col.accessor} className="h-10 text-left pl-2 ">
                  {col.header}
                </th>
              ) : (
                <th key={col.accessor} className="h-10 text-right">
                  {col.header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {data.length === 0 && (
            <tr className="bg-white text-center dark:bg-gray-900 h-24">
              <td
                className=" justify-center font-bold dark:text-gray-300"
                colSpan={columns.length}
              >
                You have no fungible tokens
              </td>
            </tr>
          )}
          {tokensToShow.length > 0 &&
            tokensToShow.map((row, rowId) => (
              <tr
                key={rowId}
                className="bg-white even:bg-gray-50 h-10  text-12 dark:bg-gray-900 my-2 text-right"
              >
                <td className="flex mx-4 my-2">
                  <img
                    src={row.token.image_url}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "/icons/logo_small.svg";
                    }}
                    alt="crypto"
                    className="w-4 h-4 mr-4 "
                  />
                  <div className=" text-left whitespace-nowrap overflow-hidden">
                    {row.token.name}
                  </div>
                  <div className=" text-left font-normal px-2">
                    {row.token.symbol}
                  </div>
                </td>
                <td>{formatAmount(row.balance)}</td>
                <td>{formatAmount(row.current_price)}</td>
                <td>{formatAmount(row.value)}</td>
                <td>{formatAmount(row.unrealized_gain)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default FungibleTokensTable;
