import React from "react";

import { TimePeriod } from "../types";

const DocumentPageDateFilters = React.createContext<{
  timePeriod: TimePeriod;
  setTimePeriod: (timePeriod: TimePeriod) => void;
}>({
  timePeriod: { start_date: new Date(), end_date: new Date() },
  setTimePeriod: () => {},
});

export default DocumentPageDateFilters;
