import { Switch } from "@headlessui/react";
import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

import { deleteUser, updateUser } from "../../api";
import { UserContext } from "../../App";
import SettingsAccountForm from "../../components/forms/SettingsAccountForm";
import { showSnackbar } from "../../components/Snackbar";
import useLocalStorage from "../../hooks/useLocalStorage";
import BnDialog from "../BnDialog";

export default function AccountSection() {
  const [theme, setTheme] = useLocalStorage("theme", "light");
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();

  const [isDeleteAccountDialog, setIsDeleteAccountDialog] = useState(false);

  const enabled = theme === "dark";

  const { mutate: startTutorial } = useMutation(
    "tutorial",
    () => updateUser({ is_tutorial_viewed: false }),
    {
      onSuccess: () => {
        setUser({ ...user, is_tutorial_viewed: false });
        history.push("/overview");
      },
      onError: () =>
        showSnackbar("Something went wrong while starting tutorial"),
    },
  );

  function toggleTheme() {
    setTheme(theme === "dark" ? "light" : "dark");
  }

  function restartTutorial() {
    startTutorial();
  }

  return (
    <div className="w-64 flex flex-col space-y-7">
      <SettingsAccountForm />
      <Switch
        checked={enabled}
        onChange={toggleTheme}
        className={`${
          enabled ? "bg-green-800" : "bg-gray-200"
        } relative inline-flex h-9 w-16 items-center rounded-full`}
      >
        <span className="sr-only">Switch theme</span>
        <img
          src={`/icons/${theme}/dropbox/moon_alt_light.svg`}
          alt="Switch theme"
          className={`${
            enabled ? "translate-x-1" : "translate-x-8"
          } transform transition duration-200 ease-in-out`}
        />
        <span
          className={`${
            enabled ? "translate-x-1 bg-white" : "-translate-x-6 bg-green-800"
          } inline-block h-7 w-7 transform rounded-full transition duration-300 ease-in-out`}
        />
      </Switch>
      <p
        className="pt-2 text-green-800 dark:text-gray-300 border-b border-green-800 cursor-pointer text-14 font-semibold w-min whitespace-nowrap"
        onClick={restartTutorial}
      >
        Restart Tutorial
      </p>
      <p
        className="pt-2 text-red-500 border-b border-red-500 cursor-pointer text-14 font-semibold w-min whitespace-nowrap"
        onClick={() => setIsDeleteAccountDialog(true)}
      >
        Delete account permanently
      </p>
      <BnDialog
        isOpen={isDeleteAccountDialog}
        title=""
        onClose={() => setIsDeleteAccountDialog(false)}
        description={
          <DeleteAccountDialogConfirmation
            onCancel={() => setIsDeleteAccountDialog(false)}
          />
        }
      />
    </div>
  );
}

type DeleteAccountDialogProps = {
  onCancel: () => void;
};

function DeleteAccountDialogConfirmation({
  onCancel,
}: DeleteAccountDialogProps) {
  const history = useHistory();

  const { mutate: removeUser } = useMutation(() => deleteUser(), {
    onSuccess: () => history.push("/auth"),
  });

  return (
    <div>
      <p className="dark:text-green-300 text-green-800 text-center font-bold text-20">
        Are you sure you want to delete your account permanently, all your data
        would be lost forever?
      </p>
      <div className="flex mt-6 justify-center">
        <button
          onClick={() => removeUser()}
          className="primary-button w-28 font-bold mr-4"
        >
          YES
        </button>
        <button
          onClick={onCancel}
          className="transparent-button w-28 font-bold border border-green-800 dark:border-gray-450"
        >
          NO
        </button>
      </div>
    </div>
  );
}
