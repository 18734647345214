import axios, { baseUrl } from "./axios";
import { ensureAddress, signChallenge } from "./metamask";
import {
  AdminUser,
  AdminUsersParams,
  AnalyticsType,
  Balances,
  Cex,
  CexResponse,
  Challenge,
  ConnectQuickbooksResponse,
  Contact,
  ContactResponse,
  ContactsParams,
  DocumentObj,
  DocumentResponse,
  DocumentsParams,
  EditableUser,
  FTC,
  FTCAnalyticsResponse,
  FTCKYC,
  FTCKYCResponse,
  FTCSetupResponse,
  FungibleTokensResponse,
  GoogleLoginResponse,
  HoldingsReportPdfRecord,
  IntegrationConnectionStateResponse,
  Invoice,
  InvoiceResponse,
  Label,
  LabelResponse,
  LimitResponse,
  LoginWithWalletResponse,
  MonthlySummary,
  Network,
  NftParams,
  NonFungibleTokensData,
  Paginated,
  PaymentData,
  PaymentProvider,
  PortfolioValue,
  PricingPlanResponse,
  SearchResults,
  Tier,
  Token,
  TokenFiatParams,
  TokenFiatResponse,
  TotalWalletsResponse,
  Transaction,
  TransactionReportPdfRecord,
  TransactionsParams,
  TranscLabel,
  TranscLabelResponse,
  User,
  UserGrowthHistory,
  Wallet,
  WalletResponse,
  WalletsParams,
} from "./types";
import {
  getWalletConnectAccounts,
  signChallenge as signWalletConnectChallenge,
} from "./walletConnect";

//Base CRUD functions
async function get<T>(url: string, params?: any) {
  const response = await axios(url, {
    params,
  });
  return response.data as T;
}

async function post<T>(url: string, data?: any) {
  const response = await axios(url, {
    method: "POST",
    data,
  });
  return response.data as T;
}

async function patch<T>(url: string, data?: any) {
  const response = await axios(url, { method: "PATCH", data });
  return response.data as T;
}

// delete is a reserved word
async function remove(url: string) {
  await axios(url, { method: "DELETE" });
}

// Auth
export async function loginWithAddress(address: string) {
  return post<User>("login-with-address", { address });
}

export async function loginWithGoogle(access_token: string) {
  return post<GoogleLoginResponse>("login-with-google", { access_token });
}

export async function loginWithMetamask() {
  // Get user's MetaMask address
  const address = await ensureAddress();

  // Get challenge from backend
  const { challenge } = await post<Challenge>("/login-with-wallet", {
    address,
    kind: "METAMASK",
  });

  // Sign challenge with MetaMask
  const signature = await signChallenge(challenge);

  return post<LoginWithWalletResponse>("/login-with-wallet/response", {
    address,
    signature,
  });
}

export async function loginWithWalletConnect() {
  // Get user's WalletConnect address
  const address = getWalletConnectAccounts()[0];

  if (!address) {
    alert(
      "WalletConnect: No address found. Check your connection status and try again.",
    );
    return;
  }

  // Get challenge from backend
  const { challenge } = await post<Challenge>("/login-with-wallet", {
    address,
    kind: "WALLET_CONNECT",
  });

  // Sign challenge with WalletConnect
  const signature = await signWalletConnectChallenge(challenge, address);

  return post<LoginWithWalletResponse>("/login-with-wallet/response", {
    address,
    signature,
  });
}

export async function logout() {
  window.dispatchEvent(new CustomEvent("Basenode:logout"));

  return post<undefined>("logout");
}

export async function getUser() {
  return get<User>("user");
}

export async function updateUser(data: EditableUser) {
  return patch<User>("user", data);
}

export async function deleteUser() {
  return remove("delete-user");
}

//FTC
export async function createFTC(newFTC: FTC) {
  return post<FTCSetupResponse>("ftc/create-user", newFTC);
}

export async function getEmailOTP(user_uuid: string) {
  return post("ftc/get-otp", { user_uuid });
}

export async function setUpFTC(user_uuid: string, one_time_password: string) {
  return post<FTCSetupResponse>("ftc/setup-user", {
    user_uuid,
    one_time_password,
  });
}

export async function getFTC() {
  return get<FTCSetupResponse>("ftc/setup-user");
}

export async function createKYCApplicant(newKYC: FTCKYC) {
  return post<FTCKYCResponse>("ftc/create-kyc-applicant", newKYC);
}

// Admin

export async function getAdminUsers(params: AdminUsersParams) {
  return get<Paginated<AdminUser>>("admin/users", params);
}
export async function getTotalWallets() {
  return get<TotalWalletsResponse>("admin/num-wallets-by-network");
}

export async function addAsAdmin(id: number) {
  return post(`admin/users/${id}/add-as-admin`);
}
export async function removeAdminAccess(id: number) {
  return post<{ plan: Tier }>(`admin/users/${id}/remove-admin-access`);
}
export function getExportUserReportAsPdfUrl(params: AdminUsersParams) {
  return axios.getUri({ url: `${baseUrl}/api/admin/users/pdf`, params });
}
export function getExportUserReportAsCsvUrl(params: AdminUsersParams) {
  return axios.getUri({ url: `${baseUrl}/api/admin/users/csv`, params });
}

//Analytics
export async function getAnalytics() {
  return get<AnalyticsType>("admin/analytics");
}

export async function getUserGrowthHistory(measured_on?: string) {
  return get<UserGrowthHistory>("admin/user-growth-history", { measured_on });
}

export async function getFTCAnalytics(days?: number) {
  return get<FTCAnalyticsResponse>("admin/ftc-analytics", { days });
}

// Address data
export async function getBalances() {
  return get<Balances>("balances");
}

export async function getFungibleTokensData(page: number, limit: number) {
  return get<FungibleTokensResponse[]>("fungible-tokens-summary");
}
export async function getNonFungibleTokensData(params: NftParams) {
  return get<NonFungibleTokensData[]>("non-fungible-tokens-summary", params);
}
export async function getPortfolioValueHistory(num_days?: number) {
  return get<PortfolioValue>("portfolio-value-history", { num_days });
}

export async function getMonthlySummary(year: number, month: number) {
  return get<MonthlySummary>("monthly-summary", { year, month });
}

//Tansactions
export async function getTransactions(params: TransactionsParams) {
  return get<Paginated<Transaction>>("transactions", params);
}

export async function editTransaction(id: number, data: any) {
  return patch<Transaction>(`transactions/${id}`, data);
}

export async function getTransactionsLimit() {
  return get<LimitResponse>(`transactions/limit`);
}
// Contacts
export async function getContacts(params: ContactsParams) {
  return get<ContactResponse[]>("contacts", params);
}

export async function createContact(newContact: Contact) {
  return post<ContactResponse>("contacts", newContact);
}

export async function editContact(id: number, contact: Contact) {
  return patch<ContactResponse>(`contacts/${id}`, contact);
}

export async function deleteContact(id: number) {
  return remove(`contacts/${id}`);
}

// Labels
export async function getLabels() {
  return get<LabelResponse[]>("labels");
}

export async function createLabel(newLabel: Label) {
  return post<LabelResponse>("labels", newLabel);
}

export async function editLabel(id: number, label: Label) {
  return patch<LabelResponse>(`labels/${id}`, label);
}

export async function deleteLabel(id: number) {
  return remove(`labels/${id}`);
}

// TranscLabel
export async function createTranscLabel(newTranscLabel: TranscLabel) {
  return post<TranscLabelResponse>("transaction_labels", newTranscLabel);
}

// Mail payment
export async function mailPayment(paymentData: PaymentData) {
  return post<any>("email-payment", paymentData);
}

// Token Fiat Value
export async function getTokenFiatValue(params: TokenFiatParams) {
  return get<TokenFiatResponse>("currency-prices", params);
}

// Wallets
export async function getWallets(params: WalletsParams) {
  return get<WalletResponse[]>("wallets", params);
}

export async function createWallet(wallet: Wallet) {
  return post<WalletResponse>("wallets", wallet);
}

export async function editWallet(id: number, wallet: Wallet) {
  return patch<WalletResponse>(`wallets/${id}`, wallet);
}

export async function updateWalletActivity(id: number, is_active: boolean) {
  return patch<WalletResponse>(`wallets/${id}`, { is_active });
}

export async function deleteWallet(id: number) {
  return remove(`wallets/${id}`);
}
export async function getWalletLimit() {
  return get<LimitResponse>(`wallets/limit`);
}

//Centralized Exchange
export async function getCex() {
  return get<CexResponse[]>("centralised-exchanges");
}

export async function createCex(Cex: Cex) {
  return post<CexResponse>("centralised-exchanges", Cex);
}

export async function deleteCex(id: number) {
  return remove(`centralised-exchanges/${id}`);
}

//Documents
export async function getDocuments(params: DocumentsParams) {
  return get<Paginated<DocumentResponse>>("documents", params);
}

export async function createDocument(document: DocumentObj) {
  return post<DocumentResponse>("documents", document);
}

export async function editDocument(id: number, document: DocumentObj) {
  return patch<DocumentResponse>(`documents/${id}`, document);
}

export async function deleteDocument(id: number) {
  return remove(`documents/${id}`);
}

export async function getDocumentPdfData(id: number) {
  return get<TransactionReportPdfRecord[]>(`documents/${id}/pdf_data`);
}
export async function getHoldingsPdfData(id: number) {
  return get<HoldingsReportPdfRecord[][]>(`documents/${id}/pdf_data`);
}
export async function getDocumentCSV(id: number, filename: string) {
  const response = await axios(`documents/${id}/export_csv`);
  const type = response.headers["content-type"];
  const link = document.createElement("a");
  const blob = new File([response.data], filename, { type });
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

//Invoices
export async function getInvoice(id: string) {
  return get<InvoiceResponse>(`invoices/${id}`);
}
export async function getInvoiceLimit() {
  return get<LimitResponse>(`invoices/limit`);
}

export async function createInvoice(invoice: Invoice) {
  return post<InvoiceResponse>("invoices", invoice);
}

export async function editInvoice(id: string, invoice: Invoice) {
  return patch<InvoiceResponse>(`invoices/${id}`, invoice);
}

export async function updateInvoiceState(
  id: string,
  state: "PROCESSING" = "PROCESSING",
) {
  return patch<InvoiceResponse>(`invoices/${id}`, { state });
}

export function invoicePdfUrl(id: string, download = false) {
  return `${baseUrl}/api/invoices/${id}/pdf?download=${download}`;
}

// Plan payments
export async function getPricingPlans() {
  return get<PricingPlanResponse>("subscription-plans");
}

export async function subscriptionPurchase(
  payment_provider: PaymentProvider,
  plan: Tier,
) {
  return post<{ user: any }>("payments", {
    payment_provider,
    plan: plan.toUpperCase(),
  });
}

export async function createStripeSessionSubscription(price_id: string) {
  return post<{ redirect_url: string }>("payments/create_checkout_session", {
    price_id,
  });
}

export async function manageCurrentSubscription() {
  return post<{ redirect_url: string }>("payments/manage");
}
// Integrations
export async function connectQuickbooks() {
  return get<ConnectQuickbooksResponse>("quickbooks/connect");
}

export async function connectQuickbooksAuthData(
  auth_code: string,
  realm_id: string,
) {
  return post("quickbooks/connect_response", { auth_code, realm_id });
}

export async function disconnectQuickbooks() {
  return get("quickbooks/disconnect");
}

export async function quickbooksConnectionState() {
  return get<IntegrationConnectionStateResponse>("quickbooks/connection_state");
}

// Others
export async function checkAddress(
  address: string,
  network: Network | "ALL" = "ETHEREUM",
) {
  return post<any>("validate-address", { address, network });
}

export async function getSupportedCurrencies(network: Network) {
  const tokens = await get<Token[]>("tokens", { network });

  tokens.sort((a, b) => {
    const symbolA = a.symbol.toLowerCase();
    const symbolB = b.symbol.toLowerCase();
    return symbolA.localeCompare(symbolB);
  });

  return tokens;
}

export async function getSearchResults(search: string) {
  return get<SearchResults>("search", { search });
}
