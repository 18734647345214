import React, { useContext, useEffect, useState } from "react";

import { updateUser } from "../../api";
import { ThemeContext, UserContext } from "../../App";
import BnDialog from "../../components/BnDialog";
import AddressForm from "../../components/forms/AddressFrom";

export default function ProfileSection() {
  const theme = useContext(ThemeContext);

  const { user, setUser } = useContext(UserContext);

  const [name, setName] = useState(
    user.first_name ? `${user.first_name} ${user.last_name}` : "",
  );
  const [email, setEmail] = useState(user.email);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isAddressDialogOpen, setIsAddressDialogOpen] = useState(false);

  useEffect(() => {
    if (!user.address) return;
    const regexEmail = new RegExp(/\S+@\S+.\S+/);
    setIsEmailInvalid(false);
    if (!regexEmail.test(email)) {
      setIsEmailInvalid(true);
      return;
    }
    const timeout = setTimeout(() => {
      setUser({ ...user, email });
      updateUser({ email });
    }, 1000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    const splittedName = name.split(" ");
    const first_name = splittedName[0];
    const last_name = splittedName.slice(1).join(" ");
    const timeout = setTimeout(() => {
      setUser({
        ...user,
        first_name,
        last_name,
      });
      updateUser({ first_name, last_name });
    }, 1000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    const newName = event.target.value;
    setName(newName);
  }

  function onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
    const newEmail = event.target.value;
    setEmail(newEmail);
  }

  function onCloseAddressDialog() {
    setIsAddressDialogOpen(false);
  }

  async function onSubmitAddressUpdate(data: any) {
    if (!data) return;
    // prevents email update if logged in by external service
    if (!user.address) {
      data.email = undefined;
    }
    const updatedUser = await updateUser(data);
    setUser({ ...user, ...updatedUser });
    onCloseAddressDialog();
  }

  return (
    <div className="w-64 text-14 text-gray-800 dark:text-gray-300 flex flex-col space-y-7">
      <label>
        <p className="mb-1">Name</p>
        <input
          type="text"
          name="name"
          value={name}
          onChange={onChangeName}
          style={{ borderWidth: "0.5px" }}
          className="pl-5 py-3 w-64 border-gray-800 dark:border-gray-300 bg-transparent rounded-md outline-none"
        />
      </label>
      <label>
        <p className="mb-1">Email</p>
        <input
          name="email"
          type="email"
          value={email}
          onChange={onChangeEmail}
          style={{ borderWidth: "0.5px" }}
          className="pl-5 py-3 w-64 border-gray-800 dark:border-gray-300 bg-transparent rounded-md outline-none"
          disabled={!user.address}
        />
        {isEmailInvalid && email && (
          <p className="text-red-500">Enter a valid email address</p>
        )}
      </label>
      <div>
        <p className="mb-5">Address</p>
        <div className="text-14 flex items-center justify-between">
          {user.address_line1 ? (
            <div className="font-semibold">
              <p>
                {user.address_line1}, {user.postal_code}
              </p>
              {user.address_line2 && <p>{user.address_line2}</p>}
              <p>
                {user.city}, {user.country}
              </p>
            </div>
          ) : (
            <p>Enter your address data</p>
          )}
          <div
            className="flex items-center cursor-pointer"
            onClick={() => setIsAddressDialogOpen(true)}
          >
            <img src={`/icons/${theme}/actions/edit_outline.svg`} alt="edit" />
            <p className="text-12">Edit</p>
          </div>
        </div>
      </div>
      <BnDialog
        isOpen={isAddressDialogOpen}
        title="Edit address"
        centerTitle
        description={
          <AddressForm onSubmit={onSubmitAddressUpdate} defaultValues={user} />
        }
        onClose={onCloseAddressDialog}
      />
    </div>
  );
}
