import React from "react";

export default function SwitchToFreePlan({
  plan_end_date,
  onSubmit,
  onCancel,
}: {
  plan_end_date: string;
  onSubmit: () => void;
  onCancel: () => void;
}) {
  return (
    <div className="flex flex-col justify-between items-center">
      <p className="text-16 color-green-900 w-full dark:text-gray-300">
        Are you sure you want to switch to{" "}
        <span className="text-green-300 font-bold capitalize">Free plan?</span>
      </p>
      <p className="text-16 color-green-900 w-full mt-4 dark:text-gray-300">
        If you proceed, your current plan subscription will be cancelled on{" "}
        <span className="text-green-300 font-bold">{plan_end_date}</span>
      </p>
      <div className="flex gap-x-2 mt-8">
        <button
          onClick={onCancel}
          className="text-16 w-40 transparent-button outline-none border border-green-800 font-bold"
        >
          Go back
        </button>
        <button onClick={onSubmit} className="text-16 w-40 always-green-button">
          Proceed
        </button>
      </div>
    </div>
  );
}
