import { pdf } from "@react-pdf/renderer";

export default async function previewPDF(component: JSX.Element) {
  const blob = await pdf(component).toBlob();
  const url = window.URL.createObjectURL(blob);
  window.open(url, "_blank");
  window.URL.revokeObjectURL(url);
}

export function previewPDFbyHref(href: string) {
  window.open(href, "_blank");
}
