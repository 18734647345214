import { Popover, Transition } from "@headlessui/react";
import { format, isAfter, isBefore } from "date-fns";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { FieldValues, UseFormReturn, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import {
  createDocument,
  editDocument,
  getCex,
  getWallets,
  quickbooksConnectionState,
} from "../../api";
import { ThemeContext } from "../../App";
import useMutationWithForm from "../../hooks/useMutationWithForm";
import { DocumentObj, DocumentResponse, DocumentTypes } from "../../types";
import DatePicker from "../DatePicker";
import Loader from "../Loader";
import { showSnackbar } from "../Snackbar";
import WalletDetails from "../WalletDetails";
import BaseForm from "./_BaseForm";
import Button from "./_Button";
import Dropdown from "./_Dropdown";
import Input from "./_Input";

type CreateDocumentProps = {
  onSubmit: () => void;
  minDate: Date;
  defaultValues?: any;
  onEdit?: boolean;
};

export default function CreateDocument({
  onSubmit,
  minDate,
  defaultValues,
  onEdit,
}: CreateDocumentProps) {
  const history = useHistory();
  const form = useForm<FieldValues>({
    mode: "onChange",
    defaultValues: {
      start_date: format(minDate, "yyyy-MM-dd"),
      end_date: format(new Date(Date.now()), "yyyy-MM-dd"),
      ...defaultValues,
      wallets: defaultValues?.wallets?.toString() ?? "All",
    },
  });

  const { mutate: addDocument, isLoading: isAddingDocument } =
    useMutationWithForm(form, (data: DocumentObj) => createDocument(data), {
      onSuccess: () => {
        onSubmit();
      },
    });

  const { mutate: modifyDocument } = useMutationWithForm(
    form,
    (data: DocumentObj) => editDocument(defaultValues!.id, data),
    {
      onSuccess: () => onSubmit(),
    },
  );

  async function onFormSubmit() {
    const data = {
      ...form.getValues(),
      kind: form.getValues().kind.toString(),
    } as DocumentResponse;
    const { id, created_at, ...document } = data;
    if (document.wallets === "All") document.wallets = undefined;
    if (document.wallets === undefined && document.cex?.length) {
      document.wallets = [];
    }

    if (document.kind === "QUICKBOOKS_REPORT") {
      const res = await quickbooksConnectionState();
      if (!res.state) {
        showSnackbar("You are not authenticated to Quickbooks!");
        history.push("/settings?section=integrations");
        return;
      }
    }

    if (onEdit) {
      modifyDocument(document as DocumentObj);
    } else {
      addDocument(document as DocumentObj);
    }
  }

  return (
    <BaseForm form={form} onSubmit={onFormSubmit}>
      {isAddingDocument && (
        <div className="absolute top-1/2 left-1/2">
          <Loader />
        </div>
      )}
      <DocumentForm form={form} minDate={minDate} disabled={!!onEdit} />
    </BaseForm>
  );
}

type DocumentFormProps = {
  form: UseFormReturn<FieldValues, any>;
  minDate: Date;
  disabled: boolean;
};

function DocumentForm({ form, minDate, disabled }: DocumentFormProps) {
  const { data: wallets } = useQuery("wallets", () =>
    getWallets({ ordering: "name" }),
  );

  const { data: cex } = useQuery("cex", () => getCex());

  const theme = useContext(ThemeContext);
  const [walletsList, setWalletsList] = useState<number[]>([]);
  const [cexList, setCexList] = useState<number[]>([]);

  const supported_document_types = DocumentTypes.filter((type) =>
    ["TRANSACTION_REPORT", "QUICKBOOKS_REPORT", "HOLDINGS_REPORT"].includes(
      type,
    ),
  );

  const updateWalletList = (wallet: number) => {
    if (walletsList.includes(wallet)) {
      setWalletsList(walletsList.filter((w) => w !== wallet));
    } else {
      setWalletsList([...walletsList, wallet]);
    }
  };

  const updateCexList = (cex: number) => {
    if (cexList.includes(cex)) {
      setCexList(cexList.filter((c) => c !== cex));
    } else {
      setCexList([...cexList, cex]);
    }
  };

  useEffect(() => {
    form.setValue("wallets", walletsList);
  }, [walletsList, form]);

  useEffect(() => {
    if (
      Object.keys(form.formState.errors).includes("start_date") ||
      !Date.parse(form.getValues("start_date")) ||
      isBefore(new Date(form.getValues("start_date")), new Date(minDate))
    ) {
      form.reset({
        ...form.getValues(),
        start_date: format(minDate, "yyyy-MM-dd"),
      });
    }

    if (
      isAfter(
        new Date(form.getValues("start_date")),
        new Date(form.getValues("end_date")),
      )
    ) {
      form.reset({
        ...form.getValues(),
        start_date: format(minDate, "yyyy-MM-dd"),
      });
    }
    // eslint-disable-next-line
  }, [form.getValues("start_date")]);

  useEffect(() => {
    if (
      Object.keys(form.formState.errors).includes("end_date") ||
      !Date.parse(form.getValues("end_date")) ||
      isAfter(new Date(form.getValues("end_date")), new Date(Date.now()))
    ) {
      form.reset({
        ...form.getValues(),
        end_date: format(new Date(Date.now()), "yyyy-MM-dd"),
      });
    }
    if (
      isAfter(
        new Date(form.getValues("start_date")),
        new Date(form.getValues("end_date")),
      )
    ) {
      form.reset({
        ...form.getValues(),
        end_date: format(new Date(Date.now()), "yyyy-MM-dd"),
      });
    }
    // eslint-disable-next-line
  }, [form.getValues("end_date")]);

  useEffect(() => {
    form.setValue("cex", cexList);
  }, [cexList, form]);
  return (
    <div className="flex flex-col mb-2 h-72">
      <div className="w-full">
        <Input
          placeholder="Name"
          registerOptions={{
            required: true,
          }}
          form={form}
          name="name"
        />
        <div className="flex justify-between   mt-4 mb-4">
          <div className=" w-1/2 min-w-max pr-2">
            <Dropdown
              form={form}
              name="kind"
              registerOptions={{ required: true }}
              placeholder="Type"
              options={[
                ...supported_document_types.map((docType) => ({
                  label: docType.replaceAll("_", " ").toLocaleLowerCase(),
                  value: docType,
                })),
              ]}
              disabled={disabled}
            />
          </div>
          <div className=" w-1/2 pl-2 ">
            {disabled ? (
              <div className="relative z-20 mt-2 rounded-md  sm:px-8 px-4 bg-gray-300 sm:text-13 flex items-center dark:text-gray-300">
                <div className="relative">
                  <button
                    className="text-center items-center text-black w-64 dark:text-gray-300 flex justify-between h-10   "
                    type="button"
                  >
                    <div className="flex">Wallet(s)</div>
                    <span className=" inset-y-0 flex pr-4">
                      <img
                        className="w-4 h-4"
                        src={`/icons/${theme}/arrows/expand_down_light.svg`}
                        alt="icon"
                      />
                    </span>
                  </button>
                </div>
              </div>
            ) : (
              <Popover className="relative z-20 mt-2 rounded-md  sm:px-8 px-4 bg-gray-100 dark:bg-gray-900 sm:text-13 flex items-center dark:text-gray-300">
                <Popover.Button className=" relative">
                  <button
                    className="text-center items-center text-black w-64 dark:text-gray-300 flex justify-between h-10"
                    type="button"
                  >
                    <div className="flex text-gray-500 ">
                      {wallets && walletsList.length ? (
                        <WalletDetails
                          wallet={wallets.find((w) => w.id === walletsList[0])!}
                        />
                      ) : cex && cexList.length ? (
                        <WalletDetails
                          wallet={cex.find((c) => c.id === cexList[0])!}
                        />
                      ) : (
                        "Select wallet(s)"
                      )}
                    </div>
                    <span className=" inset-y-0 flex pr-4">
                      <img
                        className="w-4 h-4"
                        src={`/icons/${theme}/arrows/expand_down_light.svg`}
                        alt="icon"
                      />
                    </span>
                  </button>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100 transform"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-75 transform"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Popover.Panel className="absolute left-0 top-10 bg-white  mr-1 shadow-lg z-5 w-full  rounded shadow-gray dark:bg-gray-950 ">
                    {wallets &&
                      wallets.map((wallet) => (
                        <div
                          className="flex items-center m-4  space-x-4 cursor-pointer"
                          onClick={() => updateWalletList(wallet.id)}
                          key={wallet.id}
                        >
                          {walletsList.includes(wallet.id) ? (
                            <img
                              src={`/icons/${theme}/check_rectangle.svg`}
                              className=" dark:bg-gray-600"
                              alt="check"
                            />
                          ) : (
                            <img
                              src={`/icons/${theme}/checkbox/border.svg`}
                              alt="border"
                              className=" dark:bg-gray-600"
                            />
                          )}
                          <WalletDetails wallet={wallet} />
                        </div>
                      ))}
                    {cex &&
                      cex.map((c) => (
                        <div
                          className="flex items-center m-4  space-x-4 cursor-pointer"
                          onClick={() => updateCexList(c.id)}
                          key={c.id}
                        >
                          {cexList.includes(c.id) ? (
                            <img
                              src={`/icons/${theme}/check_rectangle.svg`}
                              className=" dark:bg-gray-600"
                              alt="check"
                            />
                          ) : (
                            <img
                              src={`/icons/${theme}/checkbox/border.svg`}
                              alt="border"
                              className=" dark:bg-gray-600"
                            />
                          )}
                          <WalletDetails wallet={c} />
                        </div>
                      ))}
                  </Popover.Panel>
                </Transition>
              </Popover>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-baseline mt-2">
        <p className="capitalize text-12 text-gray-800 dark:text-gray-300 font-bold pr-3 pt-2 ">
          Choose Date:
        </p>
        <DatePicker
          form={form}
          minDate={minDate}
          isOnLeftSide
          showConfirmButton
        />
      </div>
      <div className="flex justify-end mt-10">
        <Button
          type="submit"
          text={disabled ? "save" : "continue"}
          styling="document-button"
          disabled={Object.keys(form.formState.errors).length > 0}
        />
      </div>
    </div>
  );
}
