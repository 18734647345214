import Big from "big.js";
import React, { useContext, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import {
  createTranscLabel,
  getTokenFiatValue,
  mailPayment,
  updateInvoiceState,
} from "../api";
import { ThemeContext } from "../App";
import BnDialog from "../components/BnDialog";
import Button from "../components/forms/_Button";
import Loader from "../components/Loader";
import NotificationLightBox from "../components/NotificationLightBox";
import { showSnackbar } from "../components/Snackbar";
import {
  ERC20Contract,
  ensureAddress,
  switchNetwork,
  transferEther,
} from "../metamask";
import {
  InvoiceResponse,
  LocationProps,
  PaymentData,
  TranscLabel,
} from "../types";
import {
  amountToHex,
  capitalize,
  copyText,
  formatAmount,
  formatMinutes,
  networkToChainData,
} from "../utils";
import { initWalletConnect, payWithWalletConnect } from "../walletConnect";

export default function InvoicePaymentPage() {
  const initialTime = 180; //3mins..
  const history = useHistory();
  const location = useLocation<LocationProps>();
  const invoice = location.state?.invoice;
  const theme = useContext(ThemeContext);

  const [isNotificationDialogOpen, setIsNotificationDialogOpen] =
    useState(false);

  const [time, setTime] = useState(initialTime);

  const { mutate: addTranscationLabel } = useMutation(
    "transaction_labels",
    (data: TranscLabel) => createTranscLabel(data),
  );
  const { mutate: sendMail } = useMutation(
    "email-payment",
    (data: PaymentData) => mailPayment(data),
  );

  const { data: tokenFiat, refetch } = useQuery(
    ["token-fiat"],
    () =>
      getTokenFiatValue({
        token: invoice!.token.symbol,
        fiat_currency: invoice!.fiat_currency,
      }),
    { enabled: !!invoice },
  );

  useEffect(() => {
    const timer = setInterval(() => {
      if (time > 0) {
        setTime((prevTime) => prevTime - 1);
      } else {
        refetch();
        setTime(initialTime);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [time, refetch]);

  if (!invoice) {
    showSnackbar("An error occurred while fetching invoice data.");
    history.push("/auth");
    return <p></p>;
  }

  const tokenAmount = tokenFiat
    ? Big(invoice.total_amount)
        .div(Big(tokenFiat!.quote_rate))
        .round(4)
        .toNumber()
    : 0;

  function onPaymentSuccess(invoice: InvoiceResponse, txHash: any) {
    const data: TranscLabel = {
      name: invoice.name,
      tx_hash: txHash,
      invoice: invoice.id,
    };

    const paymentData: PaymentData = {
      hash: txHash,
      data: invoice,
    };

    addTranscationLabel(data);
    sendMail(paymentData);
    setIsNotificationDialogOpen(true);
  }

  return (
    <div className="flex flex-col mt-4 justify-center items-center">
      <NotificationLightBox
        dialogTitle="Payment successfully sent"
        dialogMessage={
          <div>
            <p>
              Your payment for{" "}
              <span className="text-green-300 font-bold"> {invoice.name} </span>{" "}
              was sent to
              <span className="mx-1 text-green-300 font-bold">
                {invoice.owner.first_name} {invoice.owner.last_name}.
              </span>
            </p>
          </div>
        }
        isNotificationDialogOpen={isNotificationDialogOpen}
        onCloseDialog={() => history.push("/overview")}
        mascotImage={`/icons/mascot/${theme}/mascot_success.svg`}
        destinationOnClose="/overview"
        buttonText="DONE"
      />
      {tokenAmount ? (
        <div className="bg-white text-12 md:text-24 text-gray-800 rounded-20px p-6 pb-12 mlg:p-12 w-full max-w-3xl dark:bg-gray-900 dark:text-gray-300">
          <p className="text-20 md:text-40 font-semibold text-center mb-4 ml-6">
            Invoice
          </p>
          <div className="flex justify-between text-green-800 dark:text-gray-300 font-semibold mx-10">
            <p>From</p>
            <p>Amount</p>
            <p>To</p>
          </div>
          <div className="flex justify-between items-center my-8">
            <p className="text-10 md:text-20 text-green-800 dark:text-gray-300">{`${invoice.owner.first_name} ${invoice.owner.last_name}`}</p>
            <img
              src={`/icons/${theme}/arrows/calendar_next.svg`}
              alt="direction"
              className="w-5 h-5"
            />
            <div className="font-bold flex flex-col items-center">
              <p>{`${formatAmount(invoice.total_amount)} ${
                invoice.fiat_currency
              }`}</p>
              <img
                src="/icons/approximate.svg"
                alt="approximate"
                className="w-10 h-10"
              />
              <div className="flex justify-center">
                <img
                  src={invoice.token.image_url}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "/icons/logo_small.svg";
                  }}
                  alt={invoice.token.symbol}
                  className="w-4 h-4 md:w-5 md:h-5 md:mt-1 mr-3"
                />
                <p>{tokenAmount}*</p>
              </div>
            </div>
            <img
              src={`/icons/${theme}/arrows/calendar_next.svg`}
              alt="direction"
              className="w-5 h-5"
            />
            <p className="text-10 md:text-20 text-green-800 dark:text-gray-300">{`${invoice.contact.first_name} ${invoice.contact.last_name}`}</p>
          </div>
          <div className="flex justify-center">
            <p className="border rounded-md px-36 py-1 text-center mb-8 inline-block">
              {invoice.message ? invoice.message : "N/A"}
            </p>
          </div>
          <div className="text-green-800 dark:text-gray-300">
            <h2 className="font-semibold text-center mb-8">
              How would you like to pay?
            </h2>
            <div className="grid grid-cols-2 gap-10 mt-4 max-w-xl mx-auto">
              <WalletPayment
                invoice={invoice}
                tokenAmount={tokenAmount}
                onPaymentSuccess={onPaymentSuccess}
              />
              <FiatPayment
                invoice={invoice}
                tokenAmount={tokenAmount}
                // TODO: This doesn't work yet, tx hash could take up to 48+ hours to return...
                // Consider handling this functionality on the backend with Webhooks...
                onPaymentSuccess={onPaymentSuccess}
              />
            </div>
          </div>
          <p className="text-center mt-8 text-10 md:text-16">{`*Price will update in ${formatMinutes(
            time,
          )}`}</p>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

type PaymentProps = {
  invoice: InvoiceResponse;
  tokenAmount: number;
  onPaymentSuccess?: (invoice: InvoiceResponse, txHash: string) => any;
};

type Wallet = {
  name: string;
  desc: string;
  pay: (invoice: InvoiceResponse) => any;
  installation_page?: string;
};

function WalletPayment({
  invoice,
  tokenAmount,
  onPaymentSuccess,
}: PaymentProps) {
  const ethereum = window.ethereum;
  const networkChainData =
    invoice && networkToChainData[invoice!.wallet.network];

  const wallets: Wallet[] = [
    {
      name: "MetaMask",
      desc: "Pay with your MetaMask Wallet",
      installation_page: "https://metamask.io/download/",
      pay: payMetaMask,
    },
    {
      name: "WalletConnect",
      desc: "Choose your preferred wallet available in WalletConnect",
      pay: payWalletConnect,
    },
  ];

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isUnsupportedOpen, setIsUnsupportedOpen] = useState<boolean>(false);

  function closeModal() {
    setIsOpen(false);
  }

  function handleWallet() {
    if (networkChainData) {
      setIsOpen(true);
    } else {
      setIsUnsupportedOpen(true);
    }
  }

  function closeUnsupportedModal() {
    setIsUnsupportedOpen(false);
  }

  async function payMetaMask(invoice: InvoiceResponse) {
    closeModal();

    const address = await ensureAddress();
    if (!address) return;

    if (networkChainData!.chainId !== ethereum.chainId) {
      const switched = await switchNetwork(
        networkToChainData[invoice!.wallet.network],
      );
      if (!switched) return;
    }

    if (invoice!.token.symbol === "ETH") {
      const txHash = await transferEther(
        invoice!.wallet.address,
        amountToHex(invoice!.token.symbol, tokenAmount),
      );
      onPaymentSuccess!(invoice as InvoiceResponse, txHash);
    } else {
      const contract = new ERC20Contract(invoice!.contract_address);
      await contract.transfer(
        invoice!.wallet.address,
        amountToHex(invoice!.token.symbol, tokenAmount),
        invoice,
        onPaymentSuccess,
      );
    }
  }

  function payWalletConnect(invoice: InvoiceResponse) {
    closeModal();

    const onConnect = async () => {
      document.removeEventListener(
        "Basenode:walletconnectConnected",
        onConnect,
      );
      const txHash = await payWithWalletConnect(
        invoice,
        amountToHex(invoice.token.symbol, tokenAmount),
      );
      if (txHash) onPaymentSuccess!(invoice, txHash);
    };
    document.removeEventListener("Basenode:walletconnectConnected", onConnect);
    document.addEventListener("Basenode:walletconnectConnected", onConnect);

    initWalletConnect(false);
  }

  return (
    <div
      className="font-bold space-y-4 p-4 bg-green-300 bg-opacity-10 shadow-lg rounded-md border border-gray-100 focus:outline-none dark:bg-gray-950 dark:border-gray-900 cursor-pointer"
      onClick={handleWallet}
    >
      <div className="flex">
        <img
          src="/icons/wallets/metamask.svg"
          alt="metamask"
          className="w-11 h-11 md:w-14 md:h-14"
        />
        <p className="ml-2 md:ml-4 text-12 md:text-20">Wallet</p>
      </div>
      <p className="text-10 md:text-12">
        You pay using your Crypto or Bitcoin Wallet.
      </p>
      <BnDialog
        isOpen={isOpen}
        title=""
        maxWidth="max-w-3xl"
        header={false}
        description={
          <div className="grid grid-cols-2 space-x-5">
            {wallets.map((wallet) => (
              <button
                key={wallet.name}
                onClick={() => wallet.pay(invoice)}
                className="relative flex flex-col items-center w-full h-64 px-14 bg-gray-100 rounded-2xl border border-gray-100 focus:outline-none focus:border-green-300 dark:bg-gray-900 dark:border-gray-900"
              >
                <div className="mt-9 h-18 w-18 flex align-center justify-center">
                  <img
                    src={`/icons/wallets/${wallet.name.toLowerCase()}.svg`}
                    alt={wallet.name}
                    className="w-14 h-14"
                  />
                </div>
                <h3 className="mt-4 text-20 font-bold text-green-800 capitalize dark:text-gray-300">
                  {wallet.name}
                </h3>
                <p className="mt-4 text-16 text-green-800">{wallet.desc}</p>
                {wallet.installation_page && (
                  <div className="text-10 text-green-800 absolute bottom-1">
                    <span>{`Don't have a ${wallet.name} wallet? `}</span>
                    <a
                      href={wallet.installation_page}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="underline"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Download
                    </a>
                  </div>
                )}
              </button>
            ))}
          </div>
        }
        onClose={closeModal}
      />
      <BnDialog
        isOpen={isUnsupportedOpen}
        onClose={closeUnsupportedModal}
        title={`Please pay from your ${capitalize(
          invoice.wallet.network,
        )} Wallet:`}
        titleSize={20}
        maxWidth="max-w-2xl"
        centerTitle
        description={
          <UnsupportedNetworkPayment
            invoice={invoice}
            tokenAmount={tokenAmount}
          />
        }
      />
    </div>
  );
}

function FiatPayment({ invoice }: PaymentProps) {
  const ftcInvoice =
    invoice.ftc?.bank_iban &&
    invoice.ftc?.kyc === "FULL_USER" &&
    invoice.is_ftc;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const history = useHistory();

  const { mutate: paidInvoice, isLoading } = useMutation(
    () => updateInvoiceState(invoice.id),
    {
      onSuccess: () => history.push("/auth"),
    },
  );

  function closeModal() {
    setIsOpen(false);
  }

  function handleFTCPayment() {
    if (ftcInvoice) {
      setIsOpen(true);
    } else {
      showSnackbar(
        "This invoice can't currently be paid with Fiat. Kindly contact the invoice owner.",
      );
    }
  }

  return (
    <div
      className={`font-bold space-y-4 p-4 ${
        ftcInvoice ? "bg-green-300" : "bg-gray-950 bg-opacity-50"
      } bg-opacity-10 shadow-lg rounded-md border border-gray-100 focus:outline-none dark:bg-gray-950 dark:border-gray-900 cursor-pointer`}
      onClick={handleFTCPayment}
    >
      <div className="flex">
        <img
          src="/icons/credit_card_colored.svg"
          alt="credit_card"
          className="w-11 h-11 md:w-14 md:h-14"
        />
        <div className="ml-2 md:ml-4 text-12 md:text-20">
          <p>Bank Account</p>
          <p className="text-10 md:text-12 text-white bg-green-800 rounded-md inline-block py-1 px-2">
            EUR
          </p>
        </div>
      </div>
      <p className="text-10 md:text-12">
        Pay using SEPA or wire transfer with a processing time of 1-2 business
        days.
      </p>
      {ftcInvoice && (
        <BnDialog
          isOpen={isOpen}
          title=""
          maxWidth="max-w-xl"
          onClose={closeModal}
          description={
            <div className="flex flex-col items-center -mt-1 text-green-800 dark:text-gray-300">
              <p>{`Please pay ${formatAmount(invoice.total_amount)} ${
                invoice.fiat_currency
              } to:`}</p>
              <p className="text-green-800 dark:text-gray-300 font-bold mt-3">
                Recipient
              </p>
              <div className="text-center border rounded-md px-4 py-2 w-4/5 relative break-words">
                <p className="mr-4">{`${capitalize(
                  invoice.owner.first_name,
                )} ${capitalize(invoice.owner.last_name)}`}</p>
                <img
                  alt="copy"
                  src="/icons/copy.svg"
                  onClick={() =>
                    copyText(
                      `${capitalize(invoice.owner.first_name)} ${capitalize(
                        invoice.owner.last_name,
                      )}`,
                    )
                  }
                  className="cursor-pointer absolute right-4 top-1"
                />
              </div>
              <p className="text-green-800 dark:text-gray-300 font-bold mt-3">
                IBAN
              </p>
              <div className="text-center border rounded-md px-4 pr-6 py-2 w-4/5 relative break-words">
                <p className="mr-4">{invoice!.ftc!.bank_iban}</p>
                <img
                  alt="copy"
                  src="/icons/copy.svg"
                  onClick={() => copyText(invoice.ftc!.bank_iban as string)}
                  className="cursor-pointer absolute right-4 top-1"
                />
              </div>
              <p className="text-green-800 dark:text-gray-300 font-bold mt-3">
                BIC
              </p>
              <div className="text-center border rounded-md px-4 py-2 w-4/5 relative break-words">
                <p className="mr-4">{invoice!.ftc!.bank_bic}</p>
                <img
                  alt="copy"
                  src="/icons/copy.svg"
                  onClick={() => copyText(invoice.ftc!.bank_bic as string)}
                  className="cursor-pointer absolute right-4 top-1"
                />
              </div>
              <p className="text-green-800 dark:text-gray-300 font-bold mt-3">
                Payment Reference
              </p>
              <div className="text-center border rounded-md px-4 py-2 w-4/5 relative break-words">
                <p className="mr-4">{invoice!.ftc!.payment_ref}</p>
                <img
                  alt="copy"
                  src="/icons/copy.svg"
                  onClick={() => copyText(invoice.ftc!.payment_ref)}
                  className="cursor-pointer absolute right-4 top-1"
                />
              </div>
              <p className="text-center mt-3">
                Please make sure to copy the payment reference correctly <br />
                and do not add anything to it.
              </p>
              <Button
                text={isLoading ? "Loading..." : "I Paid the Invoice"}
                normalText
                disabled={isLoading}
                styling="primary-button"
                additionalClasses="text-14 focus:outline-none font-bold normal-case mt-2"
                onClick={paidInvoice}
              />
            </div>
          }
        />
      )}
    </div>
  );
}

function UnsupportedNetworkPayment({ invoice, tokenAmount }: PaymentProps) {
  const history = useHistory();

  return (
    <div className="flex flex-col text-16 items-center text-green-800 dark:text-gray-300">
      {invoice.wallet.network === "BITCOIN" && (
        <div className="mb-10">
          <QRCode
            value={`bitcoin:${invoice.wallet.address}?amount=${tokenAmount}`}
            size={150}
          />
        </div>
      )}
      <p className="text-20">Public Address</p>
      <div className="text-center border rounded-md px-4 py-2 w-4/5 relative break-words">
        <p className="mr-4">{invoice.wallet.address}</p>
        <img
          alt="copy"
          src="/icons/copy.svg"
          onClick={() => copyText(invoice.wallet.address)}
          className="cursor-pointer absolute right-4 top-1"
        />
      </div>
      <p className="text-20 mt-10">Amount</p>
      <div className="text-center border rounded-md px-4 py-2 w-4/5 relative mb-10 break-words">
        <p className="mr-4">{tokenAmount}</p>
        <img
          alt="copy"
          src="/icons/copy.svg"
          onClick={() => copyText(tokenAmount.toString())}
          className="cursor-pointer absolute right-4 top-1"
        />
      </div>
      <Button
        text="I Paid the Invoice"
        normalText
        styling="primary-button"
        additionalClasses="text-14 focus:outline-none font-bold normal-case"
        onClick={() => history.push("/auth")}
      />
    </div>
  );
}
