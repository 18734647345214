import { Transition } from "@headlessui/react";
import React, { Fragment, ReactElement, useEffect, useState } from "react";

const snackbarEventBus = {
  on(event: any, callback: any) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  dispatch(event: any, data: any) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: any, callback?: any) {
    document.removeEventListener(event, callback);
  },
};

export function showSnackbar(message: string) {
  snackbarEventBus.dispatch("showSnackbar", message);
}

export default function Snackbar() {
  const [isOpened, setIsOpened] = useState(false);
  const [content, setContent] = useState<ReactElement | null>(null);
  const wait = 5; //seconds until snackbar close

  useEffect(() => {
    snackbarEventBus.on("showSnackbar", (data: any) => {
      setContent(data);
      setIsOpened(true);
    });
    return () => {
      snackbarEventBus.remove("showSnackbar");
      setIsOpened(false);
      setContent(null);
    };
  }, []);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (content) {
      timer = setInterval(() => setIsOpened(false), wait * 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [content]);

  return (
    <Transition
      show={isOpened}
      as={Fragment}
      enter="transition ease-out duration-500"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-500"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <div className="fixed w-60 p-4 bg-white z-50 rounded-lg shadow-lg left-1/2 transform -translate-x-1/2 bottom-2 dark:bg-gray-900">
        <p className="text-12 font-bold text-center dark:text-gray-300">
          {content}
        </p>
      </div>
    </Transition>
  );
}
