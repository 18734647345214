import { Popover, Transition } from "@headlessui/react";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { ThemeContext } from "../App";

export default function FaqButton() {
  const theme = useContext(ThemeContext);

  const anchorStyle =
    "text-green-800 text-16 font-semibold whitespace-nowrap cursor-pointer";
  const imageStyle = "cursor-pointer";

  return (
    <Popover>
      <Popover.Button
        style={{ zIndex: 1 }}
        className="relative bg-green-800 rounded-full w-18 h-18"
      >
        <img
          src={`/icons/${theme}/help_floating_button/help.svg`}
          alt="Click for help"
          className="m-auto"
        />
      </Popover.Button>
      <Transition
        enter="transition duration-200 ease-out"
        enterFrom="transform scale-x-50 opacity-0"
        enterTo="transform scale-x-100 opacity-100"
        leave="transition duration-200 ease-out"
        leaveFrom="transform scale-x-100 opacity-100"
        leaveTo="transform scale-x-50 opacity-0"
      >
        <Popover.Panel className="absolute w-max right-0 bottom-0 bg-white h-18 rounded-full flex items-center space-x-5 pl-12 pr-24 shadow-filter">
          <a
            href="https://www.basenode.io/team"
            target="_blank"
            rel="noreferrer noopener"
            className={anchorStyle}
          >
            About us
          </a>
          <Link to="/faq" className={anchorStyle}>
            FAQ
          </Link>
          <a
            href="https://www.basenode.io/contact"
            target="_blank"
            rel="noreferrer noopener"
            className={anchorStyle}
          >
            Contact
          </a>
          <a
            href="https://www.basenode.io/legal-notice"
            target="_blank"
            rel="noreferrer noopener"
            className={anchorStyle}
          >
            Legal
          </a>
          <a
            href="https://twitter.com/basenodeio/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={`/icons/${theme}/help_floating_button/twitter.svg`}
              alt="twitter"
              className={imageStyle}
            />
          </a>
          <a
            href="https://discord.gg/UwGymSsS5g"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={`/icons/${theme}/help_floating_button/discord.svg`}
              alt="discord"
              className={imageStyle}
            />
          </a>
          <a
            href="https://de.linkedin.com/company/basenodeio"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={`/icons/${theme}/help_floating_button/linkedin.svg`}
              alt="linkedin"
              className={imageStyle}
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCJRcffBsf0I3jjieKBkohMA"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={`/icons/${theme}/help_floating_button/youtube.svg`}
              alt="youtube"
              className={imageStyle}
            />
          </a>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
