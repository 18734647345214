import { Popover, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

import { Balance } from "../../types";
import { formatAmount } from "../../utils";

type AssetListPopUPtype = {
  counter: number;
  balances: Balance[];
};

function AssetListPopUP({ counter, balances }: AssetListPopUPtype) {
  return (
    <Popover className="relative flex rounded-10px h-5 text-gray sm:text-13 text-12  dark:text-gray-300">
      <Popover.Button className=" bg-grey-200 dark:bg-gray-900 dark:shadow-dark">
        Others ({counter})
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute rounded-lg w-64 -top-4 left-10 mt-1 bg-white p-2 z-30 flex-col items-center shadow-lg dark:bg-gray-900 dark:shadow-dark font-medium opacity-100">
          <div className="top-6 left-0 z-10 p-2 rounded-xl flex flex-col space-y-4 text-center w-full overflow-hidden bg-white dark:bg-gray-900 dark:shadow-dark">
            {balances.map((b) => (
              <div className="flex items-center w-full" key={b.token.id}>
                <div className="w-max h-5 rounded-sm mr-1 ">
                  <div className="w-5"></div>
                </div>
                <div className="flex w-44 mlg:w-full whitespace-nowrap justify-between">
                  <h4 className="text-14">{b.token.symbol}</h4>
                  <p className="text-14">{formatAmount(b.amount)}</p>
                </div>
              </div>
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default AssetListPopUP;
