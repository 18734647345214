import React from "react";

import Button from "../forms/_Button";

type StartTutorialProps = {
  onStart: () => void;
  onEnd: () => void;
};

export default function StartTutorial({ onStart, onEnd }: StartTutorialProps) {
  return (
    <div className="flex flex-col items-center">
      <p className="text-green-900 text-16">
        Learn about all the features we offer. This will take about 2 minutes.
      </p>
      <img
        className="my-10"
        src="/icons/tutorial/start_tutorial.svg"
        alt="start_tutorial"
      />
      <Button
        styling="primary-button"
        normalText
        text="Get started"
        additionalClasses="w-full normal-case font-bold text-14 focus:outline-none"
        onClick={onStart}
      />
      <p className="cursor-pointer text-green-800 mt-2" onClick={onEnd}>
        Maybe Later
      </p>
    </div>
  );
}
