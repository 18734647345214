import React from "react";

type LoaderProps = {
  loaderStyle?: string;
};

export default function Loader({ loaderStyle }: LoaderProps) {
  return (
    <div className="w-full mt-20 flex justify-center">
      <img
        src="/loader.gif"
        alt="loading..."
        className={loaderStyle ? loaderStyle : "w-10 h-10"}
      />
    </div>
  );
}
