import React from "react";

import { useUser } from "../hooks/useUser";
import { Fiat } from "../types";
import { formatAmount, getFiatSign } from "../utils";

type amountProps = {
  amount: number | string;
  fiat_currency?: Fiat;
};

export default function FiatAmount({ amount, fiat_currency }: amountProps) {
  const user = useUser();

  return (
    <span className="flex items-center">
      {getFiatSign(fiat_currency ?? user.fiat_currency)}{" "}
      {formatAmount(amount, 2, 2)}
    </span>
  );
}
