import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";

import { getAnalytics } from "../api";
import AnalyticsSection from "../components/admin/AnalyticsSection";
import UsersSection, {
  UsersSectionFilterButton,
} from "../components/admin/UsersSection";
import PageHeader from "../components/PageHeader";
import ToggleButton from "../components/ToggleButton";
import { AdminTiers, AdminUsersSectionFilterFormProps } from "../types";

export default function AdminPage() {
  const [currentToggleOption, setCurrentToggleOption] = useState<
    "Users" | "Analytics"
  >("Users");
  const [usersListMinDate, setUsersListMinDate] = useState(
    new Date(Date.now()),
  );

  const usersListForm = useForm<AdminUsersSectionFilterFormProps>({
    mode: "onChange",
    defaultValues: {
      start_date: "",
      end_date: "",
      isAdminOnly: false,
      selectedNetworks: [],
      selectedLoginMethods: [],
      selectedPlans: [...AdminTiers],
    },
  });

  function onToggle() {
    setCurrentToggleOption((curr) =>
      curr === "Users" ? "Analytics" : "Users",
    );
  }
  const { data: analyticsUsersStatsData } = useQuery("analytics", () =>
    getAnalytics(),
  );

  return (
    <div>
      <PageHeader
        title=""
        Header={
          <>
            {currentToggleOption === "Users" ? (
              // rendered to always center ToggleButton
              <div className="hidden lg:block invisible">
                <UsersSectionFilterButton
                  form={usersListForm}
                  minDate={usersListMinDate}
                />
              </div>
            ) : (
              <div></div>
            )}
            <ToggleButton
              onToggle={onToggle}
              leftText="Users"
              rightText="Analytics"
              isDefault={currentToggleOption === "Users"}
            />
            {currentToggleOption === "Users" ? (
              <UsersSectionFilterButton
                form={usersListForm}
                minDate={usersListMinDate}
              />
            ) : (
              <div></div>
            )}
          </>
        }
      />
      {currentToggleOption === "Users" ? (
        <UsersSection
          setUsersMinDate={setUsersListMinDate}
          form={usersListForm}
        />
      ) : (
        <AnalyticsSection usersStatsData={analyticsUsersStatsData} />
      )}
    </div>
  );
}
