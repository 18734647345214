import Big from "big.js";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

import { getTotalWallets } from "../../api";
import { ThemeContext } from "../../App";
import { capitalize, formatAmount } from "../../utils";

type PieData = {
  network: string;
  value: number;
  percent: string;
};

const chartColors = [
  "#1F7B71",
  "#F7931A",
  "#B3E9FE",
  "#00D395",
  "#2F5A55",
  "#54A097",
  "#F8DFD0",
  "#D5E6DE",
];
export default function TotalWallets() {
  const theme = useContext(ThemeContext);

  const { data: totalWallets } = useQuery("totalWallets", () =>
    getTotalWallets(),
  );

  let total = 0;

  if (totalWallets) {
    total = Object.values(totalWallets).reduce(
      (acc: number, curr: number) => acc + curr,
      0,
    );
  }

  let pieArray: PieData[] = [];

  if (totalWallets) {
    pieArray = Object.entries(totalWallets).map(([network, data]) => ({
      network,
      value: data,
      percent: total
        ? Big(data).div(total).times(100).round(2).toString()
        : "0",
    }));
  }

  function customTooltip(props: any) {
    if (!props.payload) {
      return null;
    }
    if (props.active) {
      return (
        <div className="bg-white border-2 border-green-800 rounded-md p-3 dark:bg-gray-800 dark:border-gray-900 dark:shadow-lg">
          <p>
            {props.payload[0].payload.network.replaceAll("_", " ")}:{" "}
            <span className="font-bold">
              {props.payload[0].payload.percent}%
            </span>
          </p>
        </div>
      );
    }
    return null;
  }

  return (
    <section className="w-full mlg:w-1/2 px-8 py-9 mlg:pr-1 lg:pr-9 overview-section">
      <div className="flex sm:flex-row flex-col w-full mt-4 sm:h-60 justify-between">
        <div className="w-60 max-w-full relative">
          <div className="absolute w-40 top-24 left-10 text-green-800 dark:text-green-100">
            <h2 className="text-34 text-center font-bold">
              {formatAmount(total)}
            </h2>
            <p className="text-center">Total Wallets</p>
          </div>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={pieArray}
                innerRadius={80}
                outerRadius={120}
                dataKey="value"
                cy="50%"
                cx="50%"
                blendStroke
                startAngle={90}
                endAngle={-270}
              >
                {pieArray.map(({ network }, id) => (
                  <Cell
                    key={network}
                    fill={chartColors[id % chartColors.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={customTooltip} />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div
          className={`sm:w-1/2 mt-5 flex flex-col space-y-3 p-1 lg:p-3 ${
            theme === "dark" ? "custom-scroll-dark" : "custom-scroll"
          }`}
        >
          {pieArray!.map(({ network, value }, id) => {
            if (+value === 0) return "";
            return (
              <div className="flex items-center w-full" key={network}>
                <div
                  className="w-max h-4 rounded-sm mr-4"
                  style={{
                    backgroundColor: chartColors[id % chartColors.length],
                  }}
                >
                  <div className="w-4"></div>
                </div>
                <h4 className="text-14">{capitalize(network)}</h4>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
