import React, { useContext } from "react";

import { ThemeContext } from "../../App";
import { SuggestionProps } from "../../types";

export default function Suggestion({
  header,
  body,
  onClose,
  onClick,
  isVisible,
  extraClass,
}: SuggestionProps) {
  const theme = useContext(ThemeContext);

  return (
    <div
      className={`bg-white rounded-xl p-8 pt-0 cursor-pointer space-y-4 relative dark:bg-gray-900 dark:text-gray-300 ${
        !isVisible && "hidden"
      } ${extraClass}`}
      onClick={onClick}
    >
      <div
        className="absolute top-0 right-0 m-3"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <img src={`/icons/${theme}/close.svg`} alt="close" />
      </div>
      <h3 className="text-14 font-bold" style={{ minHeight: "2.5rem" }}>
        {header}
      </h3>
      <p className="text-12 font-light">{body}</p>
    </div>
  );
}
