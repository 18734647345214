/* eslint-disable react-hooks/exhaustive-deps */
import { isSameDay } from "date-fns";
import format from "date-fns/format";
import React, { useContext, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";

import {
  getBalances,
  getInvoice,
  getLabels,
  getTransactions,
  getTransactionsLimit,
} from "../api";
import { ThemeContext } from "../App";
import BnFilter from "../components/BnFilter";
import BnTable, { Column } from "../components/BNTable";
import ColoredAmount from "../components/ColoredAmount";
import ContactAddress from "../components/ContactAddress";
import LabelBadge from "../components/labels/LabelBadge";
import LabelsPopup from "../components/labels/LabelsPopup";
import Loader from "../components/Loader";
import { planLimitsMessage } from "../components/navBar/QuickAdd";
import NoWalletInfo from "../components/NoWalletInfo";
import EndOfSubscriptionLightBox from "../components/overview/EndOfSubscriptionLightBox";
import PageHeader from "../components/PageHeader";
import InvoicePdf from "../components/pdfDocs/InvoicePdf";
import Tooltip from "../components/Tooltip";
import Tutorial from "../components/tutorial/Tutorial";
import CheckedItemsContext from "../hooks/CheckedItemsContext";
import useEndTutorial from "../hooks/useEndTutorial";
import useLocalStorage from "../hooks/useLocalStorage";
import { useUser } from "../hooks/useUser";
import {
  DateFilter,
  LabelResponse,
  LocationProps,
  TimePeriod,
  Token,
  Transaction,
  TransactionsParams,
} from "../types";
import { capitalize, getExplorerLink, previewPDF } from "../utils";

export default function TransactionsPage() {
  const SMALL_SCREEN_THRESHOLD = 769;
  const theme = useContext(ThemeContext);
  const user = useUser();
  const location = useLocation<LocationProps>();
  const endTutorial = useEndTutorial();
  const history = useHistory();
  const tutorial = location.state?.tutorial;

  const [tutorialState, setTutorialState] = useState<string>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [selectedTokens, setSelectedTokens] = useState<string[]>([]);
  const [coins, setCoins] = useState<Token[]>([]);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState<LabelResponse[]>([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isSmallScreen = screenWidth < SMALL_SCREEN_THRESHOLD;
  const now = new Date(Date.now());

  // The easiest way to control data.
  // If set to true - tableData will be replaced with new data,
  // if set to false - new data will be added and the end
  const [shouldReplaceData, setShouldReplaceData] = useState(true);
  const [transactionsFilters, setTransactionsFilters] =
    useLocalStorage<DateFilter>("transactions_filters", {
      start_date: "",
      end_date: "",
    });
  const [minDate, setMinDate] = useState(
    new Date(
      Date.parse(transactionsFilters.start_date.toString()) || Date.now(),
    ),
  );

  const { data: balances } = useQuery("balances", getBalances);
  const { data: labels } = useQuery("labels", () => getLabels());

  useEffect(() => {
    if (balances) {
      setCoins(balances.tokens.map((b) => b.token));
    }
  }, [balances]);

  useEffect(() => {
    if (labels) {
      setCheckedItems(labels);
    }
  }, [labels]);

  const { data: lastTransaction } = useQuery(["lastTransaction"], () =>
    getTransactions({
      limit: 1,
      offset: 0,
      ordering: "created_at",
    }),
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (lastTransaction) {
        const newMinDate = new Date(
          lastTransaction.results[0]?.created_at || now,
        );
        if (isSameDay(minDate, timePeriod.start_date)) {
          setTimePeriod((prev) => ({
            ...prev,
            start_date: newMinDate,
          }));
        }
        setMinDate(newMinDate);
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [lastTransaction]);

  const [timePeriod, setTimePeriod] = useState<TimePeriod>({
    start_date: new Date(
      Date.parse(
        lastTransaction?.results[0]?.created_at ||
          transactionsFilters.start_date.toString(),
      ) || now,
    ),
    end_date: new Date(
      Date.parse(transactionsFilters.end_date.toString()) || now,
    ),
  });

  const [queryParams, setQueryParams] = useState<TransactionsParams>({
    limit: 10,
    offset: 0,
    ordering: "-created_at",
    labels: [],
    search: location.state ? location.state.search : "",
  });
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] = useState({
    isOpen: false,
    subject: "transactions",
  });
  const { data: transactionLimits } = useQuery("transactionsLimits", () =>
    getTransactionsLimit(),
  );

  const { data, isLoading, refetch } = useQuery(
    ["transactions", queryParams],
    () => getTransactions(queryParams),
    { refetchInterval: queryParams.offset === 0 ? 10000 : false },
  );

  const form = useForm<FieldValues>({
    mode: "onChange",
    defaultValues: {
      start_date: format(timePeriod.start_date, "yyyy-MM-dd"),
      end_date: format(timePeriod.end_date || now, "yyyy-MM-dd"),
      selectedTokens,
    },
  });

  useEffect(() => {
    if (location?.state?.search) {
      setQueryParams((prevParams) => ({
        ...prevParams,
        created_at_after: "",
        created_at_before: "",
        limit: 10,
        offset: 0,
        search: location.state.search,
      }));
    }
  }, [location]);

  useEffect(() => {
    if (labels)
      setQueryParams((prevParams) => ({
        ...prevParams,
        labels:
          checkedItems.length === labels.length
            ? []
            : checkedItems.length === 0
            ? [-1]
            : checkedItems.map((item) => item.id),
      }));
  }, [checkedItems]);

  useEffect(() => {
    const data = form.getValues();
    // Need some time to clear possible errors in the form
    const timeout = setTimeout(() => {
      if (
        Object.keys(form.formState.errors).length === 0 &&
        Date.parse(data.start_date) &&
        Date.parse(data.end_date)
      ) {
        setTimePeriod({
          start_date: new Date(data.start_date),
          end_date: new Date(data.end_date),
        });
      }
    }, 50);
    setSelectedTokens(data.selectedTokens);

    return () => {
      clearTimeout(timeout);
    };
  }, [JSON.stringify(form.watch())]);

  useEffect(() => {
    if (data) {
      const newRecords = data.results.map(formatTableData);
      if (shouldReplaceData) {
        setTableData(newRecords);
      } else {
        setTableData([...tableData, ...newRecords]);
      }
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (
      transactionLimits &&
      !transactionLimits?.can_create &&
      tableData.length >= Number(transactionLimits.limit)
    ) {
      setIsSubscriptionDialogOpen({ isOpen: true, subject: "transactions" });
    }
  }, [tableData]);

  //refetch after filter options change
  useEffect(() => {
    if (timePeriod.start_date) {
      onFilterChange();
    }
  }, [timePeriod, selectedTokens]);

  //Stroing user filter settings
  useEffect(() => {
    setTransactionsFilters({
      start_date: format(timePeriod.start_date, "yyyy-MM-dd"),
      end_date: format(timePeriod.end_date, "yyyy-MM-dd"),
    });
  }, [timePeriod]);

  useEffect(() => {
    form.setValue(
      "start_date",
      format(
        new Date(
          Date.parse(transactionsFilters.start_date.toString()) ||
            minDate ||
            now,
        ),
        "yyyy-MM-dd",
      ),
    );
    form.setValue(
      "end_date",
      format(
        new Date(Date.parse(transactionsFilters.end_date.toString()) || now),
        "yyyy-MM-dd",
      ),
    );
  }, [transactionsFilters]);

  useEffect(() => {
    if (tutorial) {
      setTutorialState(tutorial);
    }
  }, [tutorial]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function closeTutorialFlow() {
    endTutorial();
    setTutorialState(undefined);
  }

  if (!user.has_wallet) {
    return <NoWalletInfo />;
  }

  //Manage styling and content of the table cells
  function formatTableData(t: Transaction) {
    return {
      created_at: format(new Date(t.created_at), "yyyy-MM-dd"),
      walletName: (
        <div className="flex flex-col space-y-2 w-32 lg:w-max">
          {t.wallet && t.from_address ? (
            <ContactAddress
              address={
                t.direction === "OUTGOING" ? t.from_address : t.to_address
              }
              name={user.full_access ? t.wallet.name : "My Wallet"}
            />
          ) : (
            <ContactAddress address={t.to_address} name={t.cex?.exchange} />
          )}
          <p className="truncate mt-2">
            {t.kind === "AXIE_BREED" ? (
              <div className="flex items-center">
                <p>Breed Axies for </p>
                <img src="/icons/currency/axie.svg" alt="axie" />
                <ContactAddress address={t.nft_id} name="Axie" />
              </div>
            ) : t.kind === "SWAP" ? (
              <div className="flex items-center">
                <p>Interacted with: </p>
                {t.network === "ETHEREUM" ? (
                  <>
                    <img
                      src="/icons/uniswap.svg"
                      className="mx-1 -mt-1"
                      alt="uniswap"
                    />
                    <ContactAddress address={t.to_address.toUpperCase()} />
                  </>
                ) : (
                  <>
                    <img src="/icons/currency/axie.svg" alt="axie" />
                    <ContactAddress
                      address={t.to_address}
                      name="Axie Infinity Marketplace"
                    />
                  </>
                )}
              </div>
            ) : t.direction === "OUTGOING" ? (
              "Payment sent"
            ) : (
              "Payment received"
            )}
          </p>
        </div>
      ),
      gas_price: t.gas_price,
      gas: t.gas,
      fee: t.fee,
      fiat_currency: t.fiat_currency,
      fromTo: {
        label: t.nft_id
          ? "NFT ID:"
          : t.direction === "OUTGOING" || t.cex
          ? "To:"
          : "From:",
        address: t.nft_id
          ? t.nft_id
          : t.direction === "OUTGOING"
          ? t.to_address
          : t.from_address
          ? t.from_address
          : t.cex?.exchange,
        name: t.contact
          ? `${t.contact.first_name} ${t.contact.last_name}`
          : undefined,
      },
      network: t.network,
      nft_url: t.nft_image_url,
      amount: (
        <div>
          {t.kind === "AXIE_BREED" && (
            <>
              <p className="flex items-center">
                <span className="font-bold">
                  <ColoredAmount
                    amount={t.axie_breed_axs_cost}
                    direction="OUTGOING"
                    isCrypto
                  />
                </span>
                <img
                  src="/icons/currency/axs.svg"
                  alt="axs"
                  className="w-3 h-3 ml-1.5 mr-1"
                />
                AXS
              </p>
              <p className="flex items-center">
                <span className="font-bold">
                  <ColoredAmount
                    amount={t.axie_breed_slp_cost}
                    direction="OUTGOING"
                    isCrypto
                  />
                </span>
                <img
                  src="/icons/currency/slp.svg"
                  alt="slp"
                  className="w-3 h-3 ml-1.5 mr-1"
                />
                SLP
              </p>
            </>
          )}
          {t.kind === "SWAP" && (
            <p className="flex items-center">
              <span className="font-bold">
                <ColoredAmount
                  amount={t.swapped_amount}
                  direction="OUTGOING"
                  isCrypto
                />
              </span>
              <img
                src={t.swapped_token?.image_url}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "/icons/logo_small.svg";
                }}
                alt="swapped token"
                className="w-3 h-3 ml-1.5 mr-1"
              />
              {t.swapped_token?.symbol}
            </p>
          )}
          <p className="flex items-center">
            <span className="font-bold">
              <ColoredAmount
                amount={t.amount}
                direction={t.direction}
                isCrypto
              />
            </span>
            <img
              src={t.token.image_url}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/icons/logo_small.svg";
              }}
              alt="token"
              className="w-3 h-3 ml-1.5 mr-1"
            />
            {t.token.symbol}
          </p>
          {t.kind === "TRANSFER" && (
            <p className="forceGray">
              <ColoredAmount
                amount={t.value_fiat}
                direction={t.direction}
                fiat_currency={t.fiat_currency}
              />
            </p>
          )}
        </div>
      ),
      labels: !isSmallScreen && (
        <>
          {t.transc_label && (
            <div
              className="mb-2 inline-block px-1.5 mt-1 mr-2 py-0.5 rounded-sm bg-blue-100 cursor-pointer"
              onClick={() => previewInvoice(t.transc_label!.invoice)}
            >
              {t.transc_label.name}
            </div>
          )}
          {t.kind !== "TRANSFER" && (
            <div className="flex flex-wrap">
              <LabelBadge
                label={{
                  name: capitalize(t.kind),
                  color: t.kind === "SWAP" ? "BLUE" : "PINK",
                }}
              />
            </div>
          )}

          <LabelsPopup
            chosenLabels={t.labels}
            transactionId={t.id}
            onLabelChange={onTransactionEdit}
          >
            <div className="flex flex-wrap">
              {t.labels.length > 0 ? (
                t.labels.map((label: LabelResponse) => (
                  <LabelBadge key={label.id} label={label} />
                ))
              ) : (
                <p className="p-2 text-gray-800 dark:text-gray-300">
                  <span className="text-gray-450">+</span> Add labels
                </p>
              )}
            </div>
          </LabelsPopup>
        </>
      ),
      action: !isSmallScreen && (
        <>
          <Tooltip />
          <a
            href={getExplorerLink(t.network, t.tx_hash)}
            target="_blank"
            rel="noreferrer"
            data-tip="View On Explorer"
            className="mx-auto"
          >
            <img
              src={`/icons/${theme}/actions/export_documents.svg`}
              alt="etherscan"
              className="ml-3"
            />
          </a>
        </>
      ),
      expand: !isSmallScreen && (
        <img
          src={`/icons/${theme}/arrows/expand_down_light.svg`}
          alt="expand"
          className="mx-auto"
        />
      ),
    };
  }

  let columns: Column[] = [
    {
      header: "Date",
      accessor: "created_at",
      sortable: true,
      width: "15%",
    },
    {
      header: "Wallet Name",
      accessor: "walletName",
      width: "33%",
    },
    {
      header: "Amount",
      accessor: "amount",
      sortable: true,
      width: "20%",
    },
    ...(!isSmallScreen
      ? [
          {
            header: "Labels",
            accessor: "labels",
            width: "17%",
            filterable: !!labels?.length,
          },
          {
            header: "Explorer",
            accessor: "action",
            width: "12%",
          },
          {
            header: "",
            accessor: "expand",
            width: "3%",
          },
        ]
      : []),
  ];
  if (!user.full_access) {
    columns = columns.filter((c) => c.accessor !== "labels");
  }

  function getMoreData() {
    if (tableData.length !== 0) {
      setShouldReplaceData(false);
      setQueryParams((prevParams) => ({
        ...prevParams,
        limit: 10,
        offset:
          prevParams.limit === 10 ? prevParams.offset! + 10 : prevParams.limit,
      }));
    }
  }

  function onColumnClick(col: Column) {
    if (col.sortable) {
      setShouldReplaceData(true);
      setQueryParams((prevParams) => ({
        ...prevParams,
        limit: 10,
        offset: 0,
        ordering:
          queryParams.ordering === col.accessor
            ? `-${col.accessor}`
            : col.accessor,
      }));
    }
  }

  function onFilterChange() {
    setShouldReplaceData(true);
    setQueryParams((prevParams) => ({
      ...prevParams,
      limit: 10,
      offset: 0,
      created_at_after: format(timePeriod.start_date!, "yyyy-MM-dd"),
      created_at_before: format(timePeriod.end_date, "yyyy-MM-dd"),
      token__in: selectedTokens.join(","),
    }));
  }

  function onTransactionEdit() {
    setShouldReplaceData(true);
    setQueryParams((prevParams) => ({
      ...prevParams,
      limit:
        prevParams.limit === 10 ? prevParams.offset! + 10 : prevParams.limit,
      offset: 0,
    }));
    refetch();
  }

  async function previewInvoice(invoice: string) {
    setInvoiceLoading(true);
    const res = await getInvoice(invoice);
    if (res) {
      await previewPDF(<InvoicePdf invoice={res} />);
    }
    setInvoiceLoading(false);
  }

  return (
    <CheckedItemsContext.Provider
      value={{ checkedItems, setCheckedItems, allItems: labels }}
    >
      <EndOfSubscriptionLightBox
        dialogText={
          planLimitsMessage(
            isSubscriptionDialogOpen.subject,
            transactionLimits?.limit || 0,
          ) || ""
        }
        isSubscriptionDialogOpen={
          isSubscriptionDialogOpen.isOpen && !tutorialState
        }
        onCloseSubscriptionDialog={() =>
          setIsSubscriptionDialogOpen({
            isOpen: false,
            subject: "transactions",
          })
        }
      />
      <div>
        <PageHeader
          title="Transactions"
          Header={
            <BnFilter
              form={form}
              minDate={minDate}
              tokens={coins}
              customReset={() => {
                labels && setCheckedItems(labels);
                form.setValue("selectedTokens", []);
              }}
              customIsResetNeeded={() =>
                (labels && checkedItems.length < labels?.length) ||
                form.watch("selectedTokens").length !== 0
              }
            />
          }
        />
        {invoiceLoading && (
          <div className="absolute -mt-16 left-0 right-0 z-50">
            <Loader />
          </div>
        )}

        <div className="bg-white rounded-20px p-6 mlg:p-12 w-full overflow-auto dark:bg-gray-900">
          <InfiniteScroll
            dataLength={tableData.length}
            next={getMoreData}
            hasMore={!!data?.next}
            loader={<p className="text-center text-12">Loading...</p>}
          >
            <BnTable
              columns={columns}
              data={tableData}
              noDataMessage={
                isLoading ||
                (data && data.results.length > 0) ||
                (data && !data!.user_tx_sync) ? (
                  <Loader />
                ) : (
                  "No Activity Yet"
                )
              }
              onColumnClick={onColumnClick}
              orderedBy={queryParams.ordering}
            />
          </InfiniteScroll>
        </div>
      </div>
      <Tutorial
        title="View all your transactions in one page"
        desc="    On the Transaction page, you can see a list of all the transactions that came into/gone out of your wallets. Click on 'Add Label' to categorize your transactions for easier reporting."
        isOpen={!!tutorialState && !user.is_tutorial_viewed}
        image={tutorialState}
        currentTutorial={4}
        maxTutorial={7}
        onClose={closeTutorialFlow}
        nextTutorial={() =>
          history.push("/contacts", { tutorial: "contact_tutorial" })
        }
        prevTutorial={() =>
          history.push("/overview", { tutorial: "overview_tutorial" })
        }
        positionStyle="absolute top-24 left-28 xl:left-72"
        pointer={{
          className: "absolute left-14 -top-14",
          style: {
            borderBottom: "30px solid white",
            borderRight: "30px solid transparent",
            borderTop: "30px solid transparent",
            borderLeft: "30px solid transparent",
          },
        }}
      />
    </CheckedItemsContext.Provider>
  );
}
