import { isSameDay } from "date-fns";
import format from "date-fns/format";
import React, { useContext, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQuery } from "react-query";

import {
  addAsAdmin,
  getAdminUsers,
  getExportUserReportAsCsvUrl,
  getExportUserReportAsPdfUrl,
  removeAdminAccess,
} from "../../api";
import { ThemeContext } from "../../App";
import { useUser } from "../../hooks/useUser";
import {
  Action,
  AdminTier,
  AdminTiers,
  AdminUser,
  AdminUsersParams,
  AdminUsersSectionFilterFormProps,
  AvailableNetworks,
  LoginMethods,
  SetUseState,
  Tiers,
  TimePeriod,
} from "../../types";
import { capitalize, downloadFileByHref } from "../../utils";
import ActionPopover from "../ActionPopover";
import BnFilter from "../BnFilter";
import BnTable, { Column } from "../BNTable";
import Loader from "../Loader";
import Tooltip from "../Tooltip";

type UsersSectionParams = {
  setUsersMinDate: SetUseState<Date>;
  form: UseFormReturn<AdminUsersSectionFilterFormProps>;
};

export default function UsersSection({
  setUsersMinDate,
  form,
}: UsersSectionParams) {
  const theme = useContext(ThemeContext);
  const user = useUser();
  const [tableData, setTableData] = useState<any[]>([]);
  const [shouldReplaceData, setShouldReplaceData] = useState(true);

  const [queryParams, setQueryParams] = useState<AdminUsersParams>({
    limit: 10,
    offset: 0,
    ordering: "-created_at",
  });

  const { data, isLoading } = useQuery(["admin_users", queryParams], () =>
    getAdminUsers(queryParams),
  );

  const { data: firstUser } = useQuery("first_admin_user", () =>
    getAdminUsers({
      limit: 1,
      offset: 0,
      ordering: "created_at",
    }),
  );

  const now = new Date(Date.now());
  //minimum date used in date picker
  const [minDate, setMinDate] = useState(
    new Date(firstUser?.results[0].created_at || Date.now() - 1),
  );

  const [timePeriod, setTimePeriod] = useState<TimePeriod>({
    start_date: minDate,
    end_date: now,
  });

  useEffect(() => {
    setUsersMinDate(minDate);
  }, [minDate, setUsersMinDate]);

  useEffect(() => {
    form.setValue("start_date", format(timePeriod.start_date, "yyyy-MM-dd"));
    form.setValue("end_date", format(timePeriod.end_date, "yyyy-MM-dd"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timePeriod]);

  useEffect(() => {
    if (firstUser) {
      if (isSameDay(new Date(firstUser.results[0].created_at), minDate)) return;
      const newMinDate = new Date(firstUser.results[0].created_at);
      if (isSameDay(timePeriod.start_date, minDate)) {
        setTimePeriod((prev) => ({ ...prev, start_date: newMinDate }));
      }
      setMinDate(newMinDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstUser]);

  useEffect(() => {
    const data = form.getValues();
    if (
      Object.keys(form.formState.errors).length === 0 &&
      Date.parse(data.start_date) &&
      Date.parse(data.end_date)
    ) {
      setTimePeriod({
        start_date: new Date(data.start_date),
        end_date: new Date(data.end_date),
      });
    }

    setQueryParams((curr) => ({
      ...curr,
      limit: 10,
      offset: 0,
      blockchain__in: data.selectedNetworks.join(","),
      plan__in: data.selectedPlans.join(","),
      kind__in: data.selectedLoginMethods.join(","),
      is_staff: data.isAdminOnly || undefined,
      created_at_after: data.start_date,
      created_at_before: data.end_date,
    }));
    setShouldReplaceData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(form.watch())]);

  useEffect(() => {
    if (data) {
      const newRecords = data.results.map(formatTableData);
      if (shouldReplaceData) {
        setTableData(newRecords);
      } else {
        setTableData((prev) =>
          prev.length + newRecords.length <= data.count
            ? [...prev, ...newRecords]
            : prev,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  function getPlanColor(plan: AdminTier) {
    switch (plan) {
      case "STARTER":
        return "bg-gray-450";
      case "ADMIN":
      case "SUPER_ADMIN":
        return "bg-yellow-50";
      default:
        return "bg-green-300";
    }
  }

  const columns: Column[] = [
    {
      header: "Date Joined",
      accessor: "created_at",
      sortable: true,
      width: "15%",
    },
    {
      header: "Login",
      accessor: "userKind",
      width: "10%",
    },
    {
      header: "User ID",
      accessor: "id",
      sortable: true,
      width: "15%",
    },
    {
      header: "Address",
      accessor: "address",
      width: "22%",
    },
    {
      header: "# of Wallets",
      accessor: "wallets",
      width: "7%",
    },
    {
      header: "Blockchain",
      accessor: "blockchain",
      width: "13%",
    },
    {
      header: "Plan",
      accessor: "plan",
      width: "15%",
    },
    {
      header: "Action",
      accessor: "action",
      width: "3%",
    },
  ];

  function formatTableData(user: AdminUser) {
    const networks = Array.from(new Set(user.wallets.map((w) => w.network)));
    return {
      created_at: format(new Date(user.created_at), "yyyy-MM-dd"),
      userKind: (
        <img
          src={`/icons/wallets/${user.kind.toLowerCase()}.svg`}
          alt="wallet"
        />
      ),
      id: user.id,
      address: user.kind === "GOOGLE_LOGIN" ? user.email : user.primary_address,
      wallets: user.wallets.length,
      blockchain: (
        <div className="flex items-center flex-reverse">
          {networks.map((network, i) => (
            <img
              key={network}
              src={`/icons/network/${network.toLowerCase()}.svg`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/icons/logo_small.svg";
              }}
              className="h-5 mr-0.5"
              style={{
                transform: `translateX(-${0.4 * i}rem)`,
              }}
              alt={network}
            />
          ))}
        </div>
      ),
      plan: (
        <div
          className={`${getPlanColor(
            user.plan,
          )} capitalize text-white text-12 rounded-sm px-3 py-1 w-min`}
        >
          {capitalize(user.plan)}
        </div>
      ),
      action: <ActionPopover actions={getActions(user.id, user.plan)} />,
    };
  }

  function getActions(id: number, plan: AdminTier): Action[] {
    const actions = [
      {
        icon: "admin/cancel",
        contentText: "Blacklist user",
        clickAction: () => {},
      },
    ];
    if (Tiers.includes(plan as any)) {
      actions.push({
        icon: "admin/add_user",
        contentText: "Add as admin",
        clickAction: async () => {
          await addAsAdmin(id);
          updateUserPlan(id, "ADMIN");
        },
      });
    }
    if (user.is_superuser && plan === "ADMIN")
      actions.push({
        icon: "admin/remove",
        contentText: "Remove admin access",
        clickAction: async () => {
          plan = (await removeAdminAccess(id)).plan;
          updateUserPlan(id, plan);
        },
      });
    return actions;
  }

  function updateUserPlan(id: number, plan: AdminTier) {
    const newData =
      data?.results.map((user) => {
        if (user.id === id) {
          user.plan = plan;
        }
        return user;
      }) || [];
    setTableData(newData.map(formatTableData));
  }

  function getMoreData() {
    if (data?.results.length !== 0) {
      setShouldReplaceData(false);
      setQueryParams((prevParams) => ({
        ...prevParams,
        limit: 10,
        offset:
          prevParams.limit === 10 ? prevParams.offset! + 10 : prevParams.limit,
      }));
    }
  }

  function onColumnClick(col: Column) {
    if (col.sortable) {
      setShouldReplaceData(true);
      setQueryParams((prevParams) => ({
        ...prevParams,
        limit: 10,
        offset: 0,
        ordering:
          col.accessor === "end_date"
            ? prevParams.ordering === "-invoice__expires_at"
              ? "invoice__expires_at"
              : "-invoice__expires_at"
            : prevParams.ordering === col.accessor
            ? `-${col.accessor}`
            : col.accessor,
      }));
    }
  }

  function getExportActions() {
    return [
      {
        icon: "documents/export_as_pdf",
        contentText: "Export as PDF",
        clickAction: () => {
          const href = getExportUserReportAsPdfUrl(queryParams);
          downloadFileByHref("Users report", href);
        },
      },
      {
        icon: "documents/export_as_csv",
        contentText: "Export as CSV",
        clickAction: () => {
          const href = getExportUserReportAsCsvUrl(queryParams);
          downloadFileByHref("Users report", href);
        },
      },
    ];
  }

  return (
    <div className="bg-white rounded-20px p-6 mlg:p-12 w-full overflow-auto dark:bg-gray-900">
      <div className="flex mx-5 mb-5 space-x-5">
        <Tooltip place="right" />

        <div data-tip="Export report">
          <ActionPopover
            actions={getExportActions()}
            src={`/icons/${theme}/actions/export_documents.svg`}
            direction="left"
          />
        </div>
      </div>
      <InfiniteScroll
        dataLength={tableData.length}
        next={getMoreData}
        hasMore={!!data?.next}
        loader={<p className="text-center text-12">Loading...</p>}
      >
        <BnTable
          columns={columns}
          data={tableData}
          noDataMessage={
            isLoading || (data && data.results.length > 0) ? (
              <Loader />
            ) : (
              "No Activity Yet"
            )
          }
          onColumnClick={onColumnClick}
          orderedBy={queryParams.ordering}
        />
      </InfiniteScroll>
    </div>
  );
}

export function UsersSectionFilterButton({
  form,
  minDate,
}: {
  form: UseFormReturn<AdminUsersSectionFilterFormProps>;
  minDate: Date;
}) {
  return (
    <BnFilter
      title="Date Joined"
      form={form}
      minDate={minDate}
      networks={[...AvailableNetworks]}
      loginMethods={[...LoginMethods]}
      userPlans={[...AdminTiers]}
      isAdminOnly={form.getValues("isAdminOnly")}
      customIsResetNeeded={() =>
        !!form.getValues("selectedLoginMethods").length ||
        !!form.getValues("selectedNetworks").length ||
        form.getValues("selectedPlans").length !== AdminTiers.length ||
        form.getValues("isAdminOnly")
      }
      customReset={() => {
        form.setValue("selectedLoginMethods", []);
        form.setValue("selectedNetworks", []);
        form.setValue("selectedPlans", [...AdminTiers]);
        form.setValue("isAdminOnly", false);
      }}
    />
  );
}
