import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { getTransactions } from "../../api";
import { ThemeContext } from "../../App";
import { formatAmount } from "../../utils";
import ColoredAmount from "../ColoredAmount";
import Loader from "../Loader";

export default function LastActivity() {
  const theme = useContext(ThemeContext);

  const history = useHistory();
  //redirect to transactions screen
  const clickHandler = (e: React.MouseEvent<HTMLElement>) => {
    history.push("/transactions");
  };

  const arrowDown = (
    <img src={`/icons/${theme}/arrows/arrow_down.svg`} alt="down" />
  );
  const arrowRight = (
    <img src={`/icons/${theme}/arrows/arrow_right.svg`} alt="right" />
  );

  const { data, isLoading } = useQuery(
    "last-transactions",
    () => getTransactions({ limit: 3 }),
    { refetchInterval: 10000 },
  );

  return (
    <section
      className="w-full mlg:w-1/2 px-5 py-8 overview-section cursor-pointer"
      onClick={clickHandler}
    >
      <h2 className="overview-h2 pl-4 mb-5">Last Activity</h2>
      {isLoading || !data!.user_tx_sync ? (
        <Loader />
      ) : (
        <div className="w-full">
          {data!.results.map(
            (
              {
                amount,
                value_fiat,
                direction,
                created_at,
                from_address,
                to_address,
                contact,
                token,
              },
              id,
            ) => {
              const isOutgoing = direction === "OUTGOING";
              return (
                <div key={id}>
                  <div className="h-px bg-gray-450-t-50"></div>
                  <div className="flex py-7 px-7 gap-x-10">
                    <div className="sm:block hidden flex justify-center w-10 my-auto">
                      {isOutgoing ? arrowRight : arrowDown}
                    </div>
                    <div className="w-full">
                      <div className="last-activity-row">
                        <h3 className="md:text-20 text-16 mb-2 whitespace-nowrap w-32 2xl:w-56 truncate">
                          {contact
                            ? `${contact.first_name} ${contact.last_name}`
                            : isOutgoing
                            ? to_address
                            : from_address}
                        </h3>
                        <h3 className="md:text-20 text-16 truncate text-right mb-2">
                          <ColoredAmount
                            amount={value_fiat}
                            direction={direction}
                          />
                        </h3>
                      </div>
                      <div className="last-activity-row">
                        <p className="text-13 capitalize">
                          {isOutgoing ? "Sent" : "Received"}{" "}
                          {created_at.substring(0, 10)}
                        </p>
                        <div className="text-14 uppercase flex items-center space-x-1.5">
                          <img
                            className="w-5"
                            src={token.image_url}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = "/icons/logo_small.svg";
                            }}
                            alt={token.symbol}
                          />
                          <p>{token.symbol}</p>
                          <p className="ml-1">{formatAmount(amount)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            },
          )}
        </div>
      )}
    </section>
  );
}
