import { format } from "date-fns";
import { useContext, useEffect, useState } from "react";

import { UserContext } from "../App";
import { User } from "../types";
import { disconnectWalletConnect } from "../walletConnect";

const USER_STORAGE_KEY = "user";

export function useUser() {
  return useContext(UserContext).user;
}

export type UserContextData = {
  user: User | null;
  setUser: (user: User | null) => void;
};

export function useCachedUser(): UserContextData {
  // Should be used only once in the app.

  const cachedUser = getUserFromCache();

  const [user, setUser] = useState<User | null>(cachedUser);

  if (cachedUser !== user) {
    saveUserToCache(user);
  }

  useEffect(() => {
    onFirstDayActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    window.addEventListener("Basenode:logout", () => {
      setUser(null);
      window.localStorage.removeItem("transactions_filters");
      window.localStorage.removeItem("documents_filters");
      window.localStorage.removeItem("admin_users_filters");
      disconnectWalletConnect();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { user, setUser };
}

function getUserFromCache(): any {
  const cachedData = localStorage.getItem(USER_STORAGE_KEY);
  if (cachedData) {
    try {
      return JSON.parse(cachedData);
      // eslint-disable-next-line no-empty
    } catch {}
  }
  return null;
}

function saveUserToCache(user: User | null) {
  if (user) {
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
  } else {
    localStorage.removeItem(USER_STORAGE_KEY);
  }
}

function onFirstDayActivity() {
  const today = format(new Date(Date.now()), "yyyy-MM-dd");
  const lastActivity = format(
    new Date(window.localStorage.getItem("last_activity") || Date.now()),
    "yyyy-MM-dd",
  );
  if (lastActivity !== today) {
    const transactionsFilters = JSON.parse(
      window.localStorage.getItem("transactions_filters") || "{}",
    );
    const documentsFilters = JSON.parse(
      window.localStorage.getItem("documents_filters") || "{}",
    );

    // update transaction && document filters if user login for the first time that day
    if (
      !!transactionsFilters?.end_date &&
      lastActivity === transactionsFilters.end_date
    ) {
      window.localStorage.setItem(
        "transactions_filters",
        JSON.stringify({ ...transactionsFilters, end_date: today }),
      );
    }
    if (
      !!documentsFilters?.end_date &&
      lastActivity === documentsFilters.end_date
    ) {
      window.localStorage.setItem(
        "documents_filters",
        JSON.stringify({ ...documentsFilters, end_date: today }),
      );
    }
  }
  window.localStorage.setItem("last_activity", today);
}
