import React from "react";

import { Fiat } from "../types";
import formatAmount from "../utils/formatAmount";
import FiatAmount from "./FiatAmount";

type amountProps = {
  amount: number | string;
  direction: "INCOMING" | "OUTGOING";
  isCrypto?: boolean;
  fiat_currency?: Fiat;
  isMonthly?: boolean;
};

export default function ColoredAmount({
  amount,
  direction,
  isCrypto,
  fiat_currency,
  isMonthly = false,
}: amountProps) {
  return (
    <span
      className={`flex space-x-1  ${
        direction === "INCOMING"
          ? `text-green-300 ${
              isMonthly ? "dark:text-gray-300" : "dark:text-green-300"
            }`
          : "text-red-500"
      }

      `}
    >
      <span>{direction === "INCOMING" ? "+" : "-"}</span>
      {isCrypto ? (
        formatAmount(amount)
      ) : (
        <FiatAmount amount={amount} fiat_currency={fiat_currency} />
      )}
      <span>{isMonthly && "this month"}</span>
    </span>
  );
}
