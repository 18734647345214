import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import {
  deleteCex,
  deleteWallet,
  getCex,
  getInvoiceLimit,
  getTransactions,
  getWalletLimit,
  getWallets,
} from "../api";
import { UserContext } from "../App";
import ActionPopover from "../components/ActionPopover";
import BnDialog from "../components/BnDialog";
import ColoredAmount from "../components/ColoredAmount";
import FiatAmount from "../components/FiatAmount";
import DocumentForm from "../components/forms/DocumentForm";
import WalletForm from "../components/forms/WalletForm";
import Loader from "../components/Loader";
import { planLimitsMessage } from "../components/navBar/QuickAdd";
import NoWalletInfo from "../components/NoWalletInfo";
import EndOfSubscriptionLightBox from "../components/overview/EndOfSubscriptionLightBox";
import PageHeader from "../components/PageHeader";
import { showSnackbar } from "../components/Snackbar";
import Tooltip from "../components/Tooltip";
import Tutorial from "../components/tutorial/Tutorial";
import useEndTutorial from "../hooks/useEndTutorial";
import {
  CexResponse,
  LocationProps,
  Token,
  WalletResponse,
  WalletsParams,
} from "../types";
import { capitalize, formatAmount } from "../utils";

export default function WalletsPage() {
  const queryClient = useQueryClient();
  const endTutorial = useEndTutorial();
  const location = useLocation<LocationProps>();
  const tutorial = location.state?.tutorial;
  const history = useHistory();
  const [queryParams, setQueryParams] = useState<WalletsParams>({
    ordering: "name",
    search: location.state ? location.state.search : "",
  });

  const { user, setUser } = useContext(UserContext);
  const [isWalletDialogOpen, setIsWalletDialogOpen] = useState(false);
  const [isRemoveWalletDialogOpen, setIsRemoveWalletDialogOpen] =
    useState(false);
  const [isWalletDescriptionOpen, setIsWalletDescriptionOpen] = useState(false);
  const [walletForReport, setWalletForReport] = useState<number | null>(null);
  const [walletToRemove, setWalletToRemove] = useState<WalletResponse | null>(
    null,
  );
  const [walletToView, setWalletToView] = useState<
    WalletResponse | CexResponse | null
  >(null);
  const [onEdit, setOnEdit] = useState<WalletResponse | undefined>(undefined);
  const [walletFetching, setWalletFetching] = useState(false);
  const [wallets, setWallets] = useState<WalletResponse[]>([]);
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] = useState({
    isOpen: false,
    subject: "invoices",
  });
  const [tutorialState, setTutorialState] = useState<string>();

  const { data: invoicesLimits } = useQuery("invoiceLimits", () =>
    getInvoiceLimit(),
  );
  const { data: walletLimits } = useQuery("walletLimits", () =>
    getWalletLimit(),
  );
  const [cexes, setCexes] = useState<CexResponse[]>([]);
  const { data, isLoading, refetch } = useQuery(
    ["wallets", queryParams],
    () => getWallets(queryParams),
    {
      enabled: user.full_access,
      refetchInterval: wallets.some((w) => !w.is_synchronized) ? 10000 : false,
    },
  );

  const {
    data: cex,
    refetch: refetchCex,
    isLoading: isLoadingCex,
  } = useQuery("cex", () => getCex(), {
    enabled: user.full_access,
  });

  const { mutate: removeWallet } = useMutation(
    (id: number) => deleteWallet(id),
    {
      onSuccess: () => onDeleteWallet(),
      onError: (error: any) => {
        const msgs: string[] = error?.response?.data?.non_field_errors ?? [];
        if (msgs.length > 0) {
          showSnackbar(msgs.join(" "));
        }
      },
    },
  );

  const { mutate: removeCex } = useMutation((id: number) => deleteCex(id), {
    onSuccess: () => onDeleteCex(),
    onError: (error: any) => {
      const msgs: string[] = error?.response?.data?.non_field_errors ?? [];
      if (msgs.length > 0) {
        showSnackbar(msgs.join(" "));
      }
    },
  });

  const { data: lastTransaction } = useQuery(["lastTransaction"], () =>
    getTransactions({
      limit: 1,
      offset: 0,
      ordering: "created_at",
    }),
  );

  useEffect(() => {
    if (location?.state?.search) {
      setQueryParams((prevParams) => ({
        ...prevParams,
        search: location.state.search,
      }));
    }
  }, [location]);

  useEffect(() => {
    if (cex) {
      setCexes(cex);
    }
  }, [cex]);

  useEffect(() => {
    if (data) {
      setWallets(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // TODO: Adding user to the dependency array causes the page to re-render infinitely...
  useEffect(() => {
    if (isLoading || isLoadingCex) return;

    // check if user has no wallets to keep view updated properly
    if (wallets.length + cexes.length === 0 && user.full_access) {
      setUser({ ...user, has_wallet: false });
    } else if (!user.has_wallet && user.full_access) {
      setUser({ ...user, has_wallet: true });
    }
  }, [wallets, cexes, user, setUser, isLoading, isLoadingCex]);

  useEffect(() => {
    if (tutorial) {
      setTutorialState(tutorial);
    }
  }, [tutorial]);

  if (!user.full_access) {
    return (
      <div>
        <PageHeader title="Wallets" />
        <div className="w-full mt-8 text-center font-bold text-green-800 dark:text-gray-300">
          This feature is available only for logged in users.
        </div>
      </div>
    );
  }
  if (!user.has_wallet) {
    return <NoWalletInfo />;
  }

  function onDialogClose() {
    setIsWalletDialogOpen(false);
    setTimeout(() => {}, 300); //Wait for dialog to close
  }

  function onRemoveWalletDialogClose() {
    setIsRemoveWalletDialogOpen(false);
    setWalletToRemove(null);
    setTimeout(() => {}, 300); //Wait for dialog to close
  }

  function onViewWalletDialogClose() {
    setIsWalletDescriptionOpen(false);
    setWalletToView(null);
    setTimeout(() => {}, 300);
  }

  function onWalletSubmittion(type: string) {
    if (onEdit) {
      onDialogClose();
      refetch();
      return;
    }
    setWalletFetching(true);
    onDialogClose();
    if (type === "wallet") {
      refetch();
    } else if (type === "cex") {
      refetchCex();
    }
    setTimeout(() => {
      queryClient.resetQueries();
      setWalletFetching(false);
      window.dispatchEvent(new CustomEvent("resetFilters"));
    }, 2000); //Give backend some time to fetch data
  }

  function onDeleteWallet() {
    refetch();
    queryClient.resetQueries();
    showSnackbar("Wallet Deleted Successfully!");
  }

  function onDeleteCex() {
    refetchCex();
    queryClient.resetQueries();
    showSnackbar("Exchange Deleted Successfully!");
  }

  function onReportSubmittion() {
    setWalletForReport(null);
    history.push("/documents");
  }

  function handleWalletDescription(wallet: WalletResponse | CexResponse) {
    setWalletToView(wallet);
    setIsWalletDescriptionOpen(true);
  }

  function closeTutorialFlow() {
    endTutorial();
    setTutorialState(undefined);
  }

  function activateNextTutorialFlow(flow: number) {
    setTutorialState(undefined);
    setTimeout(() => {
      setTutorialState(`wallet_tutorial_${flow}`);
    }, 300); // wait for modal to close...
  }

  return (
    <div>
      <EndOfSubscriptionLightBox
        dialogText={
          planLimitsMessage(
            isSubscriptionDialogOpen.subject,
            isSubscriptionDialogOpen.subject === "invoices"
              ? invoicesLimits?.limit || 0
              : walletLimits?.limit || 0,
          ) || ""
        }
        isSubscriptionDialogOpen={isSubscriptionDialogOpen.isOpen}
        onCloseSubscriptionDialog={() =>
          setIsSubscriptionDialogOpen({ isOpen: false, subject: "invoices" })
        }
      />
      <div className="mx-2">
        <PageHeader
          title="Wallets"
          headerButtonProps={{
            iconName: "add_plus",
            text: "Add Wallet",
            onClick: () => {
              if (data && walletLimits?.can_create) {
                setOnEdit(undefined);
                setIsWalletDialogOpen(true);
              } else {
                setIsSubscriptionDialogOpen({
                  isOpen: true,
                  subject: "wallets",
                });
              }
            },
          }}
        />
      </div>
      {walletFetching && !tutorialState && (
        <div className="absolute mt-10 left-0 right-0 z-50">
          <Loader />
        </div>
      )}
      {isLoading && !wallets.length && !tutorialState && (
        <div className="absolute mt-10 left-0 right-0 z-50">
          <Loader />
        </div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 cursor-pointer">
        {wallets.map((wallet) => (
          <div
            className="bg-white rounded-20px p-8 m-2 relative dark:bg-gray-900 dark:text-gray-300"
            key={wallet.id}
            onClick={() => handleWalletDescription(wallet)}
          >
            <div
              className="absolute top-0 right-0 m-3"
              onClick={(e) => e.stopPropagation()}
            >
              <ActionPopover
                actions={[
                  {
                    icon: "edit",
                    contentText: "edit wallet",
                    clickAction: () => {
                      setOnEdit(wallet);
                      setIsWalletDialogOpen(true);
                    },
                  },
                  {
                    icon: "create_invoice",
                    contentText: "create invoice",
                    clickAction: () => {
                      invoicesLimits?.can_create
                        ? history.push("/new-invoice", {
                            invoice: { wallet },
                          })
                        : setIsSubscriptionDialogOpen({
                            isOpen: true,
                            subject: "invoices",
                          });
                    },
                  },
                  {
                    icon: "create_report",
                    contentText: "create report",
                    clickAction: () => {
                      setWalletForReport(wallet.id);
                    },
                  },
                  {
                    icon: "trash_documents",
                    contentText: "Delete",
                    clickAction: () => {
                      if (wallet.wallet_documents.length) {
                        setWalletToRemove(wallet);
                        setIsRemoveWalletDialogOpen(true);
                      } else removeWallet(wallet.id);
                    },
                  },
                ]}
              />
            </div>
            <img
              src={`/icons/network/${wallet.network.toLowerCase()}.svg`}
              className="w-12 mb-10"
              alt={`${capitalize(wallet.network)} icon`}
            />
            <h3 className="mb-2 font-bold">{wallet.name}</h3>
            {wallet.is_synchronized ? (
              <>
                <FiatAmount amount={wallet.total_balance} />
                {wallet.tokens.length ? (
                  <div className="flex mt-2">
                    <p className="mr-4">Tokens</p>
                    <TokensCell tokens={wallet.tokens} overlap disableClick />
                  </div>
                ) : null}
              </>
            ) : (
              <div className="text-14 font-semibold text-green-300 flex items-center gap-x-2 mt-10">
                <div className="dots-spinner" />
                <p className="loading-dots">Loading wallet transactions</p>
              </div>
            )}
          </div>
        ))}
        {cexes.map((cex) => (
          <div
            className="bg-white rounded-20px p-8 m-2 relative dark:bg-gray-900 dark:text-gray-300"
            key={cex.id}
            onClick={() => handleWalletDescription(cex)}
          >
            <div
              className="absolute top-0 right-0 m-3"
              onClick={(e) => e.stopPropagation()}
            >
              <ActionPopover
                actions={[
                  {
                    icon: "create_report",
                    contentText: "create report",
                    clickAction: () => {
                      setWalletForReport(cex.id);
                    },
                  },
                  {
                    icon: "trash_documents",
                    contentText: "Delete",
                    clickAction: () => {
                      removeCex(cex.id);
                    },
                  },
                ]}
              />
            </div>
            <img
              // TODO: Get a better image...
              src={`/icons/cex/${cex.exchange.toLowerCase()}.svg`}
              className="h-12 mb-10"
              alt={`${capitalize(cex.exchange)} icon`}
            />
            <h3 className="mb-2 font-bold">{cex.exchange}</h3>
            <FiatAmount amount={cex.total_balance} />
            {cex.tokens.length ? (
              <div className="flex mt-2">
                <p className="mr-4">Tokens</p>
                <TokensCell tokens={cex.tokens} overlap disableClick />
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <BnDialog
        isOpen={isWalletDialogOpen}
        title={`${onEdit ? "Edit" : "Add"} Wallet`}
        centerTitle
        description={
          <WalletForm
            onSubmit={(type) => onWalletSubmittion(type)}
            defaultValues={onEdit}
          />
        }
        onClose={onDialogClose}
      />
      {walletToRemove && (
        <BnDialog
          isOpen={isRemoveWalletDialogOpen}
          title=""
          centerTitle
          header={false}
          description={
            <RemoveWalletDialogDescription
              wallet={walletToRemove}
              onCancel={onRemoveWalletDialogClose}
              onDelete={() => {
                removeWallet(walletToRemove.id);
                onRemoveWalletDialogClose();
              }}
            />
          }
          onClose={onRemoveWalletDialogClose}
        />
      )}
      {walletToView && (
        <BnDialog
          isOpen={isWalletDescriptionOpen}
          title=""
          centerTitle
          description={<WalletDetailsDialog wallet={walletToView} />}
          onClose={onViewWalletDialogClose}
        />
      )}
      <BnDialog
        isOpen={!!walletForReport}
        title="Create New Report"
        centerTitle
        maxWidth="max-w-2xl"
        description={
          <DocumentForm
            onSubmit={onReportSubmittion}
            minDate={new Date(lastTransaction?.results[0]?.created_at || 0)}
            defaultValues={{ wallet: walletForReport }}
          />
        }
        onClose={() => setWalletForReport(null)}
      />
      <Tutorial
        title="Your first wallet"
        desc="All connected Wallets are displayed here. You can display additional information or remove a wallet by clicking on it."
        isOpen={
          tutorialState === "wallet_tutorial_1" && !user.is_tutorial_viewed
        }
        image={tutorialState}
        maxWidth="max-w-md"
        currentTutorial={1}
        maxTutorial={7}
        onClose={closeTutorialFlow}
        nextTutorial={() => activateNextTutorialFlow(2)}
        prevTutorial={() => history.push("/overview")}
        positionStyle="relative top-18 left-14"
        pointer={{
          className: "absolute -left-14 top-10",
          style: {
            borderBottom: "30px solid transparent",
            borderRight: "30px solid white",
            borderTop: "30px solid transparent",
            borderLeft: "30px solid transparent",
          },
        }}
      />
      <Tutorial
        title="Keep your wallets in check"
        desc="You can add additional wallets either by public address or exchange connection."
        isOpen={
          tutorialState === "wallet_tutorial_2" && !user.is_tutorial_viewed
        }
        image={tutorialState}
        currentTutorial={2}
        maxTutorial={7}
        onClose={closeTutorialFlow}
        nextTutorial={() =>
          history.push("/overview", { tutorial: "overview_tutorial" })
        }
        prevTutorial={() => activateNextTutorialFlow(1)}
        positionStyle="absolute top-64 right-28 xl:relative xl:top-20 xl:left-60"
        pointer={{
          className: "absolute right-14 -top-14",
          style: {
            borderBottom: "30px solid white",
            borderRight: "30px solid transparent",
            borderTop: "30px solid transparent",
            borderLeft: "30px solid transparent",
          },
        }}
      />
    </div>
  );
}

type TokensCellProps = {
  tokens: Token[];
  overlap?: boolean;
  disableClick?: boolean;
};

function TokensCell({ tokens, overlap, disableClick }: TokensCellProps) {
  const [showFull, setShowFull] = useState(false);
  const tokenList = showFull ? tokens : tokens.slice(0, 5);

  return (
    <div className="flex flex-wrap items-center" style={{ minWidth: "220px" }}>
      {tokenList.map((token, id) => (
        <React.Fragment key={token.id}>
          <Tooltip place="top" />
          <img
            src={token.image_url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/icons/logo_small.svg";
            }}
            className="w-6 mr-0.5"
            style={{
              transform: `translateX(-${overlap ? 0.4 * id : 0}rem)`,
            }}
            alt={`${token.symbol} icon`}
            data-tip={token.symbol}
          />
        </React.Fragment>
      ))}
      {tokens.length > 5 && !showFull && (
        <span
          className="ml-2 font-bold cursor-pointer"
          onClick={() => !disableClick && setShowFull(true)}
        >{`+ ${tokens.length - 5} more`}</span>
      )}
    </div>
  );
}

type RemoveWalletDialogDescriptionProps = {
  wallet: WalletResponse;
  onCancel: () => any;
  onDelete: () => any;
};

type WalletToViewProps = {
  wallet: WalletResponse | CexResponse;
};

function WalletDetailsDialog({ wallet }: WalletToViewProps) {
  return (
    <div className="dark:text-gray-300">
      <div className="flex justify-between mb-5">
        <div className="flex">
          {/* Check if network is a wallet and not a centralised exchange (cex)... */}
          {"network" in wallet ? (
            <>
              <img
                src={`/icons/network/${wallet.network.toLowerCase()}.svg`}
                className="w-10"
                alt={`${capitalize(wallet.network)} icon`}
              />
              <h3 className="font-bold ml-10 mt-3 text-18">
                {`${capitalize(wallet.network)} Wallet`}
              </h3>
            </>
          ) : (
            <>
              <img
                src={`/icons/cex/${wallet.exchange.toLowerCase()}.svg`}
                className="h-10"
                alt={`${wallet.exchange} icon`}
              />
              <h3 className="font-bold ml-10 mt-3 text-18">Exchange</h3>
            </>
          )}
        </div>
        <p className="mt-2 text-20">
          <FiatAmount amount={wallet.total_balance} />
        </p>
      </div>
      {/* Check if network is a wallet and not a centralised exchange (cex)... */}
      {"address" in wallet && (
        <div className="flex gap-x-12 items-center">
          <p className="font-semibold">Address</p>
          <span className="text-green-300 text-14 truncate">
            {wallet.address}
          </span>
        </div>
      )}
      {wallet.tokens.length ? (
        <div className="flex mt-2 mb-8 gap-x-12 items-center">
          <p className="font-semibold">Tokens</p>
          <TokensCell tokens={wallet.tokens} />
        </div>
      ) : (
        ""
      )}
      {wallet.last_activity.length ? <p className="mb-5">Last Activity</p> : ""}
      <div>
        {wallet.last_activity
          .sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime(),
          )
          .map((transaction) => {
            const isOutgoing = transaction.direction === "OUTGOING";
            return (
              <React.Fragment key={transaction.id}>
                <div className="h-px bg-gray-450-t-50"></div>
                <div className="flex py-1"></div>
                <div className="last-activity-row">
                  <h3 className="text-14 text-green-800 font-bold whitespace-nowrap w-32 xl:w-64 truncate">
                    {transaction.contact
                      ? `${transaction.contact.first_name} ${transaction.contact.last_name}`
                      : isOutgoing
                      ? transaction.to_address
                      : transaction.from_address}
                  </h3>
                  <h3 className="text-14 font-bold truncate text-right">
                    <ColoredAmount
                      amount={transaction.value_fiat}
                      direction={transaction.direction}
                    />
                  </h3>
                </div>
                <div className="last-activity-row">
                  <p className="text-12 text-green-800 capitalize">
                    {isOutgoing ? "Sent" : "Received"}{" "}
                    {transaction.created_at.substring(0, 10)}
                  </p>
                  <div className="text-12 uppercase flex items-center text-green-800 space-x-1.5 my-2">
                    <p>{formatAmount(transaction.amount)}</p>
                    <p>{transaction.token.symbol}</p>
                    <img
                      className="w-4"
                      src={transaction.token.image_url}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = "/icons/logo_small.svg";
                      }}
                      alt={transaction.token.symbol}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          })}
      </div>
      {/* Prevents Focus Error... */}
      <button></button>
    </div>
  );
}

function RemoveWalletDialogDescription({
  wallet,
  onCancel,
  onDelete,
}: RemoveWalletDialogDescriptionProps) {
  const firstDocName = wallet.wallet_documents[0].name;

  return (
    <div>
      <p className="text-green-900">
        You are about to delete a wallet that has a{" "}
        <span className="text-green-300 font-bold">{firstDocName}</span>{" "}
        associated with it.
      </p>
      <br />
      <p className="text-green-900">
        If you proceed, your{" "}
        <span className="text-green-300 font-bold">{firstDocName}</span> will be
        deleted as well.
      </p>
      <br />
      <p className="text-green-900">The Documents affected are:</p>
      <br />
      <ul>
        {wallet.wallet_documents.map((doc) => (
          <li className="text-green-300 font-bold" key={doc.id}>
            {doc.name}
          </li>
        ))}
      </ul>
      <div className="flex justify-center mt-8">
        <button
          onClick={onCancel}
          className="transparent-button w-28 font-bold border border-green-800 mr-4 dark:border-gray-450"
        >
          Cancel
        </button>
        <button onClick={onDelete} className="primary-button w-28 font-bold">
          Delete
        </button>
      </div>
    </div>
  );
}
