import { Fiat } from "../types";

export default function getFiatSign(currency: Fiat) {
  return {
    USD: "$",
    CAD: "$",
    EUR: "€",
    SGD: "$",
    INR: "₹",
    JPY: "¥",
    VND: "₫",
    CNY: "¥",
    KRW: "₩",
    CHF: "₣",
    RUB: "₽",
    TRY: "₺",
    ETH: "Ξ",
    BNB: "$",
    BTC: "₿",
  }[currency];
}
