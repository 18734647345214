import format from "date-fns/format";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { getPortfolioValueHistory } from "../../api";
import { ThemeContext } from "../../App";
import { getFiatSign } from "../../utils";
import Loader from "../Loader";
import CustomTooltip from "./CustomTooltip";

export default function PortfolioHistoryChart() {
  const history = useHistory();
  const [selectedInterval, setSelectedInterval] = useState(30);
  const timeIntervals = [
    { label: 7, enabled: true },
    { label: 30, enabled: true },
    { label: 60, enabled: true },
  ];
  const theme = useContext(ThemeContext);

  const { data, isLoading } = useQuery(
    ["portfolioValueHistory", { selectedInterval }],
    () => getPortfolioValueHistory(selectedInterval),
    { refetchInterval: 10000 },
  );

  if (isLoading || !data!.user_bal_sync) {
    return (
      <div style={{ minHeight: "245px" }}>
        <Loader />
      </div>
    );
  }

  const domain = [
    Math.ceil(
      Math.min(0, ...data!.data_points.map((p) => parseFloat(p.value_fiat))),
    ),
    Math.ceil(
      Math.max(...data!.data_points.map((p) => parseFloat(p.value_fiat))),
    ),
  ];

  function formatTick(value: string) {
    const formattedValue =
      Math.abs(parseFloat(value)) < 1000
        ? value
        : Math.abs(parseFloat(value)) >= 1000000000
        ? `${Math.round(parseFloat(value) / 10000000) / 100}B`
        : Math.abs(parseFloat(value)) >= 1000000
        ? `${Math.round(parseFloat(value) / 10000) / 100}M`
        : `${Math.round(parseFloat(value) / 10) / 100}K`; //rounded to 2 decimals
    return (data ? getFiatSign(data.fiat_currency) : "$") + formattedValue;
  }
  function formatxTick(value: string) {
    //formats date as day-month like 21 JUN
    return format(new Date(value), "d MMM").toUpperCase();
  }

  return (
    <div className="w-full h-56 mb-5 text-12 text-green-800 relative">
      <div className="text-13 text-green-800 flex space-x-8 self-end mr-7 mb-5 justify-end">
        {timeIntervals.map((interval) => {
          const selected = selectedInterval === interval.label;
          return (
            <div
              key={interval.label}
              className={`relative w-6 text-center ${selected && "font-bold"} ${
                interval.enabled
                  ? "cursor-pointer text-green-800 dark:text-gray-300"
                  : "cursor-default text-green-800 opacity-50 dark:text-gray-450"
              }`}
              onClick={() => {
                if (interval.enabled) setSelectedInterval(interval.label);
              }}
            >
              <p>{interval.label}D</p>
              {selected && (
                <div className="absolute inset-x-0 mt-px top-9 border border-green-300 dark:border-gray-300"></div>
              )}
            </div>
          );
        })}
      </div>
      <div className="w-full h-px bg-gray-450-t-58 mb-5"></div>
      <ResponsiveContainer className="-ml-10" width="102%" height="100%">
        <AreaChart
          data={data!.data_points.map(({ measured_on, value_fiat }) => ({
            value_fiat,
            date: measured_on.toString(),
          }))}
        >
          <XAxis
            padding={{ right: 30 }}
            interval={Math.ceil(data!.data_points.length / 8)}
            tickSize={10}
            dataKey="date"
            tickMargin={10}
            tickLine={false}
            tickFormatter={formatxTick}
            reversed
            tick={{ fill: theme === "dark" ? "#D8DFE6" : "#2F5A55" }}
          />
          <YAxis
            padding={{ top: 20 }}
            tickLine={false}
            tickMargin={10}
            width={100}
            domain={domain as any}
            tickFormatter={formatTick}
            tick={{ fill: theme === "dark" ? "#D8DFE6" : "#2F5A55" }}
          />
          <defs>
            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#54A097" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#54A097" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type="linear"
            dataKey="value_fiat"
            stroke="#54A097"
            fillOpacity={0.8}
            fill="url(#colorValue)"
          />
          <Tooltip
            content={
              <CustomTooltip
                payload={data!.data_points}
                fiat_currency={data!.fiat_currency}
              />
            }
            position={{ y: 30 }}
            cursor={{
              stroke: "green",
              strokeWidth: 0.5,
              height: "value_fiat",
              strokeDasharray: "3 3",
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
      <div
        className={`${
          data?.premium_user ? "hidden" : "block"
        } absolute bg-green-800 dark:bg-gray-900 dark:text-gray-300 text-white flex flex-col justify-center items-center p-4 z-50 rounded-xl top-1/3 left-1/3`}
      >
        <p className="text-14 font-medium w-1/2 mb-5">
          Portfolio History is only available for Plus Users
        </p>
        <p
          className="text-16 font-bold underline cursor-pointer"
          onClick={() => history.push("/pricing")}
        >
          See Plans
        </p>
      </div>
    </div>
  );
}
