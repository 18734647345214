import React from "react";
import { FieldValues, useForm } from "react-hook-form";

import { Address } from "../../types";
import countries from "../../utils/countries.json";
import BaseForm from "./_BaseForm";
import Button from "./_Button";
import Dropdown from "./_Dropdown";
import Input from "./_Input";

type FormProps = {
  onSubmit: (address?: Address) => void;
  defaultValues?: Address;
};

export default function AddressForm({ onSubmit, defaultValues }: FormProps) {
  const form = useForm<FieldValues>({
    defaultValues: {
      ...defaultValues,
    },
  });

  function onFormSubmit() {
    const data = { ...form.getValues() } as Address;
    onSubmit(data);
  }

  return (
    <div className="relative pb-10">
      <BaseForm
        form={form}
        onSubmit={onFormSubmit}
        buttons={
          <Button
            text="SAVE"
            type="submit"
            styling="primary-button"
            additionalClasses="absolute bottom-0 right-0 w-full"
          />
        }
      >
        <div>
          <Input
            form={form}
            name="address_line1"
            placeholder="Address line 1*"
            registerOptions={{ required: true }}
          />
        </div>
        <div>
          <Input
            form={form}
            name="address_line2"
            placeholder="Address line 2"
          />
        </div>
        <div className="mb-6 grid grid-cols-2 gap-x-2">
          <div>
            <Input
              form={form}
              name="city"
              placeholder="City*"
              registerOptions={{ required: true }}
            />
          </div>
          <div>
            <Input
              form={form}
              name="postal_code"
              placeholder="Postal code*"
              registerOptions={{ required: true }}
            />
          </div>
          <div>
            <Dropdown
              form={form}
              name="country"
              placeholder="Country*"
              options={countries.map((c) => ({
                label: c,
                value: c,
              }))}
              registerOptions={{ required: true }}
            />
          </div>
          <div>
            <Input form={form} name="tax_number" placeholder="Tax number" />
          </div>
        </div>
      </BaseForm>
    </div>
  );
}
