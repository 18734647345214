import React from "react";

import { AnalyticsType } from "../../types";
import StatCard from "../StatCard";

type UsersStatisticsProps = {
  data: AnalyticsType | undefined;
};
export default function UsersStatistics({ data }: UsersStatisticsProps) {
  return (
    <div className="grid sm:grid-cols-2 lg:grid-cols-3 justify-center dark:text-gray-100 dark:bg-gray-950 capitalize">
      <StatCard
        cardTitle="New users last 30 days"
        primaryValue={data ? data.num_new_users_last_30_days : 0}
        secondaryValue={data ? data.new_users_diff_pct : 0}
        iconPath="/icons/light/user.svg"
      />
      <StatCard
        cardTitle="Active users last 30 days"
        primaryValue={data ? data.num_active_users_last_30_days : 0}
        secondaryValue={data ? data.active_users_diff_pct : 0}
        iconPath="/icons/light/fire.svg"
      />
      <StatCard
        cardTitle="Total Premium users"
        primaryValue={data ? data.num_premium_users : 0}
        secondaryValue={data ? data.premium_users_diff_pct : 0}
        iconPath="/icons/light/diamond.svg"
      />
    </div>
  );
}
