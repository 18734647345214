import { Big } from "big.js";
import React from "react";

import { Fiat, InvoiceItem } from "../types";
import { getFiatSign, getInvoiceItemAmounts } from "../utils";

type SummaryProps = {
  currency: Fiat;
  items?: InvoiceItem[];
  total_amount?: string;
  total_tax_amount?: string;
  total_base_amount?: string;
};

export default function ItemsSummary({
  items,
  currency,
  total_amount,
  total_tax_amount,
  total_base_amount,
}: SummaryProps) {
  return (
    <div className="grid grid-cols-2 gap-x-10 gap-y-6 text-right">
      <p className="text-16 text-green-800 dark:text-gray-300">
        Total without Tax
      </p>
      <div className="flex">
        <p className="text-16 text-gray-800 dark:text-gray-300">
          {getFiatSign(currency)} {}
          {items
            ? items
                .map((item) => getInvoiceItemAmounts(item).basePrice)
                .reduce((sum, amount) => Big(sum).add(Big(amount)), Big(0))
                .toString()
            : parseFloat(total_base_amount || "0")}
        </p>
      </div>
      <p className="text-16 text-green-800 dark:text-gray-300">
        Total Tax amount
      </p>
      <div className="flex">
        <p className="text-16 text-gray-800 dark:text-gray-300">
          {getFiatSign(currency)} {}
          {items
            ? items
                .map((item) => getInvoiceItemAmounts(item).totalTax)
                .reduce((sum, amount) => Big(sum).add(Big(amount)), Big(0))
                .toString()
            : parseFloat(total_tax_amount || "0")}
        </p>
      </div>
      <p className="text-20 font-bold text-green-800 dark:text-gray-300">
        Subtotal
      </p>
      <div className="flex">
        <p className="text-20 font-bold text-gray-800 dark:text-gray-300">
          {getFiatSign(currency)} {}
          {items
            ? items
                .map((item) => getInvoiceItemAmounts(item).taxedPrice)
                .reduce((sum, amount) => Big(sum).add(Big(amount)), Big(0))
                .toString()
            : parseFloat(total_amount || "0")}
        </p>
      </div>
    </div>
  );
}
