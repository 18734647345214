import axios, { AxiosResponse } from "axios";

import { showSnackbar } from "./components/Snackbar";
import { API_URL, DEBUG } from "./env";

export const baseUrl =
  API_URL ||
  (["localhost", "127.0.0.1"].includes(window.location.hostname)
    ? "http://localhost:8001"
    : "");

const headers: Record<string, string> = {
  "Content-Type": "application/json",
};

if (DEBUG) {
  headers.Debug = "true";
}

const axiosInstance = axios.create({
  baseURL: `${baseUrl}/api`,
  headers,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-Csrftoken",
  withCredentials: true,
});

axiosInstance.interceptors.response.use(undefined, errorsHandler);

function errorsHandler(error: any) {
  if (!error.response) {
    showSnackbar("Network problem detected. Try again later.");
    return;
  }

  const response: AxiosResponse = error.response;
  switch (response.status) {
    case 401:
      showSnackbar("You were logged out");
      window.dispatchEvent(new CustomEvent("Basenode:logout"));
      break;
    case 403:
      showSnackbar("This feature is available only for logged in users");
      break;
    case 500:
      showSnackbar("Unexpected server error occurred");
      break;
  }

  return Promise.reject(error);
}

export default axiosInstance;
