import React from "react";

import { Tier } from "../../types";

export default function NewPlanDialog({
  plan,
  onSubmit,
}: {
  plan: Tier;
  onSubmit: () => void;
}) {
  return (
    <div className="flex flex-col justify-between items-center gap-y-9">
      <p className="text-20 text-center text-green-900 dark:text-gray-300">
        Congratulations! You are now a basenode.io PLUS member. You can manage
        your subscription in the profile section.
      </p>
      <button onClick={onSubmit} className="text-16 w-5/6 always-green-button">
        Get started
      </button>
    </div>
  );
}
