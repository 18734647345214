import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import { createInvoice, editInvoice, invoicePdfUrl } from "../api";
import InvoiceForm from "../components/forms/InvoiceForm";
import { useUser } from "../hooks/useUser";
import { Invoice, InvoiceResponse, LocationProps } from "../types";
import { downloadFileByHref } from "../utils";

export default function InvoiceCreationPage() {
  const history = useHistory();
  const user = useUser();
  const location = useLocation<LocationProps>();
  const isOnEdit = !!location.state?.invoice?.id;

  if (!user.full_access) {
    history.push("/overview");
  }

  useEffect(() => {
    // prevents from being displayed in the middle at the very first rerender
    // issue occured when redirected from page which was scrolled
    window.scrollTo(0, 0);
  }, []);

  const { mutate: onInvoiceCreate } = useMutation(
    (data: Invoice) => createInvoice(data),
    { onSuccess: (invoice) => onSubmitSuccess(invoice) },
  );

  const { mutate: onInvoiceEdit } = useMutation(
    (data: { id: string; invoice: Invoice }) =>
      editInvoice(data.id, data.invoice),
    { onSuccess: (invoice) => onSubmitSuccess(invoice) },
  );

  async function onSubmitSuccess(invoice: InvoiceResponse) {
    if (invoice.state === "DUE") {
      const href = invoicePdfUrl(invoice.id, true);
      downloadFileByHref(invoice.name, href);
      if (invoice.download_only) return;
    }
    history.push("/documents");
  }

  function onFormSubmit(data: any, type: string) {
    if (isOnEdit) {
      if (type === "DUE") {
        onInvoiceEdit({
          id: data.id!,
          invoice: { ...data, state: type } as Invoice,
        });
      } else {
        delete data.state;
        onInvoiceEdit({
          id: data.id!,
          invoice: data as Invoice,
        });
      }
    } else {
      onInvoiceCreate({ ...data, state: type } as Invoice);
    }
  }

  return (
    <div className="bg-white text-green-800 rounded-20px p-6 mlg:p-12 w-4/5 mx-auto my-12 dark:bg-gray-900 dark:text-gray-300">
      <InvoiceForm onSubmit={onFormSubmit} />
    </div>
  );
}
