import React, { useContext } from "react";
import { UseFormReturn } from "react-hook-form";

import { ThemeContext } from "../../App";
import BaseForm from "./_BaseForm";
import Input from "./_Input";

type FormProps = {
  form: UseFormReturn<any>;
};

export default function CurrencySearch({ form }: FormProps) {
  const theme = useContext(ThemeContext);

  return (
    <BaseForm form={form}>
      <div className="text-16 px-6 h-12 w-11/12 rounded-20px bg-gray-100 flex items-center gap-4 border border-gray-100 dark:bg-gray-950 dark:border-gray-450-t-50">
        <img src={`/icons/${theme}/search.svg`} alt="search" />
        <Input
          form={form}
          name="currency"
          placeholder="Search currency"
          additionalClasses="bg-gray-100 text-green-800 w-full focus:outline-none dark:bg-gray-950"
          autoFocus
          autoComplete="off"
        />
      </div>
    </BaseForm>
  );
}
