import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { format } from "date-fns";
import React from "react";

import {
  DocumentResponse,
  HoldingsReportPdfRecord,
  Network,
} from "../../types";
import { formatAmount } from "../../utils";

type PdfProps = {
  docDetails: DocumentResponse;
  holdings: HoldingsReportPdfRecord[][];
};

export default function HoldingsPdf({ docDetails, holdings }: PdfProps) {
  function createTableRow(row: HoldingsReportPdfRecord, index: number) {
    const backgroundColor = index % 2 === 0 ? "#E5E5E5" : "white";
    return (
      <View
        style={{
          flexDirection: "row",
          backgroundColor,
          height: 17,
        }}
        wrap={false}
      >
        <View style={{ flex: 1 }}>
          <Text style={styles.tokenCell}>{row.token.symbol}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.tokenCell}>{row.token.name}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.tokenCell}>
            {formatItinNumber(row.itin_number)}{" "}
          </Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <Text style={styles.tableCell}>{row.holdings_at_start}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.tableCell}>{formatAmount(row.inflow)}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.tableCell}>{formatAmount(row.outflow)}</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <Text style={styles.tableCell}>{row.holdings_at_end}</Text>
        </View>
        <View style={{ flex: 1.5, flexDirection: "row" }}>
          <Text
            style={{
              fontSize: 8,
              paddingVertical: "4",
              textAlign: "left",
              fontWeight: 500,
              width: "20%",
              paddingLeft: "5",
            }}
          >
            {row.change > 0 ? "+" : "-"}
          </Text>
          <Text
            style={{
              fontSize: 8,
              paddingVertical: "4",
              textAlign: "center",
              fontWeight: 500,
              width: "80%",
            }}
          >
            {formatAmount(Math.abs(row.change))}
          </Text>
        </View>
      </View>
    );
  }
  function createMiniTable(
    key: string,
    walletTable: HoldingsReportPdfRecord[],
  ) {
    const source = (net: Network) => {
      if (net === "BITCOIN") {
        return "Bitcoin network";
      } else if (net === "BINANCE_SMART_CHAIN") {
        return "Binance";
      } else if (net === "RONIN") {
        return "Ronin chain";
      } else {
        return "Ethereum network";
      }
    };
    return (
      <>
        <View style={{ marginTop: 16 }}></View>
        <View style={{ flex: 3 }}>
          <Text
            style={{
              fontSize: 8,
              textAlign: "center",
              fontFamily: "Nunito",
              fontWeight: 700,
            }}
          >
            {walletTable[0]?.wallet?.name || "Total holdings"}
          </Text>
        </View>
        {walletTable[0]?.wallet?.name && (
          <View style={{ flex: 3 }}>
            <Text
              style={{
                fontSize: 8,
                textAlign: "center",
                fontWeight: "normal",
                marginVertical: "1",
              }}
            >
              Source: {source(walletTable[0].wallet?.network)}
            </Text>
          </View>
        )}
        <View style={styles.tableBigSeparator}></View>
        <View
          style={{
            flexDirection: "row",
            height: 24,
          }}
          wrap={false}
        >
          <View style={{ flex: 1 }}>
            <Text style={styles.tokenHeader}>Ticker</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.tokenHeader}>Token Name</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.tokenHeader}>ITIN</Text>
          </View>
          <View style={{ flex: 0.7, flexDirection: "column" }}>
            <Text style={styles.tokenHeaderHoldings}>
              Holdings{" "}
              {`${format(new Date(docDetails.start_date), "dd.MM.yyyy")}`}
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.tokenHeader}>Inflow</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.tokenHeader}>Outflow</Text>
          </View>
          <View style={{ flex: 0.7 }}>
            <Text style={styles.tokenHeaderHoldings}>
              Holdings{" "}
              {`${format(new Date(docDetails.end_date), "dd.MM.yyyy")}`}
            </Text>
          </View>
          <View style={{ flex: 1.5 }}>
            <Text style={styles.tokenHeader}>Change(+/-)</Text>
          </View>
        </View>

        {walletTable.map((value, key) => (
          <React.Fragment key={key}>
            {createTableRow(value, key)}
          </React.Fragment>
        ))}
      </>
    );
  }
  return (
    <Document>
      <Page style={styles.body} orientation="landscape">
        <View style={styles.header}>
          <View style={{ flex: 2, flexDirection: "column" }}>
            <Text style={styles.title}>Holdings Report</Text>
            <Text style={styles.date}>
              Date range: {}
              {`${format(
                new Date(docDetails.start_date),
                "dd/MM/yyyy",
              )} - ${format(new Date(docDetails.end_date), "dd/MM/yyyy")}`}
            </Text>
          </View>
          <View style={{ flex: 1, height: 16 }}>
            <Image style={styles.topLogo} src="/pdfImages/LogoBig.png" />
          </View>
        </View>

        <View style={styles.table}>
          {Object.entries(holdings).map(([key, value]) => (
            <React.Fragment key={key}>
              {value.length > 0 && createMiniTable(key, value)}
            </React.Fragment>
          ))}
        </View>
      </Page>
    </Document>
  );
}

Font.register({
  family: "Nunito",
  src: "/fonts/Nunito-Regular.ttf",
  fonts: [
    { src: "/fonts/Nunito-Regular.ttf", fontWeight: 500 },
    { src: "/fonts/Nunito-SemiBold.ttf", fontWeight: 600 },
    { src: "/fonts/Nunito-Bold.ttf", fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 25,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 14,
    fontFamily: "Nunito",
  },
  date: {
    fontSize: 10,
    marginBottom: 12,
    marginTop: 10,
  },
  topLogo: {
    position: "absolute",
    top: -20,
    right: 15,
    width: 127,
  },
  bottomLogo: {
    width: 120,
  },
  footer: {
    flexDirection: "row",
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 45,
  },
  tableHeader: {
    fontFamily: "Nunito",
    fontSize: 8,
    fontWeight: 700,
    backgroundColor: " #D3E0DD",
  },
  tokenHeader: {
    fontFamily: "Nunito",
    fontWeight: 700,
    fontSize: 8,
    textAlign: "center",
    backgroundColor: "#D3E0DD",
    height: 25,
    marginHorizontal: 0.5,
    paddingVertical: "7",
  },
  tokenHeaderHoldings: {
    fontFamily: "Nunito",
    fontWeight: 700,
    fontSize: 8,
    textAlign: "center",
    paddingVertical: "1",
    backgroundColor: "#D3E0DD",
    marginHorizontal: 0.5,
    flexWrap: "wrap",
    height: 25,
  },
  tableCell: {
    fontSize: 8,
    paddingVertical: "4",
    textAlign: "center",
    fontWeight: 500,
  },
  tableBigSeparator: {
    width: "100%",
    height: 1,
    marginVertical: 3,
    backgroundColor: "#2F5A55",
  },
  tableSmallSeparator: {
    width: "100%",
    height: 1,
    marginVertical: 5,
    backgroundColor: "#2F5A55",
    opacity: 0.5,
  },
  senderCell: {
    flex: 3,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tokenCell: {
    fontSize: 7,
    textAlign: "center",
    paddingVertical: "4",
  },
  fromAddress: {
    fontSize: 7,
    marginBottom: 10,
  },
  fiat: {
    fontSize: 7,
    textAlign: "center",
  },
});
function formatItinNumber(itin_number: string): string {
  if (!itin_number) {
    return "";
  }
  return (
    itin_number.slice(0, 4) +
    "-" +
    itin_number.slice(4, 8) +
    "-" +
    itin_number.slice(8)
  );
}
