import React from "react";
import { RegisterOptions, UseFormReturn } from "react-hook-form";

import FormError from "./_FormError";

export type InputProps = {
  form: UseFormReturn;
  name: string;
  registerOptions?: RegisterOptions;
  mainClasses?: string;
  additionalClasses?: string;
  isHidden?: boolean;

  // native input props
  placeholder?: string;
  defaultValue?: string;
  type?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  autoComplete?: "off";
  readOnly?: boolean;
  step?: string;
  id?: string;
  min?: string;
};

export default function Input({
  form,
  name,
  registerOptions,
  mainClasses,
  additionalClasses,
  isHidden = false,
  ...inputProps
}: InputProps) {
  return (
    <div
      className={`relative w-full flex flex-col my-2 ${
        isHidden ? "hidden" : ""
      }`}
    >
      <input
        className={`dark:bg-gray-900 ${
          mainClasses || "input"
        } ${additionalClasses} input-dark `}
        {...inputProps}
        {...form.register(name, registerOptions)}
      />
      <FormError
        errors={name
          .split(".")
          .reduce((val, key) => val && val[key], form.formState.errors)}
      ></FormError>
    </div>
  );
}
