export default function formatAmount(
  amount: number | string,
  decimals = 6,
  minDecimals = 0,
) {
  if (typeof amount === "string") {
    amount = parseFloat(amount);
  }

  return amount.toLocaleString(undefined, {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: decimals,
  });
}
