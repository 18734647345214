import { Popover, Transition } from "@headlessui/react";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";

import {
  getDocuments,
  getTransactions,
  getWalletLimit,
  getWallets,
  logout,
  updateWalletActivity,
} from "../../api";
import { ThemeContext, UserContext } from "../../App";
import useLocalStorage from "../../hooks/useLocalStorage";
import { DateFilter, WalletResponse } from "../../types";
import { copyText } from "../../utils";
import BnDialog from "../BnDialog";
import WalletForm from "../forms/WalletForm";
import Loader from "../Loader";
import EndOfSubscriptionLightBox from "../overview/EndOfSubscriptionLightBox";
import { planLimitsMessage } from "./QuickAdd";

export default function UserButton() {
  const { user } = useContext(UserContext);
  const theme = useContext(ThemeContext);
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] = useState({
    isOpen: false,
    subject: "wallets",
  });

  const { data: walletLimits } = useQuery("walletLimits", () =>
    getWalletLimit(),
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { mutate } = useMutation(() => logout());

  const isGoogleUser = user.type === "GOOGLE";

  function generateIcon() {
    if (!user.type)
      return (
        <img
          className="w-7 h-7 m-auto"
          src={`/icons/${theme}/guest_user_icon.svg`}
          alt="Guest"
        />
      );

    if (isGoogleUser)
      return (
        <p className="text-green-800 text-24 m-auto">
          {user.signed_email[0].toUpperCase() ?? "W"}
        </p>
      );
    return (
      <img
        className="w-7 h-7 m-auto"
        src={`/icons/wallets/${user.type.toLowerCase()}.svg`}
        alt={user.type.toLowerCase()}
      />
    );
  }

  function closeDialog() {
    setIsDialogOpen(false);
  }

  return (
    <>
      <Popover className="relative z-30">
        <Popover.Button className="bg-green-200 dark:bg-green-180 w-10 h-10 rounded-full">
          {generateIcon()}
        </Popover.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Popover.Panel className="absolute right-0 mt-4 rounded-lg bg-white px-0 pt-5 w-72 flex flex-col shadow-filter dark:bg-gray-950 dark:shadow-dark">
            {({ close }) => (
              <>
                <div
                  className="absolute right-2.5 -top-5"
                  style={{
                    borderBottom: `10px solid ${
                      theme === "light" ? "white" : "#242629"
                    }`,
                    borderRight: "10px solid transparent",
                    borderTop: "10px solid transparent",
                    borderLeft: "10px solid transparent",
                  }}
                />
                <div className="truncate text-14 text-green-800 dark:text-gray-300 border-b border-gray-450-t-50 pb-5 px-5 whitespace-nowrap">
                  <span>Signed in as </span>
                  <span className=" font-bold">
                    {isGoogleUser ? user.signed_email : user.address}
                  </span>
                </div>
                <div className="flex flex-col space-y-3 p-5 border-b border-gray-450-t-50">
                  <p className="text-14 text-green-800 dark:text-gray-300 font-bold">
                    Connected Wallets
                  </p>
                  <CryptoAddress />
                  {user.full_access && (
                    <button
                      onClick={() =>
                        walletLimits?.can_create
                          ? setIsDialogOpen(true)
                          : setIsSubscriptionDialogOpen({
                              isOpen: true,
                              subject: "wallets",
                            })
                      }
                      className="mt-2 whitespace-nowrap outline-none truncate text-green-800 dark:text-gray-300 text-14 flex space-x-3 items-center"
                    >
                      <span className="px-1 text-24">+</span>
                      <span>Add additional wallet</span>
                    </button>
                  )}
                </div>
                <div className="flex flex-col space-y-3 text-14 p-5">
                  {user.full_access && (
                    <Link
                      onClick={() => close()}
                      to="/pricing"
                      className="text-green-200 flex items-center space-x-1 cursor-pointer"
                    >
                      <p>Get premium</p>
                      <img src="/icons/light/gem.svg" alt="gem" />
                    </Link>
                  )}
                  {user.is_staff && (
                    <Link onClick={() => close()} to="/admin">
                      <p className="text-green-800 dark:text-gray-300 cursor-pointer">
                        Switch to Admin
                      </p>
                    </Link>
                  )}
                  {user.full_access && (
                    <Link
                      onClick={() => close()}
                      to="/settings?section=profile"
                      data-text="Settings"
                    >
                      <p className="text-green-800 dark:text-gray-300 cursor-pointer">
                        Your profile
                      </p>
                    </Link>
                  )}
                  <p
                    className="text-red-500 cursor-pointer"
                    onClick={() => mutate()}
                  >
                    Sign out
                  </p>
                </div>
              </>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
      <EndOfSubscriptionLightBox
        dialogText={
          planLimitsMessage(
            isSubscriptionDialogOpen.subject,
            walletLimits?.limit || 0,
          ) || ""
        }
        isSubscriptionDialogOpen={isSubscriptionDialogOpen.isOpen}
        onCloseSubscriptionDialog={() =>
          setIsSubscriptionDialogOpen({
            isOpen: false,
            subject: "wallets",
          })
        }
      />
      <BnDialog
        isOpen={isDialogOpen}
        title="Add Wallet"
        centerTitle
        description={<WalletForm onSubmit={closeDialog} />}
        onClose={closeDialog}
      />
    </>
  );
}

function CryptoAddress() {
  const queryClient = useQueryClient();
  const theme = useContext(ThemeContext);
  const { user, setUser } = useContext(UserContext);
  const ordering = "name";

  const [transactionsFilters, setTransactionsFilters] =
    useLocalStorage<DateFilter>("transactions_filters", {
      start_date: "",
      end_date: "",
    });
  const [documentsFilters, setDocumentsFilters] = useLocalStorage<DateFilter>(
    "documents_filters",
    { start_date: "", end_date: "" },
  );

  if (!transactionsFilters.start_date || !documentsFilters.start_date)
    resetFilters();

  async function resetFilters() {
    const data = await getTransactions({
      limit: 1,
      offset: 0,
      ordering: "created_at",
    });

    const firstDoc = await getDocuments({
      limit: 1,
      offset: 0,
      ordering: "created_at",
    });

    const now = new Date(Date.now());

    const start_date_txn = new Date(data?.results[0]?.created_at || now);
    const start_date_doc = new Date(firstDoc?.results[0]?.created_at || now);
    const end_date = now;
    setTransactionsFilters({
      start_date: format(start_date_txn, "yyyy-MM-dd"),
      end_date,
    });
    setDocumentsFilters({
      start_date: format(start_date_doc, "yyyy-MM-dd"),
      end_date,
    });
  }

  const { data: wallets, isLoading: walletsLoading } = useQuery(
    ["wallets", ordering],
    () => getWallets({ ordering }),
    { onSuccess: (wallets) => setUser({ ...user, has_wallet: !!wallets[0] }) },
  );

  const { mutate: walletUpdate } = useMutation(
    (data: WalletResponse) => updateWalletActivity(data!.id, !data.is_active),
    {
      onSuccess: () => {
        resetFilters();
        queryClient.invalidateQueries();
        queryClient.refetchQueries();
      },
    },
  );

  useEffect(() => {
    window.addEventListener("resetFilters", () => {
      resetFilters();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {walletsLoading ? (
        <div
          className="-mt-20"
          style={{
            // each wallet row is 23px high
            height: Math.max(user.wallets_number, 2) * 23 + "px",
          }}
        >
          <Loader />
        </div>
      ) : (
        wallets &&
        wallets?.map((w) => (
          <div
            key={w.address}
            className="flex items-center space-x-3 space-y-1 text-14 text-green-800 dark:text-gray-300"
          >
            <div
              className={`w-5 h-5 rounded-md relative flex items-center justify-center cursor-pointer ${
                w.is_active ? "bg-green-200" : "border border-green-200"
              }`}
              onClick={() => {
                walletUpdate(w);
              }}
            >
              {w.is_active && (
                <img src={`/icons/${theme}/check_white.svg`} alt="check" />
              )}
            </div>
            <p
              className="truncate cursor-pointer"
              onClick={() => copyText(w.address)}
            >
              {w.name}
            </p>
          </div>
        ))
      )}
    </div>
  );
}
