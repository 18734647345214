import { Popover } from "@headlessui/react";
import Big from "big.js";
import React, { useContext, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { ThemeContext } from "../../App";
import { Fiat, InvoiceItem, Token } from "../../types";
import { getFiatSign, getInvoiceItemAmounts } from "../../utils";
import InfoPopover from "../InfoPopover";
import ItemsSummary from "../ItemsSummary";
import Input from "./_Input";

type TableProps = {
  form: UseFormReturn;
  chosenCrypto: Token | null;
  chosenCurrency: Fiat;
  tokenFiat?: string;
};

const baseTableRow: InvoiceItem = {
  name: "",
  description: "",
  quantity: "",
  unit_price: "",
  discount: "",
  tax: "",
  discount_is_percent: false,
  tax_is_percent: true,
};

export default function InvoiceItemsTable({
  form,
  chosenCrypto,
  chosenCurrency,
  tokenFiat,
}: TableProps) {
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const items: InvoiceItem[] = form.watch("items");
  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (!items) {
      assignItem({ ...baseTableRow }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function assignItem(item: InvoiceItem, id: number) {
    form.setValue(`items.${id}`, item);
  }
  function addNewItem() {
    assignItem({ ...baseTableRow }, items.length);
  }

  function removeItem(itemID: number) {
    const updatedItems = items;
    updatedItems.splice(itemID, 1);
    form.reset({ ...form.getValues(), items: updatedItems });
  }

  function countDecimals(value: string) {
    return (value && value.split(".")[1]?.length) ?? 0;
  }

  if (!items) return <p></p>;

  return (
    <>
      <div className="overflow-x-auto ">
        {items.map((item, id) => (
          <div
            key={id}
            className="border-gray-450 rounded-md bg-gray-100 my-4 p-5 dark:bg-gray-950 text-14 "
            onMouseEnter={() => setHoveredItem(id)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <div className="relative grid grid-cols-6 md:grid-cols-12 gap-x-2 md:gap-x-4 items-center">
              <div className="col-span-4">
                <Input
                  form={form}
                  name={`items.${id}.name`}
                  placeholder="Item name *"
                  mainClasses="bg-white px-4 h-12 rounded-md border border-gray-600 focus:outline-none dark:bg-gray-900"
                  registerOptions={{ required: true }}
                />
              </div>
              <div className="col-span-2">
                <Input
                  form={form}
                  name={`items.${id}.quantity`}
                  placeholder="Quantity*"
                  mainClasses="bg-white px-4 h-12 rounded-md border border-gray-600 focus:outline-none dark:bg-gray-900"
                  type="number"
                  registerOptions={{
                    required: true,
                    validate: {
                      positive: (v) => parseFloat(v) > 0 || "Must be positive",
                      isInteger: (v) =>
                        Number.isInteger(parseFloat(v)) ||
                        "Cannot have decimals",
                    },
                  }}
                />
              </div>
              <div className="flex items-center relative col-span-2">
                <p style={{ zIndex: 5 }} className="absolute left-4">
                  {getFiatSign(chosenCurrency)}
                </p>
                <Input
                  form={form}
                  name={`items.${id}.unit_price`}
                  placeholder="Price *"
                  mainClasses="bg-white px-4 pl-8 h-12 rounded-md border border-gray-600 focus:outline-none dark:bg-gray-900"
                  type="number"
                  step="0.00001"
                  registerOptions={{
                    required: true,
                    validate: {
                      positive: (v) => parseFloat(v) > 0 || "Must be positive",
                      maxFiveDecimals: (v) =>
                        countDecimals(v) <= 5 || "Max 5 decimals allowed",
                    },
                  }}
                />
              </div>
              <div className="relative col-span-2">
                {form.watch(`items.${id}.discount`) && (
                  <p
                    style={{ zIndex: 5 }}
                    className="absolute top-5 right-6 bg-white dark:bg-gray-900"
                  >
                    {form.watch(`items.${id}.discount_is_percent`) ? "%" : "#"}
                  </p>
                )}
                <Input
                  form={form}
                  name={`items.${id}.discount`}
                  mainClasses={`bg-white px-4 h-12 rounded-md border border-gray-600 focus:outline-none dark:bg-gray-900 ${
                    form.watch(`items.${id}.discount`) && "pr-8"
                  }`}
                  placeholder="Discount"
                  type="number"
                  step="0.00001"
                  registerOptions={{
                    required: true,
                    validate: {
                      positive: (v) =>
                        parseFloat(v) >= 0 || "Cannot be negative",
                      maxFiveDecimals: (v) =>
                        countDecimals(v) <= 5 || "Max 5 decimals allowed",
                    },
                  }}
                />
                <ItemDropDown
                  form={form}
                  id={id}
                  theme={theme}
                  name="discount_is_percent"
                />
              </div>
              <div className="relative col-span-2">
                {form.watch(`items.${id}.tax`) && (
                  <p
                    style={{ zIndex: 5 }}
                    className="absolute top-5 right-6 bg-white dark:bg-gray-900"
                  >
                    {form.watch(`items.${id}.tax_is_percent`) ? "%" : "#"}
                  </p>
                )}
                <Input
                  form={form}
                  name={`items.${id}.tax`}
                  mainClasses={`bg-white px-4 h-12 rounded-md border border-gray-600 focus:outline-none dark:bg-gray-900 ${
                    form.watch(`items.${id}.tax`) && "pr-10"
                  }`}
                  placeholder="Tax"
                  type="number"
                  step="0.01"
                  registerOptions={{
                    required: true,
                    validate: {
                      positive: (v) =>
                        parseFloat(v) >= 0 || "Cannot be negative",
                      maxFiveDecimals: (v) =>
                        countDecimals(v) <= 5 || "Max 5 decimals allowed",
                    },
                  }}
                />
                <ItemDropDown
                  form={form}
                  id={id}
                  theme={theme}
                  name="tax_is_percent"
                />
              </div>
              <img
                src={`/icons/${theme}/actions/trash_light.svg`}
                className={`absolute -right-4 cursor-pointer ${
                  hoveredItem === id && items.length > 1
                    ? "inline-block"
                    : "hidden"
                }`}
                alt="delete"
                onClick={() => removeItem(id)}
              />
            </div>
            <div className="w-full mb-8">
              <Input
                form={form}
                name={`items.${id}.description`}
                placeholder="Description"
                mainClasses="bg-white px-4 h-12 rounded-md border border-gray-600 focus:outline-none"
              />
            </div>
            <div className="text-right relative">
              <p className="font-semibold">
                Amount
                <span className="font-bold text-18 ml-10 text-gray-800 dark:text-gray-300">
                  {getFiatSign(chosenCurrency)} {}
                  {Big(
                    getInvoiceItemAmounts(form.watch(`items.${id}`)).taxedPrice,
                  ).toString()}
                </span>
              </p>
              <span className="flex justify-end text-14 mt-2 text-gray-800 dark:text-gray-300">
                {chosenCrypto ? (
                  <img
                    src={chosenCrypto.image_url}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "/icons/logo_small.svg";
                    }}
                    alt="crypto"
                    className="w-4 h-4 mr-4"
                  />
                ) : (
                  <InfoPopover
                    text="This invoice is missing a Wallet to receive the payment. Please select a Wallet to complete this invoice."
                    src="/icons/warning_circle.svg"
                    additionalPopoverClasses="right-10 -bottom-8"
                  />
                )}
                <p>{`${chosenCrypto?.symbol ?? ""} ${
                  tokenFiat
                    ? Big(
                        getInvoiceItemAmounts(form.watch(`items.${id}`))
                          .taxedPrice,
                      )
                        .div(parseFloat(tokenFiat))
                        .round(4)
                        .toString()
                    : ""
                }`}</p>
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-col sm:flex-row justify-between items-start mt-6">
        <div
          className="flex text-16 mb-6 sm:mb-0 text-green-800 cursor-pointer w-44 p-1 rounded-md hover:bg-opacity-50 hover:bg-gray-100 dark:hover:bg-gray-950 dark:text-gray-300"
          onClick={addNewItem}
        >
          <img
            src={`/icons/${theme}/plus.svg`}
            alt="add"
            className="w-4 mr-2"
          />
          <p>Add item or service</p>
        </div>
        <ItemsSummary items={items} currency={chosenCurrency} />
      </div>
    </>
  );
}

type ItemDropDownProps = {
  form: UseFormReturn;
  theme: any;
  id: number;
  name: string;
};

function ItemDropDown({ form, theme, id, name }: ItemDropDownProps) {
  return (
    <Popover
      style={{ zIndex: 2 }}
      className="absolute top-5 cursor-pointer -right-0"
    >
      <Popover.Button>
        <img
          src={`/icons/${theme}/arrows/expand_down_light.svg`}
          alt="dropdown"
          className="h-6"
        />
      </Popover.Button>
      <Popover.Panel className="absolute top-5 right-2 bg-white dark:bg-gray-900 rounded-md">
        {({ close }) => (
          <div>
            <p
              onClick={() => {
                form.setValue(`items.${id}.${name}`, true);
                close();
              }}
              className="px-4 py-2"
            >
              %
            </p>
            <p
              onClick={() => {
                form.setValue(`items.${id}.${name}`, false);
                close();
              }}
              className="px-4 py-2"
            >
              #
            </p>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
}
