import { Popover, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useRef } from "react";

import { ThemeContext } from "../App";

type InfoPopoverProps = {
  text: string;
  additionalPopoverClasses?: string;
  additionalContentClasses?: string;
  infoSize?: string;
  src?: string;
};

export default function InfoPopover({
  text,
  additionalPopoverClasses = "",
  additionalContentClasses = "",
  infoSize = "",
  src,
}: InfoPopoverProps) {
  const infoButtonRef = useRef<HTMLButtonElement | null>(null);
  const theme = useContext(ThemeContext);
  return (
    <Popover className="flex items-center">
      {({ open, close }) => (
        <>
          <Popover.Button
            className="outline-none"
            ref={infoButtonRef}
            onMouseOver={() => infoButtonRef.current?.click()}
            onMouseOut={() => close()}
          >
            <img
              src={src ? src : `/icons/${theme}/dropbox/info_light.svg`}
              alt="Info"
              className={`cursor-pointer ${infoSize}`}
            />
          </Popover.Button>
          {open && (
            <Transition
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0 translate-y-1"
              enterTo="transform scale-100 opacity-100 translate-y-0"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100 translate-y-0"
              leaveTo="transform scale-95 opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`${additionalPopoverClasses} absolute mt-20 z-10 `}
              >
                <div
                  className={`${additionalContentClasses} bg-white rounded-md w-96 h-24 px-4 text-green-900 text-14 flex items-center shadow-filter dark:bg-gray-900 dark:text-gray-100`}
                >
                  {text}
                </div>
              </Popover.Panel>
            </Transition>
          )}
        </>
      )}
    </Popover>
  );
}
