import React from "react";

export default function EndTutorial() {
  return (
    <div className="flex flex-col items-center">
      <img src="/icons/tutorial/end_tutorial.svg" alt="end_tutorial" />
      <p className="text-green-900 text-16 text-center mt-8">
        if you have any questions, make sure to check out our FAQ page and try
        the Chat on our Website.
      </p>
      <button></button>
    </div>
  );
}
