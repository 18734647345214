import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, ReactElement, useContext } from "react";

import { ThemeContext } from "../App";

type DialogProps = {
  isOpen: boolean;
  title: string;
  titleSize?: number;
  description: ReactElement;
  onClose: () => void;
  centerTitle?: boolean;
  maxWidth?: string;
  header?: boolean;
  closeButton?: boolean;
  positionStyle?: string;
  pointer?: {
    className: string;
    style: React.CSSProperties;
  };
};

export default function BnDialog({
  isOpen,
  title,
  titleSize = 16,
  description,
  onClose,
  maxWidth = "max-w-lg",
  centerTitle,
  header = true,
  closeButton = true,
  positionStyle,
  pointer,
}: DialogProps) {
  const theme = useContext(ThemeContext);
  return (
    <Transition
      appear
      show={isOpen}
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Dialog
        as="div"
        className="fixed inset-0 z-10 flex flex-col justify-center items-center"
        onClose={onClose}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div
          className={`${maxWidth} w-full bg-white rounded-2xl py-9 px-2 z-10 dark:bg-gray-950 dark:shadow-dark ${
            positionStyle ? positionStyle : "relative"
          }`}
        >
          {pointer && (
            <div className={pointer.className} style={pointer.style} />
          )}
          {header && (
            <Dialog.Title
              className={`text-16 font-bold text-green-800 flex items-center ${
                centerTitle && "justify-center"
              } px-8 dark:text-gray-300 text-${titleSize}`}
            >
              {title}
              {closeButton && (
                <img
                  src={`/icons/${theme}/close.svg`}
                  alt="close"
                  className="cursor-pointer w-4 h-4 absolute top-6 right-6"
                  onClick={onClose}
                />
              )}
            </Dialog.Title>
          )}
          <Dialog.Description
            as="div"
            className={`flex flex-col gap-4 overflow-y-auto px-8 ${
              theme === "dark" ? "custom-scroll-dark" : "custom-scroll"
            }  ${header && " mt-9"}`}
            style={{ maxHeight: "450px" }}
          >
            {description}
          </Dialog.Description>
        </div>
      </Dialog>
    </Transition>
  );
}
