const businessEmails = ["basenode.io"];

const singleEmails = [
  "vires.in.numeris.tah@gmail.com",
  "oshiegbujoel@gmail.com",
  "olisch9021@gmail.com",
];

export function isBusiness(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) return false;

  // TODO: This is a temporary fix till when email signing is available...
  if (singleEmails.includes(email.toLowerCase())) {
    return true;
  }

  const domain = email.split("@")[1];
  return businessEmails.includes(domain);
}
