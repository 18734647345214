import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { ThemeContext } from "../App";
import BnDialog from "./BnDialog";
import WalletForm from "./forms/WalletForm";

export default function NoWalletInfo() {
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function refresh() {
    setIsDialogOpen(false);
    history.push("/wallets");
  }

  return (
    <>
      <div className="flex flex-col items-center mt-28">
        <p className="text-green-900 text-24 font-bold dark:text-gray-300">
          Add your first wallet to get started!
        </p>
        <img src={`/icons/${theme}/wallet.svg`} alt="wallet" />
        <div
          className="primary-button flex items-center cursor-pointer"
          onClick={() => setIsDialogOpen(true)}
        >
          {/* Dark on purpose */}
          <img src="/icons/dark/plus.svg" alt="add" className="pl-10 mr-2" />
          <p className="text-16 pr-10 capitalize">Add wallet</p>
        </div>
      </div>
      <BnDialog
        isOpen={isDialogOpen}
        title="Add Wallet"
        centerTitle
        description={<WalletForm onSubmit={refresh} />}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
}
