import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { checkAddress, loginWithAddress, logout } from "../api";
import { UserContext } from "../App";
import Loader from "../components/Loader";
import { showSnackbar } from "../components/Snackbar";
import { publicRoutes } from "../routes";

export default function AddressFromURLPage() {
  const history = useHistory();
  const location = useLocation();
  const { user, setUser } = useContext(UserContext);
  const [loggerInfo, setLoggerInfo] = useState("Verifying address");
  const disabled = publicRoutes.some(
    (route) => location.pathname === route.path,
  );

  useEffect(() => {
    if (!disabled) {
      checkLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (disabled) {
    return <p></p>;
  }

  const address = location.pathname.slice(1);

  async function checkLocation() {
    try {
      await checkAddress(address, "ALL");
      if (user) {
        try {
          await logout();
        } catch (e) {
          console.error(e);
        }
      }
      login();
    } catch (e) {
      showSnackbar("Provided address is invalid");
      history.push("not-found");
    }
  }

  async function login() {
    try {
      setLoggerInfo("Fetching address data");
      const response = await loginWithAddress(address);
      setUser({ ...response, full_access: false, has_wallet: true });
      history.push("overview");
    } catch (e) {
      if (e.response.status === 400) {
        showSnackbar(e.response.data.address.join(", "));
      }
      history.push("auth");
    }
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <Loader />
      <p className="text-12 font-bold mt-2">{loggerInfo}</p>
    </div>
  );
}
