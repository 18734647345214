import { createContext } from "react";

const SortDocumentPageParameter = createContext<{
  sortBy: string;
  setDocumentSortPrameter: (sortBy: string) => void;
}>({
  sortBy: "total_amount",
  setDocumentSortPrameter: () => {},
});

export default SortDocumentPageParameter;
