import React, { useContext, useState } from "react";
import { useQuery } from "react-query";

import { getFTCAnalytics } from "../../api";
import { ThemeContext } from "../../App";
import { formatAmount } from "../../utils";
import Loader from "../Loader";

const supportedDays = [
  {
    name: "All Time",
    days: undefined,
  },
  {
    name: "Last 30 days",
    days: 30,
  },
];

export default function FTCStatistics() {
  const theme = useContext(ThemeContext);
  const [selectedDays, setSelectedDays] = useState(supportedDays[0]);
  const [isDaysOpen, setIsDaysOpen] = useState(false);

  const { data: ftcAnalytics } = useQuery(["ftc-analytics", selectedDays], () =>
    getFTCAnalytics(selectedDays.days),
  );

  return (
    <section className="w-full mlg:w-1/2 px-8 py-9 mlg:pr-1 lg:pr-9 overview-section relative text-green-800 dark:text-gray-300">
      <h2 className="overview-h2">FTC Usage (Unblock)</h2>
      <div className="flex">
        <div className="w-36 p-4 mb-10 top-5 absolute right-10 border bg-white border-gray-450 dark:bg-gray-950 rounded-md focus:outline-none">
          <div className="flex justify-between text-14">
            <p>{selectedDays.name}</p>
            <img
              className="w-5 relative left-2 cursor-pointer"
              src={
                isDaysOpen
                  ? `/icons/${theme}/checkbox/check.svg`
                  : `/icons/${theme}/arrows/expand_down_light.svg`
              }
              alt="dropdown"
              onClick={() => setIsDaysOpen((prev) => !prev)}
            />
          </div>
          {isDaysOpen && (
            <div
              className={`overflow-y-auto overflow-x-hidden max-h-60 ${
                theme === "dark" ? "custom-scroll-dark" : "custom-scroll"
              }`}
            >
              {supportedDays.map((days) => (
                <div
                  className="cursor-pointer text-14"
                  key={days.name}
                  onClick={() => {
                    setSelectedDays(days);
                    setIsDaysOpen(false);
                  }}
                >
                  <p>{days.name}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {ftcAnalytics ? (
        <div className="flex justify-between mt-10 w-full">
          <div className="space-y-6">
            <p>Users with successful KYC</p>
            <p>Number of FTC Invoices Created</p>
            <p>Completed Payment Volume</p>
            <p>Outstanding Payment Volume</p>
          </div>
          <div
            className={`flex justify-between mr-10 ${
              selectedDays.days ? "w-48" : "w-28"
            }`}
          >
            <div className="text-center space-y-6">
              <p>{ftcAnalytics.num_kyc_full_users}</p>
              <p>{ftcAnalytics.num_ftc_invoices}</p>
              <p>€ {formatAmount(ftcAnalytics.completed_payment_volume, 2)}</p>
              <p>
                € {formatAmount(ftcAnalytics.outstanding_payment_volume, 2)}
              </p>
            </div>
            {selectedDays.days && (
              <div className="space-y-6">
                <PercentageDifference
                  data={ftcAnalytics.num_kyc_full_users_diff_pct!}
                />
                <PercentageDifference
                  data={ftcAnalytics.num_ftc_invoices_diff_pct!}
                />
                <PercentageDifference
                  data={ftcAnalytics.completed_payment_volume_diff_pct!}
                />
                <PercentageDifference
                  data={ftcAnalytics.outstanding_payment_volume_diff_pct!}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </section>
  );
}

type PercentageDifferenceProps = {
  data: number;
};

function PercentageDifference({ data }: PercentageDifferenceProps) {
  return (
    <div
      className={`flex flex-row text-16 font-normal ml-5 mr-2 pb-1
        ${data > 0 ? " text-green-400" : "text-red-500"}`}
    >
      <img
        className="w-3 mr-2"
        src={
          data > 0
            ? "/icons/light/arrows/arrow_up_filled.svg"
            : "/icons/light/arrows/arrow_down_filled.svg"
        }
        alt="percent"
      />
      <p>{Math.abs(data)}%</p>
    </div>
  );
}
